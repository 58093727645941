import React, { Component, Fragment } from 'react';
import { Divider, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { EMPTY_STRING_VALUE, formatKeys } from '../../lib/utilities';
import TabList from './TabList';
import PoweroffHistoryTable from './PoweroffHistoryTable';

export class GatewayIdFormatter extends Component {
  render() {
    return <span>{formatKeys(this.props.id || EMPTY_STRING_VALUE)}</span>;
  }
}

GatewayIdFormatter.propTypes = {
  id: PropTypes.string,
};

class PoweroffHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gateway: {}
    };
  }

  componentDidMount() {

    const { gid } = this.props.match.params;

    this.setState({
      gid,
      gateway: {},
    });

  }

  render() {
    const { gid, gateway } = this.state;

    return (
      <Fragment>
        <Header as='h1' floated='left'>
          Power failure hisotry {gid}
        </Header>

        <TabList gateway={gateway} gid={gid} />

        <Divider clearing />

        <div className='ui four column grid' style={{ 'marginRight': '10px' }}>

          <div className='row'>
            <div className='sixteen wide column'>
              <PoweroffHistoryTable gid={this.state.gid} />
            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}

PoweroffHistory.propTypes = {
  match: PropTypes.object,
};

export default PoweroffHistory;
