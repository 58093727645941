/** @format */

import { combineReducers } from 'redux';
import { accesstoken, accesstokens } from './accesstoken';
import { application } from './application';
import {
  applicationDevices,
  applicationDevicesMap,
} from './applicationDevices';
import {
  applications,
  applicationsSharedBy,
  applicationsSharedWith,
  applicationsOptions,
} from './applications';
import { applicationShare } from './applicationShare';
import { basicstationConfigurations } from './basicstationConfigurations';
import { updatePassword } from './updatePassword';
import { signin } from './auth';
import { device } from './device';
import { deviceManager } from './deviceManager';
import { deviceShare } from './deviceShare';
import { devicePosition } from './devicePosition';
import { devices, devicesSharedBy, devicesSharedWith } from './devices';
import { deviceProfilesOptions, deviceProfiles } from './deviceProfiles';
import { downlink } from './dowlink';
import { downlinks } from './downlinks';
import { gateway } from './gateway';
import { gatewayProfile } from './gatewayProfile';
import {
  gatewayProfileGateways,
  gatewayProfileGatewaysMap,
} from './gatewayProfileGateways';
import { gatewayProfiles, gatewayProfilesOptions } from './gatewayProfiles';
import { gatewayShare } from './gatewayShare';
import { gateways, gatewaysSharedBy, gatewaysSharedWith } from './gateways';
import { me } from './me';
import { multicastGroup } from './multicastGroup';
import {
  multicastGroupDevices,
  multicastGroupDevicesMap,
} from './multicastGroupDevices';
import { multicastGroups, multicastGroupsOptions } from './multicastGroups';
import { uplinks } from './uplinks';
import { user, userStats } from './user';
import { users, usersOptions } from './users';
import userProfilesOptions from './userProfilesOptions';
import { userProfileSingle } from './userProfileSingle';
import { userProfile } from './userProfile';
import { userProfilesList } from './userProfileList';
import { tabs } from './tabs';

const rootReducer = combineReducers({
  accesstoken,
  accesstokens,
  application,
  applicationDevices,
  applicationDevicesMap,
  applications,
  applicationsSharedBy,
  applicationsSharedWith,
  applicationShare,
  applicationsOptions,
  basicstationConfigurations,
  updatePassword,
  device,
  deviceManager,
  deviceProfilesOptions,
  deviceProfiles,
  devicePosition,
  devices,
  deviceShare,
  devicesSharedBy,
  devicesSharedWith,
  downlink,
  downlinks,
  gateway,
  gatewayProfile,
  gatewayProfileGateways,
  gatewayProfileGatewaysMap,
  gatewayProfiles,
  gatewayProfilesOptions,
  gatewayShare,
  gateways,
  gatewaysSharedBy,
  gatewaysSharedWith,
  me,
  multicastGroup,
  multicastGroupDevices,
  multicastGroupDevicesMap,
  multicastGroups,
  multicastGroupsOptions,
  signin,
  uplinks,
  user,
  userProfilesOptions,
  userStats,
  users,
  usersOptions,
  userProfileSingle,
  userProfile,
  userProfilesList,
  tabs,
});

export default rootReducer;
