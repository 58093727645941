/** @format */

import Axios from 'axios/index';
import idx from 'idx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Pagination, Table } from 'semantic-ui-react';
import {
  deleteApplicationDevices,
  destroyApplicationDevices,
  fetchApplicationDevices,
} from '../../actions/applicationDevices';
import { getCurrentUserToken } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import {
  EMPTY_STRING_VALUE,
  formatKeys,
  searchMethod,
} from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  applicationDevices: state.applicationDevices,
});

const mapDispatchToProps = dispatch => ({
  fetchApplicationDevices: (params = {}) => {
    dispatch(fetchApplicationDevices(params));
  },
  deleteApplicationDevices: (application, device) => {
    dispatch(deleteApplicationDevices(application, device));
  },
  destroyApplicationDevices: () => {
    dispatch(destroyApplicationDevices());
  },
});

class DvTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      offset: 0,
      limit: 12,
      searchModel: {},
      sortModel: {
        label: '',
        direction: null /* asc || desc || null */,
      },
      modalOpen: false,
      device: null,
      deveui: null,
      check: null,
      application: {},
      direction: null,
      column: null,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams ? queryParams.join('&') : '';
        this.props.fetchApplicationDevices({ qs });
      },

      handlePageChange: (e, { activePage }) => {
        const { applicationDevices, offset } = this.props;
        const { searchModel, sortModel, limit } = this.state;

        const newOffset = (activePage - 1) * applicationDevices.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );

        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        // @TODO: FIX ESLINT
        // eslint-disable-next-line no-nested-ternary
        const currSortModel = newSortModel
          ? { ...newSortModel }
          : sortModel.label
          ? { ...sortModel }
          : null;

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        // debugger
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);
      },

      handleSearchChange: (e, { name, value }) => {
        const { searchModel } = this.state;
        const newSearchModel = { ...searchModel };
        if (name === 'expiry') {
          newSearchModel[name] = encodeURIComponent(
            moment(value).toISOString(),
          );
        } else if (value === '') {
          delete newSearchModel[name];
        } else {
          newSearchModel[name] = encodeURIComponent(value);
        }
        this.setState({ searchModel: newSearchModel });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;

            case 'desc':
              direction = null;
              break;

            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    this.axiosFetcApplication(this.props.match.params.aid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.applicationDevices !== nextProps.applicationDevices) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.props.destroyApplicationDevices();
  }

  axiosFetcApplication(application) {
    const { limit } = this.state;

    Axios.get(url.Application(application), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        const tempSearchModel = {
          appid: `${res.data.appid}-${res.data.UserId}`,
        };
        this.props.fetchApplicationDevices({
          qs: `appid=${res.data.appid}&uid=${res.data.UserId}${
            limit > 0 ? `&limit=${limit}` : ''
          }`,
        });
        this.setState({ application: res.data, searchModel: tempSearchModel });
      })
      .catch(err => devLog(err));
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      device: null,
      deveui: null,
      check: null,
    });
  }

  handleDeleteModal(device) {
    this.setState({ modalOpen: true, device, deveui: device.deveui });
  }

  handleDelete() {
    const { device } = this.state;

    this.props.deleteApplicationDevices(this.props.application.id, device.id);
    this.props.fetchApplicationDevices(this.props.application.id);
    this.setState({
      modalOpen: false,
      device: null,
      deveui: null,
      check: null,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { applicationDevices } = this.props;

    return (
      <Fragment>
        
          <Table sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('label')}
                  onClick={this.searchBox.handleSort('label')}
                >
                  Label
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('deveui')}
                  onClick={this.searchBox.handleSort('deveui')}
                >
                  DevEui
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('devaddr')}
                  onClick={this.searchBox.handleSort('devaddr')}
                >
                  Dev Address
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('type')}
                  onClick={this.searchBox.handleSort('type')}
                >
                  Type
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {applicationDevices.rows && applicationDevices.rows.length > 0 ? (
                idx(applicationDevices, accessory =>
                  accessory.rows.map(row => (
                    <Table.Row key={`row-${row.id}`}>
                      <Table.Cell key={`row-${row.id}-label`}>
                        <Link to={`${PAGES_PATH}/device/${idx(row, _ => _.id)}`}>
                          <strong>
                            {idx(row, _ => _.label) || EMPTY_STRING_VALUE}
                          </strong>
                        </Link>
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-deveui`}>
                        {idx(row, _ => formatKeys(_.deveui)) ||
                          EMPTY_STRING_VALUE}
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-devaddr`}>
                        {idx(row, _ => formatKeys(_.devaddr)) ||
                          EMPTY_STRING_VALUE}
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-type`}>
                        {idx(row, _ => _.type) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                    </Table.Row>
                  )),
                )
              ) : (
                <Table.Row key='no-data-row'>
                  <Table.Cell  key='no-data-cell'>
                    No devices for this application
                  </Table.Cell>
                </Table.Row>
                
              )}
            </Table.Body>
          </Table>

        <Container textAlign="center">
          {applicationDevices &&
            applicationDevices.size > applicationDevices.limit && (
              <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={
                  parseInt(
                    applicationDevices.offset / applicationDevices.limit,
                    10,
                  ) + 1
                }
                totalPages={Math.ceil(
                  applicationDevices.size / applicationDevices.limit,
                )}
                onPageChange={this.searchBox.handlePageChange}
              />
            )}
        </Container>
      </Fragment>
    );
  }
}

DvTable.propTypes = {
  application: PropTypes.object,
  match: PropTypes.object,
  applicationDevices: PropTypes.object,
  deleteApplicationDevices: PropTypes.func,
  destroyApplicationDevices: PropTypes.func,
  fetchApplicationDevices: PropTypes.func,
  offset: PropTypes.number,
};

const ApplicationDevicesTable = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvTable),
);

export { ApplicationDevicesTable };
