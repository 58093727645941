/** @format */

import * as actions from '../actions/device';

const defaultState = {
  isLoading: false,
  error: false,
};

export const device = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_DEVICE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_DEVICE_SUCCESS:
      return {
        device: {
          ...action.payload,
        },
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.POST_DEVICE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.POST_DEVICE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.POST_DEVICE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.PUT_DEVICE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.PUT_DEVICE_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };
    case actions.PUT_DEVICE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DELETE_DEVICE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.DELETE_DEVICE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.DELETE_DEVICE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_DEVICE_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
