/** @format */

import Axios from 'axios/index';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import update from 'react-addons-update';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Header, Input, Label, Modal } from 'semantic-ui-react';
import { destroyApplicationsSharedWith } from '../../actions/applications';
import { deleteApplicationShare } from '../../actions/applicationShare';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import DefaultTable from '../../components/Utils/DefaultTable';
import { getCurrentUserToken, userHasRole } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  applications: state.applications,
  column: state.devices.column,
  direction: state.devices.direction,
  users: state.users,
  usersOptions: state.usersOptions.options || [],
  devices: state.devices,
});

const mapDispatchToProps = dispatch => ({
  deleteApplicationShare: application => {
    dispatch(deleteApplicationShare(application));
  },
  destroyApplicationsSharedWith: () => {
    dispatch(destroyApplicationsSharedWith());
  },
  destroyApplicationSharedWith: () => {
    dispatch(destroyApplicationsSharedWith());
  },
  fetchUsers: (username, params = { qs: '' }) => {
    dispatch(fetchUsers(username, params));
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
});

class ApSTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [],
      modalOpen: false,
      share: null,
      label: null,
      check: null,
      applicationShared: [],
      numberOfDeviceForApplication: {},
      search: '',
      sortModel: {
        label: '',
        direction: null /* asc || desc || null */,
      },
      users: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    const { limit } = this.state;
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
    }
    if (userHasRole('admin')) {
      this.props.fetchUsers({ qs: 'limit=10000' });
    }
    this.getApplicationsSharedWith({ qs: limit > 0 ? `limit=${limit}` : '' });
    this.manageVisibleFilters();
  }

  // componentWillUnmount() {
  //   this.props.destroyApplicationsSharedWith();
  // }

  manageVisibleFilters() {
    const nextFormGroup = [
      {
        width: 3,
        label: 'Label',
        name: 'label',
        type: 'text',
      },
    ];
    if (userHasRole('admin')) {
      nextFormGroup.push({
        width: 4,
        label: 'Shared With',
        name: 'guestid',
        type: 'select',
      });
    }
    this.setState({
      ...this.state,
      formGroup: nextFormGroup,
    });
  }

  fetch(qs) {
    this.getApplicationsSharedWith(qs);
  }

  getApplicationsSharedWith(params) {
    Axios.get(`${url.ApplicationsSharedWith()}?${params.qs}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        const apps = res.data;
        this.setState({ applicationShared: res.data });
        this.getDevices(apps);
      })
      .catch(err => devLog(err));
  }

  getDevices(applications) {
    if (userHasRole('admin')) {
      applications.rows.forEach((app, index) => {
        Axios.get(
          url.DeviceSharedForApplicationAdm(
            app.application.appid,
            app.application.UserId,
          ),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getCurrentUserToken(),
            },
          },
        )
          .then(res =>
            this.setState(
              update(this.state, {
                numberOfDeviceForApplication: {
                  [index]: {
                    $set: res.data.size,
                  },
                },
              }),
            ),
          )
          .catch(error => devLog(error));
      });
    } else {
      applications.rows.forEach((app, index) => {
        Axios.get(url.DeviceSharedForApplicationStd(app.application.appid), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCurrentUserToken(),
          },
        })
          .then(res =>
            this.setState(
              update(this.state, {
                numberOfDeviceForApplication: {
                  [index]: {
                    $set: res.data.size,
                  },
                },
              }),
            ),
          )
          .catch(error => devLog(error));
      });
    }
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      share: null,
      label: null,
      check: null,
    });
  }

  handleDeleteModal(share) {
    this.setState({ modalOpen: true, share, label: share.label });
  }

  handleDelete() {
    const { share, limit } = this.state;
    this.props.deleteApplicationShare(share.id);
    this.setState({
      modalOpen: false,
      share: null,
      label: null,
      check: null,
    });
    setTimeout(() => {
      this.getApplicationsSharedWith({ qs: limit > 0 ? `limit=${limit}` : '' });
    }, 300);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const {
      label,
      applicationShared,
      check,
      formGroup,
      share,
      numberOfDeviceForApplication,
    } = this.state;
    const { users, usersOptions } = this.props;

    return (
      <Fragment>
        <DefaultTable
          headers={[
            { name: 'Appid', key: 'appid' },
            {
              sortable: true,
              name: 'Label',
              sortBy: 'label',
              key: 'label',
            },
            { name: 'Sharing', key: 'sharing' },
            { name: 'Devices', key: 'devices' },
            { name: 'Action', key: 'remove' },
          ]}
          path={'application'}
          typeOfSharing={'With'}
          tableType={'sharing'}
          users={users}
          formGroup={formGroup}
          deviceForApplication={numberOfDeviceForApplication}
          usersOptions={usersOptions}
          handleDeleteModal={this.handleDeleteModal}
          fetch={this.fetch}
          items={applicationShared}
        />

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will remove a share for{' '}
              {idx(share, _ => _.guest.username)}
            </h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the application label</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {idx(share, _ => _.label)}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={label !== check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

ApSTable.propTypes = {
  fetchapplicationsSharedWith: PropTypes.func,
  destroyApplicationsSharedWith: PropTypes.func,
  destroyDeviceShare: PropTypes.func,
  deleteApplicationShare: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  fetchUsers: PropTypes.func,
  users: PropTypes.array,
  usersOptions: PropTypes.array,
};

const ApplicationsTableSharedWith = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApSTable),
);

export { ApplicationsTableSharedWith };
