import React, { Component } from 'react';
import { getCurrentUserId, userHasRole } from '../../lib/auth';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ENABLE_RNC, PAGES_PATH } from '../../lib/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

class TabList extends Component {
  render() {
    const { gateway, gid } = this.props;
    return <div>
      {
        (userHasRole('admin') || gateway.UserId === getCurrentUserId()) && (
          <Button
            as={Link}
            key='edit'
            compact
            icon
            color='red'
            labelPosition='left'
            floated='right'
            style={{ marginTop: '2.4em' }}
            to={`${PAGES_PATH}/gateway/${gid}/edit`}
          >
            <FontAwesomeIcon
              icon='edit'
              className='icon'
              style={{ padding: '0.4em' }}
            />
            Edit
          </Button>
        )
      }

      {<Button
        as={Link}
        key='addShare'
        compact
        icon
        color='red'
        labelPosition='left'
        floated='right'
        style={{ marginTop: '2.4em' }}
        to={{
          pathname: `${PAGES_PATH}/gateway/share`,
          state: { gateways: [gid], UserId: gateway.UserId },
        }}
      >
        <FontAwesomeIcon
          icon='share-alt'
          className='icon'
          style={{ padding: '0.4em' }}
        />
        Share
      </Button>
      }

      <Button
        as={Link}
        key='list'
        compact
        icon
        color='red'
        labelPosition='left'
        floated='right'
        style={{ marginTop: '2.4em' }}
        to={`${PAGES_PATH}/gateways`}
      >
        <FontAwesomeIcon
          icon='list'
          className='icon'
          style={{ padding: '0.4em' }}
        />
        List
      </Button>

      { ENABLE_RNC ?
        <Button
          as={Link}
          key='spectral-scann'
          compact
          icon
          color='red'
          labelPosition='left'
          floated='right'
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/gateway/${gid}/spectral-scann`}
        >
          <FontAwesomeIcon
            icon='list'
            className='icon'
            style={{ padding: '0.4em' }}
          />
          Spectral scann
        </Button> : <span/>
      }
    </div>
  }
}

TabList.propTypes = {
  gateway: PropTypes.object,
  gid: PropTypes.any,
};

export default TabList;
