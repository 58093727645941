/** @format */

import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { Card, Divider, Grid, Header, Table } from 'semantic-ui-react';
import { userHasRole } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import { toastError } from '../../lib/utilities';
import { METRIC_URL, ENABLE_GATEWAY_STATS_INFO } from '../../lib/variables';
import { axiosCall } from './axiosService';
import { Donut } from './Doughnut';
import './Statistics.css';
import StatsPlaceholder from './StatPLaceholder';
import Timeseries from './Timeseries';
import { Top } from './Top';

// Table.Row w/ <Nothing To Show> row, aka empty row
const nothingToShow = () => (
  <Table.Row>
    <Table.Cell style={{ textAlign: 'center' }}>Nothing To Show</Table.Cell>
  </Table.Row>
);

function Metrics(props) {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(false);

  // Table.Row w/ the events
  const eventsList = () =>
    events.map(d => {
      let { key } = d;
      if (key === 'uplink') {
        key = 'gateway uplink';
      }
      if (key === 'downlink') {
        key = 'gateway downlink';
      }
      const keyUppercase = key.charAt(0).toUpperCase() + key.slice(1);
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <b>{keyUppercase}</b>
          </Table.Cell>
          <Table.Cell style={{ textAlign: 'right', paddingRight: '5rem' }}>
            {d.doc_count}
          </Table.Cell>
        </Table.Row>
      );
    });

  useEffect(() => {
    axiosCall(url.StatsEvents(), 'get')
      .then(response => {
        if (response && 'data' in response && 'rows' in response.data) {
          setEvents(response.data.rows);
        }
      })
      .catch(() => {
        setError(true);
        props.errorhandler();
      });
  }, []);

  return (
    <>
      {error !== true ? (
        <Table unstackable basic="very">
          <Table.Body>
            {events.length > 0 ? eventsList() : nothingToShow()}
          </Table.Body>
        </Table>
      ) : (
        <Card.Description>
          <StatsPlaceholder />
        </Card.Description>
      )}
    </>
  );
}

Metrics.propTypes = {
  error: PropTypes.string,
  events: PropTypes.array,
  errorhandler: PropTypes.func.isRequired,
};

function Rookies(props) {
  const [rookies, setRookies] = useState([]);
  const [error, setError] = useState(false);

  // Set if Table.Row of rookies info
  const rookiesList = () =>
    rookies.map(d => (
      <Table.Row key={d.id}>
        <Table.Cell>{d.id}</Table.Cell>
        <Table.Cell style={{ textAlign: 'right', paddingRight: '5rem' }}>
          {d.uptime}
        </Table.Cell>
      </Table.Row>
    ));

  // Set if Table.Header for the rookies table
  const rookiesHeader = () => (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Gateway ID</Table.HeaderCell>
        <Table.HeaderCell style={{ textAlign: 'right', paddingRight: '5rem' }}>
          Uptime
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  // Retrieve the info for rookies table using API REST
  useEffect(() => {
    axiosCall(url.StatsRookies(), 'get')
      .then(response => {
        if (response && 'data' in response && 'rows' in response.data) {
          setRookies(response.data.rows);
        }
      })
      .catch(() => {
        setError(true);
        props.errorhandler();
      });
  }, []);

  return (
    <>
      {error !== true ? (
        <Table unstackable basic="very">
          {rookies.length > 0 ? rookiesHeader() : null}
          <Table.Body>
            {rookies.length > 0 ? rookiesList() : nothingToShow()}
          </Table.Body>
        </Table>
      ) : (
        <Card.Description>
          <StatsPlaceholder />
        </Card.Description>
      )}
    </>
  );
}

Rookies.propTypes = {
  error: PropTypes.string,
  rookies: PropTypes.string,
  errorhandler: PropTypes.func.isRequired,
};

const urlList = [
  { url: `${METRIC_URL}/events/ls/histogram`, name: 'linkstatus' },
  { url: `${METRIC_URL}/alerts/ul/dup`, name: 'dupuplink' },
  { url: `${METRIC_URL}/alerts/ul/dc`, name: 'dutycycle' },
  { url: `${METRIC_URL}/events/join/histogram`, name: 'join' },
  { url: `${METRIC_URL}/alerts/ul/tx-delayed`, name: 'txdelayed' },
  { url: `${METRIC_URL}/alerts/ul/tx-failed`, name: 'txfailed' },
  { url: `${METRIC_URL}/events/ul/histogram`, name: 'uplink' },
  { url: `${METRIC_URL}/events/dl/histogram`, name: 'downlinks' },
];

const utilityFormatArray = hoursPerDay => {
  const time = [];
  let formattedTime;
  for (let index = 0; index < hoursPerDay + 1; index += 1) {
    formattedTime = moment().subtract(index, 'hours').format('hA');
    time.unshift(formattedTime);
  }

  return time;
};

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      timeseriesDataLoaded: false,
      fullScreen: 16,
      halfScreen: 8,
      screenCard: 4,
      events: [],
      rookies: [],
      timeseriesData: {
        labels: [],
        lines: [],
      },
      data: [],
      hoursPerDay: 24,
      now: moment().format('MMMM Do YYYY, h a'),
      error: false,
      timeError: false,
      metricsError: false,
    };

    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const { hoursPerDay } = this.state;
    const time = utilityFormatArray(hoursPerDay);
    const timeseriesData = { labels: time, lines: [] };

    if (time.length > 0) {
      const promises = [];

      urlList.forEach((_, index) => {
        timeseriesData.lines.push({
          name: _.name,
          data: Array(25).fill(0),
        });
        const promise = this.getTimeSeriesData(index, _, timeseriesData);
        promises.push(promise);
      });

      Promise.all(promises)
        .then(_ => this.setState({ timeseriesData }))
        .catch(err => this.setState({ timeError: true }));
    } else this.setState({ timeseriesData });
  }

  getTimeSeriesData = async (index, endPoint, timeseriesData) => {
    const { now } = this.state;
    const res = await axiosCall(endPoint.url, 'get');
    const { rows } = res.data;

    rows.forEach(d => {
      const hour = moment.utc(d.key_as_string).format('hA');
      const day = moment.utc(d.key_as_string).format('MMMM Do YYYY, h a');
      const indexTime = elm => elm === hour;
      const urlIndex =
        day === now ? 24 : timeseriesData.labels.findIndex(indexTime);
      timeseriesData.lines[index].data[urlIndex] = d.doc_count;
    });
  };

  handleError() {
    // manage the error tooltip (open one toast for n error with n > 0)
    const { error } = this.state;
    if (!error) {
      // FIRST TIME CALLING ERROR
      this.setState({ error: true });
      toastError('Ops... Something went wrong!');
    }
  }

  render() {
    const {
      fullScreen,
      halfScreen,
      screenCard,
      timeseriesData,
      timeError,
    } = this.state;
    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }}>
          Stat
        </Header>
        <Divider clearing style={{ marginTop: '0em' }} />
        <Grid columns="equal">
          <Grid.Row
            centered
            mobile={fullScreen}
            tablet={fullScreen}
            computer={fullScreen}
          >
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header
                    style={{ textAlign: 'center', color: '#666666' }}
                  >
                    Metrics [last 24h]
                  </Card.Header>
                  <Metrics errorhandler={this.handleError} />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          {userHasRole('admin') ? null : (
            ENABLE_GATEWAY_STATS_INFO && (
              <Grid.Row
                centered
                mobile={fullScreen}
                tablet={fullScreen}
                computer={fullScreen}
              >
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header
                        style={{ textAlign: 'center', color: '#666666' }}
                      >
                        Recently Activated Gateways
                      </Card.Header>
                      <Rookies errorhandler={this.handleError} />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            )
          )}
          <Grid.Row centered>
            <Grid.Column
              mobile={fullScreen}
              tablet={halfScreen}
              computer={halfScreen}
              largeScreen={screenCard}
              widescreen={screenCard}
            >
              <Donut
                errorhandler={this.handleError}
                title="Uplink RSSI [last 24h]"
                url={url.StatsUplinkRSSIPie()}
              />
            </Grid.Column>
            <Grid.Column
              mobile={fullScreen}
              tablet={halfScreen}
              computer={halfScreen}
              largeScreen={screenCard}
              widescreen={screenCard}
            >
              <Donut
                errorhandler={this.handleError}
                title="Uplink Frequencies [last 24h]"
                url={url.StatsUplinkFreqPie()}
              />
            </Grid.Column>
            <Grid.Column
              mobile={fullScreen}
              tablet={halfScreen}
              computer={halfScreen}
              largeScreen={screenCard}
              widescreen={screenCard}
            >
              <Donut
                errorhandler={this.handleError}
                title="Uplink Spreading Factor [last 24h]"
                url={url.StatsUplinkSfPie()}
              />
            </Grid.Column>
            <Grid.Column
              mobile={fullScreen}
              tablet={halfScreen}
              computer={halfScreen}
              largeScreen={screenCard}
              widescreen={screenCard}
            >
              <Donut
                errorhandler={this.handleError}
                title="Uplink Alerts [last 24h]"
                url={url.StatsUplinkAlertsPie()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Timeseries
                labels={timeseriesData.labels}
                lines={timeseriesData.lines}
                hasError={timeError}
              />
            </Grid.Column>
          </Grid.Row>
          {userHasRole('admin') ? (
            <Grid.Row centered>
              <Grid.Column
                mobile={fullScreen}
                tablet={fullScreen}
                computer={halfScreen}
              >
                <Top
                  errorhandler={this.handleError}
                  title="Top 10 of Devices by Upstream Messages [last 24h]"
                  url={url.StatsTop10DeviceUplink()}
                />
              </Grid.Column>
              <Grid.Column
                mobile={fullScreen}
                tablet={fullScreen}
                computer={halfScreen}
              >
                <Top
                  errorhandler={this.handleError}
                  title="Top 10 of Users by Upstream Messages [last 24h]"
                  url={url.StatsTop10UserUplink()}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row centered>
              <Grid.Column
                mobile={fullScreen}
                tablet={fullScreen}
                computer={fullScreen}
              >
                <Top
                  errorhandler={this.handleError}
                  title="Top 10 of Devices by Upstream Messages [last 24h]"
                  url={url.StatsTop10DeviceUplink()}
                />
              </Grid.Column>{' '}
            </Grid.Row>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export { Statistics };
