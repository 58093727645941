/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken, userHasRole } from '../lib/auth';
import history from '../history';
import { PAGES_PATH } from '../lib/variables';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const CHANGE_PASSWORD_REQUEST = 'changePassword/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'changePassword/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'changePassword/CHANGE_PASSWORD_FAILURE';

export function putUpdatePassword(password, uid) {
  return {
    [CALL_API]: {
      endpoint: url.UserUpdatePassword(uid),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      body: JSON.stringify({
        password,
      }),
      types: [
        CHANGE_PASSWORD_REQUEST,
        {
          type: CHANGE_PASSWORD_SUCCESS,
          payload: (action, state, res) => {
            if (userHasRole('admin')) {
              history.push(`${PAGES_PATH}/users`);
            } else {
              history.push(`${PAGES_PATH}/profile`);
            }
            return res.json().then(json => json);
          },
        },
        {
          type: CHANGE_PASSWORD_FAILURE,
          payload: (action, state, res) =>
            res.json().then(err => toastError(err)),
        },
      ],
    },
  };
}
