/** @format */

import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Divider, Form, Header } from 'semantic-ui-react';
import {
  destroyApplicationShare,
  postApplicationShare,
} from '../../actions/applicationShare';
import { destroyUsersOptions, fetchUsersOptions } from '../../actions/users';
import { userHasRole } from '../../lib/auth';

const mapStateToProps = state => ({
  applicationShare: state.applicationShare,
  usersOptions: state.usersOptions,
});

const mapDispatchToProps = dispatch => ({
  fetchUsersOptions: (params = {}) => {
    dispatch(fetchUsersOptions(params));
  },
  postApplicationShare: applications => {
    dispatch(postApplicationShare(applications));
  },
  destroyUsersOptions: () => {
    dispatch(destroyUsersOptions());
  },
  destroyApplicationShare: () => {
    dispatch(destroyApplicationShare());
  },
});

class ApplicationShareAddForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      UsedId: null,
      GuestId: null,
      share: {
        ids: this.props.location.state.applications,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeUserId = this.handleChangeUserId.bind(this);
    this.handleSearchUserIdChange = this.handleSearchUserIdChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
    }
  }

  componentWillUnmount() {
    this.props.destroyUsersOptions();
    this.props.destroyApplicationShare();
  }

  // HANDLER SECTION
  handleChange(e, { name, value }) {
    this.setState({ share: { ...this.state.share, [name]: value } });
  }

  handleCheck(e, { name, checked }) {
    this.setState({ share: { ...this.state.share, [name]: checked } });
  }

  handleChangeUserId(e, { value }) {
    this.setState({
      share: {
        ...this.state.share,
        GuestId: value,
        UserId: this.props.location.state.UserId,
      },
    });
  }

  handleSearchUserIdChange(e, { searchQuery }) {
    const params = { filter: { username: searchQuery } };
    this.props.fetchUsersOptions(params);
  }

  handleSubmit(e) {
    const { share } = this.state;
    e.preventDefault();
    this.props.postApplicationShare(share);
  }

  render() {
    const { share } = this.state;
    const { usersOptions, applicationShare } = this.props;
    const usersOptionsDropdown = [];
    usersOptions.options.forEach(element => {
      usersOptionsDropdown.push({
        key: element.username,
        text: element.username,
        value: element.id,
      });
    });

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Share Application{share.ids.length > 1 ? 's' : ''}
        </Header>
        <Divider clearing />
        <Form onSubmit={this.handleSubmit}>
          {userHasRole('admin') && (
            <Form.Dropdown
              name="GuestId"
              label="Share to"
              placeholder="choose one..."
              width={8}
              selection
              required
              search
              options={usersOptionsDropdown || []}
              // value={idx(share, (_) => _.GuestId)}
              onChange={this.handleChangeUserId}
              onSearchChange={this.handleSearchUserIdChange}
              disabled={idx(usersOptions, _ => _.isFetching)}
              loading={usersOptionsDropdown.length === 0}
            />
          )}

          {!userHasRole('admin') && (
            <Fragment>
              <Form.Input
                width={8}
                label="Guest email"
                name="GuestEmail"
                required
                onChange={this.handleChange}
                error={applicationShare.error}
              />
            </Fragment>
          )}

          <Form.Input
            width={8}
            label="Label"
            name="label"
            required
            onChange={this.handleChange}
          />

          <Form.Button
            content="Save"
            disabled={share === undefined}
            type="submit"
          />
        </Form>
      </Fragment>
    );
  }
}

ApplicationShareAddForm.propTypes = {
  location: PropTypes.object,
  applicationShare: PropTypes.object,
  deviceShare: PropTypes.object,
  usersOptions: PropTypes.object,
  fetchUsersOptions: PropTypes.func,
  destroyUsersOptions: PropTypes.func,
  destroyApplicationShare: PropTypes.func,
  postApplicationShare: PropTypes.func,
};

const ApplicationShareCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationShareAddForm),
);

export { ApplicationShareCreate };
