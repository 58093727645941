/** @format */

const devLog = (...params) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(...params);
  }
};

module.exports = devLog;
