import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';

export const FETCH_USER_ME_REQUEST = 'user_me/FETCH_REQUEST';
export const FETCH_USER_ME_SUCCESS = 'user_me/FETCH_SUCCESS';
export const FETCH_USER_ME_FAILURE = 'user_me/FETCH_FAILURE';

export const fetchUserMe = (token) => ({
  [CALL_API]: {
    endpoint: url.UserMe(),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    types: [
      FETCH_USER_ME_REQUEST,
      {
        type: FETCH_USER_ME_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_USER_ME_FAILURE,
        payload: (action, state, res) => res.json().then(err => err),
      },
    ],
  },
});