/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import idx from 'idx';
import PropTypes from 'prop-types';

import { MapContainer } from '../../components';
import {
  destroyGatewayProfileGatewaysMap,
  fetchGatewayProfileGatewaysMap,
} from '../../actions/gatewayProfileGateways';
import { GOOGLE_MAPS_API_KEY } from '../../lib/variables';

const mapStateToProps = state => ({
  gatewayProfile: state.gatewayProfile,
  gatewayProfileGatewaysMap: state.gatewayProfileGatewaysMap,
});

const mapDispatchToProps = dispatch => ({
  fetchGatewayProfileGatewaysMap: gatewayProfile => {
    dispatch(fetchGatewayProfileGatewaysMap(gatewayProfile));
  },
  destroyGatewayProfileGatewaysMap: () => {
    dispatch(destroyGatewayProfileGatewaysMap());
  },
});

class DvMap extends Component {
  componentDidMount() {
    const { fetchGatewayProfiledMap } = this.props;

    fetchGatewayProfiledMap(this.props.match.params.gpid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (
      this.props.gatewayProfileGatewaysMap !==
      nextProps.gatewayProfileGatewaysMap
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    const { destroyGatewayProfileMap } = this.props;

    destroyGatewayProfileMap();
  }

  render() {
    const { gatewayProfileGatewaysMap } = this.props;

    return (
      <Fragment>
        {idx(gatewayProfileGatewaysMap, _ => _.coord.length) > 0 && (
          <MapContainer
            bounds={idx(gatewayProfileGatewaysMap, _ => _.boundingBox)}
            markers={idx(gatewayProfileGatewaysMap, _ => _.coord)}
            isMarkerShown
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '30vh' }} />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        )}
      </Fragment>
    );
  }
}

DvMap.propTypes = {
  match: PropTypes.object,
  gatewayProfileGatewaysMap: PropTypes.object,
  fetchGatewayProfileGatewaysMap: PropTypes.func,
  destroyGatewayProfileGatewaysMap: PropTypes.func,
  fetchGatewayProfiledMap: PropTypes.func,
  destroyGatewayProfileMap: PropTypes.func,
};

const GatewayProfileGatewaysMap = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvMap),
);

export { GatewayProfileGatewaysMap };
