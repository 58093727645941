/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken, deleteCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';
// ACTION TYPES
export const FETCH_MULTICAST_GROUP_REQUEST = 'multicastGroup/FETCH_REQUEST';
export const FETCH_MULTICAST_GROUP_SUCCESS = 'multicastGroup/FETCH_SUCCESS';
export const FETCH_MULTICAST_GROUP_FAILURE = 'multicastGroup/FETCH_FAILURE';

export const POST_MULTICAST_GROUP_REQUEST = 'multicastGroup/POST_REQUEST';
export const POST_MULTICAST_GROUP_SUCCESS = 'multicastGroup/POST_SUCCESS';
export const POST_MULTICAST_GROUP_FAILURE = 'multicastGroup/POST_FAILURE';

export const PUT_MULTICAST_GROUP_REQUEST = 'multicastGroup/PUT_REQUEST';
export const PUT_MULTICAST_GROUP_SUCCESS = 'multicastGroup/PUT_SUCCESS';
export const PUT_MULTICAST_GROUP_FAILURE = 'multicastGroup/PUT_FAILURE';

export const DELETE_MULTICAST_GROUP_REQUEST = 'multicastGroup/DELETE_REQUEST';
export const DELETE_MULTICAST_GROUP_SUCCESS = 'multicastGroup/DELETE_SUCCESS';
export const DELETE_MULTICAST_GROUP_FAILURE = 'multicastGroup/DELETE_FAILURE';

export const DESTROY_MULTICAST_GROUP_SUCCESS = 'multicastGroup/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchMulticastGroup = multicastGroup => ({
  [CALL_API]: {
    endpoint: url.MulticastGroup(multicastGroup),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_MULTICAST_GROUP_REQUEST,
      {
        type: FETCH_MULTICAST_GROUP_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_MULTICAST_GROUP_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const postMulticastGroup = multicastGroup => ({
  [CALL_API]: {
    endpoint: url.MulticastGroupCreate(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(multicastGroup),
    types: [
      POST_MULTICAST_GROUP_REQUEST,
      {
        type: POST_MULTICAST_GROUP_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: POST_MULTICAST_GROUP_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const putMulticastGroup = (multicastGroup, multicastGroupId) => ({
  [CALL_API]: {
    endpoint: url.MulticastGroup(multicastGroupId),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(multicastGroup),
    types: [
      PUT_MULTICAST_GROUP_REQUEST,
      {
        type: PUT_MULTICAST_GROUP_SUCCESS,
        payload: (action, state, res) => {
          history.push({
            pathname: `${PAGES_PATH}/multicast/${multicastGroupId}`,
          });
          return res.json().then(json => json);
        },
      },
      {
        type: PUT_MULTICAST_GROUP_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const deleteMulticastGroup = (multicastGroup, tab = false) => ({
  [CALL_API]: {
    endpoint: url.MulticastGroup(multicastGroup),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_MULTICAST_GROUP_REQUEST,
      {
        type: DELETE_MULTICAST_GROUP_SUCCESS,
        payload: (action, state, res) => {
          if (tab) {
            history.push({
              pathname: `${PAGES_PATH}/devices`,
              state: { activeIndex: 3 },
            });
          }
          return res.json().then(json => json);
        },
      },
      {
        type: DELETE_MULTICAST_GROUP_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyMulticastGroup = () => ({
  type: DESTROY_MULTICAST_GROUP_SUCCESS,
});
