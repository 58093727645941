/** @format */

import * as actions from '../actions/downlinks';

const defaultState = {
  queue: [],
  isLoading: false,
  response: '',
  error: false,
  status: null,
};

export const downlinks = (state = defaultState, action) => {
  switch (action.type) {
    case actions.GET_DOWNLINKS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.GET_DOWNLINKS_SUCCESS:
      return {
        queue:
          action &&
          'payload' in action &&
          'rows' in action.payload &&
          action.payload.rows
            ? action.payload.rows
            : [],
        isLoading: false,
        error: false,
        status:
          action &&
          'payload' in action &&
          'status' in action.payload &&
          action.payload.status
            ? action.payload.status
            : null,
      };
    case actions.GET_DOWNLINKS_FAILURE:
      return {
        response:
          action &&
          'payload' in action &&
          'message' in action.payload &&
          action.payload.message
            ? action.payload.message
            : '',
        isLoading: false,
        error: true,
        status:
          action &&
          'payload' in action &&
          'status' in action.payload &&
          action.payload.status
            ? action.payload.status
            : null,
      };
    case actions.DESTROY_DOWNLINKS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };
    default:
      return state;
  }
};
