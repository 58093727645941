/** @format */

import idx from 'idx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import update from 'react-addons-update';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Header,
  Input,
  Label,
  Loader,
  Modal,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import { ApplicationCreate, ImportDevices } from '..';
import {
  deleteApplication,
  destroyApplication,
} from '../../actions/application';
import {
  destroyApplications,
  fetchApplications,
} from '../../actions/applications';
import { fetchDevices } from '../../actions/devices';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import DefaultButton from '../../components/Utils/DefaultButton';
import DefaultTable from '../../components/Utils/DefaultTable';
import {
  getCurrentUserId,
  getCurrentUserName,
  getCurrentUserToken,
  userHasRole,
} from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import { toastSuccess, toastError } from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';
import { DevicesList } from '../DevicesList';
// import devLog from '../../lib/devLog';

import './ApplicationsTable.css';

const mapStateToProps = state => ({
  applications: state.applications,
  users: state.users,
  devices: state.devices,
  usersOptions: state.usersOptions.options,
});

const mapDispatchToProps = dispatch => ({
  fetchApplications: (params = { qs: '' }) => {
    dispatch(fetchApplications(params));
  },
  deleteApplication: application => {
    dispatch(deleteApplication(application));
  },
  destroyApplications: () => {
    dispatch(destroyApplications());
  },
  destroyApplication: () => {
    dispatch(destroyApplication());
  },
  fetchUsers: (params = {}) => {
    dispatch(fetchUsers(params));
  },
  fetchDevices: (params = {}) => {
    dispatch(fetchDevices(params));
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
});
/* eslint-disable */
class ApTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formGroup: [],
      offset: 0,
      limit: 12,
      searchModel: {},
      sortModel: { label: '', direction: null /* asc || desc || null */ },
      modalOpen: false,
      modalOpenImportDevices: false,
      application: null,
      check: null,
      selected: [],
      owner: getCurrentUserId(),
      applicationSidebar: false,
      device: {
        UserId: getCurrentUserId(),
        username: getCurrentUserName(),
        supports32bitFCnt: true,
        supportsClassB: false,
        supportsClassC: false,
        deveui: '',
      },
      applications: [],
      direction: null,
      column: null,
      owners: [],
      users: [],
      numberOfDeviceForApplication: [],
      numberSetted: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleCloseImportDevicesModal = this.handleCloseImportDevicesModal.bind(
      this,
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleApplicationSidebar = this.handleApplicationSidebar.bind(this);
    this.handleApplicationSidebarToggle = this.handleApplicationSidebarToggle.bind(
      this,
    );
    this.handleImportDevicesFromApplication = this.handleImportDevicesFromApplication.bind(
      this,
    );
    this.handleImportDevicesResult = this.handleImportDevicesResult.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    const { fetchUsers, fetchUsersOptions } = this.props;
    const { limit } = this.state;
    if (userHasRole('admin')) {
      fetchUsersOptions();
      fetchUsers({ qs: 'sort=username:asc&limit=10000' });
    }
    this.manageVisibleFilters();
    this.getApplications({ qs: limit > 0 ? 'limit=' + limit : '' });
  }

  manageVisibleFilters() {
    const nextFormGroup = [
      { width: 3, label: 'ID', name: 'aid', type: 'number' },
      { width: 3, label: 'Label', name: 'label', type: 'text' },
    ];
    if (userHasRole('admin')) {
      nextFormGroup.push({
        width: 3,
        label: 'Owner',
        name: 'uid',
        type: 'select',
      });
    }
    this.setState({
      ...this.state,
      formGroup: nextFormGroup,
    });
  }

  fetch(qs) {
    this.getApplications(qs);
  }

  componentWillUnmount() {
    const { destroyApplication } = this.props;
    destroyApplication();
  }

  getApplications(params) {
    Axios.get(url.Applications() + '?' + params.qs, {
      headers: {
        'Content-Type': 'application/json',
        'pt-ns': 'frontend',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        const apps = res.data;
        this.setState({ applications: apps });
        this.getDevices(apps);
      })

      .catch(err =>
        toastError(
          err && err.response && err.response.data
            ? err.response.data
            : 'Generic error',
        ),
      );
  }

  getDevices(applications) {
    if (userHasRole('admin')) {
      applications.rows.forEach((app, index) => {
        Axios.get(url.DeviceSharedForApplicationAdm(app.appid, app.UserId), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCurrentUserToken(),
          },
        })
          .then(res =>
            this.setState(
              update(this.state, {
                numberOfDeviceForApplication: {
                  [index]: {
                    $set: res.data.size,
                  },
                },
              }),
            ),
          )
          .catch(err =>
            toastError(
              err.response && err.response.data ? err.response.data : 'Error',
            ),
          );
      });
    } else {
      this.state.applications.rows.forEach((app, index) => {
        Axios.get(url.DeviceSharedForApplicationStd(app.appid), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCurrentUserToken(),
          },
        })
          .then(res =>
            this.setState(
              update(this.state, {
                numberOfDeviceForApplication: {
                  [index]: {
                    $set: res.data.size,
                  },
                },
              }),
            ),
          )
          .catch(err => toastError(err.response.data));
      });
    }
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      application: null,
      label: null,
      check: null,
    });
  }

  handleCloseImportDevicesModal() {
    this.setState({
      modalOpenImportDevices: false,
      selected: [],
    });
  }

  handleDeleteModal(application) {
    this.setState({
      modalOpen: true,
      application: application,
      label: application.label,
    });
    this.getApplications({ qs: 'limit=12' });
  }

  handleDelete() {
    const { application } = this.state;
    const { deleteApplication, fetchApplications } = this.props;

    deleteApplication(application);
    setTimeout(() => {
      this.getApplications({ qs: 'limit=12' });
    }, 2000);
    this.setState({
      modalOpen: false,
      application: null,
      label: null,
      check: null,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleSearch(e) {
    const { applications } = this.props;
    const searchResults = [];
    applications.rows.filter(
      application =>
        application.label.includes(e.target.value) &&
        searchResults.push(application),
    );
    this.setState({ applications: searchResults });
  }

  handleCheck(id, owner) {
    const { selected, owners } = this.state;
    const i = selected.indexOf(id);
    const c = owners.indexOf(owner);
    if (i === -1) {
      selected.push(id);
      owners.push(owner);
    } else {
      selected.splice(i, 1);
      owners.splice(c, 1);
    }

    let check = [...new Set(owners)];

    this.setState({
      selected: selected,
      owner: check[0],
      checkOwner: !(check.length > 1),
    });
  }

  handleApplicationSidebar(e) {
    e.preventDefault();
    this.setState(
      { applicationSidebar: !this.state.applicationSidebar },
      () => !this.state.applicationSidebar,
    );
    setTimeout(() => {
      this.getApplications({ qs: 'limit=12' });
    }, 2000);
  }

  handleApplicationSidebarToggle(e) {
    this.setState({
      ...this.state,
      applicationSidebar: !this.state.applicationSidebar,
    });
  }

  handleImportDevicesFromApplication(selected, e) {
    const { rows: applications = [] } = this.state.applications;

    this.setState({
      modalOpenImportDevices: true,
    });
  }

  handleImportDevicesResult(response) {
    const { applications } = this.state;

    if (response instanceof Error) {
      const error = response;
      if (error.response) {
        toastError(error.response.data.message);
      }
    } else {
      this.getDevices(applications);
      toastSuccess(`${response.data.count} device have been imported`);
    }

    this.setState({
      selected: [],
      modalOpenImportDevices: false,
    });
  }

  render() {
    const {
      label,
      check,
      owner,
      selected,
      applicationSidebar,
      device,
      numberOfDeviceForApplication,
      applications,
      formGroup,
    } = this.state;

    const selectedSingleApplication =
      0 in selected && 'rows' in applications
        ? applications.rows.filter(a => {
            if (a.id === selected[0]) return a;
            return false;
          })
        : {};

    const { label: singleApplicationLabel = null } =
      0 in selectedSingleApplication ? selectedSingleApplication[0] : {};

    const { users, usersOptions } = this.props;
    return (
      <Fragment>
        <Button
          compact
          icon
          color="red"
          labelPosition="left"
          onClick={this.handleApplicationSidebarToggle}
        >
          <FontAwesomeIcon
            icon="rocket"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Add Application
        </Button>
        <DefaultButton
          disabled={selected.length === 0}
          path={{
            pathname: `${PAGES_PATH}/application/share`,
            state: { applications: selected, UserId: owner },
          }}
          icon={'share-alt'}
          desc={'Share Application(s)'}
        />
        <Button
          disabled={selected.length === 0 || selected.length > 1}
          compact
          icon
          color="red"
          labelPosition="left"
          onClick={e => this.handleImportDevicesFromApplication(selected, e)}
        >
          <FontAwesomeIcon
            icon="file-csv"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Import
        </Button>

        <Sidebar
          as={Segment}
          inverted
          color="red"
          direction="right"
          animation="overlay"
          visible={applicationSidebar}
          width="very wide"
        >
          <Header floated="left" inverted as="h2">
            New Application
          </Header>
          <Button
            floated="right"
            onClick={this.handleApplicationSidebarToggle}
            size="mini"
          >
            close
          </Button>
          <Divider clearing inverted />
          {device.UserId && (
            <ApplicationCreate
              inverted={true}
              UserId={device.UserId}
              handleDeviceApplicationSidebar={this.handleApplicationSidebar}
            />
          )}
        </Sidebar>
        {!idx(applications, _ => _.isLoading) ? (
          <DefaultTable
            headers={[
              { name: '', key: 'checkbox' },
              { sortable: true, name: 'Appid', sortBy: 'appid', key: 'appid' },
              { sortable: true, name: 'Label', sortBy: 'label', key: 'label' },
              {
                sortable: true,
                name: 'Owner',
                sortBy: 'UserId',
                admin: userHasRole('admin'),
                key: 'owner',
              },
              { name: 'Devices', key: 'devices' },
              { name: 'Actions', actions: ['edit', 'delete'], key: 'actions' },
            ]}
            path={'application'}
            selected={selected}
            // users={users}
            handleCheck={this.handleCheck}
            formGroup={formGroup}
            deviceForApplication={numberOfDeviceForApplication}
            usersOptions={usersOptions}
            handleDeleteModal={this.handleDeleteModal}
            fetch={this.fetch}
            items={applications}
          />
        ) : (
          <div className="div_full_with text_center">
            <Loader active inline content="Loading" />
          </div>
        )}

        <Modal
          open={this.state.modalOpenImportDevices}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG
              className="svg-white"
              src="/assets/images/robotYeah.svg"
              style={{ fill: 'white' }}
            />
          </div>
          <Header as="h1" content="Are you ready to load new devices??" />
          <Modal.Content>
            <Modal.Description>
              <p>
                your target application is:{' '}
                <Link
                  className="modal-link"
                  to={`${PAGES_PATH}/application/${selected[0]}`}
                >
                  {singleApplicationLabel}
                </Link>
              </p>
              <ImportDevices
                ApplicationId={0 in selected ? selected[0] : null}
                onUploadFinished={this.handleImportDevicesResult}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseImportDevicesModal}>Cancel</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will delete this Application and all related devices:
            </h3>
            <DevicesList application={this.state.application} />
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the application label</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {label}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="label..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={label !== check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

ApTable.propTypes = {
  location: PropTypes.object,
  applications: PropTypes.object,
  fetchApplications: PropTypes.func,
  destroyApplications: PropTypes.func,
  destroyApplication: PropTypes.func,
  deleteApplication: PropTypes.func,
};

const ApplicationsTable = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApTable),
);

export { ApplicationsTable };
