/** @format */

import React, { useContext } from 'react';
import { Input, Segment } from 'semantic-ui-react';
import { MacCommandCtx } from '../MacCommand';
import MacCard from './MacCard';
import MacCardListItem from './MacCardListItem';
import { useMacCard } from './useMacCard';

/**
 * [REQ 2.12-006] Ping Slot Channel: (ping-slot-channel):
 * deve essere realizzata una sezione dove sia possibile inviare il comando (con il semplice click su un bottone)
 * e attendere la risposta. Prerequisito per poter inviare questo comando è quello, che nel profilo del dispositivo,
 * sia valorizzato a true il campo “supportsClassB”. Il comando dovrà avere i seguenti parametri:
 * Frequency [MHz]: [pingSlotFreq] prende tutti valori da 100,000 a 1.670,000 (al massimo 3 decimali).
 * Data Rate: [pingSlotDR] prende tutti i numeri interi da 0 a 15.

 * L’invio del comando si realizza tramite la chiamata PUT /api/v2/device/{{did}}/params/ping-slot-channel
 * HTTP/1.1 – { "pingSlotFreq": {{ pingSlotFreq }}, "pingSlotDR ": {{ pingSlotDR }} }
 * Con l’endpoint GET /api/v2/device/{{did}}/params/ping-slot-channel
 * HTTP/1.1 si andrà a prelevare lo stato del dispositivo che potrà essere:
 *
 * HTTP/1.1 200 OK: i campi presenti potranno essere:
 * state: (sempre presente) che potrà prendere i valori “pending” (se in attesa di una risposta) o “sync”.
 * pingSlotFreq: (sempre presente)
 * pingSlotDR: (sempre presente)
 * pendingValues: oggetto contenente i valori pingSlotFreq e pingSlotDR inviati al dispositivo.
 *
 * HTTP/1.1 403 Forbidden - { "status": 403, "message": "Information not available" }; quando il device non ha mai parlato.
 *
 * Una volta inviato il comando (PUT) il componente dovrà periodicamente richiedere lo stato (GET) per visualizzare gli aggiornamenti.
 *
 * @returns {*}
 * @constructor
 */
const MacCardPingSlot = () => {
  const { deviceID, supportsClassB, deviceNeverSend } = useContext(
    MacCommandCtx,
  );
  const title = 'Ping Slot Channel';
  const { param1, param2 } = {
    param1: 'pingSlotFreq',
    param2: 'pingSlotDR',
  };
  const [dataMin, dataMax] = [0, 15];
  const [rangeMin, rangeMax] = [100, 1670];
  const neverSendData = {
    [param1]: dataMin,
    [param2]: rangeMin,
  };
  const {
    editMode,
    pendingMode,
    togglePending,
    displayDataParam,
    displayDataFrequency,
    startEdit,
    changeEdit,
    endEdit,
    tempData,
    data,
  } = useMacCard(deviceID, 'pingSlotChannel', deviceNeverSend, neverSendData, {
    visible: supportsClassB,
    frequencyParam: param1,
  });

  const msg = `Be careful!! The frequency should be between ${rangeMin} and  ${rangeMax}!`;
  const inputError = editMode
    ? displayDataFrequency(param1) < rangeMin ||
      displayDataFrequency(param1) > rangeMax
    : false;

  return (
    <MacCard
      visible={supportsClassB}
      title={title}
      startEdit={() => startEdit()}
      editMode={editMode}
      endEdit={save => endEdit(save)}
      pendingMode={pendingMode}
      togglePending={() => togglePending()}
      state={data && data.state}
    >
      <MacCardListItem
        label={'Ping Slot Freq.'}
        editMode={editMode}
        value={displayDataFrequency(param1)}
        editable={supportsClassB}
      >
        {editMode && (
          <>
            <Input
              fluid
              type="range"
              min={rangeMin}
              max={rangeMax}
              name={param1}
              onChange={(e, selection) => changeEdit(selection)}
              value={tempData[param1]}
            />
            <Input
              error={inputError}
              placeholder={msg}
              type={'number'}
              fluid
              name={param1}
              value={tempData[param1]}
              onChange={(e, selection) => changeEdit(selection)}
            />

            {inputError && (
              <Segment inverted color="red" secondary content={msg} />
            )}
          </>
        )}
      </MacCardListItem>

      <MacCardListItem
        label={'Ping Slot Dr.'}
        editMode={editMode}
        value={displayDataParam(param2)}
        editable={supportsClassB}
      >
        {editMode && (
          <Input
            fluid
            type="range"
            min={dataMin}
            max={dataMax}
            name={param1}
            onChange={(e, selection) => changeEdit(selection)}
            value={tempData[param2]}
          />
        )}
      </MacCardListItem>
    </MacCard>
  );
};

export default MacCardPingSlot;
