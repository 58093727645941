/** @format */

import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Grid, Table } from 'semantic-ui-react';
import { PAGES_PATH } from '../../lib/variables';
import { Link } from 'react-router-dom';

class GatewayStatusForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { status } = this.props.device;
    if (!status) {
        return <div>No uplink received yet</div>;
    }
    const { gateway } = status;
    if (!gateway || gateway.length < 1) {
      return <div>No uplink received yet</div>;
    }
    //
    return (
      <Fragment>
        <Grid columns={1} divided>
          <Grid.Row>
            <Grid.Column>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell key="headerSelectMac">
                      Gateway ID
                    </Table.HeaderCell>
                    <Table.HeaderCell key="headerSelectRssi">
                      RSSI
                    </Table.HeaderCell>
                    <Table.HeaderCell key="headerSelectSNR">
                      SNR
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {idx(gateway, _ =>
                    _.map(row => (
                      <Table.Row key={`row-${row.mac}`}>
                        <Table.Cell key={`row-${row.mac}-mac`}>
                          <Link to={`${PAGES_PATH}/gateway/${row.mac}`}>
                            <strong>{row.mac}</strong>
                          </Link>
                        </Table.Cell>
                        <Table.Cell key={`row-${row.mac}-rssi`}>
                          {row.rssi}
                        </Table.Cell>
                        <Table.Cell key={`row-${row.mac}-snr`}>
                          {row.snr}
                        </Table.Cell>
                      </Table.Row>
                    )),
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

GatewayStatusForm.propTypes = {
  device: PropTypes.object,
};

const GatewayStatus = GatewayStatusForm;

export { GatewayStatus };
