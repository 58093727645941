/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearConsole,
  closeSocketConnection,
  openSocketConnection,
  setUplinksLimitFilter,
} from '../../../actions/uplinks';
import { Button, Dropdown } from 'semantic-ui-react';
import { consoleTableOptionsLimit } from '../../../utils/utilityConstants';

export const Toolbar = () => {
  const dispatch = useDispatch();
  const uplinks = useSelector(store => store.uplinks);

  const socketButtonColor = !uplinks.isSocketConnectionOpen ? 'green' : 'red';
  const socketButtonText = !uplinks.isSocketConnectionOpen
    ? 'TURN ON'
    : 'TURN OFF';
  const socketIcon = !uplinks.isSocketConnectionOpen ? 'play' : 'pause';

  const logCounter = uplinks.messages.length;

  const handleOnClearConsole = () => dispatch(clearConsole());

  const handleSocketConnection = () => {
    if (uplinks.isSocketConnectionOpen) {
      dispatch(closeSocketConnection());
    } else {
      dispatch(openSocketConnection());
    }
  };

  const handleOnChange = (_event, data) => {
    dispatch(setUplinksLimitFilter(data.value));
  };

  return (
    <div className="button-bar">
      <Button
        onClick={handleSocketConnection}
        color={socketButtonColor}
        icon={socketIcon}
        content={socketButtonText}
        label
      />
      <Button
        onClick={handleOnClearConsole}
        color="linkedin"
        content="Clear Logs"
      />
      <Dropdown
        placeholder="Max number of records"
        selection
        value={uplinks.filters.limit}
        options={consoleTableOptionsLimit}
        onChange={handleOnChange}
      />
      {` Total records: ${logCounter}`}
    </div>
  );
};
