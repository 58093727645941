/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken, deleteCurrentUserToken } from '../lib/auth';
import { useThisQueryString } from '../lib/urls';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const POST_MULTICAST_GROUP_DEVICES_REQUEST =
  'multicastGroupDevices/FETCH_REQUEST';
export const POST_MULTICAST_GROUP_DEVICES_SUCCESS =
  'multicastGroupDevices/FETCH_SUCCESS';
export const POST_MULTICAST_GROUP_DEVICES_FAILURE =
  'multicastGroupDevices/FETCH_FAILURE';

export const FETCH_MULTICAST_GROUP_DEVICES_REQUEST =
  'multicastGroupDevices/FETCH_REQUEST';
export const FETCH_MULTICAST_GROUP_DEVICES_SUCCESS =
  'multicastGroupDevices/FETCH_SUCCESS';
export const FETCH_MULTICAST_GROUP_DEVICES_FAILURE =
  'multicastGroupDevices/FETCH_FAILURE';

export const FETCH_MULTICAST_GROUP_DEVICES_MAP_REQUEST =
  'multicastGroupDevicesMap/FETCH_REQUEST';
export const FETCH_MULTICAST_GROUP_DEVICES_MAP_SUCCESS =
  'multicastGroupDevicesMap/FETCH_SUCCESS';
export const FETCH_MULTICAST_GROUP_DEVICES_MAP_FAILURE =
  'multicastGroupDevicesMap/FETCH_FAILURE';

export const DELETE_MULTICAST_GROUP_DEVICES_REQUEST =
  'multicastGroupDevices/FETCH_REQUEST';
export const DELETE_MULTICAST_GROUP_DEVICES_SUCCESS =
  'multicastGroupDevices/FETCH_SUCCESS';
export const DELETE_MULTICAST_GROUP_DEVICES_FAILURE =
  'multicastGroupDevices/FETCH_FAILURE';

export const DESTROY_MULTICAST_GROUP_DEVICES_SUCCESS =
  'multicastGroupDevices/DESTROY_SUCCESS';

export const DESTROY_MULTICAST_GROUP_DEVICES_MAP_SUCCESS =
  'multicastGroupDevicesMap/DESTROY_SUCCESS';

// ACTION CREATORS
export const postMulticastGroupDevices = (id, ids) => ({
  [CALL_API]: {
    endpoint: url.MulticastGroupDeviceCreate(id),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(ids),
    types: [
      POST_MULTICAST_GROUP_DEVICES_REQUEST,
      {
        type: POST_MULTICAST_GROUP_DEVICES_SUCCESS,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/devices`);
          return res.json().then(json => json);
        },
      },
      {
        type: POST_MULTICAST_GROUP_DEVICES_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

// Fetch devices in Multicast Group
export const fetchMulticastGroupDevices = (mgid, params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.MulticastGroupsDevices(mgid), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_MULTICAST_GROUP_DEVICES_REQUEST,
      {
        type: FETCH_MULTICAST_GROUP_DEVICES_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_MULTICAST_GROUP_DEVICES_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchMulticastGroupDevicesMap = multicastGroup => ({
  [CALL_API]: {
    endpoint: url.MulticastGroupsDevicesMap(multicastGroup),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_MULTICAST_GROUP_DEVICES_MAP_REQUEST,
      {
        type: FETCH_MULTICAST_GROUP_DEVICES_MAP_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_MULTICAST_GROUP_DEVICES_MAP_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

// Delete device in Multicast Group
export const deleteMulticastGroupDevices = (multicastGroup, device) => ({
  [CALL_API]: {
    endpoint: url.MulticastGroupsDeviceDelete(multicastGroup, device),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_MULTICAST_GROUP_DEVICES_REQUEST,
      {
        type: DELETE_MULTICAST_GROUP_DEVICES_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: DELETE_MULTICAST_GROUP_DEVICES_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const destroyMulticastGroupDevices = () => ({
  type: DESTROY_MULTICAST_GROUP_DEVICES_SUCCESS,
});

export const destroyMulticastGroupDevicesMap = () => ({
  type: DESTROY_MULTICAST_GROUP_DEVICES_MAP_SUCCESS,
});
