import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { poweroffHistory } from './service';
import './PoweroffHistoryTable.css';
import PropTypes from 'prop-types';

export const PoweroffHistoryTable = ({ gid }) => {

  const [list, setList] = useState([]);
  const [ErrorPoweroff, setErrorPoweroff] = useState(false);

  useEffect(() => {
    if (gid) {
      fetch();
    }
  }, [gid]);

  const fetch = () => {

    poweroffHistory(gid).then(response => {
      if (response.status === 200) {
        setList(response.data);
        setErrorPoweroff(false);
      } else {
        setErrorPoweroff(true);
      }
    }).catch(err => {
      setErrorPoweroff(true);
    })
  }

  const nothingToShow = () => (
    <Table.Row>
      <Table.Cell style={{ textAlign: 'center' }}>empty history, no events received</Table.Cell>
    </Table.Row>
  );

  const errorLoading = () => (
    <Table.Row>
      <Table.Cell style={{ textAlign: 'center' }}><b>Error load power failure hisotry</b></Table.Cell>
    </Table.Row>
  );

  return <div className='div_full_with powerfailure'>
    <div className="content">
      Table with the history of power failure events.<br />
      Event indicates that the gateway has a power anomaly and will probably shut down in a few minutes if the power failure is not brief.<br />
      However, this event does not mean that the gateway will definitely shut down; it is only an indicator of a power anomaly.<br />
      Uptime indicates the duration the gateway has been powered-on without interruption and is logged when the event is triggered.<br />
    </div>
    <Table sortable celled={false}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Gateway Uptime (UTC)</Table.HeaderCell>
          <Table.HeaderCell>Power failure received at (UTC)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {
          // eslint-disable-next-line no-nested-ternary
          ErrorPoweroff ? errorLoading() :
            (list.length > 0 ? list.map((item, index) => (
              <Table.Row key={index}>
                <Table.Cell>{moment(item.uptime).utc().format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
                <Table.Cell>{moment(item.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
              </Table.Row>
            )) : nothingToShow())}
      </Table.Body>

    </Table>
  </div>
};

PoweroffHistoryTable.propTypes = {
  gid: PropTypes.string,
  gateway: PropTypes.object
};

export default PoweroffHistoryTable;

