/** @format */

import { CALL_API } from 'redux-api-middleware';
import history from '../history';
import { deleteCurrentUserToken, getCurrentUserToken } from '../lib/auth';
import { Endpoints as url } from '../lib/endpoints';
import { generateUrl, useThisQueryString } from '../lib/urls';
import { toastError } from '../lib/utilities';
import { PAGES_PATH } from '../lib/variables';

// ACTION TYPES
export const FETCH_MULTICAST_GROUPS_REQUEST = 'multicastGroups/FETCH_REQUEST';
export const FETCH_MULTICAST_GROUPS_SUCCESS = 'multicastGroups/FETCH_SUCCESS';
export const FETCH_MULTICAST_GROUPS_FAILURE = 'multicastGroups/FETCH_FAILURE';

export const FETCH_MULTICAST_GROUPS_OPTIONS_REQUEST =
  'multicastGroupsOptions/FETCH_REQUEST';
export const FETCH_MULTICAST_GROUPS_OPTIONS_SUCCESS =
  'multicastGroupsOptions/FETCH_SUCCESS';
export const FETCH_MULTICAST_GROUPS_OPTIONS_FAILURE =
  'multicastGroupsOptions/FETCH_FAILURE';

export const DESTROY_MULTICAST_GROUPS_SUCCESS =
  'multicastGroups/DESTROY_SUCCESS';

export const DESTROY_MULTICAST_GROUPS_OPTIONS_SUCCESS =
  'multicastGroupOptions/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchMulticastGroups = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.MulticastGroups(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_MULTICAST_GROUPS_REQUEST,
      {
        type: FETCH_MULTICAST_GROUPS_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            ...json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_MULTICAST_GROUPS_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchMulticastGroupsOptions = (params = {}) => ({
  [CALL_API]: {
    endpoint: generateUrl(url.MulticastGroupsOptions(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_MULTICAST_GROUPS_OPTIONS_REQUEST,
      {
        type: FETCH_MULTICAST_GROUPS_OPTIONS_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            options: json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_MULTICAST_GROUPS_OPTIONS_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyMulticastGroups = () => ({
  type: DESTROY_MULTICAST_GROUPS_SUCCESS,
});

export const destroyMulticastGroupsOptions = () => ({
  type: DESTROY_MULTICAST_GROUPS_OPTIONS_SUCCESS,
});
