import React, { Component, Fragment } from 'react';
import { Button, Divider, Form, Header, Label, Popup, Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { rncStartScan } from './service';
import { EMPTY_STRING_VALUE, formatKeys } from '../../lib/utilities';
import TabList from './TabList';
import ScanHistoryTable from './ScanHistoryTable';
import ScanChart from './ScanChart';
import moment from 'moment';
import { toast } from 'react-semantic-toasts';

export class GatewayIdFormatter extends Component {
  render() {
    return <span>{formatKeys(this.props.id || EMPTY_STRING_VALUE)}</span>;
  }
}

GatewayIdFormatter.propTypes = {
  id: PropTypes.string,
};

class SpectralScan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gateway: {},
      selectedScan: null,
      form: {
        startFrequency: '',
        stopFrequency: '',
        step: '',
        bandwidth: null,
        rssiPoint: '',
        rssiOffset: '',
      },
    };
  }

  componentDidMount() {

    const { gid } = this.props.match.params;

    this.setState({
      gid,
      form: { gid },
    });

  }

  handleChange = (event) => {
    const name = event.target.getAttribute('name');
    const { value } = event.target;
    this.handleChangeValue(name, value);
  }

  handleChangeValue = (name, value) => {
    const object = { form: this.state.form }
    object['form'][name] = value;
    this.setState(object)
  }

  handleSubmit = () => {

    const resetForm = () => {
      this.setState({
        form: {
          startFrequency: '',
          stopFrequency: '',
          step: '',
          bandwidth: null,
          rssiPoint: '',
          rssiOffset: '',
        },
      });
    }

    rncStartScan(this.state.form).then(response => {
      if (response.status === 201) {
        resetForm();
        toast({
          type: 'success',
          title: 'Success',
          description: 'Configuration saved',
        });
      }
    }).catch(err => {
      toast({
        type: 'error',
        title: 'Error',
        description: 'Error saving configuration',
      });
    });
  };

  render() {
    const { gid, gateway } = this.state;
    const {
      startFrequency, stopFrequency, step,
      bandwidth, rssiPoint, rssiOffset
    } = this.state.form;

    return (
      <Fragment>
        <Header as='h1' floated='left'>
          Scan configuration
        </Header>

        <TabList gateway={gateway} gid={gid} />

        <Divider clearing />

        <div className='ui four column grid' style={{ 'marginRight': '10px' }}>

          <div className='row'>
            <div className='sixteen wide column'>
              <Label>
                Gateway
                <Label.Detail>
                  <GatewayIdFormatter id={gid} />
                </Label.Detail>
              </Label>
            </div>
          </div>

          <div className='row'>
            <div className='sixteen wide column'>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>Start Frequency (KHz)</label>
                    <input
                      placeholder='Start Frequency'
                      name='startFrequency'
                      value={startFrequency}
                      onChange={this.handleChange}
                      required={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Stop Frequency (KHz)</label>
                    <input
                      placeholder='Stop Frequency'
                      name='stopFrequency'
                      value={stopFrequency}
                      onChange={this.handleChange}
                      required={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Step (KHz)</label>
                    <input
                      placeholder='Step'
                      name='step'
                      value={step}
                      onChange={this.handleChange}
                      required={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Bandwidth (KHz)</label>
                    <Select
                      name='bandwidth'
                      value={bandwidth}
                      onChange={(event, {value}) => this.handleChangeValue('bandwidth',value)}
                      required={true}
                      options={[25,50,100,125,200,250,500].map((item) => ({
                        key: item,
                        text: item,
                        value: item,
                        })
                      )}
                    />
                  </Form.Field>
                  <Popup
                    header='Allowed values'
                    content='da 1 a 655535'
                    trigger={
                      <Form.Field>
                        <label>RSSI point</label>
                        <input
                          placeholder='RSSI point'
                          name='rssiPoint'
                          value={rssiPoint}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Form.Field>
                    }
                  />
                  <Popup
                    header='Allowed values'
                    content='da -128 a 127'
                    trigger={
                      <Form.Field>
                        <label>RSSI offset</label>
                        <input
                          placeholder='RSSI offset'
                          name='rssiOffset'
                          value={rssiOffset}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Form.Field>
                    }
                  />
                </Form.Group>
                <Button type='submit'>Start new Scan</Button>
              </Form>
            </div>
          </div>

          <div className='row'>
            <div className='sixteen wide column'>
              <h3>{ this.state.selectedScan ? moment(this.state.selectedScan.createdAt).toISOString() : null }</h3>
              <ScanChart scan={this.state.selectedScan}/>
            </div>
          </div>

          <div className='row'>
            <div className='sixteen wide column'>
              <h3>Scan History</h3>
              <ScanHistoryTable gid={ this.state.gid } onSelect={ (selectedScan) => this.setState({ selectedScan })} />
            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}

SpectralScan.propTypes = {
  match: PropTypes.object,
};

export default SpectralScan;
