/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { deleteCurrentUserToken, getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_UPLINKS = 'mqtt/FETCH_UPLINKS';
export const UPDATE_UPLINKS = 'mqtt/UPDATE_UPLINKS';
export const OPEN_SOCKET_CONNECTION = 'mqtt/OPEN_SOCKET_CONNECTION';
export const CLOSE_SOCKET_CONNECTION = 'mqtt/CLOSE_SOCKET_CONNECTION';
export const CLEAR_CONSOLE = 'mqtt/CLEAR_CONSOLE';
export const SET_UPLINKS_DEV_EUI_FILTER = 'mqtt/SET_UPLINKS_DEV_EUI_FILTER';
export const SET_UPLINKS_APP_ID_FILTER = 'mqtt/SET_UPLINKS_APP_ID_FILTER';
export const SET_UPLINKS_OWNER_FILTER = 'mqtt/SET_UPLINKS_OWNER_FILTER';
export const SET_UPLINKS_SF_FILTER = 'mqtt/SET_UPLINKS_SF_FILTER';
export const SET_UPLINKS_TYPE_FILTER = 'mqtt/SET_UPLINKS_TYPE_FILTER';
export const SET_UPLINKS_PORT_FILTER = 'mqtt/SET_UPLINKS_PORT_FILTER';
export const SET_UPLINKS_PAYLOAD_FILTER = 'mqtt/SET_UPLINKS_PAYLOAD_FILTER';
export const SET_UPLINKS_ORDER_BY_FILTER = 'mqtt/SET_UPLINKS_ORDER_BY_FILTER';
export const SET_UPLINKS_START_DATE_FILTER =
  'mqtt/SET_UPLINKS_START_DATE_FILTER';
export const SET_UPLINKS_END_DATE_FILTER = 'mqtt/SET_UPLINKS_END_DATE_FILTER';
export const SET_UPLINKS_LIMIT_FILTER = 'mqtt/SET_UPLINKS_LIMIT_FILTER';

// export const QUEUE_ROWS = 100;

export const FECTH_DEVEUI_LIST_LOG_REQUEST =
  'mqtt/FECTH_DEVEUI_LIST_LOG_REQUEST';
export const FECTH_DEVEUI_LIST_LOG_SUCCESS =
  'mqtt/FECTH_DEVEUI_LIST_LOG_SUCCESS';
export const FECTH_DEVEUI_LIST_LOG_FAILURE =
  'mqtt/FECTH_DEVEUI_LIST_LOG_FAILURE';

export const fetchUplinkDevEui = deveui => ({
  [CALL_API]: {
    endpoint: url.LogDevEuiList(deveui),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FECTH_DEVEUI_LIST_LOG_REQUEST,
      {
        type: FECTH_DEVEUI_LIST_LOG_SUCCESS,
        payload: (action, state, res) => res.json().then(app => app),
      },
      {
        type: FECTH_DEVEUI_LIST_LOG_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const updateUplinks = message => {
  return {
    type: UPDATE_UPLINKS,
    message,
  };
};

export const openSocketConnection = () => {
  return {
    type: OPEN_SOCKET_CONNECTION,
  };
};

export const closeSocketConnection = () => {
  return {
    type: CLOSE_SOCKET_CONNECTION,
  };
};

export const clearConsole = () => {
  return {
    type: CLEAR_CONSOLE,
  };
};

// FIlters

export const setUplinksDevEuiFilter = data => {
  return {
    type: SET_UPLINKS_DEV_EUI_FILTER,
    payload: data,
  };
};

export const setUplinksOwnerFilter = data => {
  return {
    type: SET_UPLINKS_OWNER_FILTER,
    payload: data,
  };
};

export const setUplinksSFFilter = data => {
  return {
    type: SET_UPLINKS_SF_FILTER,
    payload: data,
  };
};

export const setUplinksTypeFilter = data => {
  return {
    type: SET_UPLINKS_TYPE_FILTER,
    payload: data,
  };
};

export const setUplinksPortFilter = data => {
  return {
    type: SET_UPLINKS_PORT_FILTER,
    payload: data,
  };
};

export const setUplinksAppIdFilter = data => {
  return {
    type: SET_UPLINKS_APP_ID_FILTER,
    payload: data,
  };
};

export const setUplinksPayloadFilter = data => {
  return {
    type: SET_UPLINKS_PAYLOAD_FILTER,
    payload: data,
  };
};

export const setUplinksOrderByFilter = orderBy => {
  return {
    type: SET_UPLINKS_ORDER_BY_FILTER,
    payload: orderBy,
  };
};

export const setUplinksStartDateFilter = startDate => {
  return {
    type: SET_UPLINKS_START_DATE_FILTER,
    payload: startDate,
  };
};

export const setUplinksEndDateFilter = endDate => {
  return {
    type: SET_UPLINKS_END_DATE_FILTER,
    payload: endDate,
  };
};

export const setUplinksLimitFilter = numOfRows => {
  return {
    type: SET_UPLINKS_LIMIT_FILTER,
    payload: numOfRows,
  };
};
