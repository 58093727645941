/** @format */

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class MenuButton extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { keyID, path, desc, icon } = this.props;
    return (
      <Button
        as={Link}
        key={keyID}
        compact
        icon
        color="red"
        labelPosition="left"
        floated="right"
        style={{ marginTop: '2.4em' }}
        to={path}
      >
        <FontAwesomeIcon
          icon={icon}
          className="icon"
          style={{ padding: '0.4em' }}
        />
        {desc}
      </Button>
    );
  }
}

MenuButton.propTypes = {
  keyID: PropTypes.string,
  path: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
};

export default MenuButton;
