/** @format */

import * as actions from '../actions/me';

const defaultState = {
  isLoading: false,
  error: false,
};

export const me = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_ME_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_USER_ME_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_USER_ME_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}