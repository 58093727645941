/** @format */

// HEADERS
export const CSV_V1_R1_HEADER = 'matricola,devEUI,devAddr,labelID,firmware,model,nwk_s_key,app_s_key,app_key,devProfile,devType'.split(
  ',',
);
export const CSV_V1_R2_HEADER = 'Serial,Dev EUI,Lora device address,Label,FW,Model,Lora network key,Lora app s key,Lora app key,Devtype,Dev profile'.split(
  ',',
);
export const CSV_V2_R0_HEADER = 'serial,deveui,devaddr,label,firmware,model,nwkSKey,appSKey,appKey,joineui,DeviceProfileId,type'.split(
  ',',
);
export const CSV_V2_R1_HEADER = 'matricola,devEUI,devAddr,labelID,firmware,model,nwk_s_key,app_s_key,app_key,app_eui,devProfile,devType'.split(
  ',',
);
export const CSV_V2_R2_HEADER = 'Serial,Dev EUI,Lora device address,Label,FW,Model,Lora network key,Lora app s key,Lora app key,Lora app eui,Devtype,Dev profile'.split(
  ',',
);
export const CSV_V2_R0_COOR_HEADER = 'serial,deveui,devaddr,label,firmware,model,nwkSKey,appSKey,appKey,joineui,DeviceProfileId,type,lat,lng,alt'.split(
  ',',
);
