/** @format */

import React, { Component, Fragment } from 'react';
import { List } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-semantic-toasts';

import PropTypes from 'prop-types';

import { formatNormalString } from '../../lib/utilities';

import './ListItem.css';

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.notify = this.notify.bind(this);
  }

  // HANDLER SECTION
  notify = () => {
    toast({
      title: 'COPIED TO CLIPBOARD',
      color: 'teal',
      type: 'success',
      icon: 'copy',
    });
  };

  render() {
    return (
      <Fragment>
        {this.props.copy === true && (
          <CopyToClipboard
            text={
              this.props.shouldBeFormatted
                ? formatNormalString(this.props.text || this.props.content, ':')
                : this.props.text || this.props.content
            }
          >
            <List.Item onClick={this.notify}>
              <List.Content floated="left" style={{ width: '15em' }}>
                <b>{this.props.label}:</b>
              </List.Content>

              <List.Content>{this.props.content}</List.Content>

              <List.Content floated="right">
                <FontAwesomeIcon
                  icon="copy"
                  style={{ color: 'rgba(0, 0, 0, 0.2)' }}
                />
              </List.Content>
            </List.Item>
          </CopyToClipboard>
        )}
        {this.props.copy === false && (
          <List.Item>
            <List.Content floated={'left'} style={{ width: '15em' }}>
              <b>{this.props.label}:</b>
            </List.Content>

            <List.Content>{this.props.content}</List.Content>
          </List.Item>
        )}
      </Fragment>
    );
  }
}

ListItem.propTypes = {
  label: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
  copy: PropTypes.bool,
  notify: PropTypes.func,
  shouldBeFormatted: PropTypes.bool,
};

export { ListItem };
