/** @format */

import * as actions from '../actions/devicePosition';

const defaultState = {
  position: {},
  isLoading: false,
  error: false,
};

export const devicePosition = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_DEVICE_POSITION_REQUEST:
      return {
        position: {},
        isLoading: true,
        error: false,
      };
    case actions.FETCH_DEVICE_POSITION_SUCCESS:
      return {
        position: { ...action.payload },
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICE_POSITION_FAILURE:
      return {
        ...action.payload,
        position: {},
        isLoading: false,
        error: true,
      };

    case actions.UPSET_DEVICE_POSITION_REQUEST:
      return {
        position: {},
        isLoading: true,
        error: false,
      };
    case actions.UPSET_DEVICE_POSITION_SUCCESS:
      return {
        position: { ...action.payload },
        isLoading: false,
        error: false,
      };
    case actions.UPSET_DEVICE_POSITION_FAILURE:
      return {
        ...action.payload,
        position: {},
        isLoading: false,
        error: true,
    };
    default:
      return state;
  }
}