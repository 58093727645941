/** @format */

import * as actions from '../actions/userProfiles';

const defaultState = {
  isLoading: false,
  error: false,
  data: {},
};

export const userProfilesList = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_PROFILES_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.FETCH_USER_PROFILES_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        ...action.payload,
      };
    case actions.FETCH_USER_PROFILES_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
};
