/** @format */

import React from 'react';
import { Card } from 'semantic-ui-react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import StatsPlaceholder from './StatPLaceholder';

export default function Timeseries({ hasError, labels, lines }) {
  const options = {
    chart: {
      id: 'line',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      formatter(val, index) {
        return val;
      },
    },
  };

  return (
    <Card fluid style={{ maxHeight: '400px' }}>
      {hasError ? (
        <Card.Content>
          <Card.Header
            style={{ textAlign: 'center', color: '#666666' }}
            content="Timeseries"
          />
          <Card.Description>
            <StatsPlaceholder />
          </Card.Description>
        </Card.Content>
      ) : (
        <Card.Content style={{ padding: '15px' }}>
          <Chart options={options} series={lines} type="line" height="350" />
        </Card.Content>
      )}
    </Card>
  );
}

Timeseries.propTypes = {
  hasError: PropTypes.bool,
  labels: PropTypes.array.isRequired,
  lines: PropTypes.array.isRequired,
};
