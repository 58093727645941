/** @format */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {
  faCheck,
  faPause,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'semantic-ui-react';


const MacCommandStatusIcon = props => {
  const displayState = () => {
    switch (props.state) {
      case 'sync':
        return (
          <FontAwesomeIcon
            icon={faCheck}
            className="icon"
            style={{ color: 'green' }}
          />
        );
      case 'pending':
        return <Icon 
          name='sync'
          style={{color: 'orange'}}
        />
      case '---':
        return (
          <FontAwesomeIcon
            icon={faPause}
            className="icon"
            style={{ color: '#E1AD01' }}
          />
        );
      default:
        return (
          <FontAwesomeIcon
            icon={faQuestion}
            className="icon"
            style={{ color: '#8e8e8e' }}
          />
        );
    }
  };
  return <>{displayState()}</>;
};

MacCommandStatusIcon.propTypes = {
  state: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
export default MacCommandStatusIcon;
