/** @format */

import React, { useEffect, useState } from 'react';
import { Divider, Header } from 'semantic-ui-react';
import BackButton from '../../components/Utils/BackButton';
import ListButton from '../../components/Utils/ListButton';
import { DEVICE_NEVER_TALK_STATUS, toastError } from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';
import devLog from '../../lib/devLog';
import DeviceStatus from './DeviceStatus/DeviceStatus';
import FrequencyChannels from './FrequencyChannels/FrequencyChannels';
import MacCards from './MacCards/MacCards';
import { useMacCommandService } from './useMacCommandService';
import PropTypes from 'prop-types';

export const MacCommandCtx = React.createContext(null);

const MacCommand = props => {
  const [device, setDevice] = useState(null);
  const [supportsClassB, setSupportsClassB] = useState(false);
  const [deviceNeverSend, setDeviceNeverSend] = useState(true);
  const [error, setError] = useState(null);
  const [errCnt, setErrCnt] = useState(0);

  const { deviceDetail } = useMacCommandService(props.match.params.did);
  const getDeviceProfile = id => {
    deviceDetail
      .getProfiles(id)
      .then(res => setSupportsClassB(res.data.supportsClassB))
      .catch(err => devLog(err));
  };
  const getDevice = () => {
    deviceDetail
      .get()
      .then(res => {
        if (res.data) {
          setDevice(res.data);
          getDeviceProfile(res.data.DeviceProfileId);
        }
      })
      .catch(err => devLog(err));
  };

  const handleDeviceError = err => {
    const dns =
      err.response && err.response.status === DEVICE_NEVER_TALK_STATUS;
    setDeviceNeverSend(dns);
    if (!dns) {
      const errorMsg =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : 'MacCommand Error';
      setError(errorMsg);
      setErrCnt(errCnt + 1);
    }
  };

  useEffect(() => {
    getDevice();
  }, []);

  useEffect(() => {
    if (error !== null && errCnt > 0) {
      toastError({ message: error });
    }
  }, [error, errCnt]);

  return (
    <MacCommandCtx.Provider
      value={{
        deviceNeverSend,
        setDeviceNeverSend,
        handleDeviceError,
        deviceID: props.match.params.did || null,
        supportsClassB,
      }}
    >
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        Mac Command for {(device && device.label) || ''}
      </Header>
      <ListButton path={`${PAGES_PATH}/devices`} />
      <BackButton path={`${PAGES_PATH}/device/${device ? device.id : null}`} />
      <Divider clearing />
      <DeviceStatus />
      <MacCards />
      <FrequencyChannels />
    </MacCommandCtx.Provider>
  );
};

MacCommand.propTypes = {
  match: PropTypes.object,
};

export { MacCommand };
