/** @format */

import React from 'react';
import { Inputs } from './Inputs';
import { Header } from './Header';

export const Filters = () => (
  <>
    <Header />
    <Inputs />
  </>
);
