import Axios from 'axios';
import { getCurrentUserToken } from '../../lib/auth';
import { RNC_API_URL } from '../../lib/variables';

const baseUrl = RNC_API_URL

export const scanHistory = (gid, page, size, sortColumn, sortDirection) => {
  return Axios.post(`${baseUrl}/gateway/scan-hisotry`, {
    msgtype: "scan-history",
    gid,
    page: Number(page),
    size: Number(size),
    sortColumn,
    sortDirection
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
  });
}

export const deleteScanRecord = (id) => {
  return Axios.delete(`${baseUrl}/gateway/scan-hisotry/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
  });
}

export const rncGatewayConfig = (gid, pollingInterval) => {
  return Axios.post(`${baseUrl}/config/${gid}`, {
    msgtype: "config",
    polling_interval: Number(pollingInterval)
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
  });
}

export const rncGatewayGetConfig = (gid) => {
  return Axios.get(`${baseUrl}/config/${gid}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
  });
}

export const rncStartScan = ({ gid, startFrequency, stopFrequency, step, bandwidth, rssiPoint, rssiOffset }) => {
  return Axios.post(`${baseUrl}/scan/${gid}`, {
    msgtype: "scan",
    freq: {
      start: Number(startFrequency),
      step: Number(step),
      stop: Number(stopFrequency),
    },
    bandwidth: Number(bandwidth),
    rssi_point: Number(rssiPoint),
    rssi_offset: Number(rssiOffset),
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
  });
}

export const poweroffHistory = (gid) => {
  return Axios.get(`${baseUrl}/poweroff/${gid}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
  });
}