/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import {
  setUplinksStartDateFilter,
  setUplinksEndDateFilter,
} from '../../../../actions/uplinks';
import moment from 'moment';

const dateTimeFormat = 'DD-MM-YYYY HH:mm';

export const DateTimeRange = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(store => store.uplinks.filters);
  const startDateStr = startDate ? startDate.format(dateTimeFormat) : '';
  const endDateStr = endDate ? endDate.format(dateTimeFormat) : '';

  const handleOnChangeStartDate = (_e, { value }) => {
    const date = value ? moment(value, dateTimeFormat) : undefined;
    dispatch(setUplinksStartDateFilter(date));
  };

  const handleOnChangeEndDate = (_e, { value }) => {
    const date = value ? moment(value, dateTimeFormat) : undefined;
    dispatch(setUplinksEndDateFilter(date));
  };

  return (
    <>
      <DateTimeInput
        dateTimeFormat={dateTimeFormat}
        name="startDateTime"
        placeholder="Start Time"
        value={startDateStr}
        iconPosition="left"
        onChange={handleOnChangeStartDate}
        clearable
      />
      <DateTimeInput
        dateTimeFormat={dateTimeFormat}
        name="endDateTime"
        placeholder="End Time"
        value={endDateStr}
        iconPosition="left"
        onChange={handleOnChangeEndDate}
        clearable
        minDate={startDate || undefined}
      />
    </>
  );
};
