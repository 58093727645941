/** @format */

import * as actions from '../actions/applicationDevices';

const defaultState = {
  filter: {},
  sort: {},
  isLoading: false,
  error: false,
};

export const applicationDevices = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_APPLICATION_DEVICES_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_APPLICATION_DEVICES_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_APPLICATION_DEVICES_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_APPLICATION_DEVICES_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

export const applicationDevicesMap = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_APPLICATION_DEVICES_MAP_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_APPLICATION_DEVICES_MAP_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_APPLICATION_DEVICES_MAP_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_APPLICATION_DEVICES_MAP_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
