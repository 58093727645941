import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import {
  getCurrentUserToken
} from '../lib/auth';

// ACTION TYPES
export const FETCH_DEVICE_POSITION_REQUEST = 'device/position/FETCH_REQUEST';
export const FETCH_DEVICE_POSITION_SUCCESS = 'device/position/FETCH_SUCCESS';
export const FETCH_DEVICE_POSITION_FAILURE = 'device/position/FETCH_FAILURE';

export const UPSET_DEVICE_POSITION_REQUEST = 'device/position/UPSET_REQUEST';
export const UPSET_DEVICE_POSITION_SUCCESS = 'device/position/UPSET_SUCCESS';
export const UPSET_DEVICE_POSITION_FAILURE = 'device/position/UPSET_FAILURE';

// ACTION CREATORS
export const fetchDevicePosition = deveui => ({
  [CALL_API]: {
    endpoint: url.DevicePosition(deveui),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_POSITION_REQUEST,
      {
        type: FETCH_DEVICE_POSITION_SUCCESS,
        payload: (action, state, res) => res.json().then(position => {
          return position;
        }),
      },
      {
        type: FETCH_DEVICE_POSITION_FAILURE,
        payload: (action, state, res) => {
          return res.json().then(err => { throw err });
        },
      },
    ],
  },
});

export const upsetDevicePosition = deveui => ({
  [CALL_API]: {
    endpoint: url.DevicePosition(deveui),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      UPSET_DEVICE_POSITION_REQUEST,
      {
        type: UPSET_DEVICE_POSITION_SUCCESS,
        payload: (action, state, res) => res.json().then(position => {
          return position;
        }),
      },
      {
        type: UPSET_DEVICE_POSITION_FAILURE,
        payload: (action, state, res) => {
          return res.json().then(err => { throw err });
        },
      },
    ],
  },
});
