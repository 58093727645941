/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios/index';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  Label,
  Message,
  Modal,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import { UserProfileCreate } from '..';
import { deleteUser, destroyUser, putUser } from '../../actions/user';
import { destroyUserProfilesOptions } from '../../actions/userProfiles';
import history from '../../history';
import {
  getCurrentUserId,
  getCurrentUserToken,
  userHasRole,
} from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import { clean, validateEmail, validatePassword } from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  // user: state.user,
  // userProfilesOptions: state.userProfilesOptions
});

const mapDispatchToProps = dispatch => ({
  // fetchUser: (username) => {
  //   dispatch(fetchUser(username))
  // },
  // fetchUserProfilesOptions: () => {
  //   dispatch(fetchUserProfilesOptions())
  // },
  putUser: (user, id) => {
    dispatch(putUser(user, id));
  },
  deleteUser: user => {
    dispatch(deleteUser(user));
  },
  destroyUserProfilesOptions: () => {
    dispatch(destroyUserProfilesOptions());
  },
  destroyUser: () => {
    dispatch(destroyUser());
  },
});

class UserEditForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      user: {},
      userProfilesOptions: [],
      userProfileSidebar: false,
      modalOpen: false,
      errors: {
        email: false,
        password: false,
      },
    };

    this.handleUserProfileSidebar = this.handleUserProfileSidebar.bind(this);
    this.handleNewUserProfile = this.handleNewUserProfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleProfileSidebar = this.handleProfileSidebar.bind(this);
  }

  componentDidMount() {
    const uid = this.props.match.params.uid ? this.props.match.params.uid : '';

    userHasRole('admin') ? this.axiosFetchUser(uid) : this.axiosFetchUser('');
    userHasRole('admin') && this.axiosFetchUserProfilesOptions();
  }

  axiosFetchUser(uid) {
    Axios.get(url.User(uid), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => this.setState({ user: res.data }))
      .catch(err => devLog(err));
  }

  axiosFetchUserProfilesOptions(name = '', value = '') {
    Axios.get(url.UserProfilesOptions(), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        if (name !== '' && value !== '') {
          const optionIdArray = res.data.filter(o => o.text === value);
          if (optionIdArray.length) {
            this.setState({
              userProfilesOptions: { options: res.data },
              user: {
                ...this.state.user,
                UserProfileId: optionIdArray[0].value,
              },
            });
          }
        } else {
          this.setState({
            userProfilesOptions: { options: res.data },
          });
        }
      })
      .catch(err => devLog(err));
  }

  componentWillUnmount() {
    this.props.destroyUserProfilesOptions();
    this.props.destroyUser();
  }

  // HANDLER SECTION
  handleUserProfileSidebar(e) {
    e.preventDefault();
    this.setState(
      { userProfileSidebar: !this.state.userProfileSidebar },
      () =>
        !this.state.userProfileSidebar && this.axiosFetchUserProfilesOptions(),
    );
  }

  handleClose() {
    this.setState({ modalOpen: false, username: null, check: null });
  }

  handleDeleteModal() {
    this.setState({ modalOpen: true });
  }

  handleDelete() {
    const { user } = this.state;

    this.props.deleteUser(user.id);
    this.setState({ modalOpen: false, check: null });
  }

  handleChange(e, { name, value }) {
    this.setState({ user: { ...this.state.user, [name]: value } });
  }

  handleCheck(e, { name, checked }) {
    this.setState({ user: { ...this.state.user, [name]: checked } });
  }

  handleNewUserProfile(name, value) {
    this.axiosFetchUserProfilesOptions(name, value);
  }

  handleBlur(e) {
    const fieldToValidate = e.target.name;

    switch (fieldToValidate) {
      case 'email':
        if (validateEmail(e.target.value)) {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: false },
          });
        } else {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: true },
          });
        }
        break;
      case 'password':
        if (validatePassword(e.target.value)) {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: false },
          });
        } else {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: true },
          });
        }
        break;
      default:
        throw new Error('Cannot validate this field.');
    }
  }

  handleProfileSidebar(e) {
    e.preventDefault();
    this.setState({ userProfileSidebar: !this.state.userProfileSidebar });
  }

  handleChangePassword = user => {
    history.push({
      pathname: `${PAGES_PATH}/change-password`,
      state: { id: user },
    });
  };

  handleSubmit(e) {
    const { user } = this.state;

    const userSend = {
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      company: user.company,
      status: userHasRole('admin') ? user.status : undefined,
      UserProfileId: userHasRole('admin') ? user.UserProfileId : undefined,
      commercialAds: user.commercialAds,
    };

    e.preventDefault();
    this.props.putUser(clean(userSend), user.id);
  }

  render() {
    const {
      user,
      userProfilesOptions,
      userProfileSidebar,
      errors,
    } = this.state;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Update {user.username}
        </Header>
        {userHasRole('admin') && user.id !== getCurrentUserId() && (
          <Button
            key="delete"
            compact
            icon
            color="red"
            labelPosition="left"
            floated="right"
            style={{ marginTop: '2.4em' }}
            onClick={() => this.handleDeleteModal(user)}
          >
            <FontAwesomeIcon
              icon="trash-alt"
              className="icon"
              style={{ padding: '0.4em' }}
            />
            Delete
          </Button>
        )}

        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/users`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>

        <Divider clearing />

        <Sidebar
          as={Segment}
          inverted
          color="red"
          direction="right"
          animation="overlay"
          visible={userProfileSidebar}
          width="very wide"
        >
          <Button
            floated="right"
            onClick={this.handleUserProfileSidebar}
            size="mini"
          >
            close
          </Button>
          <Header floated="left" as="h2">
            New User Profile
          </Header>
          <Divider clearing inverted />
          <UserProfileCreate
            inverted={true}
            parentHandleNewUserProfile={this.handleNewUserProfile}
            handleUserProfileSidebar={this.handleUserProfileSidebar}
          />
        </Sidebar>

        <Sidebar.Pusher>
          <Form onSubmit={this.handleSubmit}>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                {userHasRole('admin') && (
                  <Form.Group>
                    <Form.Dropdown
                      name="UserProfileId"
                      label="Profile"
                      placeholder="choose one..."
                      width={8}
                      selection
                      required
                      value={idx(user, _ => _.UserProfileId)}
                      options={idx(userProfilesOptions, _ => _.options) || []}
                      onChange={this.handleChange}
                    />
                    <Form.Button
                      width={6}
                      content="Create Profile"
                      label="&nbsp;"
                      onClick={this.handleProfileSidebar}
                      // disabled={!applicationDevices.UserId}
                    />
                  </Form.Group>
                )}

                <Header as="h4">Password</Header>
                <Divider clearing />
                <Form.Group>
                  <Form.Input
                    width={8}
                    value="password"
                    label="Password"
                    name="password"
                    icon="key"
                    type="password"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    disabled
                  />
                  <Form.Button
                    content="Change Password"
                    label="&nbsp;"
                    onClick={() =>
                      this.handleChangePassword(this.state.user.id)
                    }
                  />
                </Form.Group>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={7} computer={7}>
                {errors.password && (
                  <Message negative>
                    <Message.Header>Password not valid.</Message.Header>
                    <p>Your password must be at least 8 characters long.</p>
                  </Message>
                )}
              </Grid.Column>
            </Grid>

            <Header as="h4">Personal Info</Header>
            <Divider clearing />

            <Grid>
              <Grid.Column mobile={16} tablet={7} computer={7}>
                <Form.Input
                  width={8}
                  label="Email"
                  name="email"
                  disabled={userHasRole('admin') ? false : true}
                  value={idx(user, _ => _.email) || ''}
                  onChange={this.handleChange}
                />

                <Form.Input
                  width={8}
                  label="Firstname"
                  name="firstname"
                  required
                  value={idx(user, _ => _.firstname) || ''}
                  onChange={this.handleChange}
                />

                <Form.Input
                  width={8}
                  label="Lastname"
                  name="lastname"
                  required
                  value={idx(user, _ => _.lastname) || ''}
                  onChange={this.handleChange}
                />

                <Form.Input
                  width={8}
                  label="Company"
                  name="company"
                  value={idx(user, _ => _.company) || ''}
                  onChange={this.handleChange}
                />

                {userHasRole('admin') && <Form.Checkbox
                  name="status"
                  label="Status"
                  checked={idx(user, _ => _.status)}
                  onChange={this.handleCheck}
                />}

                <Form.Checkbox
                  name="commercialAds"
                  label="I want to recieve commercial ads"
                  checked={idx(user, _ => _.commercialAds)}
                  onChange={this.handleCheck}
                />

                <Form.Button content="Save" type="submit" />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={7} computer={7}>
                {errors.email && (
                  <Message negative>
                    <Message.Header>Email not valid.</Message.Header>
                    <p>Please check inserted email.</p>
                  </Message>
                )}
              </Grid.Column>
            </Grid>
          </Form>
        </Sidebar.Pusher>

        {idx(user, _ => _.username) && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            basic
            size="small"
          >
            <div style={{ width: '40%' }}>
              <SVG src="/assets/images/robotRYS.svg" />
            </div>
            <Header as="h1" content="Are you sure??" />
            <Modal.Content>
              <h3>
                This action will delete this user and all that belongs to him:
              </h3>
              <ul>
                <li>Gateways</li>
                <li>Devices</li>
                <li>Shares</li>
                <li>Groups</li>
                <li>Applications</li>
              </ul>
              <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
              <p>To proceed, please fill the field with the username</p>
              <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
                {user.username}
              </Label>
              <div>
                <Input
                  name="check"
                  placeholder="..."
                  width={8}
                  onChange={this.handleChange}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={this.handleDelete}
                color="red"
                disabled={user.username !== user.check}
              >
                Proceed
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}
      </Fragment>
    );
  }
}

UserEditForm.propTypes = {
  match: PropTypes.object,
  destroyUserProfilesOptions: PropTypes.func,
  destroyUser: PropTypes.func,
  putUser: PropTypes.func,
  deleteUser: PropTypes.func,
};

const UserUpdate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserEditForm),
);

export { UserUpdate };
