/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Button, Card, Divider, Grid, Header, List } from 'semantic-ui-react';
import {
  destroyGatewayProfile,
  fetchGatewayProfile,
} from '../../actions/gatewayProfile';
import { ListItem } from '../../components';
import { PAGES_PATH } from '../../lib/variables';

const mapStateToProps = state => ({
  gatewayProfile: state.gatewayProfile,
});

const mapDispatchToProps = dispatch => ({
  fetchGatewayProfile: gatewayProfile => {
    dispatch(fetchGatewayProfile(gatewayProfile));
  },
  destroyGatewayProfile: () => {
    dispatch(destroyGatewayProfile());
  },
});

class GPTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchGatewayProfile(this.props.match.params.gpid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.gatewayProfile !== nextProps.gatewayProfile) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.props.destroyGatewayProfile();
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { gatewayProfile } = this.props;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Gateway Profile Info
        </Header>
        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/devices`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>
        <Button
          as={Link}
          key="edit"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/gateway-profile/${gatewayProfile.id}/edit`}
        >
          <FontAwesomeIcon
            icon="edit"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Edit
        </Button>

        <Divider clearing />
        <Grid columns={1} style={{ marginTop: '5em' }}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Details</Card.Header>
                <Divider />
                <Card.Description>
                  <List verticalAlign="middle" divided selection>
                    <ListItem
                      label="label"
                      content={idx(gatewayProfile, _ => _.label) || 'no name'}
                      copy={true}
                    />
                    <ListItem
                      label="band"
                      content={idx(gatewayProfile, _ => _.band)}
                      copy={true}
                    />
                    <ListItem
                      label="transmission power"
                      content={idx(gatewayProfile, _ => _.maxTxPower)}
                      copy={true}
                    />
                    <ListItem
                      label="supports Class B"
                      content={
                        idx(gatewayProfile, _ => _.supportsClassB)
                          ? 'yes'
                          : 'no'
                      }
                      copy={false}
                    />
                    <ListItem
                      label="supports LBT"
                      content={
                        idx(gatewayProfile, _ => _.supportsLbt) ? 'yes' : 'no'
                      }
                      copy={false}
                    />
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

GPTable.propTypes = {
  gatewayProfile: PropTypes.object,
  match: PropTypes.object,
  fetchGatewayProfile: PropTypes.func,
  destroyGatewayProfile: PropTypes.func,
};

const GatewayProfileRead = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GPTable),
);

export { GatewayProfileRead };
