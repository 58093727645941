/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import {
  generateUrl,
  generateSimpleQueryV2,
  useThisQueryString,
  usersOptionsFilter,
} from '../lib/urls';
import { getCurrentUserToken, deleteCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_USERS_REQUEST = 'users/FETCH_REQUEST';
export const FETCH_USERS_SUCCESS = 'users/FETCH_SUCCESS';
export const FETCH_USERS_FAILURE = 'users/FETCH_FAILURE';

export const FETCH_USERS_OPTIONS_REQUEST = 'usersOptions/FETCH_REQUEST';
export const FETCH_USERS_OPTIONS_SUCCESS = 'usersOptions/FETCH_SUCCESS';
export const FETCH_USERS_OPTIONS_FAILURE = 'usersOptions/FETCH_FAILURE';

export const DESTROY_USERS_SUCCESS = 'users/DESTROY_SUCCESS';

export const DESTROY_USERS_OPTIONS_SUCCESS = 'usersOptions/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchUsers = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.Users(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_USERS_REQUEST,
      {
        type: FETCH_USERS_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_USERS_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

// export const fetchUsersOptions = (params = {'roles[]': 'standard'}) => {
export const fetchUsersOptions = (params = {}) => {
  const qs = usersOptionsFilter(params);
  return {
    [CALL_API]: {
      endpoint: generateUrl(url.Users(), generateSimpleQueryV2(qs)),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_USERS_OPTIONS_REQUEST,
        {
          type: FETCH_USERS_OPTIONS_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(json => ({
              options: json.rows,
              filter: 'filter' in params ? params.filter : {},
            })),
        },
        {
          type: FETCH_USERS_OPTIONS_FAILURE,
          payload: (action, state, res) => {
            if (res && res.status && res.status === 401) {
              deleteCurrentUserToken();
              history.push(`${PAGES_PATH}`);
            }
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
};

export const destroyUsers = () => ({
  type: DESTROY_USERS_SUCCESS,
});

export const destroyUsersOptions = () => ({
  type: DESTROY_USERS_OPTIONS_SUCCESS,
});
