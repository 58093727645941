import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentUserTokenWithoutBearer } from '../../lib/auth';
import PropTypes from 'prop-types';
import {
  fetchUserMe
} from '../../actions/me'

const Me = ({children}) => {
  const token = getCurrentUserTokenWithoutBearer();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserMe(token));
  }, []);

  return (
    <Fragment>
      { children }
    </Fragment>
  );
};

Me.propTypes = {
  children: PropTypes.node
};

export { Me };
