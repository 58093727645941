/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  Label,
  Message,
  Modal,
  Sidebar,
} from 'semantic-ui-react';
import {
  deleteUserProfile,
  destroyUserProfile,
  putUserProfile,
} from '../../actions/userProfile';
import { fetchUserProfileSingle } from '../../actions/userProfileSingle';
import history from '../../history';
import { getCurrentUserId, userHasRole } from '../../lib/auth';
import { PAGES_PATH } from '../../lib/variables';

const mapStateToProps = state => ({
  userProfileSingle: state.userProfileSingle,
});

const mapDispatchToProps = dispatch => ({
  fetchUserProfileSingle: userProfileSingle => {
    dispatch(fetchUserProfileSingle(userProfileSingle));
  },
  putUserProfile: (userProfile, id) => {
    dispatch(putUserProfile(userProfile, id));
  },
  deleteUserProfile: userProfile => {
    dispatch(deleteUserProfile(userProfile));
  },
  destroyUserProfile: () => {
    dispatch(destroyUserProfile());
  },
});

class UserProfileEditForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      userProfileSingle: {},
      modalOpen: false,
      errors: {
        email: false,
        password: false,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleBlur = this.handleBlur.bind(this)
  }

  componentDidMount() {
    this.props.fetchUserProfileSingle(this.props.match.params.uid);

    // fetchUserProfilesOptions()
    // userHasRole('admin') && fetchUserProfilesOptions()
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.userProfileSingle) {
      this.setState({
        userProfileSingle: {
          ...this.state.userProfileSingle,
          id: nextProps.userProfileSingle.id,
          label: nextProps.userProfileSingle.label,
          role: nextProps.userProfileSingle.role,
          maxdevices: parseInt(nextProps.userProfileSingle.maxdevices, 10),
          maxgateways: nextProps.userProfileSingle.maxgateways,
          maxgroups: nextProps.userProfileSingle.maxgroups,
          maxuplink: nextProps.userProfileSingle.maxuplink,
          maxdownlink: nextProps.userProfileSingle.maxdownlink,
          cacheperiod: nextProps.userProfileSingle.cacheperiod,
          life: nextProps.userProfileSingle.life,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyUserProfile();
  }

  backPage = () => {
    history.push({
      pathname: `${PAGES_PATH}/users`,
      state: { activeIndex: 1 },
    });
  };

  // HANDLER SECTION
  handleClose() {
    this.setState({ modalOpen: false, username: null, check: null });
  }

  handleDeleteModal() {
    this.setState({ modalOpen: true });
  }

  handleDelete() {
    if (this.props.userProfileSingle.role === 'admin') {
      this.props.userProfileSingle.error = true;
    }

    this.props.deleteUserProfile(this.props.userProfileSingle.id);
    this.setState({ modalOpen: false, check: null });
    setTimeout(() => this.backPage(), 300);
  }

  handleChange(e, { name, value }) {
    this.setState({
      userProfileSingle: {
        ...this.state.userProfileSingle,
        [name]: name === 'label' ? value : parseInt(value, 10),
      },
    });
  }

  handleCheck(e, { name, checked }) {
    this.setState({
      userProfileSingle: { ...this.state.userProfileSingle, [name]: checked },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { userProfileSingle } = this.state;
    const profileSingleUpdated = {
      ...userProfileSingle,
      maxdevices: parseInt(userProfileSingle.maxdevices, 10),
      maxdownlink: parseInt(userProfileSingle.maxdownlink, 10),
      maxgateways: parseInt(userProfileSingle.maxgateways, 10),
      maxgroups: parseInt(userProfileSingle.maxgroups, 10),
      maxuplink: parseInt(userProfileSingle.maxuplink, 10),
      cacheperiod: parseInt(userProfileSingle.cacheperiod, 10),
      life: parseInt(userProfileSingle.life, 10),
    };
    delete profileSingleUpdated.role;
    delete profileSingleUpdated.id;

    Object.keys(profileSingleUpdated).forEach(key => {
      if (profileSingleUpdated[key] === null || Number.isNaN(profileSingleUpdated[key])) {
        delete profileSingleUpdated[key];
      }
    });

    this.props.putUserProfile(profileSingleUpdated, userProfileSingle.id);
    setTimeout(
      () => history.push({ pathname: `${PAGES_PATH}/users`, state: 1 }),
      300,
    );
  }

  render() {
    const { userProfileSingle } = this.state;
    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Update {userProfileSingle.label}
        </Header>
        {userHasRole('admin') && userProfileSingle.id !== getCurrentUserId() && (
          <Button
            key="delete"
            compact
            icon
            color="red"
            labelPosition="left"
            floated="right"
            style={{ marginTop: '2.4em' }}
            onClick={() => this.handleDeleteModal(userProfileSingle)}
          >
            <FontAwesomeIcon
              icon="trash-alt"
              className="icon"
              style={{ padding: '0.4em' }}
            />
            Delete
          </Button>
        )}

        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={{ pathname: `${PAGES_PATH}/users`, state: 1 }}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>

        <Divider clearing />

        <Sidebar.Pusher>
          <Form onSubmit={this.handleSubmit}>
            <Header as="h4">Profile Info</Header>
            <Divider clearing />

            {this.props.userProfileSingle.error === true && (
              <Message negative>
                <Message.Header>
                  OPS..error another admin can't delete another admin
                </Message.Header>
                {/* <p>{this.props.userProfileSingle.message}</p> */}
                <p>Please check your information or contact-us.</p>
              </Message>
            )}

            <Grid>
              <Grid.Column mobile={16} tablet={6} computer={6}>
                <Form.Input
                  label="Label"
                  name="label"
                  required
                  value={idx(userProfileSingle, _ => _.label) || ''}
                  onChange={this.handleChange}
                />

                {userProfileSingle && userProfileSingle.role && (
                  <Form.Input
                    label="Role"
                    name="role"
                    disabled
                    value={idx(userProfileSingle, _ => _.role)}
                  />
                )}

                <Form.Input
                  label="Max Devices"
                  name="maxdevices"
                  required
                  type="number"
                  value={idx(userProfileSingle, _ => _.maxdevices)}
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Max Gateways"
                  name="maxgateways"
                  type="number"
                  required
                  value={idx(userProfileSingle, _ => _.maxgateways)}
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Max Groups"
                  name="maxgroups"
                  type="number"
                  value={idx(userProfileSingle, _ => _.maxgroups)}
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Max Up Link"
                  name="maxuplink"
                  type="number"
                  value={idx(userProfileSingle, _ => _.maxuplink)}
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Max Down Link"
                  name="maxdownlink"
                  type="number"
                  value={idx(userProfileSingle, _ => _.maxdownlink)}
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Cache Period"
                  name="cacheperiod"
                  type="number"
                  value={idx(userProfileSingle, _ => _.cacheperiod)}
                  onChange={this.handleChange}
                />

                <Form.Input
                  width={8}
                  label="Life"
                  name="life"
                  type="number"
                  value={idx(userProfileSingle, _ => _.life)}
                  onChange={this.handleChange}
                />

                <Form.Button content="Save" type="submit" />
              </Grid.Column>
            </Grid>
          </Form>
        </Sidebar.Pusher>

        {idx(userProfileSingle, _ => _.label) && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            basic
            size="small"
          >
            <div style={{ width: '40%' }}>
              <SVG src="/assets/images/robotRYS.svg" />
            </div>
            <Header as="h1" content="Are you sure??" />
            <Modal.Content>
              <h3>
                This action will delete this user profile and all that belongs
                to him:
              </h3>
              <ul>
                <li>Gateways</li>
                <li>Devices</li>
                <li>Shares</li>
                <li>Groups</li>
                <li>Applications</li>
              </ul>
              <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
              <p>To proceed, please fill the field with the username</p>
              <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
                {userProfileSingle.label}
              </Label>
              <div>
                <Input
                  name="check"
                  placeholder="..."
                  width={8}
                  onChange={this.handleChange}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={this.handleDelete}
                color="red"
                disabled={userProfileSingle.label !== userProfileSingle.check}
              >
                Proceed
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}
      </Fragment>
    );
  }
}

UserProfileEditForm.propTypes = {
  userProfileSingle: PropTypes.object,
  match: PropTypes.object,
  fetchUserProfileSingle: PropTypes.func,
  putUserProfile: PropTypes.func,
  deleteUserProfile: PropTypes.func,
  destroyUserProfile: PropTypes.func,
};

const UserProfileUpdate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfileEditForm),
);

export { UserProfileUpdate };
