/** @format */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Message } from 'semantic-ui-react';
import { userSignIn } from '../../actions/auth';

const mapStateToProps = state => ({
  signin: state.signin,
});

const mapDispatchToProps = dispatch => ({
  userSignIn: (username, password) => {
    dispatch(userSignIn(username, password));
  },
});

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      remember: false,
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  componentDidMount() {
    if (this.state.username !== null) {
      this.setState({ remember: true });
    }
  }

  handleChange(e, { name, value }) {
    if (name === 'remember') {
      this.setState({ ...this.state, [name]: !this.state.remember });
    } else {
      this.setState({ ...this.state, [name]: value });
    }
  }

  handleSignIn(e) {
    const { username, password } = this.state;
    e.preventDefault();
    if (this.state.remember) {
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }
    this.props.userSignIn(this.state.username, this.state.password);
  }

  render() {
    const { username, password, remember } = this.state;
    return (
      <Form onSubmit={this.handleSignIn}>
        {this.props.signin.error === true && (
          <Message negative>
            <Message.Header>OPS... Wrong credentials!</Message.Header>
            {/* <p>{this.props.signin.message}</p> */}
            <p>Please check your information or contact-us.</p>
          </Message>
        )}

        <Form.Input
          name="username"
          size="large"
          icon="user"
          label="Username"
          onChange={this.handleChange}
          defaultValue={username !== null ? username : ''}
          required
          error={this.props.signin.error}
        />

        <Form.Input
          name="password"
          type="password"
          size="large"
          icon="key"
          label="Password"
          onChange={this.handleChange}
          defaultValue={password !== null ? password : ''}
          required
          error={this.props.signin.error}
        />
        <Form.Checkbox
          name="remember"
          label="Remember me"
          checked={remember}
          onChange={this.handleChange}
        />
        <Button color="red" type="submit" animated="vertical">
          <Button.Content visible>Sign in</Button.Content>
          <Button.Content hidden>Come on!!!</Button.Content>
        </Button>
      </Form>
    );
  }
}

SignInForm.propTypes = {
  signin: PropTypes.object,
  userSignIn: PropTypes.func,
};

const UserSignIn = connect(mapStateToProps, mapDispatchToProps)(SignInForm);

export { UserSignIn };
