/** @format */

import Axios from 'axios';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Card, Divider, List } from 'semantic-ui-react';
import { ListItem } from '../../components';
import { getCurrentUserToken } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  // gateway: state.gateway.data || {}
});

const mapDispatchToProps = dispatch => ({});

class _GatewayAlerts extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    // The component custom status
    this.state = {
      alerts: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Update component status getting info from API REST
   */
  componentDidMount() {
    Axios.get(url.StatsAlerts(this.props.match.params.gid), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res =>
        this.setState({
          ...this.state,
          alerts: {
            ...res.data,
          },
        }),
      )
      .catch(error => {
        devLog(error);
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.stat !== nextProps.stat) {
      return true;
    }

    return false;
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  /**
   * Provide JSX given the alerts information
   * @param {object} alerts the alerts object, e.g. {rows: Array(1), size: 1, offset: 0, limits: -1}
   * @returns {object} the related JSX
   */
  alertsList = alerts => {
    if (idx(alerts, _ => _.size) > 0) {
      const list = idx(alerts, _ => _.rows);
      return list.map(d => (
        <ListItem key={d.key} label={d.key} content={d.doc_count} copy />
      ));
    }
    return <ListItem label="Alerts" content="none" copy />;
  };

  render() {
    const { alerts } = this.state;

    return (
      <Card.Content>
        <Card.Header>Alerts [last 6h]</Card.Header>
        <Divider />
        <Card.Description>
          <List verticalAlign="middle" divided selection>
            {this.alertsList(alerts)}
          </List>
        </Card.Description>
      </Card.Content>
    );
  }
}

_GatewayAlerts.propTypes = {
  gateway: PropTypes.object,
  match: PropTypes.object,
  stat: PropTypes.object,
};

const GatewayAlerts = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(_GatewayAlerts),
);

export { GatewayAlerts };
