/** @format */

import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';
import { Doughnut } from 'react-chartjs-2';
import chroma from 'chroma-js';
import PropTypes from 'prop-types';
import { axiosCall } from './axiosService';
import StatsPlaceholder from './StatPLaceholder';

class Donut extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          borderWidth: 2,
          data: [],
        },
      ],
      error: false,
    };
  }

  componentDidMount() {
    const { title, errorhandler } = this.props;
    const { labels } = this.state;
    const data = this.state.datasets;
    axiosCall(this.props.url, 'get')
      .then(res => {
        if (title === 'Uplink Alerts') {
          const { rows } = res.data;
          rows.forEach(d => {
            labels.push(d.key);
            data[0].data.push(d.doc_count);
            data[0].backgroundColor.push(chroma.random().hex());
          });
          this.setState({ labels, datasets: data });
        } else {
          const { rows } = res.data;
          rows.forEach(d => {
            if (this.props.title === 'Uplink Frequencies [last 24h]') {
              labels.push(`${d.key} Mhz`);
            } else if (this.props.title === 'Uplink Spreading Factor [last 24h]') {
              labels.push(`SF ${d.key}`);
            } else {
              labels.push(`${d.key}`);
            }
            data[0].data.push(d.doc_count);
            data[0].backgroundColor.push(chroma.random().hex());
          });
          this.setState({ labels, datasets: data });
        }
      })
      .catch(() => {
        this.setState({ error: true });
        errorhandler();
      });
  }

  render() {
    const { error } = this.state;
    const { title } = this.props;
    return (
      <Card fluid>
        {error !== true ? (
          <Card.Content style={{ textAlign: 'center' }}>
            {this.state.labels.length === 0 ? (
              <>
                <h3 style={{ color: '#666666', marginTop: '10px' }}>{title}</h3>
                <div>Nothing to show</div>
              </>
            ) : (
              <Doughnut
                data={this.state}
                height={200}
                options={{
                  title: {
                    display: true,
                    text: title,
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: 'top',
                  },
                }}
              />
            )}
          </Card.Content>
        ) : (
          <Card.Content>
            <Card.Header
              style={{ textAlign: 'center', color: '#666666' }}
              content={title}
            />
            <Card.Description>
              <StatsPlaceholder />
            </Card.Description>
          </Card.Content>
        )}
      </Card>
    );
  }
}

Donut.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  errorhandler: PropTypes.func.isRequired,
};

export { Donut };
