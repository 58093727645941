/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken, deleteCurrentUserToken } from '../lib/auth';
import { useThisQueryString } from '../lib/urls';
import history from '../history';
import { PAGES_PATH } from '../lib/variables';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const POST_APPLICATION_DEVICES_REQUEST =
  'applicationDevices/POST_REQUEST';
export const POST_APPLICATION_DEVICES_SUCCESS =
  'applicationDevices/POST_SUCCESS';
export const POST_APPLICATION_DEVICES_FAILURE =
  'applicationDevices/POST_FAILURE';

export const FETCH_APPLICATION_DEVICES_REQUEST =
  'applicationDevices/FETCH_REQUEST';
export const FETCH_APPLICATION_DEVICES_SUCCESS =
  'applicationDevices/FETCH_SUCCESS';
export const FETCH_APPLICATION_DEVICES_FAILURE =
  'applicationDevices/FETCH_FAILURE';

export const FETCH_APPLICATION_DEVICES_MAP_REQUEST =
  'applicationDevicesMap/FETCH_REQUEST';
export const FETCH_APPLICATION_DEVICES_MAP_SUCCESS =
  'applicationDevicesMap/FETCH_SUCCESS';
export const FETCH_APPLICATION_DEVICES_MAP_FAILURE =
  'applicationDevicesMap/FETCH_FAILURE';

export const DELETE_APPLICATION_DEVICES_REQUEST =
  'applicationDevices/DELETE_REQUEST';
export const DELETE_APPLICATION_DEVICES_SUCCESS =
  'applicationDevices/DELETE_SUCCESS';
export const DELETE_APPLICATION_DEVICES_FAILURE =
  'applicationDevices/DELETE_FAILURE';

export const DESTROY_APPLICATION_DEVICES_SUCCESS =
  'applicationDevices/DESTROY_SUCCESS';

export const DESTROY_APPLICATION_DEVICES_MAP_SUCCESS =
  'applicationDevicesMap/DESTROY_SUCCESS';

// ACTION CREATORS
export const postApplicationDevices = applicationsDevices => ({
  [CALL_API]: {
    endpoint: url.ApplicationDeviceCreate(applicationsDevices.ApplicationId),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(applicationsDevices),
    types: [
      POST_APPLICATION_DEVICES_REQUEST,
      {
        type: POST_APPLICATION_DEVICES_SUCCESS,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/devices`);
          return res.json().then(json => json);
        },
      },
      {
        type: POST_APPLICATION_DEVICES_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

// Fetch devices in Multicast Group
export const fetchApplicationDevices = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.ApplicationDevicesNew(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_APPLICATION_DEVICES_REQUEST,
      {
        type: FETCH_APPLICATION_DEVICES_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_APPLICATION_DEVICES_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const fetchApplicationDevicesMap = application => ({
  [CALL_API]: {
    endpoint: url.ApplicationDevicesMap(application),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_APPLICATION_DEVICES_MAP_REQUEST,
      {
        type: FETCH_APPLICATION_DEVICES_MAP_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(applications => applications),
      },
      {
        type: FETCH_APPLICATION_DEVICES_MAP_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

// Delete device in Multicast Group
export const deleteApplicationDevices = (application, device) => ({
  [CALL_API]: {
    endpoint: url.ApplicationDeviceDelete(application, device),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_APPLICATION_DEVICES_REQUEST,
      {
        type: DELETE_APPLICATION_DEVICES_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: DELETE_APPLICATION_DEVICES_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const destroyApplicationDevices = () => ({
  type: DESTROY_APPLICATION_DEVICES_SUCCESS,
});

export const destroyApplicationDevicesMap = () => ({
  type: DESTROY_APPLICATION_DEVICES_MAP_SUCCESS,
});
