/** @format */

import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Header,
  Input,
  Label,
  Modal,
  Pagination,
  Table,
} from 'semantic-ui-react';
import {
  deleteGatewayProfile,
  destroyGatewayProfile,
} from '../../actions/gatewayProfile';
import {
  destroyGatewayProfiles,
  fetchGatewayProfiles,
} from '../../actions/gatewayProfiles';
import {
  EMPTY_TABLE_MESSAGE,
  formatNormalString,
  searchMethod,
} from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';
import SearchBox from '../../ui/SearchBox';

const mapStateToProps = state => ({
  gatewayProfiles: state.gatewayProfiles
});

const mapDispatchToProps = dispatch => ({
  fetchGatewayProfiles: (params = '?limit=100') => {
    dispatch(fetchGatewayProfiles(params));
  },
  deleteGatewayProfile: gateway => {
    dispatch(deleteGatewayProfile(gateway));
  },
  destroyGatewayProfiles: () => {
    dispatch(destroyGatewayProfiles());
  },
  destroyGatewayProfile: () => {
    dispatch(destroyGatewayProfile());
  }
});

class GwTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [
        {
          width: 4,
          label: 'Transmission Power',
          name: 'maxTxPower',
          type: 'select',
        },
        {
          width: 3,
          label: 'Band',
          name: 'band',
          type: 'select',
        },
        {
          width: 3,
          label: 'Supports Class B',
          name: 'supportsClassB',
          type: 'select',
        },
        {
          width: 3,
          label: 'Supports LBT',
          name: 'supportsLbt',
          type: 'select',
        },
      ],
      offset: 0,
      limit: 12,
      searchModel: {},
      sortModel: {
        label: '',
        direction: null,
        /* asc || desc || null */
      },
      modalOpen: false,
      gatewayProfile: null,
      label: null,
      check: null,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.getGatewaysUsed = this.getGatewaysUsed.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams ? queryParams.join('&') : '';
        this.props.fetchGatewayProfiles({ qs });
      },

      handlePageChange: (e, { activePage }) => {
        const { gatewayProfiles, offset } = this.props;
        const { searchModel, sortModel, limit } = this.state;

        const newOffset = (activePage - 1) * gatewayProfiles.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );

        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        let currSortModel = null;
        if (newSortModel) currSortModel = { ...newSortModel };
        else if (sortModel.label) currSortModel = { ...sortModel };

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        // debugger
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);
      },

      handleSearchChange: (e, { name, value }) => {
        const { searchModel } = this.state;
        const newFormGroup = [...this.state.formGroup];
        const newSearchModel = { ...searchModel };

        const formIdx = newFormGroup.map(x => x.name).indexOf(name);
        switch (value) {
          case '':
            delete newSearchModel[name];
            break;

          default:
            switch (name) {
              case 'id':
                newSearchModel[name] = encodeURIComponent(
                  formatNormalString(value, ':'),
                );
                break;
              default:
                newSearchModel[name] = encodeURIComponent(value);
            }
        }
        if (formIdx >= 0) newFormGroup[formIdx].selected = value;
        this.setState({
          searchModel: newSearchModel,
          formGroup: newFormGroup,
        });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;

            case 'desc':
              direction = null;
              break;

            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    const { limit } = this.state;
    this.props.fetchGatewayProfiles({ qs: limit > 0 ? `limit=${limit}` : '' });
  }

  componentWillUnmount() {
    // this.props.destroyGatewayProfiles();
    this.props.destroyGatewayProfile();
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      gateway: null,
      gatewayId: null,
      check: null,
    });
  }

  handleDeleteModal(gatewayProfile) {
    this.setState({
      modalOpen: true,
      gatewayProfile,
      label: gatewayProfile.label,
    });
  }

  handleDelete() {
    const { gatewayProfile } = this.state;
    this.props.deleteGatewayProfile(gatewayProfile);
    this.props.fetchGatewayProfiles();
    this.setState({
      modalOpen: false,
      gateway: null,
      gatewayId: null,
      check: null,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  getGatewaysUsed = (id, gateways) => {
    const o = gateways.rows.filter(gateway => gateway.GatewayProfileId === id);
    return o.length || 0;
  };

  render() {
    const { gatewayProfiles } = this.props;
    const { formGroup } = this.state;

    return (
      <Fragment>
        {formGroup.length &&
          gatewayProfiles &&
          gatewayProfiles.rows &&
          gatewayProfiles.rows.length > 0 && (
            <SearchBox
              maxTxPower={[14, 27]}
              supportsBool={['false', 'true']}
              bandValues={['AS920-925', 'EU863-870', 'US902-928']}
              formGroup={formGroup}
              startSearch={this.searchBox.handleSearch}
              updateSearch={this.searchBox.handleSearchChange}
            />
          )}

        {gatewayProfiles.rows && gatewayProfiles.rows.length > 0 ? (
          <Table sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('id')}
                  onClick={this.searchBox.handleSort('id')}
                >
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('label')}
                  onClick={this.searchBox.handleSort('label')}
                >
                  Label
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('maxTxPower')}
                  onClick={this.searchBox.handleSort('maxTxPower')}
                >
                  Transmission Power
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('supportsClassB')}
                  onClick={this.searchBox.handleSort('supportsClassB')}
                >
                  Supports Class B
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('supportsLbt')}
                  onClick={this.searchBox.handleSort('supportsLbt')}
                >
                  Supports LBT
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('Band')}
                  onClick={this.searchBox.handleSort('bandValues')}
                >
                  Band
                </Table.HeaderCell>
                <Table.HeaderCell className="unsortable">
                  Gateways
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {idx(gatewayProfiles, accessory =>
                accessory.rows.map(row => (
                  <Table.Row key={`row-${row.id}`}>
                    <Table.Cell key={`row-${row.id}-label`}>
                      <Link
                        to={`${PAGES_PATH}/gateway-profile/${idx(
                          row,
                          _ => _.id,
                        )}`}
                      >
                        <strong>{idx(row, _ => _.id) || 'no name'}</strong>
                      </Link>
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-transmission`}>
                      {idx(row, _ => _.label)}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-maxTxPower`}>
                      {idx(row, _ => _.maxTxPower)}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-supportsClassB`}>
                      {idx(row, _ => _.supportsClassB) ? 'yes' : 'no'}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-supportsLbt`}>
                      {idx(row, _ => _.supportsLbt) ? 'yes' : 'no'}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-bandValues`}>
                      {idx(row, _ => _.band)}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-gateways`}>
                      {idx(row, _ => _.gateways)}
                    </Table.Cell>
                  </Table.Row>
                )),
              )}
            </Table.Body>
          </Table>
        ) : (
          EMPTY_TABLE_MESSAGE
        )}

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will delete this profile and all related gateways.
            </h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the application label</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {this.state.label}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={this.state.label !== this.state.check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>

        <Container textAlign="center">
          {gatewayProfiles && gatewayProfiles.size > gatewayProfiles.limit && (
            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              activePage={
                parseInt(gatewayProfiles.offset / gatewayProfiles.limit, 10) + 1
              }
              totalPages={Math.ceil(
                gatewayProfiles.size / gatewayProfiles.limit,
              )}
              onPageChange={this.searchBox.handlePageChange}
            />
          )}
        </Container>
      </Fragment>
    );
  }
}

GwTable.propTypes = {
  gatewayProfiles: PropTypes.object,
  fetchGatewayProfiles: PropTypes.func,
  destroyGatewayProfiles: PropTypes.func,
  destroyGatewayProfile: PropTypes.func,
  deleteGatewayProfile: PropTypes.func,
  offset: PropTypes.object,
  gateways: PropTypes.object,
};

const GatewayProfilesTable = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GwTable),
);

export { GatewayProfilesTable };
