/** @format */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Message } from 'semantic-ui-react';

import { fetchDevicePosition, upsetDevicePosition } from '../../actions/devicePosition';

import { MapContainer } from '../../components';

import { GOOGLE_MAPS_API_KEY } from '../../lib/variables';

const DevicePosition = ({ deveui = null }) => {
  const dispatch = useDispatch();
  const { position: remotePosition = {}, isLoading = false, error = false } = useSelector(state => state.devicePosition);

  const [position, setPosition] = useState(null)

  useEffect(() => {
    if (deveui) {
      dispatch(fetchDevicePosition(deveui));
    }
  }, []);

  useEffect(() => {
    setPosition(remotePosition)
  }, [position])

  const updatePosition = () => {
    dispatch(upsetDevicePosition(deveui));
  }

  return (
    <>
      {error && <Message color='yellow'>It was not possible to recover the position</Message>}
      {!isLoading && remotePosition && remotePosition.latitude && remotePosition.longitude && GOOGLE_MAPS_API_KEY && <>
        <MapContainer
          markers={[
            { lat: remotePosition.latitude, lng: remotePosition.longitude },
          ]}
          isMarkerShown
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
        />
      </>}
      {!isLoading && remotePosition && remotePosition.latitude && remotePosition.longitude && !GOOGLE_MAPS_API_KEY && <iframe title='DevicePosition' src={`https://maps.google.com/maps?q=${remotePosition.latitude},${remotePosition.longitude}&z=16&output=embed`} height="450" width="100%" style={{border: 0}} />}

      <div style={{marginTop: "1em"}}>
        <Button onClick={updatePosition}>UPDATE POSITION</Button>
      </div>
    </>
  )
};


DevicePosition.propTypes = {
  deveui: PropTypes.string
};

export { DevicePosition };