/** @format */

import React, { Component } from 'react';
import { List } from 'semantic-ui-react';
import Axios from 'axios';
import { Endpoints as url } from '../../lib/endpoints';
import { getCurrentUserToken, userHasRole } from '../../lib/auth';
import devLog from '../../lib/devLog';
import PropTypes from 'prop-types';
class DevicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
    };
  }

  componentWillMount() {
    const { application } = this.props;
    if (userHasRole('admin')) {
      Axios.get(
        url.ApplicationDevicesListAdm(application.appid, application.UserId),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCurrentUserToken(),
          },
        },
      )
        .then(response => this.setState({ devices: response.data.rows }))
        .catch(error => devLog(error));
    } else {
      Axios.get(
        url.ApplicationDevicesListStd(application.appid, application.UserId),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCurrentUserToken(),
          },
        },
      )
        .then(response => this.setState({ devices: response.data.rows }))
        .catch(error => devLog(error));
    }
  }

  render() {
    const { devices } = this.state;

    return (
      <List bulleted>
        {devices.map(d => (
          <List.Item key={d.id}>
            {d.label || 'no name'} [ {d.deveui} ]
          </List.Item>
        ))}
      </List>
    );
  }
}
DevicesList.propTypes = {
  application: PropTypes.object,
};
export { DevicesList };
