/** @format */

import React, { useEffect } from 'react';
import { Button, Card, Container, Grid, Header, Image } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
// import { Timeline } from 'react-twitter-widgets'
import SVG from 'react-inlinesvg';
import { PAGES_PATH } from '../../lib/variables';
import { getCurrentUserToken } from '../../lib/auth';

import { Footer } from '../../components';
import { UserSignIn } from '../../containers';

import './Home.css';

const Home = () => {
  const history = useHistory();
  const token = getCurrentUserToken();

  useEffect(() => {
    if (token) history.push(`${PAGES_PATH}`);
  }, []);

  return (
    <div className="home">
      <Container fluid className="main">
        <Grid padded columns={1} textAlign="center">
          <Grid.Column mobile={16} tablet={7} computer={7}>
            <Card fluid>
              <Card.Content textAlign="left">
                <Image.Group size="small">
                  <Image src={'/assets/images/logoColor.png'} />
                </Image.Group>
              </Card.Content>
              <Card.Content>
                <Container>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column
                        textAlign="left"
                        verticalAlign="bottom"
                        width={8}
                      >
                        <Header color="teal" className="brandPT">
                          NETWORK
                          <br />
                          SERVER
                          <br />
                          PATAVINA
                          <br />
                          NETSUITE
                        </Header>
                      </Grid.Column>
                      <Grid.Column
                        textAlign="right"
                        verticalAlign="bottom"
                        width={8}
                      >
                        <SVG
                          src="/assets/images/robotLove.svg"
                          className="brandLove"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Card.Content>
  
              <Card.Content extra textAlign="left">
                <UserSignIn />
              </Card.Content>
              <Card.Content extra>
                <Container>
                  <Button
                    as={Link}
                    color="red"
                    className="mini"
                    to="/user/remember"
                    floated="right"
                  >
                    Forgot password
                  </Button>
                </Container>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export { Home };
