/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const POST_GATEWAY_SHARE_REQUEST = 'gatewayShare/POST_REQUEST';
export const POST_GATEWAY_SHARE_SUCCESS = 'gatewayShare/POST_SUCCESS';
export const POST_GATEWAY_SHARE_FAILURE = 'gatewayShare/POST_FAILURE';

export const DELETE_GATEWAY_SHARE_REQUEST = 'gatewayShare/DELETE_REQUEST';
export const DELETE_GATEWAY_SHARE_SUCCESS = 'gatewayShare/DELETE_SUCCESS';
export const DELETE_GATEWAY_SHARE_FAILURE = 'gatewayShare/DELETE_FAILURE';

export const DESTROY_GATEWAY_SHARE_SUCCESS = 'gatewaysSahre/DESTROY_SUCCESS';

// ACTION CREATORS
export const postGatewayShare = gateways => ({
  [CALL_API]: {
    endpoint: url.GatewayShareCreate(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(gateways),
    types: [
      POST_GATEWAY_SHARE_REQUEST,
      {
        type: POST_GATEWAY_SHARE_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/gateways` });
          return res.json().then(json => json);
        },
      },
      {
        type: POST_GATEWAY_SHARE_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});
export const deleteGatewayShare = gateway => ({
  [CALL_API]: {
    endpoint: url.GatewayShareDelete(gateway),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_GATEWAY_SHARE_REQUEST,
      {
        type: DELETE_GATEWAY_SHARE_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/gateways` });
          return res.json().then(json => json);
        },
      },
      {
        type: DELETE_GATEWAY_SHARE_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/gateways`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyGatewayShare = () => ({
  type: DESTROY_GATEWAY_SHARE_SUCCESS,
});
