/** @format */

import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Button,
  Divider,
  Form,
  Header,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import { ApplicationCreate } from '..';
import {
  destroyApplicationDevices,
  postApplicationDevices,
} from '../../actions/applicationDevices';
import { fetchApplicationsOptions } from '../../actions/applications';
import { fetchUsersOptions } from '../../actions/users';
import { formatOptions } from '../../lib/utilities';

const mapStateToProps = state => ({
  usersOptions: state.usersOptions,
  application: state.application,
  applicationDevices: state.applicationDevices,
  applicationsOptions: state.applicationsOptions,
});

const mapDispatchToProps = dispatch => ({
  fetchUsersOptions: (params = {}) => {
    dispatch(fetchUsersOptions(params));
  },
  fetchApplicationsOptions: (params = {}) => {
    dispatch(fetchApplicationsOptions(params));
  },
  postApplicationDevices: applicationDevices => {
    dispatch(postApplicationDevices(applicationDevices));
  },
  destroyApplicationDevices: () => {
    dispatch(destroyApplicationDevices());
  },
});

class ApplicationAddForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      applicationDevices: {
        ids: this.props.location.state.devices,
        UserId: this.props.location.state.UserId,
        ApplicationId: null,
      },
      applicationSidebar: false,
    };

    this.handleApplicationSidebar = this.handleApplicationSidebar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchApplicationsOptions(this.state.applicationDevices.UserId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }

    if (
      nextProps.applicationsOptions.options !==
      this.props.applicationsOptions.options
    ) {
      return true;
    }

    if (nextProps.application !== this.props.application) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.application.id !== this.props.application.id) {
      const params = { filter: { label: this.props.application.label } };
      this.props.fetchApplicationsOptions(
        this.state.applicationDevices.UserId,
        params,
      );

      this.setState({
        applicationDevices: {
          ...this.state.applicationDevices,
          ApplicationId: this.props.application.id,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyApplicationDevices();
  }

  // HANDLER SECTION
  handleApplicationSidebar(e) {
    e.preventDefault();
    this.setState({ applicationSidebar: !this.state.applicationSidebar });
  }

  handleChange(e, { name, value }) {
    this.setState({
      applicationDevices: { ...this.state.applicationDevices, [name]: value },
    });
  }

  handleSubmit(e) {
    const { applicationDevices } = this.state;

    e.preventDefault();
    this.props.postApplicationDevices(applicationDevices);
  }

  render() {
    const { applicationDevices, applicationSidebar } = this.state;
    const { applicationsOptions } = this.props;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Add to an Application
        </Header>
        <Divider clearing />
        <Sidebar.Pushable style={{ height: '80vh' }}>
          <Sidebar
            as={Segment}
            inverted
            color="red"
            direction="right"
            animation="overlay"
            visible={applicationSidebar}
            width="very wide"
          >
            <Button
              floated="right"
              onClick={this.handleApplicationSidebar}
              size="mini"
            >
              close
            </Button>
            <Header floated="left" as="h2">
              New Application
            </Header>
            <Divider clearing inverted />
            {idx(applicationDevices, _ => _.UserId) && (
              <ApplicationCreate
                inverted={true}
                UserId={idx(applicationDevices, _ => _.UserId)}
                parentHandleSidebar={this.handleApplicationSidebar}
              />
            )}
          </Sidebar>

          <Sidebar.Pusher>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Dropdown
                  name="ApplicationId"
                  label=""
                  placeholder="choose one..."
                  selection
                  required
                  search
                  options={idx(applicationsOptions, _ =>
                    formatOptions(_.options.rows, 'Application'),
                  )}
                  value={idx(applicationDevices, _ => _.id)}
                  onChange={this.handleChange}
                  onSearchChange={this.handleSearchApplicationIdChange}
                  loading={idx(applicationsOptions, _ => _.isFetching)}
                  disabled={!applicationDevices.UserId}
                />

                <Form.Button
                  content="Create Application"
                  label="&nbsp;"
                  onClick={this.handleApplicationSidebar}
                  disabled={!applicationDevices.UserId}
                />
              </Form.Group>

              <Form.Button content="Save" type="submit" />
            </Form>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    );
  }
}

ApplicationAddForm.propTypes = {
  location: PropTypes.object,
  applicationsOptions: PropTypes.object,
  application: PropTypes.object,
  fetchApplicationsOptions: PropTypes.func,
  destroyApplicationDevices: PropTypes.func,
  postApplicationDevices: PropTypes.func,
};

const ApplicationAddTo = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationAddForm),
);

export { ApplicationAddTo };
