/**
 * /* eslint-disable no-console
 *
 * @format
 */

import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Checkbox, Divider, Header, Icon } from 'semantic-ui-react';
import { getCurrentUserToken } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import Axios from 'axios';

import './ImportGateways.css';

const ImportGateways = ({
  onUploadFinished,
  disabled = false,
}) => {
  const [force, setForce] = useState(false);

  const onDrop = acceptedFiles => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    formData.append('force', force);
    formData.append('dry-run', false);

    Axios.post(`${url.GatewaysImport()}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => onUploadFinished(res))
      .catch(error => {
        onUploadFinished(error)
      });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'text/csv',
    disabled: disabled,
  });
  return (
    <>
      <div
        {...getRootProps()}
        className={`drag-and-drop-area ${disabled ? 'disabled' : ''}`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="text-center">Drop the file here ...</p>
        ) : (
          <p className="text-center">
            Drag 'n' drop some file here, or click to select file
          </p>
        )}
      </div>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="options" />
          Options
        </Header>
      </Divider>
      <div>
        <Checkbox
          label="force update"
          onChange={(e, data) => setForce(data.checked)}
          checked={force}
        />
      </div>
      <Divider hidden />
    </>
  );
};

ImportGateways.propTypes = {
  onUploadFinished: PropTypes.func,
  disabled: PropTypes.bool,
};

export { ImportGateways };
