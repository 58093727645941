/** @format */

import { CALL_API } from 'redux-api-middleware';
import { getCurrentUserToken } from '../lib/auth';
import { Endpoints as url } from '../lib/endpoints';
import { toastError } from '../lib/utilities';

export const FETCH_USER_PROFILE_SINGLE_REQUEST =
  'userProfileSingle/FETCH_REQUEST';
export const FETCH_USER_PROFILE_SINGLE_SUCCESS =
  'userProfileSingle/FETCH_SUCCESS';
export const FETCH_USER_PROFILE_SINGLE_FAILURE =
  'userProfileSingle/FETCH_FAILURE';

export const DESTROY_USER_PROFILE_SINGLE_SUCCESS =
  'userProfileSingle/DESTROY_SUCCESS';

export const fetchUserProfileSingle = userProfileId => ({
  [CALL_API]: {
    endpoint: url.UserProfileSingle(userProfileId || ''),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_USER_PROFILE_SINGLE_REQUEST,
      {
        type: FETCH_USER_PROFILE_SINGLE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_USER_PROFILE_SINGLE_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const destroyUserProfileSingle = () => ({
  type: DESTROY_USER_PROFILE_SINGLE_SUCCESS,
});
