/** @format */

import axios from 'axios';
import React from 'react';
import { getCurrentUserToken } from '../../lib/auth';
import devLog from '../../lib/devLog';

export const useAxiosCall = async (url, method) => {
  const [response, setResponse] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // const res = await axios[method](url, {
        //   headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: getCurrentUserToken(),
        //   },
        // });
        setResponse(data => data);
      } catch (err) {
        devLog(err);
      }
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return response;
};

// fetch data from a url endpoint and return a promise
export const axiosCall = async (url, method, query = '') =>
  axios[method](url + query, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
  });
