/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

/** @format */
import idx from 'idx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useLayoutEffect, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchDevice, destroyDevice } from '../../actions/device';
import {
  fetchDeviceProfileOptionsById,
  destroyDeviceProfilesOptions,
} from '../../actions/deviceProfiles';
import {
  fetchApplications,
  destroyApplications,
} from '../../actions/applications';
import { Button, Card, Divider, Grid, Header, List, Modal } from 'semantic-ui-react';
import { ListItem, MapContainer } from '../../components';
import EditButton from '../../components/Utils/EditButton';
import ListButton from '../../components/Utils/ListButton';
import MenuButton from '../../components/Utils/MenuButton';
import ShareButton from '../../components/Utils/ShareButton';
import { DeviceDownlink } from '../DeviceDownlink';
import { DevicePosition } from '../DevicePosition';
import { GatewayStatus } from '../GatewayStatus';
import { UtilityTopic } from '..';
import { GOOGLE_MAPS_API_KEY, PAGES_PATH, ENABLE_GEOLOCATION } from '../../lib/variables';
import {
  EMPTY_STRING_VALUE,
  formatKeys,
  checkPadding,
} from '../../lib/utilities';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { userHasRole } from '../../lib/auth';

const DeviceRead = () => {
  const dispatch = useDispatch();
  const { did } = useParams();
  const element = useSelector(state => state.device);
  const { deviceProfile } = useSelector(state => state.deviceProfilesOptions);
  const applications = useSelector(state => state.applications);
  const [device, setDevice] = useState({});
  const [profileId, setProfileId] = useState(null);
  const [application, setApplication] = useState({});
  const [downlinkModal, setDownlinkModal] = useState(false);

  // UseEffect listening on the state device
  useEffect(() => {
    // Recover the device and update the state
    if (!('device' in element) && !element.isLoading)
      dispatch(fetchDevice(did));
    if (Object.keys(device).length === 0) setDevice(element.device || {});
    // Recover the device profile
    if ('DeviceProfileId' in device) {
      setProfileId(device.DeviceProfileId);
      if (deviceProfile && Object.keys(deviceProfile).length === 0) {
      }
    }
    if ('appid' in device) {
      if (!('rows' in applications)) {
        let query;
        if (userHasRole('admin')) {
          // query string if the user is admin
          query = `appid=${device.appid}&uid=${device.UserId}`;
        } else {
          // query string if the user is not admin
          query = `appid=${device.appid}`;
        }

        // Recover the application using the filters, it should return only one
        dispatch(
          fetchApplications({
            qs: query,
          }),
        );
      } else {
        const applicationDevice = applications.rows.find(app => {
          if (app.appid === device.appid && app.UserId === device.UserId) {
            return true;
          }
          return false;
        });
        setApplication(applicationDevice);
      }
    }
  }, [device]);

  useEffect(() => {
    if (profileId) dispatch(fetchDeviceProfileOptionsById(profileId));
  }, [profileId]);

  // UseEffect listening on the state applications
  useEffect(() => {
    if ('rows' in applications) {
      if (0 in applications.rows) {
        const applicationDevice = applications.rows.find(app => {
          if (app.appid === device.appid && app.UserId === device.UserId) {
            return true;
          }
          return false;
        });
        setApplication(applicationDevice);
      } else {
        setApplication({});
      }
    }
  }, [applications]);

  // componentWillUnmount
  useLayoutEffect(() => {
    return () => {
      // Delete in REDUX device, applications and profile
      dispatch(destroyDevice());
      dispatch(destroyDeviceProfilesOptions());
      dispatch(destroyApplications());
    };
  }, []);

  return (
    <Fragment>
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        {device.label || 'Device Details'}
      </Header>
      <EditButton path={`${PAGES_PATH}/device/${device.id}/edit`} />
      <ShareButton
        path={{
          pathname: `${PAGES_PATH}/device/share`,
          state: { devices: [device.id], UserId: device.UserId },
        }}
      />
      <MenuButton
        keyID={'maccommand'}
        path={`${PAGES_PATH}/device/${device.id}/maccommand`}
        desc={'Mac Command'}
        icon={faWaveSquare}
      />
      <ListButton path={`${PAGES_PATH}/devices`} />
      <Divider clearing />
      <Grid columns={2} doubling style={{ marginTop: '5em' }}>
        <Grid.Column>
          <Card fluid>
            <Card.Content>
              <Card.Header>Network Info</Card.Header>
              <Divider />
              <Card.Description>
                <List verticalAlign="middle" divided selection>
                  <ListItem
                    label="label"
                    content={idx(device, _ => _.label) || 'no name'}
                    copy={true}
                  />
                  <ListItem
                    label="type"
                    content={idx(device, _ => _.type)}
                    copy={true}
                  />
                  {idx(device, _ => _.deveui) && (
                    <ListItem
                      shouldBeFormatted
                      label="device EUI"
                      content={formatKeys(device.deveui) || '---'}
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.appeui) && (
                    <ListItem
                      shouldBeFormatted
                      label="app EUI"
                      content={formatKeys(device.appeui) || '---'}
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.type) === 'ABP' &&
                    idx(device, _ => _.devaddr) && (
                      <ListItem
                        shouldBeFormatted
                        label="device Address"
                        content={
                          formatKeys(checkPadding(device.devaddr, 8)) || '---'
                        }
                        copy={true}
                      />
                    )}
                  {idx(device, _ => _.type) === 'OTAA' && (
                    <ListItem
                      shouldBeFormatted
                      label="device Address"
                      content={
                        idx(device, _ => _.status.devaddr)
                          ? formatKeys(checkPadding(device.status.devaddr, 8))
                          : '---'
                      }
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.type) !== 0 &&
                    idx(device, _ => _.joineui) && (
                      <ListItem
                        shouldBeFormatted
                        label="join EUI"
                        content={formatKeys(device.joineui) || '---'}
                        copy={true}
                      />
                    )}
                  <ListItem
                    label="model"
                    content={idx(device, _ => _.model) || EMPTY_STRING_VALUE}
                    copy={true}
                  />
                  <ListItem
                    label="serial"
                    content={idx(device, _ => _.serial) || EMPTY_STRING_VALUE}
                    copy={true}
                  />
                  <ListItem
                    label="firmware"
                    content={idx(device, _ => _.firmware) || EMPTY_STRING_VALUE}
                    copy={true}
                  />
                  <ListItem
                    label="Uplink"
                    content={idx(device, _ => _.status.fCntUp) || 0}
                    copy={true}
                  />
                  <ListItem
                    label="Downlink"
                    content={idx(device, _ => _.status.fCntDwn) || 0}
                    copy={true}
                  />
                  <ListItem
                    label="Last Uplink [UTC]"
                    content={idx(device, _ => _.status.lastUpTime) || '---'}
                    copy={true}
                  />
                </List>
              </Card.Description>
            </Card.Content>
            <Card.Content>
              <Card.Header>Profile</Card.Header>
              <Divider />
              <Card.Description>
                <List verticalAlign="middle" divided selection>
                  <ListItem
                    label="protocol"
                    content={
                      idx(deviceProfile, _ => _.macVersion) ||
                      EMPTY_STRING_VALUE
                    }
                    copy={true}
                  />
                  <ListItem
                    label="supports 32bit counter"
                    content={
                      idx(deviceProfile, _ => _.supports32bitFCnt)
                        ? 'yes'
                        : 'no'
                    }
                    copy={false}
                  />
                  <ListItem
                    label="supports class B"
                    content={
                      idx(deviceProfile, _ => _.supportsClassB) ? 'yes' : 'no'
                    }
                    copy={false}
                  />
                  <ListItem
                    label="supports class C"
                    content={
                      idx(deviceProfile, _ => _.supportsClassC) ? 'yes' : 'no'
                    }
                    copy={false}
                  />
                  <ListItem
                    label="Current Class"
                    content={idx(device, _ => _.status.currentClass) || '---'}
                    copy={true}
                  />
                  <ListItem
                    label="Frequency Plan"
                    content={idx(device, _ => _.status.freqBand) || '---'}
                    copy={true}
                  />
                </List>
              </Card.Description>
            </Card.Content>
            <Card.Content>
              <Card.Header>Application</Card.Header>
              <Divider />
              <Card.Description>
                <List verticalAlign="middle" divided selection>
                  {userHasRole('admin') && (
                    <ListItem
                      label="Application ID"
                      content={idx(application, _ => _.id) || '---'}
                      copy={true}
                    />
                  )}
                  <ListItem
                    label="label"
                    content={idx(application, _ => _.label) || '---'}
                    copy={true}
                  />
                  <ListItem
                    label="appid"
                    content={idx(application, _ => _.appid) || '---'}
                    copy={true}
                  />
                </List>
              </Card.Description>
            </Card.Content>
            <Card.Content>
              <Card.Header>Keys</Card.Header>
              <Divider />
              <Card.Description>
                <List verticalAlign="middle" divided selection>
                  {idx(device, _ => _.appKey) && (
                    <ListItem
                      shouldBeFormatted
                      label="appKey"
                      content={formatKeys(device.appKey)}
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.nwkKey) && (
                    <ListItem
                      shouldBeFormatted
                      label="nwkKey"
                      content={formatKeys(device.nwkKey)}
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.appSKey) && (
                    <ListItem
                      shouldBeFormatted
                      label="appSKey"
                      content={formatKeys(device.appSKey)}
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.nwkSKey) && (
                    <ListItem
                      shouldBeFormatted
                      label="nwkSKey"
                      content={formatKeys(device.nwkSKey)}
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.fNwkSIntKey) && (
                    <ListItem
                      shouldBeFormatted
                      label="fNwkSIntKey"
                      content={formatKeys(device.fNwkSIntKey)}
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.sNwkSIntKey) && (
                    <ListItem
                      shouldBeFormatted
                      label="sNwkSIntKey"
                      content={formatKeys(device.sNwkSIntKey)}
                      copy={true}
                    />
                  )}
                  {idx(device, _ => _.nwkSEncKey) && (
                    <ListItem
                      shouldBeFormatted
                      label="nwkSEncKey"
                      content={formatKeys(device.nwkSEncKey)}
                      copy={true}
                    />
                  )}
                </List>
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column>
          {idx(device, _ => _.position.lat) &&
            idx(device, _ => _.position.lng) && (
              <Card fluid>
                <Card.Content>
                  <Card.Header>Map</Card.Header>
                  <Divider />
                  <MapContainer
                    markers={[
                      { lat: device.position.lat, lng: device.position.lng },
                    ]}
                    isMarkerShown
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </Card.Content>
              </Card>
            )}
          <Card fluid>
            <Card.Content>
              <Card.Header>Queue downlink</Card.Header>
              <Divider />
              {idx(device, _ => _.deveui) && did && (
                <DeviceDownlink
                  pollingActive={true}
                  device={device}
                  deveui={device.deveui}
                  deviceId={did}
                />
              )}
            <Divider hidden={true} />
            <Button onClick={() => {
              setDownlinkModal(true)
            }}><FontAwesomeIcon icon="paper-plane" /> Add downlink</Button>
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>Gateway status</Card.Header>
              <Divider />
              {did && <GatewayStatus device={device} />}
            </Card.Content>
          </Card>
          {device && device.appid && device.deveui && (
            <Card fluid>
              <Card.Content>
                <Card.Header>MQTT</Card.Header>
                <Divider />
                <Card.Description>
                  <UtilityTopic appid={device.appid} deveui={device.deveui} user={device.owner.username} />
                </Card.Description>
              </Card.Content>
            </Card>
          )}
          {ENABLE_GEOLOCATION && device && device.deveui && <Card fluid>
            <Card.Content>
            <Card.Header>Position</Card.Header>
            <Divider />
            <Card.Description>
              <DevicePosition deveui={device.deveui} />
            </Card.Description>
            </Card.Content>
          </Card>}
        </Grid.Column>
      </Grid>
      <Modal
            open={downlinkModal}
            onClose={()=>{
              setDownlinkModal(false)
            }}
            size="small"
          >
            <Header as="h1" content="Downlink" />
            <Modal.Content>
              {element && 'device' in element && <DeviceDownlink
                pollingActive={true}
                device={element.device}
                deveui={element.device.deveui}
                type="full"
              />}
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={()=> {
                setDownlinkModal(false)
              }}>Close</Button>
            </Modal.Actions>
          </Modal>
    </Fragment>
  );
};

export { DeviceRead };
