/** @format */

import Axios from 'axios';
import { useEffect, useState } from 'react';
import { Endpoints as url } from '../../lib/endpoints';
import { getCurrentUserToken } from '../../lib/auth';

export const pollingStates = ['on', 'off'];

export const useMacCommandPolling = (did, pollingCall) => {
  const pollingTime = 60000;
  const [polling, setPolling] = useState(pollingStates[1]);

  useEffect(() => {
    let interval = null;
    if (did !== null && polling === pollingStates[0]) {
      setTimeout(pollingCall, 10000);
      interval = setInterval(pollingCall, pollingTime);
    }
    return () => {
      interval !== null && clearInterval(interval);
    };
  }, [polling, did]);

  return {
    polling,
    setPolling,
  };
};

export const useMacCommandService = did => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: getCurrentUserToken(),
  };

  const deviceDetail = {
    get: () => Axios.get(url.Device(did), { headers }),
    getProfiles: id =>
      Axios.get(url.DeviceProfilesOptionsById(id), { headers }),
  };

  const deviceStatus = {
    get: () => Axios.get(url.DeviceStatus(did), { headers }),
    put: (data = {}) => Axios.put(url.DeviceStatus(did), data, { headers }),
  };

  const macCard = {
    dutyCycle: {
      get: () => Axios.get(url.DeviceDutyCycle(did), { headers }),
      put: (data = {}) =>
        Axios.put(url.DeviceDutyCycle(did), data, { headers }),
    },
    rxParams: {
      get: () => Axios.get(url.DeviceRxParam(did), { headers }),
      put: (data = {}) => Axios.put(url.DeviceRxParam(did), data, { headers }),
    },
    pingSlotChannel: {
      get: () => Axios.get(url.DevicePingSlot(did), { headers }),
      put: (data = {}) => Axios.put(url.DevicePingSlot(did), data, { headers }),
    },
    rxDelay: {
      get: () => Axios.get(url.DeviceRxDelay(did), { headers }),
      put: (data = {}) => Axios.put(url.DeviceRxDelay(did), data, { headers }),
    },
  };

  const deviceFrequencyCh = {
    get: () => Axios.get(url.DeviceFrequencyCh(did), { headers }),
    put: data => Axios.put(url.DeviceFrequencyCh(did), data, { headers }),
    del: rowIndex =>
      Axios.delete(url.DeviceFrequencyChDelete(did, rowIndex), { headers }),
  };

  return {
    deviceDetail,
    deviceStatus,
    macCard,
    deviceFrequencyCh,
  };
};
