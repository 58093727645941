/** @format */

import * as actions from '../actions/deviceProfiles';

const defaultState = {
  isLoading: false,
  error: false,
  options: {},
  deviceProfile: {},
  deviceProfilesOptionsById: {},
  deviceProfilesList: [],
};

export const deviceProfilesOptions = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_DEVICE_PROFILES_OPTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        options: {},
      };
    case actions.FETCH_DEVICE_PROFILES_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICE_PROFILES_OPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        options: {},
      };

    case actions.FETCH_DEVICE_PROFILE_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        deviceProfile: {},
      };
    case actions.FETCH_DEVICE_PROFILE_ID_SUCCESS:
      return {
        ...state,
        deviceProfile: action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICE_PROFILE_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        deviceProfile: {},
      };

    case actions.DESTROY_DEVICE_PROFILES_OPTIONS_SUCCESS:
      return {
        ...state.options,
        isLoading: false,
        error: false,
      };

    case actions.FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_REQUEST:
      return {
        ...state,
        deviceProfilesOptionsById: {},
        isLoading: true,
        error: false,
      };
    case actions.FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        deviceProfilesOptionsById: action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_FAILURE:
      return {
        ...state,
        deviceProfilesOptionsById: {},
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
};

export const deviceProfiles = (state = {
  rows: [],
  isLoading: false,
  error: false
}, action) => {
  switch (action.type) {
    case actions.FETCH_DEVICE_PROFILES_LIST_REQUEST:
      return {
        ...state,
        rows: [],
        isLoading: true,
        error: false,
      };
    case actions.FETCH_DEVICE_PROFILES_LIST_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICE_PROFILES_LIST_FAILURE:
      return {
        ...state,
        rows: [],
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}