/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  Message,
  Button,
  Divider,
  Form,
  Header,
  Input,
  Label,
  Modal,
  Segment,
  Sidebar,
  Grid,
} from 'semantic-ui-react';
import idx from 'idx';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import _ from 'lodash';
import SVG from 'react-inlinesvg';
import Axios from 'axios';
import { Endpoints as url } from '../../lib/endpoints';
import { ApplicationCreate } from '..';
import {
  getCurrentUserId,
  userHasRole,
  getCurrentUserToken,
} from '../../lib/auth';
import { destroyUsersOptions, fetchUsersOptions } from '../../actions/users';
import {
  destroyDeviceProfilesOptions,
  fetchDeviceProfilesOptions,
  fetchDeviceProfileId,
  fetchDeviceProfileOptionsById,
  fetchDeviceProfileList,
} from '../../actions/deviceProfiles';
import {
  destroyApplicationsOptions,
  fetchApplicationsOptions,
} from '../../actions/applications';
import { deleteDevice, fetchDevice, putDevice } from '../../actions/device';
import { PAGES_PATH } from '../../lib/variables';
import {
  isCoordinate,
  formatBytes,
  formatNormalString,
  coupleOfBytesLengths,
  generatePlaceholder,
  formatOptions,
  cleaveOptions,
  clean,
} from '../../lib/utilities';
import ListButton from '../../components/Utils/ListButton';
import DeleteButton from '../../components/Utils/DeleteButton';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  usersOptions: state.usersOptions,
  applicationsOptions: state.applicationsOptions,
  options: state.deviceProfilesOptions.options,
  deviceProfile: state.deviceProfilesOptions.deviceProfile,
  deviceFromProps: state.device.device || {},
  isLoading: state.device.isLoading,
  error: state.device.error,
  application: state.application,
  deviceProfilesList: state.deviceProfilesOptions.deviceProfilesList,
});

const mapDispatchToProps = dispatch => ({
  fetchDevice: (owner, device) => {
    dispatch(fetchDevice(owner, device));
  },
  fetchUsersOptions: (params = {}) => {
    dispatch(fetchUsersOptions(params));
  },
  fetchDeviceProfilesOptions: () => {
    dispatch(fetchDeviceProfilesOptions());
  },
  fetchApplicationsOptions: (user, params = {}) => {
    dispatch(fetchApplicationsOptions(user, params));
  },
  fetchDeviceProfileId: (
    macVersion,
    supports32bitFCnt,
    supportsClassB,
    supportsClassC,
  ) => {
    dispatch(
      fetchDeviceProfileId(
        macVersion,
        supports32bitFCnt,
        supportsClassB,
        supportsClassC,
      ),
    );
  },
  putDevice: device => {
    dispatch(putDevice(device));
  },
  deleteDevice: device => {
    dispatch(deleteDevice(device));
  },
  destroyUsersOptions: () => {
    dispatch(destroyUsersOptions());
  },
  destroyDeviceProfilesOptions: () => {
    dispatch(destroyDeviceProfilesOptions());
  },
  destroyApplicationsOptions: () => {
    dispatch(destroyApplicationsOptions());
  },
  fetchDeviceProfileList: () => {
    dispatch(fetchDeviceProfileList());
  },
  fetchDeviceProfileOptionsById: deviceProfileId => {
    dispatch(fetchDeviceProfileOptionsById(deviceProfileId));
  },
});

class DeviceEditForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      defaultFormWidth: 8,
      device: {
        appid: '',
        id: '',
        UserId: getCurrentUserId(),
        supports32bitFCnt: true,
        supportsClassB: false,
        supportsClassC: false,
        macVersion: '1.0.2',
        deveui: '',
        devaddr: '',
        nwkSKey: '',
        appSKey: '',
        appeui: '',
        appKey: '',
        label: '',
        position: {
          lat: '',
          lng: '',
          alt: '',
        },
        type: '',
        DeviceProfileId: null,
      },
      deviceProfile: {},
      deviceApplicationSidebar: false,
      errors: {
        lat: false,
        lng: false,
        alt: false,
      },
    };

    this.handleDeviceApplicationSidebar = this.handleDeviceApplicationSidebar.bind(
      this,
    );
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeUserId = this.handleChangeUserId.bind(this);
    this.handleSearchApplication = this.handleSearchApplication.bind(this);
    this.handleSearchUserId = this.handleSearchUserId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onDeviceBlur = this.onDeviceBlur.bind(this);
    this.handleChangeCleave = this.handleChangeCleave.bind(this);
    this.onTypeBlur = this.onTypeBlur.bind(this);
  }

  componentDidMount() {
    Axios.get(url.Device(this.props.match.params.did), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(response => {
        this.setState({
          ...this.state,
          device: { ...this.state.device, ...response.data },
        });
        Axios.get(
          url.DeviceProfilesOptionsById(response.data.DeviceProfileId),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getCurrentUserToken(),
            },
          },
        )
          .then(responseForFetchingDeviceProfileId => {
            delete responseForFetchingDeviceProfileId.data.id;
            this.setState({
              ...this.state,
              device: {
                ...this.state.device,
                ...responseForFetchingDeviceProfileId.data,
              },
            });
          })

          .catch(error => devLog(error));
      })
      .catch(error => devLog(error));

    this.props.fetchDevice(this.props.match.params.did);
    // this.props.match.params.did
    this.props.fetchDeviceProfilesOptions();
    this.props.fetchDeviceProfileList();
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
    } else {
      this.props.fetchApplicationsOptions(getCurrentUserId());
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      idx(nextProps, acs => acs.deviceProfile) &&
      !_.isEmpty(nextProps.deviceProfile)
    ) {
      this.setState({
        ...this.state,
        device: {
          ...this.state.device,
        },
        deviceProfile: {
          ...nextProps.deviceProfile,
        },
      });
    }

    if (idx(nextProps, _acs => _acs.application.id)) {
      this.setState({
        ...this.state,
        device: {
          ...this.state.device,
          ApplicationId: nextProps.application.id,
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { application } = this.props;

    if (this.state.device.UserId !== prevState.device.UserId) {
      const params = { filter: { uid: this.state.device.UserId } };
      this.props.fetchApplicationsOptions(this.state.device.UserId, params);
    }

    if (prevProps.application.id !== this.props.application.id) {
      const params = { filter: { label: application.label } };
      this.props.fetchApplicationsOptions(this.state.device.UserId, params);
      this.setState({
        device: {
          ...this.state.device,
          appid: application.id,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyUsersOptions();
    this.props.destroyDeviceProfilesOptions();
    this.props.destroyApplicationsOptions();
  }

  // HANDLER SECTION
  handleDeviceApplicationSidebar(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      deviceApplicationSidebar: !this.state.deviceApplicationSidebar,
    });
  }

  handleChangeCleave(e) {
    this.setState({
      ...this.state,
      device: { ...this.state.device, [e.target.name]: e.target.value },
    });
  }

  handleChange(e, { name, value }) {
    if (name === 'lat' || name === 'lng' || name === 'alt') {
      this.setState({
        ...this.state,
        device: {
          ...this.state.device,
          position: { ...this.state.device.position, [name]: value },
        },
      });
    } else {
      this.setState({
        device: {
          ...this.state.device,
          [name]: value,
          ...(name === 'macVersion' && (value === '1.0.2' || value === '1.0.4')
            ? {
                supports32bitFCnt: true,
                ...(name === 'macVersion' &&
                ((process.env.REACT_APP_LORA_104_CLASS_B_ALLOWED != 'true' &&
                  value === '1.0.4') ||
                  value === '1.0.2')
                  ? { supportsClassB: false }
                  : {}),
                ...(process.env.REACT_APP_LORA_104_CLASS_C_ALLOWED != 'true'
                  ? { supportsClassC: false }
                  : {}),
              }
            : {}),
        },
      });
    }
  }

  handleCheck(e, { name, checked }) {
    this.setState({
      ...this.state,
      device: { ...this.state.device, [name]: checked },
    });
    setTimeout(() => {
      if (
        this.state.device.macVersion === '1.0.3' &&
        name === 'supportsClassB'
      ) {
        this.setState({
          ...this.state,
          device: { ...this.state.device, supportsClassC: false },
        });
      }
      if (
        this.state.device.macVersion === '1.0.3' &&
        name === 'supportsClassC'
      ) {
        this.setState({
          ...this.state,
          device: { ...this.state.device, supportsClassB: false },
        });
      }

      if (
        this.state.device.macVersion === '1.0.4' &&
        name === 'supportsClassB'
      ) {
        this.setState({
          ...this.state,
          device: {
            ...this.state.device,
            supportsClassC: false,
            supports32bitFCnt: true,
          },
        });
      }
      if (
        this.state.device.macVersion === '1.0.4' &&
        name === 'supportsClassC'
      ) {
        this.setState({
          ...this.state,
          device: {
            ...this.state.device,
            supportsClassB: false,
            supports32bitFCnt: true,
          },
        });
      }
    }, 200);
  }

  handleChangeUserId(e, { value }) {
    e.persist();
    this.setState({
      device: {
        ...this.state.device,
        UserId: value,
        username: e.target.textContent,
        ApplicationId: null,
      },
    });
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      owner: null,
      application: null,
      deveui: null,
      check: null,
    });
  }

  handleDeleteModal() {
    this.setState({ modalOpen: true });
  }

  handleDelete() {
    this.props.deleteDevice(this.props.match.params.did);
    this.setState({ modalOpen: false, check: null });
  }

  handleSearchUserId({ searchQuery }) {
    const params = { filter: { username: searchQuery } };
    this.props.fetchUsersOptions(params);
  }

  handleSearchApplication({ searchQuery }) {
    const params = { filter: { label: searchQuery } };
    this.props.fetchApplicationsOptions(this.state.device.UserId, params);
  }

  handleSubmit(e, prevDevice) {
    const { device } = this.state;

    e.preventDefault();

    Axios.get(
      url.DeviceProfileID(
        device.macVersion,
        device.supports32bitFCnt,
        device.supportsClassB,
        device.supportsClassC,
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCurrentUserToken(),
        },
      },
    )
      .then(
        response =>
          response.status === 200 &&
          this.setState(
            {
              ...this.state,
              device: {
                ...this.state.device,
                DeviceProfileId: response.data.rows[0].id,
              },
            },
            () => {
              let formattedDevice = {
                ...device,
                deveui: formatNormalString(device.deveui, ' '),
                DeviceProfileId: response.data.rows[0].id,
              };

              if (formattedDevice.type === 'ABP') {
                if (formattedDevice.devaddr !== prevDevice.devaddr || formattedDevice.nwkSKey !== prevDevice.nwkSKey || formattedDevice.appSKey !== prevDevice.appSKey) {
                  formattedDevice.devaddr = formatNormalString(formattedDevice.devaddr, ' ');
                  formattedDevice.nwkSKey = formatNormalString(formattedDevice.nwkSKey, ' ');
                  formattedDevice.appSKey = formatNormalString(formattedDevice.appSKey, ' ');
                } else {
                  delete formattedDevice.type;
                  delete formattedDevice.devaddr;
                  delete formattedDevice.nwkSKey;
                  delete formattedDevice.appSKey;
                }

                delete formattedDevice.appKey;
                delete formattedDevice.appeui;
                delete formattedDevice.username;
              } else if (formattedDevice.type === 'OTAA') {
                formattedDevice = {
                  ...formattedDevice,
                  ...formattedDevice.appeui !== prevDevice.appeui ? { appeui: formatNormalString(formattedDevice.appeui, ' ') } : {},
                  ...formattedDevice.appKey !== prevDevice.appKey ? { appKey: formatNormalString(formattedDevice.appKey, ' ') } : {},
                };

                if (formattedDevice.appeui !== prevDevice.appeui || formattedDevice.appKey !== prevDevice.appKey) {
                  formattedDevice.appeui = formatNormalString(formattedDevice.appeui, ' ');
                  formattedDevice.appKey = formatNormalString(formattedDevice.appKey, ' ');
                } else {
                  delete formattedDevice.type;
                  delete formattedDevice.appeui;
                  delete formattedDevice.appKey;
                }

                delete formattedDevice.devaddr;
                delete formattedDevice.nwkSKey;
                delete formattedDevice.appSKey;
                delete formattedDevice.username;
              } else {
                delete formattedDevice.type;
                delete formattedDevice.appeui;
                delete formattedDevice.appKey;
                delete formattedDevice.devaddr;
                delete formattedDevice.nwkSKey;
                delete formattedDevice.appSKey;
                delete formattedDevice.username;
              }

              if (formattedDevice.DeviceProfileId === prevDevice.DeviceProfileId) {
                delete formattedDevice.DeviceProfileId;
              }

              formattedDevice = {
                ...formattedDevice,
                lat:
                  isCoordinate(formattedDevice.position.lat, 'lat') &&
                  parseFloat(formattedDevice.position.lat),
                lng:
                  isCoordinate(formattedDevice.position.lng, 'lng') &&
                  parseFloat(formattedDevice.position.lng),
                alt:
                  isCoordinate(formattedDevice.position.alt, 'alt') &&
                  parseFloat(formattedDevice.position.alt),
              };
              if (Number.isNaN(formattedDevice.lat) || !formattedDevice.lat) {
                delete formattedDevice.lat;
              }
              if (Number.isNaN(formattedDevice.lng) || !formattedDevice.lng) {
                delete formattedDevice.lng;
              }
              if (Number.isNaN(formattedDevice.alt) || !formattedDevice.alt) {
                delete formattedDevice.alt;
              }

              if (!formattedDevice.model) delete formattedDevice.model;
              if (!formattedDevice.serial) delete formattedDevice.serial;
              if (!formattedDevice.firmware) delete formattedDevice.firmware;
              !userHasRole('admin') && delete formattedDevice.UserId;

              delete formattedDevice.macVersion;
              delete formattedDevice.supports32bitFCnt;
              delete formattedDevice.supportsClassB;
              delete formattedDevice.supportsClassC;
              delete formattedDevice.deveui;
              delete formattedDevice.rows;
              delete formattedDevice.createdAt;
              delete formattedDevice.updatedAt;
              delete formattedDevice.size;
              delete formattedDevice.offset;
              delete formattedDevice.limit;
              delete formattedDevice.position;
              delete formattedDevice.status;
              delete formattedDevice.appid;
              delete formattedDevice.UserId;
              delete formattedDevice.owner;

              this.props.putDevice(clean(formattedDevice));
            },
          ),
      )
      .catch(error => devLog(error));
  }

  onTypeBlur() {
    const { device } = this.state;
    const {
      macVersion,
      supports32bitFCnt,
      supportsClassB,
      supportsClassC,
    } = device;
    this.props.fetchDeviceProfileId(
      macVersion,
      supports32bitFCnt,
      supportsClassB,
      supportsClassC,
    );
  }

  validateField(ev) {
    if (ev.target.value === '') {
      return this.setState({
        errors: { ...this.state.errors, [ev.target.name]: false },
      });
    }
    return isCoordinate(ev.target.value, ev.target.name)
      ? this.setState({
          errors: { ...this.state.errors, [ev.target.name]: false },
        })
      : this.setState({
          errors: { ...this.state.errors, [ev.target.name]: true },
        });
  }

  onDeviceBlur(event, bytesEnum) {
    const reducer = formatNormalString(
      event.target.value.replace(/^0+/g, ''),
      ':',
    );

    this.setState({
      ...this.state,
      device: {
        ...this.state.device,
        [event.target.name]: reducer.padStart(bytesEnum * 2, '0'),
      },
    });
  }

  render() {
    const {
      device,
      deviceApplicationSidebar,
      errors,
      defaultFormWidth,
    } = this.state;
    const {
      usersOptions,
      applicationsOptions,
      deviceFromProps,
      options,
    } = this.props;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Edit {deviceFromProps.label}
        </Header>
        <DeleteButton handleDeleteModal={this.handleDeleteModal} />
        <ListButton path={`${PAGES_PATH}/devices`} />
        <Divider clearing />
        <Sidebar.Pushable>
          <Sidebar
            as={Segment}
            inverted
            color="red"
            direction="right"
            animation="overlay"
            visible={deviceApplicationSidebar}
            width="very wide"
          >
            <Button
              floated="right"
              onClick={this.handleDeviceApplicationSidebar}
              size="mini"
            >
              close
            </Button>
            {idx(device, accessory => accessory.UserId) && (
              <ApplicationCreate
                inverted
                UserId={device.UserId}
                handleDeviceApplicationSidebar={
                  this.handleDeviceApplicationSidebar
                }
              />
            )}
          </Sidebar>

          <Sidebar.Pusher>
            {!device.isLoading && (
              <Form onSubmit={(e) => this.handleSubmit(e, deviceFromProps)}>
                {userHasRole('admin') && (
                  <Form.Dropdown
                    name="UserId"
                    label="Owner"
                    placeholder="choose one..."
                    width={defaultFormWidth}
                    search
                    selection
                    disabled
                    options={idx(usersOptions, __a =>
                      formatOptions(__a.options, 'Owner'),
                    )}
                    value={idx(device, __b => __b.UserId)}
                    onChange={this.handleChangeUserId}
                    onSearchChange={this.handleSearchUserId}
                    loading={idx(usersOptions, __c => __c.isFetching)}
                  />
                )}

                <Form.Group widths="equal">
                  <Form.Dropdown
                    name="appid"
                    label="Application"
                    placeholder="choose one..."
                    width={defaultFormWidth}
                    search
                    selection
                    disabled
                    options={
                      idx(applicationsOptions, _v =>
                        formatOptions(_v.options.rows, 'Application'),
                      ) || []
                    }
                    value={idx(device, _t => _t.appid)}
                    onChange={this.handleChange}
                    onSearchChange={this.handleSearchApplication}
                    loading={idx(applicationsOptions, _u => _u.isFetching)}
                  />

                  <Form.Button
                    width={defaultFormWidth}
                    disabled
                    content="Create Application"
                    label="&nbsp;"
                    onClick={this.handleDeviceApplicationSidebar}
                  />
                </Form.Group>

                <Form.Dropdown
                  name="macVersion"
                  label="Protocol"
                  placeholder="choose one..."
                  width={defaultFormWidth}
                  selection
                  required
                  options={
                    idx(options, a =>
                      formatOptions(a.macVersion, 'Protocol'),
                    ) || []
                  }
                  value={device.macVersion}
                  onChange={this.handleChange}
                  disabled={!(device.UserId && device.appid)}
                />

                <Form.Checkbox
                  toggle
                  label="Supports 32 bit Frame Counter"
                  name="supports32bitFCnt"
                  checked={idx(device, _s => _s.supports32bitFCnt)}
                  onChange={this.handleCheck}
                  disabled={
                    !idx(device, b => b.UserId) ||
                    !idx(device, c => c.appid) ||
                    ['1.0.4'].includes(idx(device, d => d.macVersion))
                  }
                />

                <Form.Checkbox
                  toggle
                  label="Supports class B"
                  name="supportsClassB"
                  checked={idx(device, e => e.supportsClassB)}
                  onChange={this.handleCheck}
                  disabled={
                    !idx(device, b => b.UserId) ||
                    !idx(device, c => c.appid) ||
                    idx(device, d => d.macVersion) === '1.0.2' ||
                    (idx(device, d => d.macVersion) === '1.0.4' &&
                      process.env.REACT_APP_LORA_104_CLASS_B_ALLOWED !== 'true')
                  }
                />

                <Form.Checkbox
                  toggle
                  label="Supports class C"
                  name="supportsClassC"
                  checked={idx(device, f => f.supportsClassC)}
                  onChange={this.handleCheck}
                  disabled={
                    !idx(device, b => b.UserId) ||
                    !idx(device, c => c.appid) ||
                    (idx(device, d => d.macVersion) === '1.0.4' &&
                      process.env.REACT_APP_LORA_104_CLASS_C_ALLOWED !== 'true')
                  }
                />

                <Form.Dropdown
                  width={defaultFormWidth}
                  label="Type"
                  name="type"
                  placeholder="choose one..."
                  selection
                  required
                  options={[
                    { key: 0, value: 'ABP', text: 'ABP' },
                    { key: 128, value: 'OTAA', text: 'OTAA' },
                  ]}
                  value={device.type}
                  onChange={this.handleChange}
                  disabled={
                    !(
                      device.UserId &&
                      device.appid &&
                      device.macVersion &&
                      false
                    )
                  }
                  onBlur={this.onTypeBlur}
                />

                <Form.Input
                  width={defaultFormWidth}
                  label="Label"
                  name="label"
                  required
                  value={device.label}
                  onChange={this.handleChange}
                  // disabled={!((deviceFromProps.UserId && deviceFromProps.macVersion && deviceFromProps.type !== ''))}
                />

                <Form.Input
                  label="Dev Eui"
                  required
                  disabled
                  width={defaultFormWidth}
                >
                  <Cleave
                    placeholder={generatePlaceholder(
                      coupleOfBytesLengths.DEVEUI,
                    )}
                    name="deveui"
                    value={formatBytes(device.deveui, null)}
                    options={{
                      ...cleaveOptions,
                      blocks: new Array(coupleOfBytesLengths.DEVEUI).fill(2),
                    }}
                    onBlur={ev =>
                      this.onDeviceBlur(ev, coupleOfBytesLengths.DEVEUI)
                    }
                    onChange={this.handleChangeCleave}
                  />
                </Form.Input>

                {idx(device, l => l.type) === 'OTAA' && (
                  <Form.Input
                    width={9}
                    label={device.macVersion === '1.1' ? 'Join Eui' : 'App Eui'}
                    required
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                  >
                    <Cleave
                      placeholder={generatePlaceholder(
                        coupleOfBytesLengths.JOINEUI,
                      )}
                      name="appeui"
                      value={formatBytes(device.appeui, null)}
                      options={{
                        ...cleaveOptions,
                        blocks: new Array(coupleOfBytesLengths.JOINEUI).fill(2),
                      }}
                      onBlur={ev =>
                        this.onDeviceBlur(ev, coupleOfBytesLengths.JOINEUI)
                      }
                      onChange={this.handleChangeCleave}
                    />
                  </Form.Input>
                )}

                {idx(device, m => m.type) === 'OTAA' && (
                  <Fragment>
                    <Header as="h4">Authentication Keys</Header>
                    <Divider clearing />
                    <Form.Input
                      width={9}
                      label="Application Key"
                      required
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                    >
                      <Cleave
                        placeholder={generatePlaceholder(
                          coupleOfBytesLengths.APPKEY,
                        )}
                        name="appKey"
                        value={formatBytes(device.appKey, null)}
                        options={{
                          ...cleaveOptions,
                          blocks: new Array(coupleOfBytesLengths.APPKEY).fill(
                            2,
                          ),
                        }}
                        onBlur={ev =>
                          this.onDeviceBlur(ev, coupleOfBytesLengths.APPKEY)
                        }
                        onChange={this.handleChangeCleave}
                      />
                    </Form.Input>
                  </Fragment>
                )}

                {idx(device, n => n.type) === 'ABP' && (
                  <Fragment>
                    <Form.Input
                      width={9}
                      label="Device Address"
                      required
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                    >
                      <Cleave
                        placeholder={generatePlaceholder(
                          coupleOfBytesLengths.DEVADDR,
                        )}
                        name="devaddr"
                        value={formatBytes(device.devaddr, null)}
                        options={{
                          ...cleaveOptions,
                          blocks: new Array(coupleOfBytesLengths.DEVADDR).fill(
                            2,
                          ),
                        }}
                        onBlur={ev =>
                          this.onDeviceBlur(ev, coupleOfBytesLengths.DEVADDR)
                        }
                        onChange={this.handleChangeCleave}
                      />
                    </Form.Input>

                    <Fragment>
                      <Header as="h4">Authentication Keys</Header>
                      <Divider clearing />
                      <Form.Input
                        width={9}
                        label="Application Session Key"
                        required
                        disabled={
                          !(
                            device.UserId &&
                            device.macVersion &&
                            device.type !== ''
                          )
                        }
                      >
                        <Cleave
                          placeholder={generatePlaceholder(
                            coupleOfBytesLengths.APPSKEY,
                          )}
                          name="appSKey"
                          value={formatBytes(device.appSKey, null)}
                          options={{
                            ...cleaveOptions,
                            blocks: new Array(
                              coupleOfBytesLengths.APPSKEY,
                            ).fill(2),
                          }}
                          onBlur={ev =>
                            this.onDeviceBlur(ev, coupleOfBytesLengths.APPSKEY)
                          }
                          onChange={this.handleChangeCleave}
                        />
                      </Form.Input>

                      <Form.Input
                        width={9}
                        label="Network Session Key"
                        required
                        disabled={
                          !(
                            device.UserId &&
                            device.macVersion &&
                            device.type !== ''
                          )
                        }
                      >
                        <Cleave
                          placeholder={generatePlaceholder(
                            coupleOfBytesLengths.NWKSKEY,
                          )}
                          name="nwkSKey"
                          value={formatBytes(device.nwkSKey, null)}
                          options={{
                            ...cleaveOptions,
                            blocks: new Array(
                              coupleOfBytesLengths.NWKSKEY,
                            ).fill(2),
                          }}
                          onBlur={ev =>
                            this.onDeviceBlur(ev, coupleOfBytesLengths.NWKSKEY)
                          }
                          onChange={this.handleChangeCleave}
                        />
                      </Form.Input>
                    </Fragment>
                  </Fragment>
                )}

                {idx(device, __ => __.macVersion) === '1.1' &&
                  idx(device, __ => __.type) === 'OTAA' && (
                    <Fragment>
                      <Header as="h4">Authentication Keys</Header>
                      <Divider clearing />
                      <Form.Input
                        width={9}
                        label="Application Key"
                        required
                        disabled={
                          !(
                            device.UserId &&
                            device.macVersion &&
                            device.type !== ''
                          )
                        }
                      >
                        <Cleave
                          placeholder={generatePlaceholder(
                            coupleOfBytesLengths.APPKEY,
                          )}
                          name="appKey"
                          value={formatBytes(device.appKey, null)}
                          options={{
                            ...cleaveOptions,
                            blocks: new Array(coupleOfBytesLengths.APPKEY).fill(
                              2,
                            ),
                          }}
                          onBlur={ev =>
                            this.onDeviceBlur(ev, coupleOfBytesLengths.APPKEY)
                          }
                          onChange={this.handleChangeCleave}
                        />
                      </Form.Input>

                      <Form.Input
                        width={defaultFormWidth}
                        label="Network Key"
                        name="nwkKey"
                        required
                        value={formatBytes(device.appKey, 2)}
                        onChange={this.handleChange}
                        disabled={
                          !(
                            device.UserId &&
                            device.macVersion &&
                            device.type !== ''
                          )
                        }
                      />
                    </Fragment>
                  )}

                {idx(device, ___ => ___.macVersion) === '1.1' &&
                  idx(device, _a => _a.type) === 'ABP' && (
                    <Fragment>
                      <Form.Input
                        width={defaultFormWidth}
                        label="Device Address"
                        name="devaddr"
                        required
                        value={idx(device, __z => __z.devaddr)}
                        onChange={this.handleChange}
                        disabled={
                          !(
                            device.UserId &&
                            device.macVersion &&
                            device.type !== ''
                          )
                        }
                      />

                      <Fragment>
                        <Header as="h4">Authentication Keys</Header>
                        <Divider clearing />
                        <Form.Input
                          width={defaultFormWidth}
                          label="Application Session Key"
                          name="appSKey"
                          required
                          value={idx(device, _b => _b.appSKey)}
                          onChange={this.handleChange}
                          disabled={
                            !(
                              device.UserId &&
                              device.macVersion &&
                              device.type !== ''
                            )
                          }
                        />

                        <Form.Input
                          width={defaultFormWidth}
                          label="Forwarding Network Session Integrity Key"
                          name="fNwkSIntKey"
                          value={idx(device, _c => _c.fNwkSIntKey)}
                          onChange={this.handleChange}
                          disabled={
                            !(
                              device.UserId &&
                              device.macVersion &&
                              device.type !== ''
                            )
                          }
                        />

                        <Form.Input
                          width={defaultFormWidth}
                          label="Serving Network Session Integrity Key"
                          name="sNwkSIntKey"
                          value={idx(device, _d => _d.sNwkSIntKey)}
                          onChange={this.handleChange}
                          disabled={
                            !(
                              device.UserId &&
                              device.macVersion &&
                              device.type !== ''
                            )
                          }
                        />

                        <Form.Input
                          width={defaultFormWidth}
                          label="Network Session Encryption Key"
                          name="nwkSEncKey"
                          value={idx(device, _e => _e.nwkSEncKey)}
                          onChange={this.handleChange}
                          disabled={
                            !(
                              device.UserId &&
                              device.macVersion &&
                              device.type !== ''
                            )
                          }
                        />
                      </Fragment>
                    </Fragment>
                  )}

                <Header as="h4">Position</Header>
                <Divider clearing />

                <Grid padded columns={2}>
                  <Grid.Column mobile={16} tablet={7} computer={7}>
                    <Form.Input
                      // width={defaultFormWidth}
                      label="Latitude"
                      name="lat"
                      type="number"
                      onBlur={this.validateField}
                      value={idx(device, _f => _f.position.lat) || ''}
                      onChange={this.handleChange}
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                      error={errors.lat}
                    />

                    <Form.Input
                      // width={defaultFormWidth}
                      label="Longitude"
                      name="lng"
                      type="number"
                      value={device.position.lng || ''}
                      onChange={this.handleChange}
                      onBlur={this.validateField}
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                      error={errors.lng}
                    />

                    <Form.Input
                      // width={defaultFormWidth}
                      label="Altitude"
                      name="alt"
                      type="number"
                      value={device.position.alt || ''}
                      onChange={this.handleChange}
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                      error={errors.alt}
                    />
                  </Grid.Column>

                  <Grid.Column mobile={16} tablet={7} computer={7}>
                    {Object.values(errors).includes(true) && (
                      <Message negative>
                        <Message.Header>Set all coordinates</Message.Header>
                        <p>Please check your coordinates</p>
                      </Message>
                    )}
                  </Grid.Column>
                </Grid>

                <Header as="h4">Device Details</Header>
                <Divider clearing />

                <Form.Input
                  width={defaultFormWidth}
                  label="Model"
                  name="model"
                  value={idx(device, _g => _g.model) || ''}
                  onChange={this.handleChange}
                  disabled={
                    !(device.UserId && device.macVersion && device.type !== '')
                  }
                />

                <Form.Input
                  width={defaultFormWidth}
                  label="Serial"
                  name="serial"
                  value={idx(device, _h => _h.serial) || ''}
                  onChange={this.handleChange}
                  disabled={
                    !(device.UserId && device.macVersion && device.type !== '')
                  }
                />

                <Form.Input
                  width={defaultFormWidth}
                  label="Firmware"
                  name="firmware"
                  value={idx(device, _l => _l.firmware) || ''}
                  onChange={this.handleChange}
                  disabled={
                    !(device.UserId && device.macVersion && device.type !== '')
                  }
                />

                <Form.Button
                  content="Save"
                  type="submit"
                  disabled={
                    !(device.UserId && device.macVersion && device.type !== '')
                  }
                />
              </Form>
            )}
          </Sidebar.Pusher>
        </Sidebar.Pushable>

        {idx(device, ___v => ___v.deveui) && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            basic
            size="small"
          >
            <div style={{ width: '40%' }}>
              <SVG src="/assets/images/robotRYS.svg" />
            </div>
            <Header as="h1" content="Are you sure??" />
            <Modal.Content>
              <h3>
                This action will delete this device and all related shares.
              </h3>
              <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
              <p>To proceed, please fill the field with the device Eui</p>
              <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
                {idx(device, _o => _o.deveui)}
              </Label>
              <div>
                <Input
                  name="check"
                  placeholder="..."
                  width={defaultFormWidth}
                  onChange={this.handleChange}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={this.handleDelete}
                color="red"
                disabled={device.deveui !== device.check}
              >
                Proceed
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}
      </Fragment>
    );
  }
}

DeviceEditForm.propTypes = {
  device: PropTypes.object,
  application: PropTypes.object,
  usersOptions: PropTypes.object,
  applicationsOptions: PropTypes.object,
  match: PropTypes.object,
  deviceProfilesOptions: PropTypes.object,
  fetchDevice: PropTypes.func,
  fetchDeviceProfilesOptions: PropTypes.func,
  fetchApplicationsOptions: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  destroyUsersOptions: PropTypes.func,
  destroyDeviceProfilesOptions: PropTypes.func,
  destroyApplicationsOptions: PropTypes.func,
  destroyDevice: PropTypes.func,
  putDevice: PropTypes.func,
  deleteDevice: PropTypes.func,
  fetchDeviceProfileList: PropTypes.func,
  deviceProfile: PropTypes.object,
  fetchDeviceProfileId: PropTypes.func,
  deviceFromProps: PropTypes.object,
  options: PropTypes.array,
};

const DeviceUpdate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceEditForm),
);

export { DeviceUpdate };
