/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import idx from 'idx';
import PropTypes from 'prop-types';

import { Button, Divider, Form, Header } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  destroyGatewayProfile,
  fetchGatewayProfile,
  putGatewayProfile,
} from '../../actions/gatewayProfile';
import { PAGES_PATH } from '../../lib/variables';

const mapStateToProps = state => ({
  gatewayProfile: state.gatewayProfile,
});

const mapDispatchToProps = dispatch => ({
  fetchGatewayProfile: gatewayProfile => {
    dispatch(fetchGatewayProfile(gatewayProfile));
  },
  putGatewayProfile: gateway => {
    dispatch(putGatewayProfile(gateway));
  },
  destroyGatewayProfile: () => {
    dispatch(destroyGatewayProfile());
  },
});

class GatewayProfileEditForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      gatewayProfile: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchGatewayProfile(this.props.match.params.gpid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }

    if (nextProps.gatewayProfile !== this.props.gatewayProfile) {
      return true;
    }

    return false;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.gatewayProfile) {
      this.setState({
        gatewayProfile: {
          ...this.state.gatewayProfile,
          id: nextProps.gatewayProfile.id,
          label: nextProps.gatewayProfile.label,
          UserId: nextProps.gatewayProfile.UserId,
          maxTxPower: nextProps.gatewayProfile.maxTxPower,
          supportsClassB: nextProps.gatewayProfile.supportsClassB,
          supportsLbt: nextProps.gatewayProfile.supportsLbt,
          band: nextProps.gatewayProfile.band,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyGatewayProfile();
  }

  // HANDLER SECTION
  handleChange(e, { name, value }) {
    this.setState({
      gatewayProfile: { ...this.state.gatewayProfile, [name]: value },
    });
  }

  handleCheck(e, { name, checked }) {
    this.setState({
      gatewayProfile: { ...this.state.gatewayProfile, [name]: checked },
    });
  }

  handleSubmit(e) {
    const { gatewayProfile } = this.state;
    e.preventDefault();
    this.props.putGatewayProfile(gatewayProfile);
  }

  render() {
    const { gatewayProfile } = this.state;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Edit Gateway Profile
        </Header>
        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/gateways`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>
        <Divider clearing />
        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            width={8}
            label="Label"
            name="label"
            value={idx(gatewayProfile, _ => _.label) || ''}
            onChange={this.handleChange}
          />

          <Form.Dropdown
            width={8}
            label="Band"
            name="band"
            value={idx(gatewayProfile, _ => _.band) || ''}
            selection
            options={[
              { key: 'EU863-870', value: 'EU863-870', text: 'EU863-870' },
              { key: 'US902-928', value: 'US902-928', text: 'US902-928' },
              { key: 'AS920-925', value: 'AS920-925', text: 'AS920-925' },
            ]}
            onChange={this.handleChange}
          />

          <Form.Dropdown
            width={8}
            name="maxTxPower"
            label="Max Transmission Power"
            placeholder="choose one..."
            selection
            required
            options={[
              { key: 14, text: 14, value: 14 },
              { key: 27, text: 27, value: 27 },
            ]}
            value={idx(gatewayProfile, _ => _.maxTxPower)}
            onChange={this.handleChange}
          />

          <Form.Checkbox
            width={8}
            toggle
            label="Supports LBT (Listen Before Talk)"
            name="supportsLbt"
            checked={idx(gatewayProfile, _ => _.supportsLbt) || false}
            onChange={this.handleCheck}
          />

          <Form.Checkbox
            width={8}
            toggle
            label="Supports Class B [not available]"
            name="supportsClassB"
            checked={idx(gatewayProfile, _ => _.supportsClassB) || false}
            onChange={this.handleCheck}
            disabled
          />

          <Form.Button content="Save" type="submit" />
        </Form>
      </Fragment>
    );
  }
}

GatewayProfileEditForm.propTypes = {
  match: PropTypes.object,
  gatewayProfile: PropTypes.object,
  fetchGatewayProfile: PropTypes.func,
  destroyGatewayProfile: PropTypes.func,
  putGatewayProfile: PropTypes.func,
};

const GatewayProfileUpdate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GatewayProfileEditForm),
);

export { GatewayProfileUpdate };
