/** @format */

import React from 'react';
import { Label, List } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const MacCardListItem = props => {
  const { label, value, editable = true, editMode = false } = props;
  return (
    <List.Item
      className={['mac-card-list-item', editMode ? 'editing' : ''].join(' ')}
    >
      <List.Header className={'mac-card-list-item__content-label'}>
        {label}
      </List.Header>
      <List.Content className={'mac-card-list-item__content-value'}>
        {editMode ? (
          <Label size={'large'} content={value} color={'teal'} />
        ) : (
          value
        )}
      </List.Content>
      {editable && editMode && (
        <List.Content className={'mac-card-list-item__editable-content'}>
          {props.children}
        </List.Content>
      )}
    </List.Item>
  );
};

MacCardListItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  editable: PropTypes.bool,
  editMode: PropTypes.bool,
  children: PropTypes.node,
};

export default MacCardListItem;
