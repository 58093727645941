/** @format */

import { any, arrayOf, func, string } from 'prop-types';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

export const FilterSelect = ({
  options,
  values,
  placeHolder,
  onSearch: handleOnSearch,
  onChange,
}) => {
  const handleOnChange = (_event, data) => {
    const newOptions = data.value.map((value, index) => {
      return { key: index, text: value, value };
    });
    onChange(newOptions);
  };

  return (
    <Dropdown
      placeholder={placeHolder}
      fluid
      multiple
      search
      selection
      value={values}
      options={options}
      onChange={handleOnChange}
      onSearchChange={handleOnSearch}
    />
  );
};

FilterSelect.propTypes = {
  placeHolder: string,
  options: arrayOf(any),
  values: arrayOf(string),
  onSearch: func,
  onChange: func,
};
