/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Divider, Header, Table } from 'semantic-ui-react';
import { MacCommandCtx } from '../MacCommand';
import MacCommandStatusIcon from '../MacCommandStatusIcon';
import {
  pollingStates,
  useMacCommandPolling,
  useMacCommandService,
} from '../useMacCommandService';
import Battery from '../Battery/Battery';

const DeviceStatus = props => {
  const {
    deviceID,
    deviceNeverSend,
    setDeviceNeverSend,
    handleDeviceError,
  } = useContext(MacCommandCtx);
  const [status, setStatus] = useState(null);
  const { deviceStatus } = useMacCommandService(deviceID);

  const getStatus = () => {
    deviceStatus
      .get()
      .then(res => {
        setStatus(res.data);
        setDeviceNeverSend(false);
      })
      .catch(err => handleDeviceError(err));
  };

  useEffect(() => {
    if (deviceID !== null) getStatus();
  }, [deviceID]);

  const { setPolling } = useMacCommandPolling(deviceID, getStatus);

  const sendCommand = () => {
    deviceStatus
      .put()
      .then(res => {
        getStatus();
      })
      .catch(err => {
        getStatus();
        handleDeviceError(err);
      });
  };

  useEffect(() => {
    if (status && status.state && status.state === 'pending') {
      setPolling(pollingStates[0]);
    } else {
      setPolling(pollingStates[1]);
    }
  }, [status]);

  return (
    <Card fluid>
      <Card.Content>
        <Header as="h3" floated="left">
          Device Status
        </Header>
        <Button
          icon
          color="linkedin"
          labelPosition="left"
          floated="right"
          disabled={deviceNeverSend}
          onClick={() => sendCommand()}
        >
          <FontAwesomeIcon
            icon="paper-plane"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Send
        </Button>
        <Divider clearing className="secondary-color" />
        <Table
          className="unstackable"
          basic="very"
          style={{ textAlign: 'center' }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Battery Level</Table.HeaderCell>
              <Table.HeaderCell>SNR [db]</Table.HeaderCell>
              <Table.HeaderCell>Timestamp</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <MacCommandStatusIcon
                  state={(status && status.state) || '---'}
                />
              </Table.Cell>
              <Table.Cell>{(status && 'battery' in status && status.battery !== null) ? <Battery value={status.battery} /> : '---'}</Table.Cell>
              <Table.Cell>{(status && 'margin' in status && status.margin !== null) ? status.margin : '---'}</Table.Cell>
              <Table.Cell>{(status && status.time) || '---'}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  );
};

export default DeviceStatus;
