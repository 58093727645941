/** @format */

import { sortBy } from 'lodash';
import * as actions from '../actions/devices';

const defaultState = {
  filter: {},
  sort: {},
  isLoading: false,
  error: false,
  searchText: '',
  column: null,
  direction: null,
};

export const devices = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_DEVICES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.FETCH_DEVICES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICES_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_DEVICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case actions.SEARCH_DEVICES:
      return {
        ...state,
        searchText: action.payload,
      };
    case actions.ORDER_DEVICES:
      if (state.column !== action.payload) {
        return {
          ...state,
          column: action.payload,
          devices: sortBy(state.rows, [action.payload]),
          direction: 'ascending',
        };
      }

      return {
        ...state,
        devices: state.rows.reverse(),
        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
      };

    default:
      return state;
  }
};

export const devicesSharedBy = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_DEVICES_SHARED_BY_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_DEVICES_SHARED_BY_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICES_SHARED_BY_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_DEVICES_SHARED_BY_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

export const devicesSharedWith = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_DEVICES_SHARED_WITH_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_DEVICES_SHARED_WITH_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_DEVICES_SHARED_WITH_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_DEVICES_SHARED_WITH_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
