/** @format */

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class EditButton extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { path } = this.props;
    return (
      <Button
        as={Link}
        key="edit"
        compact
        icon
        color="red"
        labelPosition="left"
        floated="right"
        style={{ marginTop: '2.4em' }}
        to={path}
      >
        <FontAwesomeIcon
          icon="edit"
          className="icon"
          style={{ padding: '0.4em' }}
        />
        Edit
      </Button>
    );
  }
}

EditButton.propTypes = {
  path: PropTypes.string.isRequired,
};
