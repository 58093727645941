import { HeatMapGrid } from 'react-grid-heatmap';
import React from 'react';
import { getColor, IRONBOW_PALETTE } from './palette';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const labelshow  = [0, 25, 50, 75, 100];

function percentage(percent, total) {
  return Math.floor((percent/ 100) * total)
}

const ScanChart = ({ scan, onClick }) => {

  if(!scan || scan === -1){
    return <Segment>
      Please select an item from the table to show the chart
    </Segment> ;
  }

  const chiaviFrequenze = Object.keys(scan.result);
  const data = {};

  Object.keys(scan.result).forEach(chiaveFrequenza => {
    const mappa = {};
    for (let i = 0; i < scan.result[chiaveFrequenza].length; i = i + 2) {
      mappa[scan.result[chiaveFrequenza][i]] = scan.result[chiaveFrequenza][i + 1];
    }
    Object.keys(mappa).forEach(db => {
      if(data[db]){
        data[db].push(mappa[db]);
      } else {
        data[db] = [mappa[db]];
      }
    })
  });

  const indexToShow = labelshow.map(percent => percentage(percent, Object.keys(data).length -1))

  return <div style={{ width: "100%", fontFamily: "sans-serif" }}>
      <HeatMapGrid
        data={Object.values(data)}
        xLabels={chiaviFrequenze}
        yLabels={Object.keys(data)}
        cellRender={(x, y, value) => (<span title={`${value}`}>&nbsp;</span>)}
        xLabelsStyle={(index) => ({
          color: '#777',
          visibility: index %2 === 0 ? 'visible' : 'hidden',
          fontSize: '.8rem',
        })}
        yLabelsStyle={(index) => {

          const visible = indexToShow.includes(index);
          const visibility = visible ? 'visible' : 'hidden';

          return {
            fontSize: '.8rem',
            textTransform: 'uppercase',
            visibility
          }
        }}
        cellStyle={(_x, _y, ratio) => ({
          background: getColor(IRONBOW_PALETTE, ratio),
          fontSize: '1rem',
          border: '0px',
          borderRadius: '0',
          color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`,
        })}
        cellHeight="0.5rem"
        xLabelsPos="bottom"
        onClick={ onClick }
      />
    </div>

}

ScanChart.propTypes = {
  scan: PropTypes.any,
  onClick: PropTypes.func,
};

export default ScanChart;
