/** @format */

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class DeleteButton extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.handleDeleteModal = this.handleDeleteModal.bind(this);
  }

  handleDeleteModal() {
    this.props.handleDeleteModal();
  }

  render() {
    return (
      <Button
        key="delete"
        compact
        icon
        color="red"
        labelPosition="left"
        floated="right"
        style={{ marginTop: '2.4em' }}
        onClick={() => this.handleDeleteModal()}
      >
        <FontAwesomeIcon
          icon="trash-alt"
          className="icon"
          style={{ padding: '0.4em' }}
        />
        Delete
      </Button>
    );
  }
}

DeleteButton.propTypes = {
  handleDeleteModal: PropTypes.func.isRequired,
};
