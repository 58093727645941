/** @format */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardGroup,
  CardContent,
  CardHeader,
  CardDescription,
  Container,
  Divider,
  Grid,
  Header,
  HeaderSubheader,
  List,
  Message,
  MessageHeader,
  Pagination,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from 'semantic-ui-react';
import { ReactFontSizeByTextLength } from 'react-font-size-by-text-length';

import { ListItem } from '../../../components';
import { MessageList, RenderStatus } from '../Utils/Utils';
import { userHasRole } from '../../../lib/auth';

import { fetchDeviceManagerImportDetail } from '../../../actions/deviceManager';

import { PAGES_PATH } from '../../../lib/variables';

const DeviceImportSingle = ({ importId }) => {
  const {
    single: {
      id = null,
      kind = null,
      username = null,
      filename = null,
      status = null,
      total = 0,
      success = 0,
      error = 0,
      message = null,
      rows: errors = [],
      offset = 0,
      limit = 10,
      size = null,
    } = {},
  } = useSelector(state => state.deviceManager);

  const dispatch = useDispatch();

  useEffect(() => {
    if (importId) dispatch(fetchDeviceManagerImportDetail(importId));
  }, [importId]);

  const onPageChange = (e, { activePage }) => {
    dispatch(
      fetchDeviceManagerImportDetail(importId, {
        offset: (activePage - 1) * limit,
        limit,
      }),
    );
  };

  return (
    <>
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        {kind ? kind : '-'} <RenderStatus status={status} message={null} />
        <HeaderSubheader>
          <span style={{ fontSize: '.7rem' }}>{importId}</span>
        </HeaderSubheader>
      </Header>
      <Divider clearing />
      {message && (
        <Message error>
          <MessageHeader>Error</MessageHeader>
          <p>{message}</p>
        </Message>
      )}
      <Grid columns={2} doubling style={{ marginTop: '5em' }}>
        <Grid.Column>
          <Card fluid>
            <Card.Content>
              <Card.Header>Info</Card.Header>
              <Divider />
              <Card.Description>
                <List verticalAlign="middle" divided selection>
                  <ListItem label="id" content={id || '-'} copy={true} />
                  <ListItem label="type" content={kind || '-'} copy={true} />
                  {userHasRole('admin') && (
                    <ListItem
                      label="user"
                      content={username || '-'}
                      copy={true}
                    />
                  )}
                  {userHasRole('admin') && (
                    <ListItem
                      label="filename"
                      content={filename || '-'}
                      copy={true}
                    />
                  )}
                  <ListItem
                    label="status"
                    content={status || '-'}
                    copy={true}
                  />
                </List>
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column>
          <CardGroup>
            {total > 0 && (
              <Card color="green">
                <CardContent>
                  <CardHeader style={{ fontSize: '3em', color: '#21ba45' }}>
                    success
                  </CardHeader>
                  <CardDescription style={{ margin: '2em' }}>
                    <ReactFontSizeByTextLength
                      maxPercent={100}
                      minPercent={60}
                      changePerChar={10}
                    >
                      <div style={{ fontSize: '5em', textAlign: 'right' }}>
                        {success}
                        <span style={{ color: 'lightgray' }}>/{total}</span>
                      </div>
                    </ReactFontSizeByTextLength>
                  </CardDescription>
                </CardContent>
              </Card>
            )}
            {total > 0 && (
              <Card color="red">
                <CardContent>
                  <CardHeader style={{ fontSize: '3em', color: '#db2828' }}>
                    errors
                  </CardHeader>
                  <CardDescription style={{ margin: '2em' }}>
                    <ReactFontSizeByTextLength
                      maxPercent={100}
                      minPercent={60}
                      changePerChar={10}
                    >
                      <div style={{ fontSize: '5em', textAlign: 'right' }}>
                        {error}
                        <span style={{ color: 'lightgray' }}>/{total}</span>
                      </div>
                    </ReactFontSizeByTextLength>
                  </CardDescription>
                </CardContent>
              </Card>
            )}
          </CardGroup>
        </Grid.Column>
      </Grid>
      {errors && errors.length > 0 && (
        <Grid columns={1} doubling>
          <Grid.Column>
            <Table celled>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>DevEui</TableHeaderCell>
                  {userHasRole('admin') && (
                    <TableHeaderCell>User</TableHeaderCell>
                  )}
                  <TableHeaderCell>Message</TableHeaderCell>
                  <TableHeaderCell>Line</TableHeaderCell>
                </TableRow>
              </TableHeader>

              <TableBody>
                {errors.map(item => {
                  return (
                    <TableRow key={item.deveui}>
                      <TableCell>
                        <Link to={`${PAGES_PATH}/device/${item.deveui}`}>
                          {item.deveui}
                        </Link>
                      </TableCell>
                      {userHasRole('admin') && (
                        <TableCell>{item.owner || '-'}</TableCell>
                      )}
                      <TableCell>
                        <MessageList message={item.message} />
                      </TableCell>
                      <TableCell>{item.line || '-'}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <Container textAlign="center">
              <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={parseInt(offset / limit, 10) + 1}
                totalPages={Math.ceil((size || 0) / limit)}
                onPageChange={onPageChange}
              />
            </Container>
          </Grid.Column>
        </Grid>
      )}
    </>
  );
};

DeviceImportSingle.propTypes = {
  importId: PropTypes.string,
};

export { DeviceImportSingle };
