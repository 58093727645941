/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import idx from 'idx';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import {
  Button,
  Divider,
  Form,
  Header,
  Input,
  Label,
  Modal,
  Message,
} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cleave from 'cleave.js/react';
import Axios from 'axios';
import { MulticastGroupDevicesTable } from '..';

import {
  deleteMulticastGroup,
  destroyMulticastGroup,
  fetchMulticastGroup,
  putMulticastGroup,
} from '../../actions/multicastGroup';
import { PAGES_PATH } from '../../lib/variables';
import {
  formatBytes,
  formatNormalString,
  coupleOfBytesLengths,
  generatePlaceholder,
  cleaveOptions,
} from '../../lib/utilities';
import { getCurrentUserToken } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  usersOptions: state.usersOptions,
  multicastGroup: state.multicastGroup,
});

const mapDispatchToProps = dispatch => ({
  fetchMulticastGroup: multicast => {
    dispatch(fetchMulticastGroup(multicast));
  },
  putMulticastGroup: (multicast, multicastGroupId) => {
    dispatch(putMulticastGroup(multicast, multicastGroupId));
  },
  deleteMulticastGroup: (multicastGroup, tab) => {
    dispatch(deleteMulticastGroup(multicastGroup, tab));
  },
  destroyMulticastGroup: () => {
    dispatch(destroyMulticastGroup());
  },
});

class MulticastGroupEditForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      multicastGroup: {
        supports32bitFCnt: false,
        devaddr: '',
        appSKey: '',
        nwkSKey: '',
      },
      errors: {
        fCnt: false,
      },
      maxValue: 2 ** 31,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChangeCleave = this.handleChangeCleave.bind(this);
  }

  componentDidMount() {
    Axios.get(url.MulticastGroup(this.props.match.params.mgid), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => this.setState({ multicastGroup: res.data }))
      .catch(error => devLog(error));
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      idx(nextProps, _ => _.multicastGroup.owner) &&
      this.state.multicastGroup.UserId === undefined
    ) {
      this.setState({
        multicastGroup: {
          ...this.state.multicastGroup,
          id: nextProps.multicastGroup.id,
          label: nextProps.multicastGroup.label,
          devaddr: nextProps.multicastGroup.devaddr,
          appSKey: nextProps.multicastGroup.appSKey,
          nwkSKey: nextProps.multicastGroup.nwkSKey,
          supports32bitFCnt: nextProps.multicastGroup.supports32bitFCnt,
          fCnt: nextProps.multicastGroup.fCnt,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyMulticastGroup();
  }

  handleChange(e, { name, value }) {
    if (name === 'fCnt' && value !== 0) {
      this.setState({
        multicastGroup: { ...this.state.multicastGroup, fCnt: Number(value) },
      });
    }
    if (name === 'fCnt' && value === 0) {
      this.setState({
        multicastGroup: { ...this.state.multicastGroup, fCnt: '' },
      });
    } else {
      this.setState({
        multicastGroup: { ...this.state.multicastGroup, [name]: value },
      });
    }
  }

  handleChangeCleave(e) {
    this.setState({
      multicastGroup: {
        ...this.state.multicastGroup,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCheck(e, { name, checked }) {
    this.setState({
      multicastGroup: { ...this.state.multicastGroup, [name]: checked },
    });
  }

  handleClose() {
    this.setState({ modalOpen: false, devaddr: null, check: null });
  }

  handleDeleteModal() {
    this.setState({
      modalOpen: true,
      devaddr: this.props.multicastGroup.devaddr,
    });
  }

  handleDelete() {
    this.props.deleteMulticastGroup(this.props.match.params.mgid, 3);
    this.setState({ modalOpen: false, check: null });
  }

  handleSubmit(e) {
    const { multicastGroup, errors } = this.state;
    e.preventDefault();
    const tempMulticastGroup = {
      ...multicastGroup,
      fCnt: parseInt(multicastGroup.fCnt, 10),
      devaddr: formatNormalString(multicastGroup.devaddr, ':'),
      appSKey: formatNormalString(multicastGroup.appSKey, ':'),
      nwkSKey: formatNormalString(multicastGroup.nwkSKey, ':'),
    };

    delete tempMulticastGroup.createdAt;
    delete tempMulticastGroup.updatedAt;
    delete tempMulticastGroup.id;
    delete tempMulticastGroup.UserId;

    if (!errors.fCnt)
      this.props.putMulticastGroup(
        tempMulticastGroup,
        this.props.match.params.mgid,
      );
  }

  handleBlur({ target }) {
    const { maxValue } = this.state;

    Number(target.value) <= maxValue
      ? this.setState({ ...this.state, errors: { [target.name]: false } })
      : this.setState({ ...this.state, errors: { [target.name]: true } });
  }

  onDeviceBlur(event, bytesEnum) {
    const reducer = formatNormalString(
      event.target.value.replace(/^0+/g, ''),
      ':',
    );

    this.setState({
      multicastGroup: {
        ...this.state.multicastGroup,
        [event.target.name]: reducer.padStart(bytesEnum * 2, '0'),
      },
    });
  }

  render() {
    const { multicastGroup, errors } = this.state;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Edit Multicast Group
        </Header>
        <Button
          key="delete"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          onClick={() => this.handleDeleteModal()}
        >
          <FontAwesomeIcon
            icon="trash-alt"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Delete
        </Button>

        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={{ pathname: `${PAGES_PATH}/devices`, state: { activeIndex: 3 } }}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>

        <Divider clearing />
        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            width={8}
            label="Label"
            name="label"
            value={idx(multicastGroup, _ => _.label) || 'no name'}
            onChange={this.handleChange}
          />

          <Form.Input width={9} label="Device Address" required>
            <Cleave
              placeholder={generatePlaceholder(coupleOfBytesLengths.DEVADDR)}
              name="devaddr"
              value={formatBytes(multicastGroup.devaddr, null)}
              options={{
                ...cleaveOptions,
                blocks: new Array(coupleOfBytesLengths.DEVADDR).fill(2),
              }}
              onBlur={ev => this.onDeviceBlur(ev, coupleOfBytesLengths.DEVADDR)}
              onChange={this.handleChangeCleave}
            />
          </Form.Input>

          <Form.Input width={9} label="Application Session Key" required>
            <Cleave
              placeholder={generatePlaceholder(coupleOfBytesLengths.APPSKEY)}
              name="appSKey"
              value={formatBytes(multicastGroup.appSKey, null)}
              options={{
                ...cleaveOptions,
                blocks: new Array(coupleOfBytesLengths.APPSKEY).fill(2),
              }}
              onBlur={ev => this.onDeviceBlur(ev, coupleOfBytesLengths.APPSKEY)}
              onChange={this.handleChangeCleave}
            />
          </Form.Input>

          <Form.Input width={9} label="Network Session Key" required>
            <Cleave
              placeholder={generatePlaceholder(coupleOfBytesLengths.NWKSKEY)}
              name="nwkSKey"
              value={formatBytes(multicastGroup.nwkSKey, null)}
              options={{
                ...cleaveOptions,
                blocks: new Array(coupleOfBytesLengths.NWKSKEY).fill(2),
              }}
              onBlur={ev => this.onDeviceBlur(ev, coupleOfBytesLengths.NWKSKEY)}
              onChange={this.handleChangeCleave}
            />
          </Form.Input>

          <Form.Checkbox
            width={8}
            toggle
            label="Supports 32 bit Frame Counter Size"
            name="supports32bitFCnt"
            checked={idx(multicastGroup, _ => _.supports32bitFCnt)}
            onChange={this.handleCheck}
          />

          <Form.Input
            width={8}
            label="Frame Counter"
            name="fCnt"
            value={idx(multicastGroup, _ => _.fCnt) || ''}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
          />

          {Object.values(errors).includes(true) && (
            <Message negative>
              <Message.Header>Wrong "Frame Counter" value</Message.Header>
              <p>Please check the "Frame Counter" field</p>
            </Message>
          )}

          <Form.Button content="Save" type="submit" />
        </Form>
        <MulticastGroupDevicesTable multicastGroup={multicastGroup} isLimited />

        {idx(multicastGroup, _ => _.devaddr) && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            basic
            size="small"
          >
            <div style={{ width: '40%' }}>
              <SVG src="/assets/images/robotRYS.svg" />
            </div>
            <Header as="h1" content="Are you sure??" />
            <Modal.Content>
              <h3>This action will remove this Multiocast Group.</h3>
              <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
              <p>To proceed, please fill the field with the Gateway ID</p>
              <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
                {idx(multicastGroup, _ => _.devaddr)}
              </Label>
              <div>
                <Input
                  name="check"
                  placeholder="..."
                  width={8}
                  onChange={this.handleChange}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={this.handleDelete}
                color="red"
                disabled={multicastGroup.devaddr !== multicastGroup.check}
              >
                Proceed
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}
      </Fragment>
    );
  }
}

MulticastGroupEditForm.propTypes = {
  match: PropTypes.object,
  multicastGroup: PropTypes.object,
  fetchMulticastGroup: PropTypes.func,
  destroyMulticastGroup: PropTypes.func,
  putMulticastGroup: PropTypes.func,
  deleteMulticastGroup: PropTypes.func,
};

const MulticastGroupUpdate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MulticastGroupEditForm),
);

export { MulticastGroupUpdate };
