/** @format */

import * as actions from '../actions/gateways';

const defaultState = {
  filter: {},
  sort: {},
  isLoading: false,
  error: false,
};

export const gateways = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_GATEWAYS_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_GATEWAYS_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_GATEWAYS_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_GATEWAYS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

export const gatewaysSharedBy = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_GATEWAYS_SHARED_BY_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_GATEWAYS_SHARED_BY_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_GATEWAYS_SHARED_BY_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_GATEWAYS_SHARED_BY_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

export const gatewaysSharedWith = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_GATEWAYS_SHARED_WITH_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_GATEWAYS_SHARED_WITH_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_GATEWAYS_SHARED_WITH_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_GATEWAYS_SHARED_WITH_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
