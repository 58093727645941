/** @format */

import * as actions from '../actions/userProfiles';

const defaultState = {
  isLoading: false,
  error: false,
};

const userProfilesOptions = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_PROFILES_OPTIONS_REQUEST:
      return {
        isLoading: true,
        error: false,
        options: [],
      };
    case actions.FETCH_USER_PROFILES_OPTIONS_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_USER_PROFILES_OPTIONS_FAILURE:
      return {
        isLoading: false,
        error: true,
        options: [],
      };
    case actions.DESTROY_USER_PROFILES_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default userProfilesOptions;
