/**
 *
 *
 * @format
 */

/* eslint-disable no-nested-ternary */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUplinkDevEui } from '../../actions/uplinks';
import './Console.scss';
import { Toolbar } from './components/Toolbar';
import { List } from './components/List';
import { Table } from 'semantic-ui-react';

import { Filters } from './components/Filters';

export const ViewConsole = () => {
  const dispatch = useDispatch();

  const effectLoad = () => {
    dispatch(fetchUplinkDevEui());
  };

  useEffect(effectLoad, []);

  return (
    <div>
      <Toolbar />
      <div className="TableBody">
        <Table compact="very">
          <Table.Header>
            <Filters />
          </Table.Header>
          <Table.Body className="TableBody">
            <List />
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
