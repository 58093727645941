/** @format */

import * as actions from '../actions/gateway';

const defaultState = {
  data: {},
  isLoading: false,
  error: false,
};

export const gateway = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_GATEWAY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.FETCH_GATEWAY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_GATEWAY_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: true,
      };

    case actions.POST_GATEWAY_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.POST_GATEWAY_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.POST_GATEWAY_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.PUT_GATEWAY_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.PUT_GATEWAY_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.PUT_GATEWAY_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DELETE_GATEWAY_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.DELETE_GATEWAY_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.DELETE_GATEWAY_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_GATEWAY_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
