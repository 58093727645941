/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { userHasRole } from '../../lib/auth';
import { PAGES_PATH } from '../../lib/variables';

import './Menu.css';

const MainMenu = (props) => (
  <Menu
    vertical={!props.mobile}
    icon={ !props.mobile ? 'labeled' : undefined }
    secondary
    color="teal"
    inverted
    widths={1}
    stackable={props.mobile}
    width="very thin"
    fluid={props.mobile}
    floated={!props.mobile ?  true : false }
    style={!props.mobile ? { fontSize: '1em', height: '100vh', backgroundColor: '#3e87a6' } : {}}
  >
    <Menu.Item name="home">
      { props.hasIcons && <SVG src="/assets/images/logoSmall.svg" className={'menu-icon-svg'} /> }
    </Menu.Item>
    <Menu.Item
      as={NavLink}
      to={`${PAGES_PATH}/devices`}
      name="devices"
    >
      { props.hasIcons && <SVG src="/assets/images/icoDevices.svg" className={'menu-icon-svg'} /> }
      DEVICES
    </Menu.Item>
    <Menu.Item
      as={NavLink}
      to={`${PAGES_PATH}/applications`}
      name="applications"
    >
      { props.hasIcons && <SVG src="/assets/images/icoApps.svg" className={'menu-icon-svg'} /> }
      APPS
    </Menu.Item>
    <Menu.Item
      as={NavLink}
      to={`${PAGES_PATH}/gateways`}
      name="gateways"
    >
      { props.hasIcons && <SVG src="/assets/images/icoGateways.svg" className={'menu-icon-svg'} /> }
      GWAYS
    </Menu.Item>
    <Menu.Item
      as={NavLink}
      to={`${PAGES_PATH}/console`}
      name="Console"
    >
      { props.hasIcons && <SVG src="/assets/images/icoConsole.svg" className={'menu-icon-svg'} /> }
      LOGS
    </Menu.Item>
    <Menu.Item
      as={NavLink}
      to={`${PAGES_PATH}/statistics`}
      name="Statistics"
    >
      { props.hasIcons && <SVG src="/assets/images/icoStats.svg" className={'menu-icon-svg'} /> }
      STATS
    </Menu.Item>
    <Menu.Item as={NavLink} to={`${PAGES_PATH}/docs`} name="Docs">
    { props.hasIcons &&  <Icon name="help" size="huge" className={'menu-icon-svg'}  /> }
      DOCS
    </Menu.Item>
    {userHasRole('admin') && (
      <Menu.Item
        as={NavLink}
        to={`${PAGES_PATH}/users`}
        name="users"
      >
        { props.hasIcons && <SVG src="/assets/images/icoUsers.svg" className={'menu-icon-svg'} /> }
        USERS
      </Menu.Item>
    )}
    <Menu.Item
      as={NavLink}
      to={`${PAGES_PATH}/profile`}
      name="profile"
    >
      { props.hasIcons && <SVG src="/assets/images/robotAccount.svg" className={'menu-icon-svg'} /> }
      <div>ME</div>
    </Menu.Item>
    <Menu.Item onClick={props.logOut} active={false}>
      { props.hasIcons && <SVG src="/assets/images/icoLogout.svg" className={'menu-icon-svg'} /> }
      <div>OUT</div>
    </Menu.Item>
  </Menu>
);

MainMenu.propTypes = {
  logOut: PropTypes.func,
  hasIcons: PropTypes.bool,
  mobile: PropTypes.bool
};

MainMenu.defaultProps = {
  hasIcons: true,
  mobile: false
};

export { MainMenu };