/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const POST_USER_PROFILE_REQUEST = 'userProfile/POST_REQUEST';
export const POST_USER_PROFILE_SUCCESS = 'userProfile/POST_SUCCESS';
export const POST_USER_PROFILE_FAILURE = 'userProfile/POST_FAILURE';

export const PUT_USER_PROFILE_REQUEST = 'userProfile/PUT_REQUEST';
export const PUT_USER_PROFILE_SUCCESS = 'userProfile/PUT_SUCCESS';
export const PUT_USER_PROFILE_FAILURE = 'userProfile/PUT_FAILURE';

export const DELETE_USER_PROFILE_REQUEST = 'userProfile/DELETE_REQUEST';
export const DELETE_USER_PROFILE_SUCCESS = 'userProfile/DELETE_SUCCESS';
export const DELETE_USER_PROFILE_FAILURE = 'userProfile/DELETE_FAILURE';

export const DESTROY_USER_PROFILE_SUCCESS = 'userProfile/DESTROY_SUCCESS';

// ACTION CREATORS
export function postUserProfile(userProfile) {
  return {
    [CALL_API]: {
      endpoint: url.UserProfileCreate(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      body: JSON.stringify(userProfile),
      types: [
        POST_USER_PROFILE_REQUEST,
        {
          type: POST_USER_PROFILE_SUCCESS,
          payload: (action, state, res) => {
            return res.json().then(json => json);
          },
        },
        {
          type: POST_USER_PROFILE_FAILURE,
          payload: (action, state, res) =>
            res.json().then(err => toastError(err)),
        },
      ],
    },
  };
}

export function putUserProfile(userProfile, pid) {
  return {
    [CALL_API]: {
      endpoint: url.UserProfileSingle(pid),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      body: JSON.stringify(userProfile),
      types: [
        PUT_USER_PROFILE_REQUEST,
        {
          type: PUT_USER_PROFILE_SUCCESS,
          payload: (action, state, res) => res.json().then(json => json),
        },
        {
          type: PUT_USER_PROFILE_FAILURE,
          payload: (action, state, res) =>
            res.json().then(err => toastError(err)),
        },
      ],
    },
  };
}

export function deleteUserProfile(userProfileId) {
  return {
    [CALL_API]: {
      endpoint: url.UserProfileSingle(userProfileId),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        DELETE_USER_PROFILE_REQUEST,
        {
          type: DELETE_USER_PROFILE_SUCCESS,
          payload: (action, state, res) => res.json().then(json => json),
        },
        {
          type: DELETE_USER_PROFILE_FAILURE,
          payload: (action, state, res) =>
            res.json().then(err => toastError(err)),
        },
      ],
    },
  };
}

export const destroyUserProfile = () => ({
  type: DESTROY_USER_PROFILE_SUCCESS,
});
