/** @format */

const CURRENT_USER_FIELD = 'currentUser';
const TOKEN_FIELD = 'token';
const USERNAME_FIELD = 'username';
const USERID_FIELD = 'id';

/* setCurrentUser: @TODO */
export const setCurrentUser = currentUser => {
  if (currentUser) {
    localStorage.setItem(CURRENT_USER_FIELD, JSON.stringify(currentUser));
  }
};

/* getCurrentUser: @TODO */
export const getCurrentUser = () =>
  JSON.parse(localStorage.getItem(CURRENT_USER_FIELD)) || false;

/* getCurrentUserName: @TODO */
export const getCurrentUserName = () => {
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_FIELD));

  if (user && USERNAME_FIELD in user) {
    return user[USERNAME_FIELD];
  }
  return false;
};

/* getCurrentUserId: @TODO */
export const getCurrentUserId = () => {
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_FIELD));

  if (user && USERID_FIELD in user) {
    return user[USERID_FIELD];
  }
  return false;
};

/* getCurrentUserToken: @TODO */
export const getCurrentUserToken = () => {
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_FIELD));

  if (user && TOKEN_FIELD in user) {
    return `Bearer ${user.token}`;
  }
  return false;
};

export const getCurrentUserTokenWithoutBearer = () => {
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_FIELD));

  if (user && TOKEN_FIELD in user) {
    return user.token;
  }
  return false;
};

/**
 * wrap token in the format require by broker and auth-api (token:[...])
 * @returns {String|false} return the wrapped token in the format require by broker and auth-api (token:[...])
 */
export const getCurrentUserMqttToken = () => {
  const mqttToken = getCurrentUserTokenWithoutBearer();
  if (mqttToken) {
    return `token:[${mqttToken}]`;
  }
  return false;
};

/* deleteCurrentUserToken: @TODO */
export const deleteCurrentUserToken = () => {
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_FIELD));

  if (user && TOKEN_FIELD in user) {
    delete user[TOKEN_FIELD];
    localStorage.setItem(CURRENT_USER_FIELD, JSON.stringify(user));
    return true;
  }
  return false;
};

/* isLoggedIn: @TODO */
export const isLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_FIELD));

  if (user && TOKEN_FIELD in user) {
    return true;
  }
  return false;
};

/* userHasRole: @TODO */
export const userHasRole = role => {
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_FIELD));

  if (user.role === role) return true;

  return false;
};
