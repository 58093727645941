/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import {
  generateUrl,
  generateSimpleQueryV2,
  useThisQueryString,
} from '../lib/urls';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_APPLICATIONS_REQUEST = 'applications/FETCH_REQUEST';
export const FETCH_APPLICATIONS_SUCCESS = 'applications/FETCH_SUCCESS';
export const FETCH_APPLICATIONS_FAILURE = 'applications/FETCH_FAILURE';

export const FETCH_APPLICATIONS_SHARED_BY_REQUEST =
  'applicationsSharedBy/FETCH_REQUEST';
export const FETCH_APPLICATIONS_SHARED_BY_SUCCESS =
  'applicationsSharedBy/FETCH_SUCCESS';
export const FETCH_APPLICATIONS_SHARED_BY_FAILURE =
  'applicationsSharedBy/FETCH_FAILURE';

export const FETCH_APPLICATIONS_SHARED_WITH_REQUEST =
  'applicationsSharedWith/FETCH_REQUEST';
export const FETCH_APPLICATIONS_SHARED_WITH_SUCCESS =
  'applicationsSharedWith/FETCH_SUCCESS';
export const FETCH_APPLICATIONS_SHARED_WITH_FAILURE =
  'applicationsSharedWith/FETCH_FAILURE';

export const FETCH_APPLICATIONS_OPTIONS_REQUEST =
  'applicationsOptions/FETCH_REQUEST';
export const FETCH_APPLICATIONS_OPTIONS_SUCCESS =
  'applicationsOptions/FETCH_SUCCESS';
export const FETCH_APPLICATIONS_OPTIONS_FAILURE =
  'applicationsOptions/FETCH_FAILURE';

export const DESTROY_APPLICATIONS_SUCCESS = 'applications/DESTROY_SUCCESS';
export const DESTROY_APPLICATIONS_SHARED_BY_SUCCESS =
  'applicationsSharedBy/DESTROY_SUCCESS';
export const DESTROY_APPLICATIONS_SHARED_WITH_SUCCESS =
  'applicationsSharedWith/DESTROY_SUCCESS';
export const DESTROY_APPLICATIONS_OPTIONS_SUCCESS =
  'applicationsOptions/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchApplications = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.Applications(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_APPLICATIONS_REQUEST,
      {
        type: FETCH_APPLICATIONS_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_APPLICATIONS_FAILURE,
        payload: (action, state, res) => res.json().then(err => err),
      },
    ],
  },
});

export const fetchApplicationsOptions = (user, params = {}) => ({
  [CALL_API]: {
    endpoint: generateUrl(
      url.ApplicationsOptions(user),
      generateSimpleQueryV2(params),
    ),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_APPLICATIONS_OPTIONS_REQUEST,
      {
        type: FETCH_APPLICATIONS_OPTIONS_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            options: json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_APPLICATIONS_OPTIONS_FAILURE,
        payload: (action, state, res) => res.json().then(err => err),
      },
    ],
  },
});

export const fetchApplicationsSharedBy = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.ApplicationsSharedBy(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_APPLICATIONS_SHARED_BY_REQUEST,
      {
        type: FETCH_APPLICATIONS_SHARED_BY_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            ...json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_APPLICATIONS_SHARED_BY_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/applications`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchapplicationsSharedWith = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.ApplicationsSharedWith(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_APPLICATIONS_SHARED_WITH_REQUEST,
      {
        type: FETCH_APPLICATIONS_SHARED_WITH_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            ...json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_APPLICATIONS_SHARED_WITH_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/devices`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyApplications = () => ({
  type: DESTROY_APPLICATIONS_SUCCESS,
});

export const destroyApplicationsSharedBy = () => ({
  type: DESTROY_APPLICATIONS_SHARED_BY_SUCCESS,
});

export const destroyApplicationsSharedWith = () => ({
  type: DESTROY_APPLICATIONS_SHARED_WITH_SUCCESS,
});

export const destroyApplicationsOptions = () => ({
  type: DESTROY_APPLICATIONS_OPTIONS_SUCCESS,
});
