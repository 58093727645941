/** @format */
/* eslint-disable react/display-name */

import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider, Header, Label, Menu, Tab } from 'semantic-ui-react';
import {
  UsersList,
  UserProfilesList,
  AccessTokensRead,
} from '../../containers';
import { fetchUsers } from '../../actions/users';
import { fetchUserProfilesList } from '../../actions/userProfiles';
import { updateTab } from '../../actions/tabs';

const Users = props => {
  const users = useSelector(state => state.users);
  const UserProfiles = useSelector(state => state.userProfilesList);
  const index = useSelector(state => state.tabs.users.index);
  const dispatch = useDispatch();
  const limit = 12;

  useEffect(() => {
    if (users && !('size' in users))
      dispatch(
        fetchUsers({
          qs: `limit=${limit}&not_roles[]=system&not_roles[]=admin`,
        }),
      );
    if (UserProfiles && !('size' in UserProfiles))
      dispatch(fetchUserProfilesList());
  }, []);

  const handleTabChange = (e, { activeIndex }) => {
    e.preventDefault();
    dispatch(updateTab({ users: { index: activeIndex || 0 } }));
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item key="usersList">
          users list
          {users && 'size' in users && (
            <Label color={index == 0 ? 'teal' : 'grey'}>{users.size}</Label>
          )}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <UsersList
            UserProfiles={'rows' in UserProfiles ? UserProfiles.rows : []}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="profilesList">
          profiles list
          {UserProfiles && 'size' in UserProfiles && (
            <Label color={index == 1 ? 'teal' : 'grey'}>
              {UserProfiles.size}
            </Label>
          )}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <UserProfilesList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: <Menu.Item key="accessTokenList">access token</Menu.Item>,
      render: () => (
        <Tab.Pane>
          <AccessTokensRead disableButton={true} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Fragment>
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        Users
      </Header>
      <Divider clearing />

      <Tab panes={panes} activeIndex={index} onTabChange={handleTabChange} />
    </Fragment>
  );
};

Users.propTypes = {
  location: PropTypes.object.isRequired,
};

export { Users };
