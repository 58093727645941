/** @format */

export const REGEX_UPLINK = new RegExp(
  '(?:/|)sub/(?:v1|v2)/users/(?<user>[^//]{1,})(?:/shared/(?<shared>[^//]{1,}))?/apps/(?<appid>[0-9]{1,})/devices/(?<deveui>[a-fA-F0-9]{1,16})/(?<type>uplink|downlink)/(?<port>[0-9]{1,})',
);
export const REGEX_EVENT = new RegExp(
  '(?:/|)sub/(?:v1|v2)/users/(?<user>[^//]{1,})(?:/shared/(?<shared>[^//]{1,}))?/apps/(?<appid>[0-9]{1,})/devices/(?<deveui>[a-fA-F0-9]{1,16})/events/(?<type>\\S{1,})',
);
export const REGEX_ALERT = new RegExp(
  '(?:/|)sub/(?:v1|v2)/users/(?<user>[^//]{1,})(?:/shared/(?<shared>[^//]{1,}))?/apps/(?<appid>[0-9]{1,})/devices/(?<deveui>[a-fA-F0-9]{1,16})/alerts/(?<type>[-a-zA-Z]{1,})',
);
export const REGEX_EVENT_GW = new RegExp(
  '(?:/|)sub/(?:v1|v2)/users/(?<user>[^//]{1,})(?:/shared/(?<shared>[^//]{1,}))?/gateways/(?<gwid>[a-fA-F0-9]{1,16})/events/(?<type>\\S{1,})',
);

export const REGEX_ALERT_GW = new RegExp(
  '(?:/|)sub/(?:v1|v2)/users/(?<user>[^//]{1,})(?:/shared/(?<shared>[^//]{1,}))?/gateways/(?<gwid>[a-fA-F0-9]{1,16})/alerts/(?<type>[-a-zA-Z]{1,})',
);

export const REGEX_ALERT_USER = new RegExp(
  '(?:/|)sub/(?:v1|v2)/users/(?<user>[^//]{1,})/alerts/(?<type>[-a-zA-Z]{1,})',
);
