/** @format */

import React from 'react';
import { Link, Route, Router, Switch } from 'react-router-dom';
import { Card, Grid, Header, Image } from 'semantic-ui-react';
import { Footer } from '../../components';
import { UserForgotPassword } from '../../containers';
import history from '../../history';
import './Authentication.css';

export const Authentication = () => (
  <div className="home">
    <Grid padded textAlign="left" className="auth">
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={7} computer={7}>
          <Card fluid>
            <Card.Content as={Grid}>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Link to="/">
                    <Image.Group size="small">
                      <Image src={'/assets/images/logoColor.png'} />
                    </Image.Group>
                  </Link>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  <Header color="red" textAlign="right" className="brandPT">
                    NETWORK
                    <br />
                    SERVER
                    <br />
                    PATAVINA
                    <br />
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Card.Content>
            <Card.Content>
              <Router history={history}>
                <Switch>
                  <Route
                    exact
                    path="/user/remember"
                    component={UserForgotPassword}
                  />
                </Switch>
              </Router>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Footer />
  </div>
);
