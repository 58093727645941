/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import {
  deleteCurrentUserToken,
  getCurrentUserToken,
  userHasRole,
} from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_APPLICATION_REQUEST = 'application/FETCH_REQUEST';
export const FETCH_APPLICATION_SUCCESS = 'application/FETCH_SUCCESS';
export const FETCH_APPLICATION_FAILURE = 'application/FETCH_FAILURE';

export const POST_APPLICATION_REQUEST = 'application/POST_REQUEST';
export const POST_APPLICATION_SUCCESS = 'application/POST_SUCCESS';
export const POST_APPLICATION_FAILURE = 'application/POST_FAILURE';

export const PUT_APPLICATION_REQUEST = 'application/PUT_REQUEST';
export const PUT_APPLICATION_SUCCESS = 'application/PUT_SUCCESS';
export const PUT_APPLICATION_FAILURE = 'application/PUT_FAILURE';

export const DELETE_APPLICATION_REQUEST = 'application/DELETE_REQUEST';
export const DELETE_APPLICATION_SUCCESS = 'application/DELETE_SUCCESS';
export const DELETE_APPLICATION_FAILURE = 'application/DELETE_FAILURE';

export const DESTROY_APPLICATION_SUCCESS = 'application/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchApplication = application => ({
  [CALL_API]: {
    endpoint: url.Application(application),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_APPLICATION_REQUEST,
      {
        type: FETCH_APPLICATION_SUCCESS,
        payload: (action, state, res) => res.json().then(app => app),
      },
      {
        type: FETCH_APPLICATION_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const postApplication = application => ({
  [CALL_API]: {
    endpoint: url.ApplicationCreate(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(application),
    types: [
      POST_APPLICATION_REQUEST,
      {
        type: POST_APPLICATION_SUCCESS,
        payload: (action, state, res) => res.json().then(app => app),
      },
      {
        type: POST_APPLICATION_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const deleteApplication = application => ({
  [CALL_API]: {
    endpoint: url.Application(application.id),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_APPLICATION_REQUEST,
      {
        type: DELETE_APPLICATION_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/applications` });
          return res.json().then(json => json);
        },
      },
      {
        type: DELETE_APPLICATION_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const putApplication = application => ({
  [CALL_API]: {
    endpoint: url.ApplicationUpdate(application.id),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: () => {
      let tempApp = {};

      if (userHasRole('admin')) {
        tempApp = {
          label: application.label,
          UserId: application.UserId,
        };
      } else {
        tempApp = {
          label: application.label,
        };
      }
      return JSON.stringify(tempApp);
    },
    types: [
      PUT_APPLICATION_REQUEST,
      {
        type: PUT_APPLICATION_SUCCESS,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/application/${application.id}`);
          return res.json().then(json => json);
        },
      },
      {
        type: PUT_APPLICATION_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyApplication = () => ({
  type: DESTROY_APPLICATION_SUCCESS,
});
