/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_GATEWAY_PROFILE_REQUEST = 'gatewayProfile/FETCH_REQUEST';
export const FETCH_GATEWAY_PROFILE_SUCCESS = 'gatewayProfile/FETCH_SUCCESS';
export const FETCH_GATEWAY_PROFILE_FAILURE = 'gatewayProfile/FETCH_FAILURE';

export const POST_GATEWAY_PROFILE_REQUEST = 'gatewayProfile/POST_REQUEST';
export const POST_GATEWAY_PROFILE_SUCCESS = 'gatewayProfile/POST_SUCCESS';
export const POST_GATEWAY_PROFILE_FAILURE = 'gatewayProfile/POST_FAILURE';

export const DELETE_GATEWAY_PROFILE_REQUEST = 'gatewayProfile/DELETE_REQUEST';
export const DELETE_GATEWAY_PROFILE_SUCCESS = 'gatewayProfile/DELETE_SUCCESS';
export const DELETE_GATEWAY_PROFILE_FAILURE = 'gatewayProfile/DELETE_FAILURE';

export const DESTROY_GATEWAY_PROFILE_SUCCESS = 'gatewayProfile/DESTROY_SUCCESS';

// ACTION CREATORS
export function fetchGatewayProfile(gateway) {
  return {
    [CALL_API]: {
      endpoint: url.GatewayProfile(gateway),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_GATEWAY_PROFILE_REQUEST,
        {
          type: FETCH_GATEWAY_PROFILE_SUCCESS,
          payload: (action, state, res) => res.json().then(json => json),
        },
        {
          type: FETCH_GATEWAY_PROFILE_FAILURE,
          payload: (action, state, res) => {
            history.push(`${PAGES_PATH}/gateways`);
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export function postGatewayProfile(gatewayProfile) {
  return {
    [CALL_API]: {
      endpoint: url.GatewayProfileCreate(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      body: JSON.stringify(gatewayProfile),
      types: [
        POST_GATEWAY_PROFILE_REQUEST,
        {
          type: POST_GATEWAY_PROFILE_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(json => {
              history.push(`${PAGES_PATH}/gateway-profile/${json.id}`);
              return json;
            }),
        },
        {
          type: POST_GATEWAY_PROFILE_FAILURE,
          payload: (action, state, res) => {
            history.push(`${PAGES_PATH}/gateways`);
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export function putGatewayProfile(gatewayProfile) {
  return {
    [CALL_API]: {
      endpoint: url.GatewayProfile(gatewayProfile.id),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      body: JSON.stringify(gatewayProfile),
      types: [
        POST_GATEWAY_PROFILE_REQUEST,
        {
          type: POST_GATEWAY_PROFILE_SUCCESS,
          payload: (action, state, res) => {
            history.push(`${PAGES_PATH}/gateway-profile/${gatewayProfile.id}`);
            return res.json().then(json => json);
          },
        },
        {
          type: POST_GATEWAY_PROFILE_FAILURE,
          payload: (action, state, res) => {
            history.push(`${PAGES_PATH}/gateways`);
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export const deleteGatewayProfile = gatewayProfile => ({
  [CALL_API]: {
    endpoint: url.GatewayProfile(gatewayProfile.id),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_GATEWAY_PROFILE_REQUEST,
      {
        type: DELETE_GATEWAY_PROFILE_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/gateways` });
          return res.json().then(json => json);
        },
      },
      {
        type: DELETE_GATEWAY_PROFILE_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/gateways`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyGatewayProfile = () => ({
  type: DESTROY_GATEWAY_PROFILE_SUCCESS,
});
