/** @format */

import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { mapOptions } from './mapOptions';

import './MapContainer.css';

const {
  MarkerClusterer,
} = require('react-google-maps/lib/components/addons/MarkerClusterer');

const MapContainer = withScriptjs(
  withGoogleMap(props => {
    const { markers, bounds } = props;

    return (
      <GoogleMap
        ref={map => map && bounds && map.fitBounds(bounds)}
        defaultOptions={mapOptions}
        defaultZoom={14}
        defaultCenter={{ lat: markers[0].lat, lng: markers[0].lng }}
        onIdle={props.onMapIdle}
      >
        <MarkerClusterer
          zoomOnClick
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >
          {markers.map((marker, i) => (
            <Marker key={i} position={{ lat: marker.lat, lng: marker.lng }} />
          ))}
        </MarkerClusterer>
      </GoogleMap>
    );
  }),
);

export { MapContainer };
