/** @format */

import * as actions from '../actions/uplinks';

const defaultState = {
  messages: [],
  filter: {},
  sort: {},
  isLoading: false,
  error: false,
  isSocketConnectionOpen: false,
  filters: {
    owners: [],
    SFs: [],
    types: [],
    ports: [],
    appIds: [],
    devEuis: {
      selectedFilters: [],
      records: [],
    },
    payload: [],
    limit: 100,
  },
};

export const uplinks = (state = defaultState, action) => {
  const newState = state;

  switch (action.type) {
    case actions.FETCH_UPLINKS:
      return {
        ...state,
        messages: action.message,
      };
    case actions.UPDATE_UPLINKS:
      newState.messages.push(action.message);
      // newState.messages = newState.messages.slice(-actions.QUEUE_ROWS);
      newState.messages = newState.messages.slice(-newState.filters.limit);

      return {
        ...newState,
        isLoading: false,
        error: false,
      };
    case actions.OPEN_SOCKET_CONNECTION:
      return {
        ...state,
        isSocketConnectionOpen: true,
      };
    case actions.CLOSE_SOCKET_CONNECTION:
      return {
        ...state,
        isSocketConnectionOpen: false,
      };
    case actions.CLEAR_CONSOLE:
      return {
        ...state,
        messages: [],
      };
    case actions.SET_UPLINKS_DEV_EUI_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          devEuis: {
            ...state.filters.devEuis,
            selectedFilters: action.payload,
          },
        },
      };
    case actions.FECTH_DEVEUI_LIST_LOG_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          devEuis: {
            ...state.filters.devEuis,
            records: action.payload.rows,
          },
        },
      };
    case actions.SET_UPLINKS_OWNER_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          owners: action.payload,
        },
      };
    case actions.SET_UPLINKS_SF_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          SFs: action.payload,
        },
      };
    case actions.SET_UPLINKS_TYPE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          types: action.payload,
        },
      };
    case actions.SET_UPLINKS_PORT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ports: action.payload,
        },
      };
    case actions.SET_UPLINKS_APP_ID_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          appIds: action.payload,
        },
      };
    case actions.SET_UPLINKS_PAYLOAD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          payload: action.payload,
        },
      };
    case actions.SET_UPLINKS_ORDER_BY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload,
        },
      };
    case actions.SET_UPLINKS_START_DATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload,
        },
      };
    case actions.SET_UPLINKS_END_DATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          endDate: action.payload,
        },
      };
    case actions.SET_UPLINKS_LIMIT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          limit: action.payload,
        },
      };
    default:
      return state;
  }
};
