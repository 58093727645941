/** @format */

import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import interceptor from 'redux-api-middleware-interceptor';
import reducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const apiMiddlewareInterceptor = interceptor({ 
  headers: (origHeaders, state) => {
    const headers = Object.assign({
      'pt-ns': 'frontend'
    }, origHeaders);
    return headers;
  }
});

export const configureStore = () => {
  const enhancer = composeWithDevTools(
    applyMiddleware(apiMiddlewareInterceptor, apiMiddleware, ReduxThunk),
  );

  const store = createStore(reducer, enhancer);

  return store;
};
