/** @format */

import React from 'react';
import { Header } from 'semantic-ui-react';
import SVG from 'react-inlinesvg';

import './NotFound.css';

const NotFound = () => (
  <div className="container-404">
    <div className="robot">
      <SVG src="/assets/images/robotNotFound.svg" fluid />
    </div>
    <div className="robotNotFound">
      <Header as="h1" style={{ color: '#3e87a6' }} textAlign="center">
        <div style={{ fontSize: '120px' }}>404</div>
      </Header>
      <Header as="h1" style={{ color: '#3e87a6' }} textAlign="center">
        <div>Not Found</div>
      </Header>
    </div>
  </div>
);

export { NotFound };
