/** @format */

import React, { useEffect, useState } from 'react';
import { Button, Container, Input, Label, Table } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faSave,
  faTrashAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import MacCommandStatusIcon from '../MacCommandStatusIcon';
import './FrequencyChannel.css';
import PropTypes from 'prop-types';

const TableCellEdit = props => {
  const {
    min,
    max,
    value,
    name,
    handleChange,
    step = 1,
    detail = false,
  } = props;
  return (
    <>
      <Label size={'large'} content={value} color={'teal'} />

      <Container
        style={{
          textAlign: 'center',
          width: '150px',
          margin: '10px auto 0 auto',
        }}
      >
        {detail && (
          <Input
            style={{
              width: '100%',
            }}
            size="mini"
            name={name}
            step={step}
            type="number"
            onChange={(e, selection) => handleChange(selection)}
            value={value}
          />
        )}
        <Input
          fluid
          style={{
            width: '100%',
          }}
          min={min}
          max={max}
          step={step}
          name={name}
          onChange={(e, selection) => handleChange(selection)}
          type="range"
          value={value}
        />
      </Container>
    </>
  );
};

const FrequencyChannel = props => {
  const { row, idx, stopPolling, deleteRow, editRow, editOn } = props;
  const { index, state, frequency, minDR, maxDR } = row;

  const [editMode, setEditMode] = useState(false);
  const [tempFrequency, setTempFrequency] = useState(null);
  const [tempMinDR, setTempMinDR] = useState(null);
  const [tempMaxDR, setTempMaxDR] = useState(null);

  const startEditMode = () => {
    if (row) {
      setTempFrequency(row.frequency);
      setTempMinDR(row.minDR);
      setTempMaxDR(row.maxDR);
      setEditMode(true);
      stopPolling();
    }
  };

  useEffect(() => {
    if (editOn) {
      setEditMode(true);
      startEditMode();
    }
  }, []);

  const endEditMode = (save = false) => {
    if (save) {
      const tempRow = {
        chIndex: row.index,
        frequency: tempFrequency,
        minDR: tempMinDR,
        maxDR: tempMaxDR,
      };
      editRow(tempRow);
    }
    setTempFrequency(null);
    setTempMinDR(null);
    setTempMaxDR(null);
    setEditMode(false);
  };

  const TableCell = (staticView, editView = null) => (
    <Table.Cell>{editMode ? editView || staticView : staticView}</Table.Cell>
  );

  const TableCellActionsEdit = () => (
    <>
      <Button
        icon
        style={{ background: 'none' }}
        onClick={() => endEditMode(true)}
      >
        <FontAwesomeIcon
          icon={faSave}
          className="icon"
          color={'green'}
          style={{
            marginRight: '0.5rem',
            fontSize: ' 1.65rem',
            cursor: 'pointer',
          }}
        />
      </Button>
      <Button icon style={{ background: 'none' }} onClick={() => endEditMode()}>
        <FontAwesomeIcon
          icon={faTimes}
          className="icon"
          color={'#D46161'}
          style={{
            marginLeft: '0.5rem',
            fontSize: ' 1.4rem',
            cursor: 'pointer',
          }}
        />
      </Button>
    </>
  );

  const TableCellActions = () =>
    idx >= 3 ? (
      <>
        <Button
          icon
          style={{ background: 'none' }}
          onClick={() => startEditMode()}
        >
          <FontAwesomeIcon
            icon={faEdit}
            className="icon"
            color={'green'}
            style={{
              marginRight: '0.5rem',
              fontSize: ' 1.4rem',
              cursor: 'pointer',
            }}
          />
        </Button>
        <Button icon style={{ background: 'none' }} onClick={() => deleteRow()}>
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="icon"
            color={'#D46161'}
            style={{
              marginLeft: '0.5rem',
              fontSize: ' 1.4rem',
              cursor: 'pointer',
            }}
          />
        </Button>
      </>
    ) : null;

  const handleChange = ({ name, value }) => {
    switch (name) {
      case 'frequency':
        setTempFrequency(+value);
        break;
      case 'minDR':
        setTempMinDR(+value);
        break;
      case 'maxDR':
        setTempMaxDR(+value);
        break;
      default:
    }
  };

  return (
    <Table.Row
      className={['freq-channel', editMode ? 'editing' : ''].join(' ')}
    >
      {TableCell(<MacCommandStatusIcon state={state} />)}
      {TableCell(index)}
      {TableCell(
        frequency,
        <TableCellEdit
          value={tempFrequency}
          name={'frequency'}
          handleChange={e => handleChange(e)}
          step={0.1}
          min={100}
          max={1670}
          detail={true}
        />,
      )}
      {TableCell(
        minDR,
        <TableCellEdit
          value={tempMinDR}
          name={'minDR'}
          handleChange={e => handleChange(e)}
          min={0}
          max={7}
        />,
      )}
      {TableCell(
        maxDR,
        <TableCellEdit
          value={tempMaxDR}
          name={'maxDR'}
          handleChange={e => handleChange(e)}
          min={0}
          max={7}
        />,
      )}
      {TableCell(<TableCellActions />, <TableCellActionsEdit />)}
    </Table.Row>
  );
};

TableCellEdit.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  step: PropTypes.number,
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

FrequencyChannel.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  step: PropTypes.number,
  detail: PropTypes.object,
  row: PropTypes.object,
  idx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stopPolling: PropTypes.func,
  deleteRow: PropTypes.func,
  editRow: PropTypes.func,
  editOn: PropTypes.func,
};

export default FrequencyChannel;
