/** @format */

import React, { useState } from 'react';
import { Accordion, Checkbox, Form, Icon, Select } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';

const Search = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [datesRange, setDatesRange] = useState('');

  const handleChange = (e, { name, value }) => {
    setDatesRange(value);
  };

  return (
    <>
      {false && (
        <div style={{ marginTop: '.5em' }}>
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={() => setActiveIndex(activeIndex === 0 ? null : 0)}
              style={{ lineHeight: '0' }}
            >
              <Icon name="dropdown" />
              Search
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Form size="tiny">
                <Form.Group>
                  <Form.Field
                    label="Type"
                    control={Select}
                    options={[
                      { key: null, value: null, text: '' },
                      { key: 'create', value: 'create', text: 'create' },
                      { key: 'delete', value: 'delete', text: 'delete' },
                    ]}
                    clearable
                  />
                  <Form.Field
                    label="Status"
                    control={Select}
                    options={[
                      { key: null, value: null, text: '' },
                      { key: 'success', value: 'success', text: 'success' },
                      { key: 'pending', value: 'pending', text: 'pending' },
                      { key: 'error', value: 'error', text: 'error' },
                    ]}
                    clearable
                  />
                  <Form.Field
                    label="Date"
                    control={DatesRangeInput}
                    name="datesRange"
                    placeholder="From - To"
                    value={datesRange}
                    iconPosition="left"
                    onChange={handleChange}
                    clearable
                    width={3}
                  />
                </Form.Group>
                <Form.Group inline>
                  <Form.Field
                    label="has erros?"
                    control={Checkbox}
                    toggle
                    // inline
                  />
                </Form.Group>
              </Form>
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </>
  );
};

export { Search };
