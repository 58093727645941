/** @format */

import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, Form, Select } from 'semantic-ui-react';
import { formatOptions } from '../lib/utilities';
import devLog from '../lib/devLog';

const searchBox = props => {
  const { formGroup } = props;

  return (
    <Form size="tiny" onSubmit={props.startSearch}>
      <Form.Group style={{ flexWrap: 'wrap' }}>
        {formGroup.map(form => {
          let input = null;
          const emptyOption = (k0 = '') => ({
            key: `${k0}_`,
            value: '',
            text: '',
          });
          const selectedValue = form.selected || emptyOption().value;

          switch (form.type) {
            case 'email':
              input = (
                <Form.Input
                  style={{ marginBottom: '1em' }}
                  key={form.name}
                  width={form.width}
                  label={form.label}
                  name={form.name}
                  type={form.type}
                  onChange={props.updateSearch}
                  value={selectedValue}
                />
              );
              break;
            case 'select':
              switch (form.label) {
                case 'Application':
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      search 
                      selection
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Dropdown}
                      options={[
                        { ...emptyOption(form.label) },
                        ...formatOptions(props.applicationsOptions, form.label),
                      ]}
                      onChange={props.updateSearch}
                      value={selectedValue}
                    />
                  );
                  break;
                case 'Application_Uid':
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      search 
                      selection
                      key={form.name}
                      width={form.width}
                      label={'Application'}
                      name={form.name}
                      control={Dropdown}
                      options={[
                        { ...emptyOption(form.label) },
                        ...formatOptions(props.applicationsOptions, form.label),
                      ]}
                      onChange={props.updateSearch}
                      value={selectedValue}
                    />
                  );
                  break;
                case 'Visibility':
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Select}
                      options={[
                        { ...emptyOption(form.label) },
                        ...formatOptions(props.visibilityProps, form.label),
                      ]}
                      onChange={props.updateSearch}
                      value={selectedValue}
                    />
                  );
                  break;
                case 'Transmission Power':
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Select}
                      options={[
                        { ...emptyOption(form.label) },
                        ...formatOptions(props.maxTxPower, form.label),
                      ]}
                      onChange={props.updateSearch}
                      value={selectedValue}
                    />
                  );
                  break;
                case 'Supports Class B':
                case 'Supports LBT':
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Select}
                      options={[
                        { ...emptyOption(form.label) },
                        ...formatOptions(props.supportsBool, form.label),
                      ]}
                      onChange={props.updateSearch}
                      value={selectedValue}
                    />
                  );
                  break;
                case 'Band':
                  // eslint-disable-next-line no-console
                  devLog(form.label);
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Select}
                      options={[
                        { ...emptyOption(form.label) },
                        ...formatOptions(props.bandValues, form.label),
                      ]}
                      onChange={props.updateSearch}
                      value={selectedValue}
                    />
                  );
                  break;
                case 'Profile ID':
                  input = form.options ? (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Select}
                      options={[
                        { ...emptyOption(form.label) },
                        ...form.options,
                      ]}
                      onChange={props.updateSearch}
                      value={selectedValue}
                    />
                  ) : null;
                  break;
                case 'Type':
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Select}
                      options={[
                        { key: '', value: '', text: '' },
                        { key: 1, value: 'otaa', text: 'OTAA' },
                        { key: 2, value: 'abp', text: 'ABP' },
                      ]}
                      onChange={props.updateSearch}
                    />
                  );
                  break;
                case 'Status':
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Select}
                      options={[
                        { key: '', value: '', text: '' },
                        { key: 1, value: 'true', text: 'UP' },
                        { key: 2, value: 'false', text: 'DOWN' },
                      ]}
                      onChange={props.updateSearch}
                    />
                  );
                  break;
                default:
                  input = (
                    <Form.Field
                      style={{ marginBottom: '1em' }}
                      search 
                      selection
                      key={form.name}
                      width={form.width}
                      label={form.label}
                      name={form.name}
                      control={Dropdown}
                      options={[
                        { ...emptyOption(form.label) },
                        ...formatOptions(props.usersOptions, form.label),
                      ]}
                      onChange={props.updateSearch}
                      value={selectedValue}
                    />
                  );
                  break;
              }
              break;
            default:
              input = (
                <Form.Input
                  style={{ marginBottom: '1em' }}
                  key={form.name}
                  width={form.width}
                  label={form.label}
                  name={form.name}
                  type={form.type}
                  onChange={props.updateSearch}
                  value={selectedValue}
                />
              );
              break;
          }

          return input;
        })}
      </Form.Group>
      <Form.Group>
        <Form.Button width={4} content="Search" type="submit" />
      </Form.Group>
    </Form>
  );
};

searchBox.propTypes = {
  formGroup: PropTypes.array,
  startSearch: PropTypes.func,
  updateSearch: PropTypes.func,
};

export default searchBox;
