/** @format */

import { Container, Label, Pagination, Table } from 'semantic-ui-react';
import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import idx from 'idx';
import { withRouter } from 'react-router';
import { cleanObject, EMPTY_TABLE_MESSAGE } from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';
import {
  destroyGatewayProfileGateways,
  fetchGatewayProfileGateways,
} from '../../actions/gatewayProfileGateways';

const mapStateToProps = state => ({
  gatewayProfile: state.gatewayProfile,
  gatewayProfileGateways: state.gatewayProfileGateways,
});

const mapDispatchToProps = dispatch => ({
  fetchGatewayProfileGateways: gatewayProfile => {
    dispatch(fetchGatewayProfileGateways(gatewayProfile));
  },
  destroyGatewayProfileGateways: () => {
    dispatch(destroyGatewayProfileGateways());
  },
});

class GPTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.handleChange = this.handleChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchGatewayProfileGateways(this.props.match.params.gpid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (
      this.props.gatewayProfileGateways !== nextProps.gatewayProfileGateways
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.props.destroyGatewayProfileGateways();
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handlePageChange(e, { activePage }) {
    const { gatewayProfileGateways } = this.props;

    const params = cleanObject({ ...gatewayProfileGateways }, [
      'filter',
      'sort',
      'offset',
      'limit',
    ]);
    params.offset = (activePage - 1) * gatewayProfileGateways.limit;
    this.props.fetchGatewayProfileGateways(params);
  }

  render() {
    const { gatewayProfileGateways } = this.props;

    return (
      <Fragment>
        {gatewayProfileGateways.rows &&
        gatewayProfileGateways.rows.length > 0 ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell key="headerLabel">Label</Table.HeaderCell>
                <Table.HeaderCell key="headerGatewayProfile">
                  GatewayProfile
                </Table.HeaderCell>
                <Table.HeaderCell key="headerDevEui">
                  Gateway ID
                </Table.HeaderCell>
                <Table.HeaderCell key="headerDevAddr">
                  Visibility
                </Table.HeaderCell>
                <Table.HeaderCell key="headerType">Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {idx(gatewayProfileGateways, accessory =>
                accessory.rows.map(row => (
                  <Table.Row key={`row-${row.id}`}>
                    <Table.Cell key={`row-${row.id}-label`}>
                      <Link to={`${PAGES_PATH}/device/${idx(row, _ => _.id)}`}>
                        <strong>{idx(row, _ => _.label) || 'no name'}</strong>
                      </Link>
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-profile`}>
                      {idx(row, _ => _.profile.label)}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-gatewayId`}>
                      {idx(row, _ => _.gatewayId)}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-visibility`}>
                      {idx(row, _ => _.public) ? (
                        <Label size="mini" color="green">
                          Public
                        </Label>
                      ) : (
                        <Label size="mini" color="blue">
                          Private
                        </Label>
                      )}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-type`}>
                      {idx(row, _ => _.status.state.lastUpdateTimeS) * 1000 +
                        120000 >
                      Date.now() ? (
                        <Label size="mini" color="green">
                          UP
                        </Label>
                      ) : (
                        <Label size="mini" color="red">
                          DOWN
                        </Label>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )),
              )}
            </Table.Body>
          </Table>
        ) : (
          EMPTY_TABLE_MESSAGE
        )}

        {'offset' in gatewayProfileGateways &&
          'count' in gatewayProfileGateways &&
          'limit' in gatewayProfileGateways && (
            <Container textAlign="center">
              <Pagination
                activePage={
                  parseInt(
                    gatewayProfileGateways.offset /
                      gatewayProfileGateways.limit,
                    10,
                  ) + 1
                }
                totalPages={parseInt(
                  Math.ceil(
                    gatewayProfileGateways.count / gatewayProfileGateways.limit,
                  ),
                  10,
                )}
                onPageChange={this.handlePageChange}
              />
            </Container>
          )}
      </Fragment>
    );
  }
}

GPTable.propTypes = {
  match: PropTypes.object,
  gatewayProfileGateways: PropTypes.object,
  fetchGatewayProfileGateways: PropTypes.func,
  destroyGatewayProfileGateways: PropTypes.func,
};

const GatewayProfileGatewaysTable = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GPTable),
);

export { GatewayProfileGatewaysTable };
