import React from 'react';
import { Container, Divider } from 'semantic-ui-react';
import { toastSuccess, toastError } from '../../lib/utilities';

import { ImportGateways, ImportGatewaysTemplateDownload } from '../'



const ImportGatewaysTab = () => {
  return <>
    <Container>
      <ImportGateways
        onUploadFinished={(response) => {
          if (response instanceof Error) {
            const error = response;
            if (error.response) {
              toastError(error.response.data.message);
            }
          } else {
            const { new: newest = 0, move = 0, update = 0 } = response.data || {};
            if (newest > 0) toastSuccess(`${newest} gateways have been imported`);
            if ((update > 0) || move > 0) toastSuccess(`${update + move} gateways have been updated`);
            if (newest === 0 && update === 0 && move === 0) toastSuccess('nothing to import or update', 'Attention')
          }
        }}
      />
      <Divider hidden />
      <ImportGatewaysTemplateDownload />
    </Container>
  </>
}

export { ImportGatewaysTab };