/** @format */

import React from 'react';
import { useParams } from 'react-router';

import { DeviceImportSingle } from '../../containers/DeviceManager/DeviceImport/DeviceImportSigle';

const DeviceImport = () => {
  // get params from URL
  const { id: importId } = useParams();

  return (
    <>
      <DeviceImportSingle importId={importId} />
    </>
  );
};

export { DeviceImport };
