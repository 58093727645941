/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_BASIC_STATION_CONFIGURATION_REQUEST = 'basicstationConfiguration/FETCH_REQUEST';
export const FETCH_BASIC_STATION_CONFIGURATION_SUCCESS = 'basicstationConfiguration/FETCH_SUCCESS';
export const FETCH_BASIC_STATION_CONFIGURATION_FAILURE = 'basicstationConfiguration/FETCH_FAILURE';


// ACTION CREATORS
export function fetchBasicstationConfigurations() {
  return {
    [CALL_API]: {
      endpoint: url.BasicstationConfigurationList(),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_BASIC_STATION_CONFIGURATION_REQUEST,
        {
          type: FETCH_BASIC_STATION_CONFIGURATION_SUCCESS,
          payload: (action, state, res) => res.json().then(json => json),
        },
        {
          type: FETCH_BASIC_STATION_CONFIGURATION_FAILURE,
          payload: (action, state, res) => {
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}