/** @format */

import Axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Form, Grid, Header, Message } from 'semantic-ui-react';
import { putUpdatePassword } from '../../actions/updatePassword';
import { getCurrentUserToken, userHasRole } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import { validatePassword } from '../../lib/utilities';
import devLog from '../../lib/devLog';
import PropTypes from 'prop-types';

const mapDispatchToProps = dispatch => ({
  putUpdatePassword: (password, uid) => {
    dispatch(putUpdatePassword(password, uid));
  },
});

class ChangePasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      confirmPassword: '',
      isPasswordInvalid: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e, { name, value }) {
    this.setState({ ...this.state, [name]: value });
  }

  handleBlur(e) {
    if (e.target.value !== '') {
      if (validatePassword(e.target.value))
        this.setState({ isPasswordInvalid: false });
      else this.setState({ isPasswordInvalid: true });
    } else this.setState({ isPasswordInvalid: false });
  }

  handleSubmit(e) {
    const { newPassword } = this.state;
    e.preventDefault();
    userHasRole('admin')
      ? this.props.putUpdatePassword(newPassword, this.props.location.state.id)
      : Axios.get(url.UserEmail(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCurrentUserToken(),
          },
        })
          .then(response =>
            this.props.putUpdatePassword(newPassword, response.data.id),
          )
          .catch(error => devLog(error));
  }

  render() {
    const { newPassword, confirmPassword, isPasswordInvalid } = this.state;

    return (
      <>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Change Password
        </Header>
        <Divider clearing />
        <Form onSubmit={this.handleSubmit}>
          <Grid>
            <Grid.Column mobile={16} tablet={7} computer={7}>
              <Form.Input
                width={8}
                label="New Password"
                name="newPassword"
                required
                type="password"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <Form.Input
                label="Confirm Password"
                name="confirmPassword"
                required
                type="password"
                width={8}
                onChange={this.handleChange}
              />
              <Form.Button
                content="Change Password"
                label="&nbsp;"
                onClick={this.handleProfileSidebar}
                disabled={
                  newPassword === '' ||
                  (confirmPassword === '' && isPasswordInvalid)
                }
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={7} computer={7}>
              {confirmPassword.length > 0 && newPassword !== confirmPassword && (
                <Message negative>
                  <Message.Header>Invalid Password</Message.Header>
                  <p>Passwords must be egual.</p>
                </Message>
              )}
              {isPasswordInvalid && (
                <Message negative>
                  <Message.Header>Invalid Password</Message.Header>
                  <p>Passwords must be at least 8 character long.</p>
                </Message>
              )}
            </Grid.Column>
          </Grid>
        </Form>
      </>
    );
  }
}

ChangePasswordComponent.propTypes = {
  putUpdatePassword: PropTypes.func,
  location: PropTypes.object,
};

const ChangePassword = connect(
  null,
  mapDispatchToProps,
)(ChangePasswordComponent);

export { ChangePassword };
