/** @format */

import { isString } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';
import { destroyUserProfile, postUserProfile } from '../../actions/userProfile';

const ROLES = [
  { key: 'standard', value: 'standard', text: 'Standard' },
  { key: 'system', value: 'system', text: 'System' },
  { key: 'admin', value: 'admin', text: 'Administrator' },
];

const mapStateToProps = state => ({
  userProfile: state.userProfile,
});

const mapDispatchToProps = dispatch => ({
  postUserProfile: userprofile => {
    dispatch(postUserProfile(userprofile));
  },
  destroyUserProfile: () => {
    dispatch(destroyUserProfile());
  },
});

class UserProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {
        maxdevices: 0,
        maxgateways: 0
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.props.destroyUserProfile();
  }

  handleChange(e, { name, value }) {
    if (name === 'label' || name === 'role') {
      this.setState({
        userProfile: { ...this.state.userProfile, [name]: value },
      });
    } else {
      this.setState({
        userProfile: { ...this.state.userProfile, [name]: parseInt(value, 10) },
      });
    }
  }

  handleSubmit(e) {
    const { userProfile } = this.state;

    const profile = userProfile;
    Object.keys(profile).forEach(key => {
      if (Number.isNaN(profile[key]) && !isString(profile[key])) {
        delete profile[key];
      }
    });
    e.preventDefault();
    this.props.postUserProfile(profile);
    this.props.handleUserProfileSidebar(e);
    this.props.parentHandleNewUserProfile('label', profile.label);
  }

  render() {
    const { userProfile } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} inverted={this.props.inverted}>
        {this.props.userProfile.error === true && (
          <div style={{ position: 'absolute', right: '-95vh' }}>
            <Message negative>
              <Message.Header>OPS...</Message.Header>
              <p>{this.props.userProfile.message}</p>
              <p>Please check your information or contact-us.</p>
            </Message>
          </div>
        )}
        <Form.Input
          label="Label"
          name="label"
          placeholder="Label"
          required
          onChange={this.handleChange}
        />

        <Form.Dropdown
          label="Role"
          name="role"
          placeholder="choose one..."
          selection
          required
          options={ROLES}
          onChange={this.handleChange}
        />

        <Form.Input
          label="Number of Devices"
          name="maxdevices"
          required
          min={0}
          type="number"
          value={userProfile.maxdevices || 0}
          onChange={this.handleChange}
        />

        <Form.Input
          label="Number of Gateways"
          name="maxgateways"
          required
          min={0}
          type="number"
          value={userProfile.maxgateways || 0}
          onChange={this.handleChange}
        />

        <Form.Input
          label="Number of Groups"
          name="maxgroups"
          value={userProfile.maxgroups || 0}
          onChange={this.handleChange}
        />

        <Form.Input
          label="Max Uplink (milliseconds)"
          name="maxuplink"
          value={userProfile.maxuplink || 0}
          onChange={this.handleChange}
        />

        <Form.Input
          label="Max Downlink (milliseconds)"
          name="maxdownlink"
          value={'maxdownlink' in userProfile ? userProfile.maxdownlink || 0 : 144000}
          onChange={this.handleChange}
        />

        <Form.Input
          label="Cache Period (seconds)"
          name="cacheperiod"
          value={userProfile.cacheperiod || 0}
          onChange={this.handleChange}
        />

        <Form.Input
          width={8}
          label="Profile Life (days)"
          name="life"
          value={userProfile.life || 0}
          onChange={this.handleChange}
        />

        <Form.Button
          content="Save"
          type="submit"
          style={{ marginTop: '2em' }}
        />
      </Form>
    );
  }
}

UserProfileForm.propTypes = {
  destroyUserProfile: PropTypes.func,
  postUserProfile: PropTypes.func,
  inverted: PropTypes.bool,
  handleUserProfileSidebar: PropTypes.func,
  parentHandleNewUserProfile: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  userProfile: PropTypes.object,
};

const UserProfileCreate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfileForm);

export { UserProfileCreate };
