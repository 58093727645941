/* eslint-disable react/display-name */
/** @format */

import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Header, Label, Menu, Tab } from 'semantic-ui-react';
import {
  ApplicationsTable,
  ApplicationsTableSharedBy,
  ApplicationsTableSharedWith,
} from '../../containers';
import { fetchApplications, fetchApplicationsSharedBy, fetchapplicationsSharedWith } from '../../actions/applications';
import { updateTab } from '../../actions/tabs';

import './Applications.css';

const Applications = (props) => {
  const applications = useSelector(state => state.applications);
  const applicationsSharedBy = useSelector(state => state.applicationsSharedBy);
  const applicationsSharedWith = useSelector(state => state.applicationsSharedWith);
  const index = useSelector(state => state.tabs.applications.index);
  const dispatch = useDispatch();

  useEffect(() => {
    if (applications && !('size' in applications)) dispatch(fetchApplications());
    if (applicationsSharedBy && !('size' in applicationsSharedBy)) dispatch(fetchApplicationsSharedBy());
    if (applicationsSharedWith && !('size' in applicationsSharedWith)) dispatch(fetchapplicationsSharedWith());
  }, []);

  const handleTabChange = (e, { activeIndex }) => {
    e.preventDefault();
    dispatch(updateTab({applications: { index: activeIndex || 0 }}));
  }

  const panes = [
    {
      menuItem:
        <Menu.Item key="myApplications">
          applications list
          {applications && ('size' in applications) && <Label color={index == 0 ? 'teal' : 'grey'}>{ applications.size }</Label>}
        </Menu.Item>,
      render: () => (
        <Tab.Pane>
          <ApplicationsTable />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="applicationsSharedWith">
          shared with
          {applicationsSharedWith && ('size' in applicationsSharedWith) && <Label color={index == 1 ? 'teal' : 'grey'}>{ applicationsSharedWith.size }</Label>}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <ApplicationsTableSharedWith />
        </Tab.Pane>
      ),
    },
    {
      menuItem:
        <Menu.Item key="applicationsSharedBy">
          shared by
          {applicationsSharedBy && ('size' in applicationsSharedBy) && <Label color={index == 2 ? 'teal' : 'grey'}>{ applicationsSharedBy.size }</Label>}
        </Menu.Item>,
      render: () => (
        <Tab.Pane>
          <ApplicationsTableSharedBy />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Fragment>
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        Applications
      </Header>
      <Divider clearing />
      <Tab
        menu={{ attached: true, tabular: true, className: "wrapped" }}
        panes={panes}
        activeIndex={index}
        onTabChange={handleTabChange}
      />
    </Fragment>
  );
}

export { Applications };
