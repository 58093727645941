/** @format */

import * as actions from '../actions/userProfile';

const defaultState = {
  isLoading: false,
  error: false,
};

export const userProfile = (state = defaultState, action) => {
  switch (action.type) {
    case actions.POST_USER_PROFILE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.POST_USER_PROFILE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.POST_USER_PROFILE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.PUT_USER_PROFILE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.PUT_USER_PROFILE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.PUT_USER_PROFILE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DELETE_USER_PROFILE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.DELETE_USER_PROFILE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.DELETE_USER_PROFILE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_USER_PROFILE_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
