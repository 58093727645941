/** @format */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Input, Label, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import { deleteAccessToken } from '../../actions/accesstoken';

const AccessTokenModalDelete = ({
  element,
  onClose,
  onSuccess = () => {},
  onFail = () => {},
}) => {
  const accessToken = useSelector(state => state.accesstoken);
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState('');

  const dispatch = useDispatch();

  const handleDelete = id => {
    dispatch(deleteAccessToken(id));
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (element && 'id' in element) setOpen(true);
    else setOpen(false);
  }, [element]);

  useEffect(() => {
    if (open) {
      onSuccess();
      setOpen(false);
    }
  }, [accessToken]);

  return (
    <>
      {element && 'id' in element && (
        <Modal open={open} onClose={onClose} basic size="small">
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>This action will delete this access token.</h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the LABEL</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {element.label || '...'}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={(e, { value }) => setCheck(value)}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => handleDelete(element.id)}
              color="red"
              disabled={'label' in element && element.label !== check}
            >
              Proceed
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

AccessTokenModalDelete.propTypes = {
  element: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
};

export { AccessTokenModalDelete };
