/** @format */

export const consoleTableOptionsType = [
  { text: '---', disabled: true },
  { key: 'uplink', text: 'Uplink', value: 'uplink' },
  { key: 'downlink', text: 'Downlink', value: 'downlink' },
  { key: 'join', text: 'Join', value: 'join' },
  { key: 'unjoin', text: 'Unjoin', value: 'unjoin' },
  { key: 'link-status', text: 'Link-Status', value: 'link-status' },
  { key: 'duty-cycle', text: 'Duty-Cycle', value: 'duty-cycle' },
  { text: '---', disabled: true },
  {
    key: 'exceeded-airtime',
    text: 'Exceeded-Airtime',
    value: 'exceeded-airtime',
  },
  { key: 'tx-delayed', text: 'Tx-Delayed', value: 'tx-delayed' },
  { key: 'tx-failed', text: 'Tx-Failed', value: 'tx-failed' },
  { key: 'dup-uplink', text: 'Duplicate-Uplink', value: 'dup-uplink' },
  { key: 'duty-cycle', text: 'Duty-Cycle', value: 'duty-cycle' },
  { text: '---', disabled: true },
  { key: 'rx-param', text: 'Rx-Param', value: 'rx-param' },
  { key: 'rx-timing', text: 'Rx-Timing', value: 'rx-timing' },
  { key: 'ping-slot-ch', text: 'Ping-Slot-Channel', value: 'ping-slot-ch' },
];

export const consoleTableOptionsSF = [
  { key: '7', text: '7', value: 7 },
  { key: '8', text: '8', value: 8 },
  { key: '9', text: '9', value: 9 },
  { key: '10', text: '10', value: 10 },
  { key: '11', text: '11', value: 11 },
  { key: '12', text: '12', value: 12 },
];

export const consoleTableOptionsLimit = [
  { key: 'No limit', text: 'No limit', value: 0 },
  { key: '25', text: '25', value: 25 },
  { key: '50', text: '50', value: 50 },
  { key: '100', text: '100', value: 100 },
];
