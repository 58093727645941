import { faker } from '@faker-js/faker';

const MAX_GATEWAYS = 10;

const generateCSVrow = (header, type, options = {}) => {
  const { users = [] } = options;
  const body = [];
  

  if (type === 'otaa' || type === 'abp') {
    const hex = faker.datatype.hexadecimal(8).substring(2).toLowerCase();
    for (const item of header) {
      switch (item) {
        case 'serial':
        case 'Serial':
        case 'matricola':
          body.push(faker.datatype.number());
          break;
        case 'deveui':
        case 'Dev EUI':
        case 'devEUI':
          body.push(`00000000${hex}`);
          break;
        case 'devaddr':
        case 'Lora device address':
        case 'devAddr':
          if (type === 'abp') body.push(hex);
          else body.push('');
          break;
        case 'label':
        case 'Label':
        case 'labelID':
          body.push(`device 00000000${hex}`);
          break;
        case 'firmware':
        case 'FW':
          body.push(faker.datatype.number());
          break;
        case 'model':
        case 'Model':
          body.push(faker.datatype.number());
          break;
        case 'nwkSKey':
        case 'Lora network key':
        case 'nwk_s_key':
          if (type === 'abp') body.push(`000000000000000000000000${hex}`);
          else body.push('');
          break;
        case 'appSKey':
        case 'Lora app s key':
        case 'app_s_key':
          if (type === 'abp') body.push(`000000000000000000000000${hex}`);
          else body.push('');
          break;
        case 'appKey':
        case 'Lora app key':
        case 'app_key':
          if (type === 'otaa') body.push(`000000000000000000000000${hex}`);
          else body.push('');
          break;
        case 'joineui':
        case 'appeui':
        case 'Lora app eui':
        case 'app_eui':
          if (type === 'otaa') body.push(`00000000${hex}`);
          else body.push('');
          break;
        case 'type':
        case 'Devtype':
        case 'devType':
          body.push(type === 'otaa' ? 128 : 0);
          break;
        case 'DeviceProfileId':
        case 'devProfile':
        case 'Dev profile':
          body.push(2);
          break;
        case 'lat':
          body.push(faker.address.latitude())
          break;
        case 'lng':
          body.push(faker.address.longitude())
          break;
        case 'alt':
          body.push(faker.datatype.number({min: 0, max: 200}))
          break;
        default:
          break;
      }
    }
  } else if (type === 'gateways') {
    const gatewayId = faker.datatype.hexadecimal(16).substring(2).toLowerCase();
    const supportsRemoteControl = faker.datatype.boolean();

    for (const item of header) {
      switch (item) {
        case 'id':
          body.push(gatewayId);
          break;
        case 'label':
          body.push(`gateway ${gatewayId}`);
          break;
        case 'UserId':
          body.push(faker.helpers.arrayElement(users));
          break;
        case 'public':
          body.push(faker.datatype.boolean());
          break;
        case 'lat':
          body.push(faker.address.latitude())
          break;
        case 'lng':
          body.push(faker.address.longitude())
          break;
        case 'alt':
          body.push(faker.datatype.number({min: 0, max: 200}))
          break;
        case 'model':
          body.push(faker.datatype.number());
          break;
        case 'serial':
          body.push(faker.datatype.number());
          break;
        case 'firmware':
          body.push(faker.datatype.number());
          break;
        case 'subnet':
          body.push('');
          break;
        case 'GatewayProfileId':
          body.push(2);
          break;
        case 'supportsRemoteControl':
          body.push(supportsRemoteControl);
          break;
        case 'rctrlUsername':
          if (supportsRemoteControl) body.push(faker.internet.userName());
          else body.push('');
          break;
        case 'rctrlPassword':
          if (supportsRemoteControl) body.push(faker.internet.password());
          else body.push('');
          break;
        default:
          break;
      }
    }
  }
  return body;
};

const generateCSV = (type, header, options = {}) => {
  const { otaa = 5, abp = 5, users = [] } = options;
  const csv = [];

  if (type === 'devices') {
    for (let i = 0; i < otaa; i += 1) {
      csv.push(generateCSVrow(header, 'otaa'));
    }
    for (let i = 0; i < abp; i += 1) {
      csv.push(generateCSVrow(header, 'abp'));
    }
  } else if (type === 'gateways') {
    for (let i = 0; i < MAX_GATEWAYS; i += 1) {
      csv.push(generateCSVrow(header, 'gateways', { users }));
    }
  }

  return csv;
};

export { generateCSVrow, generateCSV }