/**
 * /* eslint-disable no-multi-spaces
 *
 * @format
 */

/* eslint-disable key-spacing */
import { API_URL, GWSTAT_URL, METRIC_URL } from './variables';
// import { getCurrentUserId } from '../lib/auth'

export const Endpoints = {
  AccessTokens: () => `${API_URL}/user/access-tokens`,
  AccessTokenRemove: accessToken =>
    `${API_URL}/user/access-token/${accessToken}`,
  AccessTokenCreate: () => `${API_URL}/user/access-token`,
  Application: application => `${API_URL}/application/${application}`,
  ApplicationCreate: () => `${API_URL}/application`,
  ApplicationDevices: application =>
    `${API_URL}/application/${application}/devices`,
  ApplicationDevicesNew: () => `${API_URL}/devices`,
  BasicstationConfigurationList: () =>
    `${API_URL}/basic-station-configurations?fields[]=id`,
  LogDevEuiList: deveui => {
    if (!deveui) return `${API_URL}/devices?limit=20`;
    return `${API_URL}/devices?deveui=${deveui}&limit=20`;
  },
  LogAppIdList: appId => {
    if (!appId) return `${API_URL}/applications?limit=20`;
    return `${API_URL}/applications?appid=${appId}&limit=20`;
  },
  ApplicationDevicesListAdm: (appid, UserId) =>
    `${API_URL}/devices?appid=${appid}&uid=${UserId}`,
  ApplicationDevicesListStd: appid => `${API_URL}/devices?appid=${appid}`,
  ApplicationDevicesMap: application =>
    `${API_URL}/application/${application}/devices/map`,
  ApplicationDeviceCreate: application =>
    `${API_URL}/application/${application}/device`,
  ApplicationDeviceDelete: (application, device) =>
    `${API_URL}/application/${application}/device/${device}`,
  Applications: () => `${API_URL}/applications`,
  ApplicationShareCreate: () => `${API_URL}/application-share`,
  ApplicationShareDelete: application =>
    `${API_URL}/application-share/${application}`,
  ApplicationsSharedBy: () => `${API_URL}/applications/shared/by`,
  ApplicationsSharedWith: () => `${API_URL}/applications/shared/with`,
  ApplicationIdAdmin: (appid, UserId) =>
    `${API_URL}/applications?appid=${appid}&uid=${UserId}`,
  ApplicationIdUser: appid => `${API_URL}/applications?appid=${appid}`,
  ApplicationsOptions: () => `${API_URL}/applications/`,
  ApplicationUpdate: application => `${API_URL}/application/${application}`,
  Device: device => `${API_URL}/device/${device}`,
  DeviceSharedForApplicationAdm: (appid, UserId) =>
    `${API_URL}/devices?appid=${appid}&uid=${UserId}&limit=1`,
  DeviceSharedForApplicationStd: appid =>
    `${API_URL}/devices?appid=${appid}&limit=1`,
  DeviceCreate: () => `${API_URL}/device`,
  DeviceProfiles: () => `${API_URL}/device-profiles`,
  DeviceProfilesOptions: () => `${API_URL}/device-profile/properties`,
  DeviceProfilesOptionsById: deviceProfileOptionsId =>
    `${API_URL}/device-profile/${deviceProfileOptionsId}`,
  DeviceProfileID: (
    macVersion,
    supports32bitFCnt,
    supportsClassB,
    supportsClassC,
  ) =>
    `${API_URL}/device-profiles?macVersion=${macVersion}&supports32bitFCnt=${supports32bitFCnt}&supportsClassB=${supportsClassB}&supportsClassC=${supportsClassC}`,
  Devices: () => `${API_URL}/devices`,
  DeviceStatus: did => `${API_URL}/device/${did}/params/dev-status`,
  DeviceDutyCycle: did => `${API_URL}/device/${did}/params/duty-cycle`,
  DeviceFrequencyCh: did => `${API_URL}/device/${did}/params/channels`,
  DeviceFrequencyChDelete: (did, chIndex) =>
    `${API_URL}/device/${did}/params/channels/${chIndex}`,
  DevicePingSlot: did => `${API_URL}/device/${did}/params/ping-slot-channel`,
  DeviceRxParam: did => `${API_URL}/device/${did}/params/rx-param`,
  DeviceRxDelay: did => `${API_URL}/device/${did}/params/rx-timing`,
  DeviceShareCreate: () => `${API_URL}/device-share`,
  DeviceShareDelete: (device, guest) => `${API_URL}/device-share/${device}`,
  DevicesSharedWith: () => `${API_URL}/devices/shared/with`,
  DevicesSharedBy: () => `${API_URL}/devices/shared/by`,
  // ----------------- Import Endpoints -----------------
  DevicesImport: application =>
    `${API_URL}/application/${application}/devices/import`,
  DeviceManagerUpload: () => `${API_URL}/manager/device/upload`, // POST
  DeviceManagerImportList: () => `${API_URL}/manager/device/imports`, // GET
  DeviceManagerImportStatus: id =>
    `${API_URL}/manager/device/import/${id}/status`, // GET
  DeviceManagerImportDetail: id =>
    `${API_URL}/manager/device/import/${id}/detail`, // GET
  // ----------------- Device Position Endpoints -----------------
  DevicePosition: deveui => `${API_URL}/device/${deveui}/location`,
  // ----------------- Downlink Endpoints -----------------
  DownlinkCreate: (application, device) =>
    `${API_URL}/device/${device}/downlink-queue`,
  DownlinkDelete: (did, downlinkId) =>
    `${API_URL}/device/${did}/downlink-queue/${downlinkId}`,
  Downlinks: (application, device) =>
    `${API_URL}/device/${device}/downlink-queue`,
  // ----------------- Gateway Endpoints -----------------
  Gateways: () => `${API_URL}/gateways`,
  Gateway: gateway => `${API_URL}/gateway/${gateway}`,
  GatewayCreate: () => `${API_URL}/gateway`,
  GatewayUpdate: (user, gateway) => `${API_URL}/gateway/${gateway}`,
  // ----------------- Gateway Profile Endpoints -----------------
  GatewayProfile: gatewayProfile =>
    `${API_URL}/gateway-profile/${gatewayProfile}`,
  GatewayProfileGateways: gatewayProfile =>
    `${API_URL}/gateway-profile/${gatewayProfile}/gateways`,
  GatewayProfileGatewaysMap: gatewayProfile =>
    `${API_URL}/gateway-profile/${gatewayProfile}/gateways/map`,
  GatewayProfileCreate: () => `${API_URL}/gateway-profile`,
  GatewayProfileProperties: () => `${API_URL}/gateway-profile/properties`,
  GatewayProfiles: () => `${API_URL}/gateway-profiles`,
  GatewayProfilesParams: params => `${API_URL}/gateway-profiles/${params}`,
  GatewayProfilesOptions: () => `${API_URL}/gateway-profiles`,
  // ----------------- Gateway Share Endpoints -----------------
  GatewaysSharedBy: () => `${API_URL}/gateways/shared/by`,
  GatewaysSharedWith: () => `${API_URL}/gateways/shared/with`,
  GatewayShareCreate: () => `${API_URL}/gateway-share`,
  GatewayShareDelete: (gateway, guest) => `${API_URL}/gateway-share/${gateway}`,
  GatewaysStatus: () => `${API_URL}/gateways/status`,
  GatewaysShared: () => `${API_URL}/gateways/shared`,
  // ----------------- Gateway Import Endpoints -----------------
  GatewaysImport: () => `${API_URL}/gateways/csv`,
  // ----------------- Multicast Endpoints -----------------
  MulticastGroup: multicastGroup =>
    `${API_URL}/multicast-group/${multicastGroup}`,
  MulticastGroupCreate: () => `${API_URL}/multicast-group`,
  MulticastGroupDeviceCreate: id => `${API_URL}/multicast-group/${id}/device`,
  MulticastGroupsDeviceDelete: (multicastGroup, device) =>
    `${API_URL}/multicast-group/${multicastGroup}/device/${device}`,
  MulticastGroups: () => `${API_URL}/multicast-groups`,
  MulticastGroupsDevices: mgid => `${API_URL}/multicast-group/${mgid}/devices`,
  MulticastGroupsDevicesMap: multicastGroup =>
    `${API_URL}/multicast/${multicastGroup}/devices/map`,
  MulticastGroupsOptions: () => `${API_URL}/multicast-groups`,
  // ----------------- User Endpoints -----------------
  UserMe: () => `${API_URL}/user`,
  User: userId => `${API_URL}/user/${userId}`,
  UserEmail: () => `${API_URL}/user`,
  UserCreate: () => `${API_URL}/user`,
  UserUpdatePassword: uid => `${API_URL}/user/${uid}/change-password`,
  UserChangePassword: () => `${API_URL}/auth/change-password`,
  UserForgetPassword: () => `${API_URL}/auth/forgot-password`,
  UserByToken: () => `${API_URL}/auth/check-token`,
  AuthUserSignIn: () => `${API_URL}/auth/login`,
  UserProfileSingle: userProfileId =>
    `${API_URL}/user-profile/${userProfileId}`,
  UserProfileCreate: () => `${API_URL}/user-profile`,
  UserProfilesOptions: () => `${API_URL}/utils/user-profiles`,
  UserProfilesList: () => `${API_URL}/user-profiles`,
  UserStats: user => `${API_URL}/stats`,
  // ----------------- Stats Endpoints -----------------
  StatsEvents: () => `${METRIC_URL}/events`,
  StatsDownlinkHistogram: () => `${METRIC_URL}/events/dl/histogram`,
  StatsJoinHistogram: () => `${METRIC_URL}/events/join/histogram`,
  StatsLinkStatusHistogram: () => `${METRIC_URL}/events/ls/histogram`,
  StatsUplinkHistogram: () => `${METRIC_URL}/events/ul/histogram`,
  StatsUplinkRSSIPie: () => `${METRIC_URL}/events/ls/rssi`,
  StatsUplinkFreqPie: () => `${METRIC_URL}/stats/ul/freq`,
  StatsUplinkSfPie: () => `${METRIC_URL}/stats/ul/sf`,
  StatsUplinkAlertsPie: () => `${METRIC_URL}/stats/ul/alerts`,
  StatsTop10DeviceUplink: () => `${METRIC_URL}/top/dev-by-ups`,
  StatsTop10UserUplink: () => `${METRIC_URL}/top/usr-by-ups`,
  StatsDupUplinkAlerts: () => `${METRIC_URL}/alerts/ul/dup`,
  StatsDutyCycleAlerts: () => `${METRIC_URL}/alerts/ul/dc`,
  StatsUplinkTxDelayed: () => `${METRIC_URL}/alerts/ul/tx-delayed`,
  StatsUplinkTxFailed: () => `${METRIC_URL}/alerts/ul/tx-failed`,
  StatsRookies: () => `${GWSTAT_URL}/rookies`,
  StatsUptime: gateway => `${GWSTAT_URL}/gw/${gateway}/uptime`,
  StatsAlerts: gateway => `${GWSTAT_URL}/gw/${gateway}/alerts`,
  StatsPackets: gateway => `${GWSTAT_URL}/gw/${gateway}/packets`,

  // -----------------
  Users: () => `${API_URL}/users`,
  UsersOptions: () => `${API_URL}/users`,
  UsersOptionsFilterd: () =>
    `${API_URL}/users?not_roles[]=admin&not_roles[]=system`,

  Informations: () => `${API_URL}/info`,
};
