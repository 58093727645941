/** @format */

import * as actions from '../actions/multicastGroups';

const defaultState = {
  filter: {},
  sort: {},
  isLoading: false,
  error: false,
};

export const multicastGroups = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_MULTICAST_GROUPS_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_MULTICAST_GROUPS_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_MULTICAST_GROUPS_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_MULTICAST_GROUPS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

const defaultOptions = {
  filter: {},
  sort: {},
  options: [],
  isFetching: false,
  error: false,
};

export const multicastGroupsOptions = (state = defaultOptions, action) => {
  switch (action.type) {
    case actions.FETCH_MULTICAST_GROUPS_OPTIONS_REQUEST:
      return {
        isLoading: true,
        error: false,
        options: [],
      };
    case actions.FETCH_MULTICAST_GROUPS_OPTIONS_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_MULTICAST_GROUPS_OPTIONS_FAILURE:
      return {
        isLoading: false,
        error: true,
        options: [],
      };

    case actions.DESTROY_MULTICAST_GROUPS_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
