/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';
// ACTION TYPES
export const POST_DEVICE_SHARE_REQUEST = 'deviceShare/POST_REQUEST';
export const POST_DEVICE_SHARE_SUCCESS = 'deviceShare/POST_SUCCESS';
export const POST_DEVICE_SHARE_FAILURE = 'deviceShare/POST_FAILURE';

export const DELETE_DEVICE_SHARE_REQUEST = 'deviceShare/DELETE_REQUEST';
export const DELETE_DEVICE_SHARE_SUCCESS = 'deviceShare/DELETE_SUCCESS';
export const DELETE_DEVICE_SHARE_FAILURE = 'deviceShare/DELETE_FAILURE';

export const DESTROY_DEVICE_SHARE_SUCCESS = 'deviceShare/DESTROY_SUCCESS';

// ACTION CREATORS
export const postDeviceShare = devices => ({
  [CALL_API]: {
    endpoint: url.DeviceShareCreate(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(devices),
    types: [
      POST_DEVICE_SHARE_REQUEST,
      {
        type: POST_DEVICE_SHARE_SUCCESS,
        payload: (action, state, res) => {
          history.push({
            pathname: `${PAGES_PATH}/devices`,
            state: { activeIndex: 1 },
          });
          return res.json().then(json => json);
        },
      },
      {
        type: POST_DEVICE_SHARE_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const deleteDeviceShare = (device, guest) => ({
  [CALL_API]: {
    endpoint: url.DeviceShareDelete(device, guest),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_DEVICE_SHARE_REQUEST,
      {
        type: DELETE_DEVICE_SHARE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: DELETE_DEVICE_SHARE_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/devices`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyDeviceShare = () => ({
  type: DESTROY_DEVICE_SHARE_SUCCESS,
});
