/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_GATEWAY_REQUEST = 'gateway/FETCH_REQUEST';
export const FETCH_GATEWAY_SUCCESS = 'gateway/FETCH_SUCCESS';
export const FETCH_GATEWAY_FAILURE = 'gateway/FETCH_FAILURE';

export const POST_GATEWAY_REQUEST = 'gateway/POST_REQUEST';
export const POST_GATEWAY_SUCCESS = 'gateway/POST_SUCCESS';
export const POST_GATEWAY_FAILURE = 'gateway/POST_FAILURE';

export const PUT_GATEWAY_REQUEST = 'gateway/PUT_REQUEST';
export const PUT_GATEWAY_SUCCESS = 'gateway/PUT_SUCCESS';
export const PUT_GATEWAY_FAILURE = 'gateway/PUT_FAILURE';

export const DELETE_GATEWAY_REQUEST = 'gateway/DELETE_REQUEST';
export const DELETE_GATEWAY_SUCCESS = 'gateway/DELETE_SUCCESS';
export const DELETE_GATEWAY_FAILURE = 'gateway/DELETE_FAILURE';

export const DESTROY_GATEWAY_SUCCESS = 'gateway/DESTROY_SUCCESS';

// ACTION CREATORS
export function fetchGateway(gateway) {
  return {
    [CALL_API]: {
      endpoint: url.Gateway(gateway),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_GATEWAY_REQUEST,
        {
          type: FETCH_GATEWAY_SUCCESS,
          payload: (action, state, res) => res.json().then(json => json),
        },
        {
          type: FETCH_GATEWAY_FAILURE,
          payload: (action, state, res) => {
            history.push(`${PAGES_PATH}/gateways`);
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export function postGateway(gateway) {
  return {
    [CALL_API]: {
      endpoint: url.GatewayCreate(),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      body: JSON.stringify(gateway),
      types: [
        POST_GATEWAY_REQUEST,
        {
          type: POST_GATEWAY_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(json => {
              history.push({ pathname: `${PAGES_PATH}/gateway/${json.id}` });
              return json;
            }),
        },
        {
          type: POST_GATEWAY_FAILURE,
          payload: (action, state, res) => {
            history.push(`${PAGES_PATH}/gateways`);
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export function putGateway(gateway, gatewayId) {
  return {
    [CALL_API]: {
      endpoint: url.GatewayUpdate(gateway.UserId, gatewayId),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      body: JSON.stringify(gateway),
      types: [
        PUT_GATEWAY_REQUEST,
        {
          type: PUT_GATEWAY_SUCCESS,
          payload: (action, state, res) => {
            history.push({ pathname: `${PAGES_PATH}/gateway/${gatewayId}` });
            return res.json().then(json => json);
          },
        },
        {
          type: PUT_GATEWAY_FAILURE,
          payload: (action, state, res) =>
            res.json().then(err => toastError(err)),
        },
      ],
    },
  };
}

export function deleteGateway(gateway, gatewayId) {
  return {
    [CALL_API]: {
      endpoint: url.Gateway(gatewayId),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      body: JSON.stringify(gateway),
      types: [
        DELETE_GATEWAY_REQUEST,
        {
          type: DELETE_GATEWAY_SUCCESS,
          payload: (action, state, res) => {
            history.push({ pathname: `${PAGES_PATH}/gateways` });
            return res.json().then(json => json);
          },
        },
        {
          type: DELETE_GATEWAY_FAILURE,
          payload: (action, state, res) => {
            history.push(`${PAGES_PATH}/gateways`);
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export const destroyGateway = () => ({
  type: DESTROY_GATEWAY_SUCCESS,
});
