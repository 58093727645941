/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addValidationRule } from 'formsy-react';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Loader,
  Message,
  Table,
} from 'semantic-ui-react';
import { omit } from 'lodash'
import { deleteDownlink, postDownlink } from '../../actions/downlink';
import { destroyDownlinks, getDownlinks } from '../../actions/downlinks';
import { userHasRole } from '../../lib/auth';
import { DEVICE_NEVER_TALK_STATUS, toastSuccess } from '../../lib/utilities';

addValidationRule('isPort', (values, value) => {
  // eslint-disable-next-line no-param-reassign
  value = parseInt(value, 10);
  if (typeof value !== 'number') {
    return false;
  }
  return value < 256 && value > 0;
});

const mapStateToProps = state => ({
  downlinks: state.downlinks,
});

const mapDispatchToProps = dispatch => ({
  postDownlink: (application, device, downlink) => {
    dispatch(postDownlink(application, device, downlink));
  },
  deleteDownlink: (application, device, downlink) => {
    dispatch(deleteDownlink(application, device, downlink));
  },
  getDownlinks: (application, device) => {
    dispatch(getDownlinks(application, device));
  },
  destroyDownlinks: () => {
    dispatch(destroyDownlinks());
  },
});

class DeviceDownlinkForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      downlink: {
        priority: 0,
        port: 1,
        confirmed: false,
        deveui: 'deveui' in this.props ? this.props.deveui : null,
        window: 'BOTH',
        payload: '',
      },
      selectedDownlink: {},
      deviceNeverSend: false,
      message: '',
      isLoading: false,
      error: false,
      interval: null,
      intervalTiming: 20000, // intervalforPolling
      payloadErrorMatch: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteDownlink = this.handleDeleteDownlink.bind(this);
    this.handleDownlinkSave = this.handleDownlinkSave.bind(this);
    this.isQueueEmpty = this.isQueueEmpty.bind(this);
  }

  // HANDLER SECTION
  handleChange(e, { name, value }) {
    let payloadErrorMatch = false;

    // check if payload is hex
    if (name === 'payload') {
      const match = value.match(/^\b([0-9A-Fa-f][a-fA-F0-9]){1,242}\b$/g);

      if (match) {
        // eslint-disable-next-line no-param-reassign
        value = value.toLowerCase();
        payloadErrorMatch = false;
      } else {
        payloadErrorMatch = true;
      }
    }

    this.setState({
      ...this.state,
      downlink: {
        ...this.state.downlink,
        [name]: value,
      },
      payloadErrorMatch,
    });
  }

  handleShowDownlink(downlink) {
    this.setState({ selectedDownlink: downlink });
  }

  handleCopyDownlink(copyDownlink) {
    const { downlink } = this.state;
    toastSuccess('You copied the downlink successfully');
    this.setState({ downlink: { ...downlink, ...copyDownlink } });
  }

  handleDeleteDownlink(DeviceId, DownlinkId) {
    this.props.deleteDownlink(DeviceId, DownlinkId);
    this.setState({ selectedDownlink: {}, isLoading: true });
    setTimeout(() => {
      this.props.getDownlinks(this.props.device.ApplicarionId, DeviceId);
      this.setState({ isLoading: false });
    }, 5000);
  }

  handleDownlinkSave() {
    this.setState({ isLoading: true });
    const { downlink } = this.state;
    const { device } = this.props;
    const downtoSend = { 
      ...omit(downlink, ['id', 'deveui']),
      ...'priority' in downlink ? { priority: parseInt(downlink.priority, 10) } : {},
    };

    if (userHasRole('admin')) {
      downtoSend.UserId = device.UserId;
    }
    downtoSend.port = Number(downlink.port);

    this.props.postDownlink(device.ApplicationId, device.id, downtoSend);
    this.setState({ selectedDownlink: {} });

    setTimeout(() => {
      this.props.getDownlinks(device.ApplicationId, device.id);
      this.setState({ isLoading: false });
    }, 5000);
  }

  isQueueEmpty() {
    const { downlinks } = this.props;
    if ('queue' in downlinks && downlinks.queue.length >= 4) {
      return true;
    }
    return false;
  }

  queueDownlink() {
    const { isLoading } = this.state;
    const { device, downlinks } = this.props;
    if (isLoading) {
      return (
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="4">
                <Dimmer active inverted>
                  <Loader active inline="centered">
                    Loading
                  </Loader>
                </Dimmer>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );
    }
    if (downlinks.queue && downlinks.queue.length > 0) {
      return (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell key="headerSelectId">id</Table.HeaderCell>
              <Table.HeaderCell key="headerSelectPayload">
                payload
              </Table.HeaderCell>
              <Table.HeaderCell key="headerSelectPort">port</Table.HeaderCell>
              <Table.HeaderCell key="headerSelectActions">
                actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {idx(downlinks, _ =>
              _.queue.map(row => (
                <Table.Row key={`row-${row.id}`}>
                  <Table.Cell key={`row-${row.id}-id`}>{row.id}</Table.Cell>
                  <Table.Cell key={`row-${row.id}-payload`}>
                    {row.payload}
                  </Table.Cell>
                  <Table.Cell key={`row-${row.id}-port`}>{row.port}</Table.Cell>
                  <Table.Cell key={`row-${row.id}-actions`}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Link
                            to="#"
                            onClick={() => {
                              this.handleShowDownlink(row);
                            }}
                          >
                            <FontAwesomeIcon icon="search" />
                          </Link>
                        </Grid.Column>
                        {!this.isQueueEmpty() && (
                          <Grid.Column>
                            <Link
                              to="#"
                              onClick={() => {
                                this.handleCopyDownlink(row);
                              }}
                            >
                              <FontAwesomeIcon icon="copy" />
                            </Link>
                          </Grid.Column>
                        )}
                        <Grid.Column>
                          <Link
                            to="#"
                            onClick={() => {
                              this.handleDeleteDownlink(device.id, row.id);
                            }}
                          >
                            <FontAwesomeIcon icon="trash-alt" />
                          </Link>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
              )),
            )}
          </Table.Body>
        </Table>
      );
    }
    if (
      this.props.downlinks.isLoading === false &&
      this.props.downlinks.error === true
    ) {
      if (this.props.downlinks.status === DEVICE_NEVER_TALK_STATUS) {
        // device che non ha mai parlato
        return <div>The queue is empty...</div>;
      }
      return (
        <Message negative>
          <Message.Header>OPS... {downlinks.response}</Message.Header>
          <p>Please check your information or contact-us.</p>
        </Message>
      );
    }
    return <div>The queue is empty...</div>;
  }

  render() {
    const { downlink, isLoading, selectedDownlink } = this.state;

    return (
      <Fragment>
        <Grid
          columns={this.props.match.params.did === undefined || this.props.type === 'full' ? 2 : 1}
          divided
        >
          <Grid.Row>
            <Grid.Column
              style={{
                display:
                  this.props.match.params.did === undefined || this.props.type === 'full' ? 'block' : 'none',
              }}
            >
              <Form onSubmit={this.handleDownlinkSave}>
                <Form.Field>
                  <Form.Input
                    label="Priority"
                    name="priority"
                    placeholder="Priority"
                    required
                    type="number"
                    min={0}
                    max={2147483647}
                    disabled={
                      isLoading ||
                      this.isQueueEmpty() ||
                      this.props.downlinks.error
                    }
                    value={`${idx(downlink, _ => _.priority)}`}
                    onChange={this.handleChange}
                  />
                  <Form.Select
                    name="confirmed"
                    label="Type"
                    fluid
                    placeholder="choose one..."
                    selection
                    required
                    options={[
                      { key: 'opt1', value: false, text: 'unconfirmed' },
                      { key: 'opt2', value: true, text: 'confirmed' },
                    ]}
                    defaultValue={downlink.confirmed}
                    disabled={
                      isLoading ||
                      this.isQueueEmpty() ||
                      this.props.downlinks.error
                    }
                    value={idx(downlink, _ => _.type)}
                    onChange={this.handleChange}
                  />

                  <Form.Dropdown
                    name="window"
                    label="Rx Window"
                    placeholder="choose one..."
                    selection
                    required
                    options={[
                      { key: 0, value: 'BOTH', text: 'Any' },
                      { key: 1, value: 'RX1', text: 'RX1' },
                      { key: 2, value: 'RX2', text: 'RX2' },
                    ]}
                    defaultValue={'BOTH'}
                    disabled={
                      isLoading ||
                      this.isQueueEmpty() ||
                      this.props.downlinks.error
                    }
                    value={idx(downlink, _ => _.type)}
                    onChange={this.handleChange}
                  />

                  <Form.Input
                    label="Port"
                    name="port"
                    placeholder="Port"
                    required
                    type="number"
                    validations="isPort"
                    disabled={
                      isLoading ||
                      this.isQueueEmpty() ||
                      this.props.downlinks.error
                    }
                    value={idx(downlink, _ => _.port)}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    label="DevEui"
                    name="deveui"
                    placeholder="DevEui"
                    required
                    readOnly
                    disabled={
                      isLoading ||
                      this.isQueueEmpty() ||
                      this.props.downlinks.error
                    }
                    value={idx(downlink, _ => _.deveui)}
                  />

                  <Form.TextArea
                    label="Payload"
                    name="payload"
                    placeholder=""
                    maxLength="242"
                    required
                    type="text"
                    disabled={
                      isLoading ||
                      this.isQueueEmpty() ||
                      this.props.downlinks.error
                    }
                    value={idx(downlink, _ => _.payload)}
                    onChange={this.handleChange}
                    error={this.state.payloadErrorMatch}
                  />
                  <Button
                    type="submit"
                    color="red"
                    disabled={
                      isLoading ||
                      this.isQueueEmpty() ||
                      this.props.downlinks.error ||
                      downlink.payload.length < 2 ||
                      this.state.payloadErrorMatch
                    }
                  >
                    Save
                  </Button>
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column>
              {this.queueDownlink()}

              {selectedDownlink && Object.keys(selectedDownlink).length !== 0 && (
                <Fragment>
                  <Divider horizontal>
                    <Header as="h4">
                      <FontAwesomeIcon icon="search" />
                      &nbsp;View
                    </Header>
                  </Divider>
                  <ReactJson
                    name={null}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    src={selectedDownlink}
                  />
                </Fragment>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }

  componentDidMount() {
    this.props.match.params.did !== undefined
      ? this.props.getDownlinks(
          this.props.device.ApplicationId,
          this.props.match.params.did,
        )
      : this.props.getDownlinks(
          this.props.device.ApplicationId,
          this.props.device.id,
        );
  }

  componentWillUnmount() {
    const { interval } = this.state;

    if (interval) clearInterval(interval);
    // this.props.destroyDownlinks();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { device, pollingActive } = this.props;
    const { interval } = this.state;
    if (pollingActive) {
      const didTOuse =
        this.props.match.params.did !== undefined
          ? this.props.match.params.did
          : device.id;

      if (
        this.props.downlinks &&
        this.props.downlinks.queue &&
        this.props.downlinks.queue.length > 0
      ) {
        if (interval === null) {
          this.setState({
            ...this.state,
            interval: setInterval(
              () => this.props.getDownlinks(device.ApplicationId, didTOuse),
              this.state.intervalTiming,
            ),
          });
        }
      } else {
        if (interval) clearInterval(this.state.interval);
      }
    }
  }
}

DeviceDownlinkForm.propTypes = {
  device: PropTypes.object,
  pollingActive: PropTypes.bool,
  downlinks: PropTypes.object,
  deveui: PropTypes.string,
  postDownlink: PropTypes.func,
  deleteDownlink: PropTypes.func,
  getDownlinks: PropTypes.func,
  destroyDownlinks: PropTypes.func,
  isQueueEmpty: PropTypes.func,
  match: PropTypes.object,
  type: PropTypes.string
};

const DeviceDownlink = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceDownlinkForm),
);

export { DeviceDownlink };
