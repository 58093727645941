/** @format */

import React from 'react';
import idx from 'idx';
import { useSelector } from 'react-redux';
import { Row } from './Row';
import moment from 'moment';

export const List = () => {
  const uplinks = useSelector(store => store.uplinks);

  const sortedMessageList = accessory => {
    if (!accessory) return [];
    if (uplinks.filters.orderBy === 'desc') {
      return accessory.messages.slice().reverse();
    } else {
      return accessory.messages;
    }
  };

  const handleIsfilterMatching = row => {
    const {
      startDate,
      endDate,
      owners,
      SFs,
      types,
      ports,
      appIds,
      payload,
      devEuis,
    } = uplinks.filters;

    let isMatchingTime = true;
    if (row.timestamp && (startDate || endDate)) {
      const currDate = moment(row.timestamp);
      if (startDate)
        isMatchingTime = currDate.isSameOrAfter(startDate, 'minute');
      if (isMatchingTime && endDate)
        isMatchingTime = currDate.isSameOrBefore(endDate, 'minute');
    }

    const isMatchingOwner =
      owners.length > 0
        ? owners.some(record => row.user === record.text)
        : true;
    const isMatchingPort =
      ports.length > 0 ? ports.some(record => row.port === record.text) : true;
    const isMatchingSF =
      SFs.length > 0
        ? SFs.some(record => {
            const sf =
              row.message &&
              row.message.statistics &&
              row.message.statistics.sf;
            return !!sf && parseInt(sf) === record.value;
          })
        : true;
    const isMatchingType =
      types.length > 0 ? types.some(record => row.type === record.text) : true;
    const isMatchingAppId =
      appIds.length > 0
        ? appIds.some(record => row.appid === record.text)
        : true;
    const isMatchingDevEui =
      devEuis.selectedFilters.length > 0
        ? devEuis.selectedFilters.some(record => row.deveui === record.text)
        : true;
    const isMatchingPayload =
      payload.length > 0
        ? payload.some(record => row.message.payload === record.text)
        : true;

    const isFilterEmpty =
      !startDate &&
      !endDate &&
      owners.length === 0 &&
      ports.length === 0 &&
      SFs.length === 0 &&
      types.length === 0 &&
      appIds.length === 0 &&
      devEuis.selectedFilters.length === 0 &&
      payload.length === 0;

    const selectedFilters =
      isMatchingTime &&
      isMatchingOwner &&
      isMatchingPort &&
      isMatchingSF &&
      isMatchingType &&
      isMatchingAppId &&
      isMatchingDevEui &&
      isMatchingPayload;

    return isFilterEmpty ? true : selectedFilters;
  };

  return (
    <>
      {idx(uplinks, accessory =>
        sortedMessageList(accessory).map(row => {
          const isMatchingFilter = handleIsfilterMatching(row);
          if (isMatchingFilter) return <Row row={row} key={row.id} />;
          return null;
        }),
      )}
    </>
  );
};
