/** @format */

import * as actions from '../actions/applications';

const defaultState = {
  filter: {},
  sort: {},
  isFetching: false,
  error: false,
};

export const applications = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_APPLICATIONS_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_APPLICATIONS_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_APPLICATIONS_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_APPLICATIONS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

export const applicationsSharedBy = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_APPLICATIONS_SHARED_BY_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_APPLICATIONS_SHARED_BY_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_APPLICATIONS_SHARED_BY_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_APPLICATIONS_SHARED_BY_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

export const applicationsSharedWith = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_APPLICATIONS_SHARED_WITH_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_APPLICATIONS_SHARED_WITH_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_APPLICATIONS_SHARED_WITH_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_APPLICATIONS_SHARED_WITH_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

const defaultOptions = {
  filter: {},
  sort: {},
  options: [],
  isFetching: false,
  error: false,
};

export const applicationsOptions = (state = defaultOptions, action) => {
  switch (action.type) {
    case actions.FETCH_APPLICATIONS_OPTIONS_REQUEST:
      return {
        isLoading: true,
        error: false,
        options: [],
      };
    case actions.FETCH_APPLICATIONS_OPTIONS_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_APPLICATIONS_OPTIONS_FAILURE:
      return {
        isLoading: false,
        error: true,
        options: [],
      };

    case actions.DESTROY_APPLICATIONS_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
