/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken, deleteCurrentUserToken } from '../lib/auth';
import { useThisQueryString } from '../lib/urls';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_GATEWAYS_REQUEST = 'gateways/FETCH_REQUEST';
export const FETCH_GATEWAYS_SUCCESS = 'gateways/FETCH_SUCCESS';
export const FETCH_GATEWAYS_FAILURE = 'gateways/FETCH_FAILURE';

export const FETCH_GATEWAYS_SHARED_BY_REQUEST =
  'gatewaysSharedBy/FETCH_REQUEST';
export const FETCH_GATEWAYS_SHARED_BY_SUCCESS =
  'gatewaysSharedBy/FETCH_SUCCESS';
export const FETCH_GATEWAYS_SHARED_BY_FAILURE =
  'gatewaysSharedBy/FETCH_FAILURE';

export const FETCH_GATEWAYS_SHARED_WITH_REQUEST =
  'gatewaysSharedWith/FETCH_REQUEST';
export const FETCH_GATEWAYS_SHARED_WITH_SUCCESS =
  'gatewaysSharedWith/FETCH_SUCCESS';
export const FETCH_GATEWAYS_SHARED_WITH_FAILURE =
  'gatewaysSharedWith/FETCH_FAILURE';

export const DESTROY_GATEWAYS_SUCCESS = 'gateways/DESTROY_SUCCESS';
export const DESTROY_GATEWAYS_SHARED_BY_SUCCESS =
  'gatewaysSharedBy/DESTROY_SUCCESS';
export const DESTROY_GATEWAYS_SHARED_WITH_SUCCESS =
  'gatewaysSharedWith/DESTROY_SUCCESS';

// ACTION CREATORS
export function fetchGateways(params = { qs: '' }) {
  return {
    [CALL_API]: {
      endpoint: useThisQueryString(url.Gateways(), params),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_GATEWAYS_REQUEST,
        {
          type: FETCH_GATEWAYS_SUCCESS,
          payload: (action, state, res) => res.json().then(json => json),
        },
        {
          type: FETCH_GATEWAYS_FAILURE,
          payload: (action, state, res) =>
            res.json().then(err => toastError(err)),
        },
      ],
    },
  };
}

export function fetchGatewaysSharedBy(params = { qs: '' }) {
  return {
    [CALL_API]: {
      endpoint: useThisQueryString(url.GatewaysSharedBy(), params),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_GATEWAYS_SHARED_BY_REQUEST,
        {
          type: FETCH_GATEWAYS_SHARED_BY_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(json => ({
              ...json,
              filter: 'filter' in params ? params.filter : {},
            })),
        },
        {
          type: FETCH_GATEWAYS_SHARED_BY_FAILURE,
          payload: (action, state, res) => {
            if (res && res.status && res.status === 401) {
              deleteCurrentUserToken();
              history.push(`${PAGES_PATH}`);
            }
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export function fetchGatewaysSharedWith(params = { qs: '' }) {
  return {
    [CALL_API]: {
      endpoint: useThisQueryString(url.GatewaysSharedWith(), params),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_GATEWAYS_SHARED_WITH_REQUEST,
        {
          type: FETCH_GATEWAYS_SHARED_WITH_SUCCESS,
          payload: (action, state, res) =>
            res.json().then(json => ({
              ...json,
              filter: 'filter' in params ? params.filter : {},
            })),
        },
        {
          type: FETCH_GATEWAYS_SHARED_WITH_FAILURE,
          payload: (action, state, res) => {
            if (res && res.status && res.status === 401) {
              deleteCurrentUserToken();
              history.push(`${PAGES_PATH}`);
            }
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export const destroyGateways = () => ({
  type: DESTROY_GATEWAYS_SUCCESS,
});

export const destroyGatewaysSharedBy = () => ({
  type: DESTROY_GATEWAYS_SHARED_BY_SUCCESS,
});

export const destroyGatewaysSharedWith = () => ({
  type: DESTROY_GATEWAYS_SHARED_WITH_SUCCESS,
});
