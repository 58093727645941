/** @format */

import * as actions from '../actions/auth';

export const signin = (state = { isLoading: false, error: false }, action) => {
  switch (action.type) {
    case actions.AUTH_SIGNIN_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.AUTH_SIGNIN_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.AUTH_SIGNIN_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    default:
      return state;
  }
};
