/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { objectToQueryString } from '../lib/urls';

export const POST_ACCESS_TOKEN_REQUEST = 'accesstoken/POST_REQUEST';
export const POST_ACCESS_TOKEN_SUCCESS = 'accesstoken/POST_SUCCESS';
export const POST_ACCESS_TOKEN_FAILURE = 'accesstoken/POST_FAILURE';

export const DELETE_ACCESS_TOKEN_REQUEST = 'accesstoken/DELETE_REQUEST';
export const DELETE_ACCESS_TOKEN_SUCCESS = 'accesstoken/DELETE_SUCCESS';
export const DELETE_ACCESS_TOKEN_FAILURE = 'accesstoken/DELETE_FAILURE';

export const GET_ACCESS_TOKENS_REQUEST = 'accesstokens/GET_REQUEST';
export const GET_ACCESS_TOKENS_SUCCESS = 'accesstokens/GET_SUCCESS';
export const GET_ACCESS_TOKENS_FAILURE = 'accesstokens/GET_FAILURE';

export const DESTROY_ACCESS_TOKEN_SUCCESS = 'accesstoken/DESTROY_SUCCESS';

export const postAccessToken = accesstoken => ({
  [CALL_API]: {
    endpoint: url.AccessTokenCreate(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(accesstoken),
    types: [
      POST_ACCESS_TOKEN_REQUEST,
      {
        type: POST_ACCESS_TOKEN_SUCCESS,
        payload: (action, state, res) => {
          const result = res.json().then(response => {
            return response;
          });
          return result;
        },
      },
      {
        type: POST_ACCESS_TOKEN_FAILURE,
        payload: (action, state, res) => {
          return res.json().then(err => err);
        },
      },
    ],
  },
});

export const deleteAccessToken = accessToken => ({
  [CALL_API]: {
    endpoint: url.AccessTokenRemove(accessToken),
    method: 'DELETE',
    headers: {
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_ACCESS_TOKEN_REQUEST,
      {
        type: DELETE_ACCESS_TOKEN_SUCCESS,
        payload: (action, state, res) => {
          return {};
        },
      },
      DELETE_ACCESS_TOKEN_FAILURE,
    ],
  },
});

export const getAccessTokens = (query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.AccessTokens()}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      Authorization: getCurrentUserToken(),
    },
    types: [
      GET_ACCESS_TOKENS_REQUEST,
      {
        type: GET_ACCESS_TOKENS_SUCCESS,
        payload: (action, state, res) => {
          return res.json().then(response => response);
        },
      },
      GET_ACCESS_TOKENS_FAILURE,
    ],
  },
});

export const destroyAccessToken = () => ({
  type: DESTROY_ACCESS_TOKEN_SUCCESS,
});
