/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { setCurrentUser } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';

// ACTION TYPES
export const AUTH_SIGNIN_REQUEST = 'signin/AUTH_SIGNIN_REQUEST';
export const AUTH_SIGNIN_SUCCESS = 'signin/AUTH_SIGNIN_SUCCESS';
export const AUTH_SIGNIN_FAILURE = 'signin/AUTH_SIGNIN_FAILURE';

// ACTION CREATORS
export const userSignIn = (username, password) => ({
  [CALL_API]: {
    endpoint: url.AuthUserSignIn(),
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: { 'Content-Type': 'application/json' },
    types: [
      {
        type: AUTH_SIGNIN_REQUEST,
        payload: (action, state) => ({ endpoint: action.endpoint }),
      },
      {
        type: AUTH_SIGNIN_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => {
            setCurrentUser(json);
            history.push(`${PAGES_PATH}/devices`);
            return json;
          }),
      },
      {
        type: AUTH_SIGNIN_FAILURE,
        payload: (action, state, res) => res.json().then(err => err),
      },
    ],
  },
});
