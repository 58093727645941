import React from "react";
import { Card, Divider } from 'semantic-ui-react';
import ReactJson from 'react-json-view';
import PropTypes from 'prop-types';

const BasicstationConfiguration = ({ configuration = {} }) => {
  const { data = null } = configuration;
  return <>
    <Card fluid>
      <Card.Content>
        <Card.Header>
          Configuration
        </Card.Header>
        <Divider />
        <Card.Description>
          <ReactJson name={false} src={data} collapsed />
        </Card.Description>
      </Card.Content>
    </Card>
  </>
}

BasicstationConfiguration.propTypes = {
  configuration: PropTypes.object
};

export { BasicstationConfiguration };