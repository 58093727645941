/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import idx from 'idx';
import PropTypes from 'prop-types';

import {
  Button,
  Divider,
  Form,
  Header,
  Sidebar,
  Segment,
} from 'semantic-ui-react';

import { MulticastGroupCreate } from '..';
import {
  destroyUsersOptions,
  fetchUsersOptions,
  fetchUsers,
} from '../../actions/users';
import {
  destroyMulticastGroupDevices,
  postMulticastGroupDevices,
} from '../../actions/multicastGroupDevices';
import { userHasRole } from '../../lib/auth';
import {
  destroyMulticastGroupsOptions,
  fetchMulticastGroupsOptions,
} from '../../actions/multicastGroups';
import { formatOptions } from '../../lib/utilities';

const mapStateToProps = state => ({
  users: state.users,
  usersOptions: state.usersOptions.options || [],
  multicastGroup: state.multicastGroup,
  multicastGroupsDevices: state.multicastGroupsDevices,
  multicastGroupsOptions: state.multicastGroupsOptions,
});

const mapDispatchToProps = dispatch => ({
  fetchUsersOptions: (params = {}) => {
    dispatch(fetchUsersOptions(params));
  },
  fetchUsers: (username, params = { qs: '' }) => {
    dispatch(fetchUsers(username, params));
  },
  fetchMulticastGroupsOptions: (params = {}) => {
    dispatch(fetchMulticastGroupsOptions(params));
  },
  postMulticastGroupDevices: (id, ids) => {
    dispatch(postMulticastGroupDevices(id, ids));
  },
  destroyUsersOptions: () => {
    dispatch(destroyUsersOptions());
  },
  destroyMulticastGroupsOptions: () => {
    dispatch(destroyMulticastGroupsOptions());
  },
  destroyMulticastGroupDevices: () => {
    dispatch(destroyMulticastGroupDevices());
  },
});

class MulticastGroupAddForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      UserId: this.props.location.state.UserId,
      multicastGroupsDevices: {
        ids: this.props.location.state.devices,
        MulticastGroupId: null,
      },
      multicastGroupSidebar: false,
      offset: 0,
      limit: 12,
    };

    this.handleMulticastGroupSidebar = this.handleMulticastGroupSidebar.bind(
      this,
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { limit, UserId } = this.state;

    const qs = {
      limit,
      ...(userHasRole('admin') ? { uid: UserId } : {}),
    };

    this.props.fetchMulticastGroupsOptions(qs);
    const isAdmin = userHasRole('admin');
    if (isAdmin) {
      this.props.fetchUsersOptions();
      this.props.fetchUsers({ qs: 'limit=10000' });
    }
  }

  componentWillUnmount() {
    this.props.destroyUsersOptions();
    this.props.destroyMulticastGroupsOptions();
    this.props.destroyMulticastGroupDevices();
  }

  // HANDLER SECTION
  handleMulticastGroupSidebar(e) {
    const { limit, UserId } = this.state;

    e.preventDefault();
    setTimeout(() => {
      const qs = {
        limit,
        ...(userHasRole('admin') ? { uid: UserId } : {}),
      };

      this.props.fetchMulticastGroupsOptions(qs);
    }, 500);

    this.setState({ multicastGroupSidebar: !this.state.multicastGroupSidebar });
  }

  handleChange(e, { name, value }) {
    this.setState({
      multicastGroupsDevices: {
        ...this.state.multicastGroupsDevices,
        [name]: value,
      },
    });
  }

  handleSubmit(e) {
    const { multicastGroupsDevices } = this.state;
    e.preventDefault();
    this.props.postMulticastGroupDevices(
      multicastGroupsDevices.MulticastGroupId,
      {
        ids: multicastGroupsDevices.ids,
      },
    );
  }

  render() {
    const {
      multicastGroupsDevices,
      multicastGroupSidebar,
      UserId,
    } = this.state;
    const { multicastGroupsOptions } = this.props;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Add to a Multicast Group
        </Header>
        <Divider clearing />
        <Sidebar.Pushable style={{ height: '80vh' }}>
          <Sidebar
            as={Segment}
            inverted
            color="red"
            direction="right"
            animation="overlay"
            visible={multicastGroupSidebar}
            width="very wide"
          >
            <Button
              floated="right"
              onClick={this.handleMulticastGroupSidebar}
              size="mini"
            >
              close
            </Button>
            <Header floated="left" as="h2">
              New Multicast Group
            </Header>
            <Divider clearing inverted />
            {userHasRole('admin') ? (
              <MulticastGroupCreate
                inverted={true}
                UserId={UserId}
                parentHandleSidebar={this.handleMulticastGroupSidebar}
              />
            ) : (
              <MulticastGroupCreate
                inverted={true}
                parentHandleSidebar={this.handleMulticastGroupSidebar}
              />
            )}
          </Sidebar>

          <Sidebar.Pusher>
            <Form onSubmit={this.handleSubmit} style={{ marginTop: '2rem' }}>
              <Form.Group>
                <Form.Dropdown
                  name="MulticastGroupId"
                  placeholder="choose one..."
                  width={8}
                  selection
                  search
                  options={
                    idx(multicastGroupsOptions, _ =>
                      formatOptions(_.options.rows, 'Multicast Groups'),
                    ) || []
                  }
                  value={idx(multicastGroupsDevices, _ => _.MulticastGroupId)}
                  onChange={this.handleChange}
                  onSearchChange={this.handleSearchMulticastGroupIdChange}
                  loading={idx(multicastGroupsOptions, _ => _.isFetching)}
                  disabled={!UserId}
                />

                <Form.Button
                  width={8}
                  content="Create Multicast Group (pippo)"
                  onClick={this.handleMulticastGroupSidebar}
                  disabled={!UserId}
                />
              </Form.Group>

              <Form.Button
                content="Save"
                disabled={multicastGroupsDevices.MulticastGroupId === null}
                type="submit"
              />
            </Form>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    );
  }
}

MulticastGroupAddForm.propTypes = {
  location: PropTypes.object,
  multicastGroup: PropTypes.object,
  multicastGroupsOptions: PropTypes.object,
  fetchMulticastGroupsOptions: PropTypes.func,
  destroyUsersOptions: PropTypes.func,
  destroyMulticastGroupsOptions: PropTypes.func,
  destroyMulticastGroupDevices: PropTypes.func,
  postMulticastGroupDevices: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  fetchUsers: PropTypes.func,
};

const MulticastGroupAddTo = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MulticastGroupAddForm),
);

export { MulticastGroupAddTo };
