/** @format */

import * as actions from '../actions/user';

const defaultState = {
  isLoading: false,
  error: false,
};

export const user = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_USER_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_USER_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.FETCH_USER_BY_NAME_OR_EMAIL_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_USER_BY_NAME_OR_EMAIL_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_USER_BY_NAME_OR_EMAIL_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.FETCH_USER_BY_TOKEN_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_USER_BY_TOKEN_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_USER_BY_TOKEN_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.POST_USER_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.POST_USER_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.POST_USER_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.PUT_USER_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.PUT_USER_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.PUT_USER_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DELETE_USER_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.DELETE_USER_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.DELETE_USER_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_USER_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };
    case actions.USER_CHANGE_FORGET_PASSWORD_REQUEST:
      return {
        isLoading: true,
        passwordChanged: false,
        error: false,
      };
    case actions.USER_CHANGE_FORGET_PASSWORD_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        passwordChanged: true,
        error: false,
      };
    case actions.USER_CHANGE_FORGET_PASSWORD_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        passwordChanged: false,
        error: true,
      };

    default:
      return state;
  }
};

export const userStats = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_STATS_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_USER_STATS_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_USER_STATS_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_USER_STATS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
