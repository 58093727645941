/** @format */

import React, { Fragment, useState } from 'react';
import { List, Select } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ListItem } from '../../components';

const TopicItem = ({items}) => {
  const labels = Object.keys(items);
  return (
    <Fragment>
      {
        labels.map(label => {
          return (<List key={label} verticalAlign="middle" divided selection>
          <ListItem
            label={label}
            content={items[label]}
            copy={true}
          />
        </List>)
        })
      }
    </Fragment>
  );
};

TopicItem.propTypes = {
  items: PropTypes.object
};

const UtilityTopic = ({ appid = null, deveui = null, user = null, gatewayId = null }) => {
  const owner = useSelector(state => state.me);
  const [apiVersion, setApiVersion] = useState('v2');
  const apiOptions = [
    { key: '1', value: 'v1', text: 'v1' },
    { key: '2', value: 'v2', text: 'v2' }
  ];

  // add start / if api version is v1
  const checkTopic = (topic) => {
    return apiVersion === 'v2' ? `${topic}` : `/${topic}`
  }

  const generateTopic = (_appid, _deveui, _user,  _gatewayId) => {
    if (_appid) {
      if (_deveui) return {
        uplink: checkTopic(`sub/${apiVersion}/users/${_user || owner.username}/apps/${_appid}/devices/${_deveui}/uplink/+`),
        events: checkTopic(`sub/${apiVersion}/users/${_user || owner.username}/apps/${_appid}/devices/${_deveui}/events/+`),
        alerts: checkTopic(`sub/${apiVersion}/users/${_user || owner.username}/apps/${_appid}/devices/${_deveui}/alerts/+`)
      };
      else return {
        uplink: checkTopic(`sub/${apiVersion}/users/${_user || owner.username}/apps/${_appid}/devices/+/uplink/+`),
        events: checkTopic(`sub/${apiVersion}/users/${_user || owner.username}/apps/${_appid}/devices/+/events/+`),
        alerts: checkTopic(`sub/${apiVersion}/users/${_user || owner.username}/apps/${_appid}/devices/+/alerts/+`)
      };
    } else if (_gatewayId) return {
      events: checkTopic(`sub/${apiVersion}/users/${_user || owner.username}/gateways/${_gatewayId}/events/+`),
      alerts: checkTopic(`sub/${apiVersion}/users/${_user || owner.username}/gateways/${_gatewayId}/alerts/+`)
    };
    else return '';
  }

  const selectApiVersion = (e, { value }) => {
    setApiVersion(value);
  };

  return (
    <Fragment>
      {(appid || deveui || user || gatewayId) && <div>
        <Select name="apiVersion" placeholder='select api version' options={apiOptions} value={apiVersion} onChange={selectApiVersion} />
        <TopicItem items={generateTopic(appid, deveui, user, gatewayId)} />
      </div>}
    </Fragment>
  )
};

UtilityTopic.propTypes = {
  appid: PropTypes.number,
  deveui: PropTypes.string,
  user: PropTypes.string,
  gatewayId: PropTypes.string
};

export { UtilityTopic };