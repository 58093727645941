/** @format */

import idx from 'idx';
import React, { useState } from 'react';
import { Table, Label, Image, Modal, Header, Grid } from 'semantic-ui-react';
import { any } from 'prop-types';
import moment from 'moment';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const tags = {
  join: 'olive',
  'link-status': 'orange',
  uplink: 'yellow',
};

const getTagsColor = tag => {
  if (tag in tags) {
    return tags[tag];
  }
  return 'grey';
};

export const Row = ({ row }) => {
  const [payload, setPayload] = useState(null);

  const handleOpenModal = () => setPayload(row);

  const handleCloseModal = () => setPayload(null);

  const stat =
    row.message && row.message.statistics ? row.message.statistics : {};
  const SF = stat ? stat.sf : '-';
  const SQNO = stat ? stat.seqno : '-';

  return (
    <>
      <Table.Row key={`${row.id}-${row.timestamp}-data`}>
        <Table.Cell>{moment(row.timestamp).format('HH:mm:ss')}</Table.Cell>
        <Table.Cell>{idx(row, _ => _.user)}</Table.Cell>
        <Table.Cell>{SQNO}</Table.Cell>
        <Table.Cell>{SF}</Table.Cell>
        <Table.Cell>
          {'type' in row ? (
            <Label as="span" color={getTagsColor(row.type)} size="mini">
              {row.type}
            </Label>
          ) : (
            '-'
          )}
        </Table.Cell>
        <Table.Cell>{'port' in row ? row.port : '-'}</Table.Cell>
        <Table.Cell>{'appid' in row ? row.appid : '-'}</Table.Cell>
        <Table.Cell>
          {'deveui' in row && (
            <>
              <Image
                style={{
                  backgroundColor: '#db2828',
                  padding: '5px',
                }}
                src="/assets/images/icoDevices.svg"
                avatar
              />
              {row.deveui}
            </>
          )}
          {'gwid' in row ? (
            <>
              <Image
                style={{
                  backgroundColor: '#3e87a6',
                  padding: '5px',
                }}
                src="/assets/images/icoGateways.svg"
                avatar
              />
              {row.gwid}
            </>
          ) : (
            '-'
          )}
        </Table.Cell>
        <Table.Cell>
          {'payload' in row.message ? row.message.payload : '-'}
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Grid columns="one" divided>
            <Grid.Row>
              <Grid.Column>
                <Link to="#" onClick={handleOpenModal}>
                  <FontAwesomeIcon icon="info-circle" />
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
      </Table.Row>
      {idx(payload, _ => _.message) && (
        <Modal open={!!payload} onClose={handleCloseModal} size="small">
          <Header>
            <h4 style={{ wordWrap: 'break-word' }}>
              {payload.topic || 'Information'}
            </h4>
          </Header>
          <Modal.Content>
            <div className="json">
              <ReactJson
                name={null}
                displayObjectSize={false}
                displayDataTypes={false}
                src={payload.message}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Link to="#" color="red" onClick={handleCloseModal}>
              Close
            </Link>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

Row.propTypes = {
  row: any,
};
