/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_DEVICE_PROFILES_OPTIONS_REQUEST =
  'deviceProfilesOptions/FETCH_REQUEST';
export const FETCH_DEVICE_PROFILES_OPTIONS_SUCCESS =
  'deviceProfilesOptions/FETCH_SUCCESS';
export const FETCH_DEVICE_PROFILES_OPTIONS_FAILURE =
  'deviceProfilesOptions/FETCH_FAILURE';

export const FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_REQUEST =
  'deviceProfileOptions/FETCH_REQUEST';
export const FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_SUCCESS =
  'deviceProfileOptions/FETCH_SUCCESS';
export const FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_FAILURE =
  'deviceProfileOptions/FETCH_FAILURE';

export const FETCH_DEVICE_PROFILE_ID_REQUEST =
  'deviceProfileOptions/FETCH_REQUEST';
export const FETCH_DEVICE_PROFILE_ID_SUCCESS =
  'deviceProfileOptions/FETCH_SUCCESS';
export const FETCH_DEVICE_PROFILE_ID_FAILURE =
  'deviceProfileOptions/FETCH_FAILURE';

export const FETCH_DEVICE_PROFILES_LIST_REQUEST =
  'deviceProfiles/FETCH_REQUEST';
export const FETCH_DEVICE_PROFILES_LIST_SUCCESS =
  'deviceProfiles/FETCH_SUCCESS';
export const FETCH_DEVICE_PROFILES_LIST_FAILURE =
  'deviceProfiles/FETCH_FAILURE';

export const DESTROY_DEVICE_PROFILES_OPTIONS_SUCCESS =
  'deviceProfilesOptions/DESTROY_SUCCESS';

// ACTION CREATORS
export function fetchDeviceProfilesOptions() {
  return {
    [CALL_API]: {
      endpoint: url.DeviceProfilesOptions(),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_DEVICE_PROFILES_OPTIONS_REQUEST,
        {
          type: FETCH_DEVICE_PROFILES_OPTIONS_SUCCESS,
          payload: (action, state, res) => res.json().then(json => json),
        },
        {
          type: FETCH_DEVICE_PROFILES_OPTIONS_FAILURE,
          payload: (action, state, res) => {
            history.push(`${PAGES_PATH}/devices`);
            return res.json().then(err => toastError(err));
          },
        },
      ],
    },
  };
}

export function fetchDeviceProfileOptionsById(deviceProfileId) {
  return {
    [CALL_API]: {
      endpoint: url.DeviceProfilesOptionsById(deviceProfileId),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
      types: [
        FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_REQUEST,
        {
          type: FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_SUCCESS,
          payload: (action, state, res) => res.json().then(json => json),
        },
        {
          type: FETCH_DEVICE_PROFILES_OPTIONS_BY_ID_FAILURE,
          payload: (action, state, res) =>
            res.json().then(err => toastError(err)),
        },
      ],
    },
  };
}

export const fetchDeviceProfileId = (
  macVersion,
  supports32bitFCnt,
  supportsClassB,
  supportsClassC,
) => ({
  [CALL_API]: {
    endpoint: url.DeviceProfileID(
      macVersion,
      supports32bitFCnt,
      supportsClassB,
      supportsClassC,
    ),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_PROFILE_ID_REQUEST,
      {
        type: FETCH_DEVICE_PROFILE_ID_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_PROFILE_ID_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const fetchDeviceProfileList = () => ({
  [CALL_API]: {
    endpoint: url.DeviceProfiles(),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_PROFILES_LIST_REQUEST,
      {
        type: FETCH_DEVICE_PROFILES_LIST_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_PROFILES_LIST_FAILURE,
        payload: (action, state, res) => res.json().then(err => err),
      },
    ],
  },
});

export const destroyDeviceProfilesOptions = () => ({
  type: DESTROY_DEVICE_PROFILES_OPTIONS_SUCCESS,
});
