/** @format */

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class DefaultButton extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { path, desc, icon, disabled, onClick } = this.props;
    return (
      <Button
        as={Link}
        compact
        disabled={disabled}
        icon
        color="red"
        labelPosition="left"
        to={path}
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={icon}
          className="icon"
          style={{ padding: '0.4em' }}
        />
        {desc}
      </Button>
    );
  }
}

DefaultButton.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  desc: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
