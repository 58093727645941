/** @format */

import * as actions from '../actions/accesstoken';

const defaultState = {
  isFetching: false,
  error: false,
};

export const accesstoken = (state = defaultState, action) => {
  switch (action.type) {
    // CREATE_ACCESS_TOKEN
    case actions.POST_ACCESS_TOKEN_REQUEST:
      return {
        isFetching: true,
        error: false,
      };
    case actions.POST_ACCESS_TOKEN_SUCCESS:
      return {
        ...action.payload,
        isFetching: false,
        error: false,
      };
    case actions.POST_ACCESS_TOKEN_FAILURE:
      return {
        isFetching: false,
        error: true,
      };
    // DELETE_ACCESS_TOKEN
    case actions.DELETE_ACCESS_TOKEN_REQUEST:
      return {
        isFetching: true,
        error: false,
      };
    case actions.DELETE_ACCESS_TOKEN_SUCCESS:
      return {
        ...action.payload,
        isFetching: false,
        error: false,
      };
    case actions.DELETE_ACCESS_TOKEN_FAILURE:
      return {
        isFetching: false,
        error: true,
      };
    case actions.DESTROY_ACCESS_TOKEN_SUCCESS:
      return {
        isFetching: false,
        error: false,
      };
    default:
      return state;
  }
};

export const accesstokens = (state = defaultState, action) => {
  switch (action.type) {
    // GET_ACCESS_TOKENS
    case actions.GET_ACCESS_TOKENS_REQUEST:
      return {
        isFetching: true,
        error: false,
      };
    case actions.GET_ACCESS_TOKENS_SUCCESS:
      return {
        ...action.payload,
        isFetching: false,
        error: false,
      };
    case actions.GET_ACCESS_TOKENS_FAILURE:
      return {
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
};
