/**
 * /* eslint-disable standard/object-curly-even-spacing
 *
 * @format
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Header, Input, Label, Modal } from 'semantic-ui-react';
import SVG from 'react-inlinesvg';
import idx from 'idx';
import PropTypes from 'prop-types';
import { userHasRole } from '../../lib/auth';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import DefaultTable from '../../components/Utils/DefaultTable';

import {
  destroyDevicesSharedBy,
  fetchDevicesSharedBy,
} from '../../actions/devices';
import { deleteDeviceShare } from '../../actions/deviceShare';

const mapStateToProps = state => ({
  formGroup: [
    {
      width: 3,
      label: 'Label',
      name: 'label',
      type: 'text',
    },
  ],
  devicesSharedBy: state.devicesSharedBy,
  applications: state.applications,
  column: state.devices.column,
  direction: state.devices.direction,
  users: state.users,
  usersOptions: state.usersOptions.options || [],
});

const mapDispatchToProps = dispatch => ({
  fetchDevicesSharedBy: (params = { qs: '' }) => {
    dispatch(fetchDevicesSharedBy(params));
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
  fetchUsers: (username, params = { qs: '' }) => {
    dispatch(fetchUsers(username, params));
  },
  deleteDeviceShare: (device, guest) => {
    dispatch(deleteDeviceShare(device, guest));
  },
  destroyDevicesSharedBy: () => {
    dispatch(destroyDevicesSharedBy());
  },
});

class DvTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [
        {
          width: 3,
          label: 'Label',
          name: 'label',
          type: 'text',
        },
      ],
      modalOpen: false,
      share: null,
      deveui: null,
      check: null,
      modalType: null,
      offset: 0,
      limit: 12,
      device: null,
      checkOwner: false,
      selected: [],
      owners: [],
      owner: null,
      devices: [],
      column: null,
      direction: null,
      search: '',
      sortModel: { label: '', direction: null },
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    const { limit } = this.state;

    this.props.fetchDevicesSharedBy({ qs: limit > 0 ? `limit=${limit}` : '' });
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
      this.props.fetchUsers({ qs: 'limit=10000' });
    }
  }

  // componentWillUnmount() {
  //   this.props.destroyDevicesSharedBy();
  // }

  fetch(qs) {
    this.props.fetchDevicesSharedBy(qs);
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      share: null,
      deveui: null,
      check: null,
    });
  }

  handleDeleteModal(share) {
    this.setState({
      modalOpen: true,
      share,
      deveui: share.device.deveui,
    });
  }

  handleDelete() {
    const { share } = this.state;
    this.props.deleteDeviceShare(share.id, share.GuestId);
    this.setState({
      modalOpen: false,
      share: null,
      deveui: null,
      check: null,
    });
    setTimeout(() => {
      this.props.fetchDevicesSharedBy();
    }, 1000);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { deveui, check, formGroup, share } = this.state;
    const { applications, users, usersOptions, devicesSharedBy } = this.props;
    return (
      <Fragment>
        <DefaultTable
          headers={[
            { sortable: true, name: 'Label', sortBy: 'label' },
            { name: 'Sharing' },
            { name: 'DevEui' },
            { name: 'Dev Address' },
            { name: 'Type' },
            // { name: 'Action' },
          ]}
          path={'device'}
          users={users}
          typeOfSharing={'By'}
          tableType={'sharing'}
          applications={applications}
          formGroup={formGroup}
          usersOptions={usersOptions}
          handleDeleteModal={this.handleDeleteModal}
          fetch={this.fetch}
          items={devicesSharedBy}
        />
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will remove a share for{' '}
              {idx(share, _ => _.share.guest.username)}
            </h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the application label</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {deveui}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={deveui !== check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

DvTable.propTypes = {
  devicesSharedBy: PropTypes.object,
  fetchDevicesSharedBy: PropTypes.func,
  destroyDevicesSharedBy: PropTypes.func,
  destroyDeviceShare: PropTypes.func,
  deleteDeviceShare: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  fetchUsers: PropTypes.func,
  applications: PropTypes.object,
  users: PropTypes.object,
  usersOptions: PropTypes.array,
};

const DevicesTableSharedBy = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvTable),
);

export { DevicesTableSharedBy };
