/** @format */

import React, { Component, Fragment } from 'react';
// import { Divider, Header, Menu, Tab } from 'semantic-ui-react'
import Axios from 'axios';

import { Endpoints as url } from '../../lib/endpoints';
import devLog from '../../lib/devLog';

// @TODO: to move to a global
const BRANCH = process.env.ENV_COMMIT_REF_NAME || null;
const TAG = process.env.ENV_COMMIT_TAG || null;

class Informations extends Component {
  constructor() {
    super();

    this.state = {
      isUpdated: false,
      mgmtsrvV2: {
        name: null,
        commitSha: null,
        commitShortSha: null,
        branch: null,
        tag: null,
      },
      frontend: {
        branch: BRANCH,
        tag: TAG,
      },
    };
  }

  componentDidMount() {
    Axios.get(url.Informations(), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        this.setState({
          isUpdated: true,
          mgmtsrvV2: {
            ...res.data,
          },
        });
      })
      .catch(err => {
        devLog(err);
      })
      .finally(() => {});
  }

  render() {
    const { isUpdated, mgmtsrvV2, frontend } = this.state;
    return (
      <Fragment>
        {isUpdated && frontend.tag && (
          <div>
            <small>gui: {frontend.tag} </small>
          </div>
        )}
        {isUpdated && mgmtsrvV2.tag && (
          <div>
            <small>v2: {mgmtsrvV2.tag}</small>
          </div>
        )}
      </Fragment>
    );
  }
}

export { Informations };
