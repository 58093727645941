import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Battery = props => {
  const { value: batteryLevelRow } = props;
  const [batteryLevel, setBatteryLevel] = useState(null);
  const [batteryStatus, setBatteryStatus] = useState(null);
  const empty = '---'

  useEffect(() => {
    setBatteryLevel(batteryLevelRow);
    if (batteryLevel === 0) {
      setBatteryStatus('pluged on grid');
    } else if (batteryLevel > 0 && batteryLevel < 255) {
      setBatteryStatus(`${parseFloat(batteryLevel * 100 / 254).toFixed(2)} %`);
    } else {
      setBatteryStatus('n/a');
    }
  }, [batteryLevel]);

  return (
    <Fragment>
      {batteryStatus !== null ? batteryStatus : empty}
    </Fragment>
  )
}

Battery.propTypes = {
  value: PropTypes.number
};

export default Battery;