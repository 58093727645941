/* eslint-disable react/display-name */
/** @format */

import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Header, Label, Menu, Tab } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GatewayProfilesTable,
  GatewaysTable,
  GatewaysTableSharedBy,
  GatewaysTableSharedWith,
  ImportGatewaysTab,
} from '../../containers';
import { fetchGateways, fetchGatewaysSharedBy, fetchGatewaysSharedWith } from '../../actions/gateways';
import { fetchGatewayProfiles } from '../../actions/gatewayProfiles';
import { updateTab } from '../../actions/tabs';
import {
  userHasRole,
} from '../../lib/auth';

import './Gateways.css';

const Gateways = (props) =>  {
  const gateways = useSelector(state => state.gateways);
  const gatewaysSharedBy = useSelector(state => state.gatewaysSharedBy);
  const gatewaysSharedWith = useSelector(state => state.gatewaysSharedWith);
  const gatewayProfiles = useSelector(state => state.gatewayProfiles);
  const index = useSelector(state => state.tabs.gateways.index);
  const dispatch = useDispatch();

  useEffect(() => {
    if (gateways && !('size' in gateways)) dispatch(fetchGateways());
    if (gatewaysSharedBy && !('size' in gatewaysSharedBy)) dispatch(fetchGatewaysSharedBy());
    if (gatewaysSharedWith && !('count' in gatewaysSharedWith)) dispatch(fetchGatewaysSharedWith());
    if (gatewayProfiles && !('size' in gatewayProfiles)) dispatch(fetchGatewayProfiles());
  }, []);

  const handleTabChange = (e, {activeIndex}) => {
    e.preventDefault();
    dispatch(updateTab({gateways: { index: activeIndex || 0 }}));
  }

  const panes = [
    {
      menuItem:
        <Menu.Item key="myGateways">
          gateways list
          {gateways && ('size' in gateways) && <Label color={index == 0 ? 'teal' : 'grey'}>{ gateways.size }</Label>}
        </Menu.Item>,
      render: () => (
        <Tab.Pane>
          <GatewaysTable />
        </Tab.Pane>
      ),
    },
    {
      menuItem:
        <Menu.Item key="sharedWith">
          shared with
          {gatewaysSharedWith && ('count' in gatewaysSharedWith) && <Label color={index == 1 ? 'teal' : 'grey'}>{ gatewaysSharedWith.count }</Label>}
        </Menu.Item>,
      render: () => (
        <Tab.Pane>
          <GatewaysTableSharedWith />
        </Tab.Pane>
      ),
    },
    {
      menuItem:
        <Menu.Item key="sharedBy">
          shared by
          {gatewaysSharedBy && ('count' in gatewaysSharedBy) && <Label color={index == 2 ? 'teal' : 'grey'}>{ gatewaysSharedBy.count }</Label>}
        </Menu.Item>,
      render: () => (
        <Tab.Pane>
          <GatewaysTableSharedBy />
        </Tab.Pane>
      ),
    },
    {
      menuItem:
        <Menu.Item key="profiles">
          profiles
          {gatewayProfiles && ('size' in gatewayProfiles) && <Label color={index == 3 ? 'teal' : 'grey'}>{ gatewayProfiles.size }</Label>}
        </Menu.Item>,
      render: () => (
        <Tab.Pane>
          <GatewayProfilesTable />
        </Tab.Pane>
      ),
    },
  ];

  if (userHasRole('admin')) panes.push({
    displayName: 'import',
    menuItem: {
      key: 'import',
      icon: (
        <FontAwesomeIcon
          icon="file-csv"
          size="2x"
          style={{ marginRight: '5px', color: '#999' }}
        />
      ),
      content: <span>import</span>,
    },
    render: () => (
      <Tab.Pane>
        <ImportGatewaysTab />
      </Tab.Pane>
    ),
  });

  return (
    <Fragment>
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        Gateways
      </Header>
      <Divider clearing />

      <Tab
        menu={{ attached: true, tabular: true, className: "wrapped" }}
        panes={panes}
        activeIndex={index}
        onTabChange={handleTabChange}
      />
    </Fragment>
  );
}

export { Gateways };
