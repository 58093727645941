/**
 * /* eslint-disable no-console
 *
 * @format
 */

import React from 'react';
import {
  Icon,
  Grid
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

import {
  CSV_V2_R0_COOR_HEADER
} from '../../lib/importDevices';

import { generateCSV } from '../../lib/csv';

const ImportDevicesTemplateDownload = ({applicationVersion}) => {
  const csvHeader = CSV_V2_R0_COOR_HEADER;
  const csvData = generateCSV('devices', csvHeader);

  return (
    <>
      <Grid>
        <Grid.Row centered columns={1}>
          <Grid.Column textAlign='center'>
            <CSVLink
              data={csvData}
              headers={csvHeader}
              filename={`api-v2-import-template.csv`}
              className={`ui button`}
            >
              <Icon name="download" /> Download template
            </CSVLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      
    </>
  )
}

ImportDevicesTemplateDownload.propTypes = {
  applicationVersion: PropTypes.string
};

export { ImportDevicesTemplateDownload };