/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import idx from 'idx';
import PropTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';
import Cleave from 'cleave.js/react';
import {
  destroyMulticastGroup,
  postMulticastGroup,
} from '../../actions/multicastGroup';
import {
  formatBytes,
  coupleOfBytesLengths,
  generatePlaceholder,
  cleaveOptions,
  formatNormalString,
  toastError,
} from '../../lib/utilities';

const mapStateToProps = state => ({
  multicastGroup: state.multicastGroup,
});

const mapDispatchToProps = dispatch => ({
  postMulticastGroup: multicastGroup => {
    dispatch(postMulticastGroup(multicastGroup));
  },
  destroyMulticastGroup: () => {
    dispatch(destroyMulticastGroup());
  },
});

class MulticastGroupAddForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      multicastGroup: {
        UserId: this.props.UserId,
        supports32bitFCnt: true,
        devaddr: '',
        appSKey: '',
        nwkSKey: '',
      },
      errors: {
        fCnt: false,
      },
      maxValue: 2 ** 31,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChangeCleave = this.handleChangeCleave.bind(this);
  }

  componentWillUnmount() {
    this.props.destroyMulticastGroup();
  }

  handleChange(e, { name, value }) {
    if (name === 'fCnt' && value !== 0)
      this.setState({
        multicastGroup: { ...this.state.multicastGroup, fCnt: Number(value) },
      });
    if (name === 'fCnt' && value === 0)
      this.setState({
        multicastGroup: { ...this.state.multicastGroup, fCnt: 0 },
      });
    if (name === 'fCnt' && value === '')
      this.setState({
        multicastGroup: { ...this.state.multicastGroup, fCnt: 0 },
      });
    else {
      this.setState({
        multicastGroup: { ...this.state.multicastGroup, [name]: value },
      });
    }
  }

  handleChangeCleave(e) {
    this.setState({
      multicastGroup: {
        ...this.state.multicastGroup,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCheck(e, { name, checked }) {
    this.setState({
      ...this.state,
      multicastGroup: { ...this.state.multicastGroup, [name]: checked },
      maxValue: checked ? 2 ** 31 : 2 ** 15,
    });
  }

  handleSubmit(e) {
    const { parentHandleSidebar } = this.props;
    const { errors, multicastGroup } = this.state;
    const tempMulticastGroup = {
      ...multicastGroup,
      fCnt:
        multicastGroup.fCnt !== undefined
          ? parseInt(multicastGroup.fCnt, 10)
          : 0,
    };
    if (!errors.fCnt) {
      this.props.postMulticastGroup(tempMulticastGroup);
      setTimeout(() => {
        if (this.props.multicastGroup.error === false) {
          parentHandleSidebar(e);
        } else {
          toastError(this.props.multicastGroup.message);
        }
      }, 500);
    }
  }

  handleBlur({ target }) {
    const { maxValue } = this.state;
    Number(target.value) <= maxValue
      ? this.setState({ ...this.state, errors: { [target.name]: false } })
      : this.setState({ ...this.state, errors: { [target.name]: true } });
  }

  onDeviceBlur(event, bytesEnum) {
    const reducer = formatNormalString(
      event.target.value.replace(/^0+/g, ''),
      ':',
    );

    this.setState({
      multicastGroup: {
        ...this.state.multicastGroup,
        [event.target.name]: reducer.padStart(bytesEnum * 2, '0'),
      },
    });
  }

  render() {
    const { multicastGroup, errors } = this.state;
    return (
      <Form
        onSubmit={this.handleSubmit}
        inverted={this.props.inverted || false}
      >
        <Form.Input
          label="Label"
          name="label"
          value={idx(multicastGroup, _ => _.label) || ''}
          onChange={this.handleChange}
          required
        />

        <Form.Input width={16} label="Device Address">
          <Cleave
            required
            placeholder={generatePlaceholder(coupleOfBytesLengths.DEVADDR)}
            name="devaddr"
            value={formatBytes(multicastGroup.devaddr, null)}
            options={{
              ...cleaveOptions,
              blocks: new Array(coupleOfBytesLengths.DEVADDR).fill(2),
            }}
            onBlur={ev => this.onDeviceBlur(ev, coupleOfBytesLengths.DEVADDR)}
            onChange={this.handleChangeCleave}
          />
        </Form.Input>

        <Form.Input width={16} label="Application Session Key">
          <Cleave
            required
            placeholder={generatePlaceholder(coupleOfBytesLengths.APPSKEY)}
            name="appSKey"
            value={formatBytes(multicastGroup.appSKey, null)}
            options={{
              ...cleaveOptions,
              blocks: new Array(coupleOfBytesLengths.APPSKEY).fill(2),
            }}
            onBlur={ev => this.onDeviceBlur(ev, coupleOfBytesLengths.APPSKEY)}
            onChange={this.handleChangeCleave}
          />
        </Form.Input>

        <Form.Input width={16} label="Network Session Key">
          <Cleave
            required
            placeholder={generatePlaceholder(coupleOfBytesLengths.NWKSKEY)}
            name="nwkSKey"
            value={formatBytes(multicastGroup.nwkSKey, null)}
            options={{
              ...cleaveOptions,
              blocks: new Array(coupleOfBytesLengths.NWKSKEY).fill(2),
            }}
            onBlur={ev => this.onDeviceBlur(ev, coupleOfBytesLengths.NWKSKEY)}
            onChange={this.handleChangeCleave}
          />
        </Form.Input>

        <Form.Checkbox
          toggle
          label="Supports 32 bit Frame Counter Size"
          name="supports32bitFCnt"
          checked={idx(multicastGroup, _ => _.supports32bitFCnt) || ''}
          onChange={this.handleCheck}
        />

        <Form.Input
          label="Frame Counter"
          name="fCnt"
          value={idx(multicastGroup, _ => _.fCnt) || ''}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={errors.fCnt}
        />

        <Form.Button
          content="Save"
          type="submit"
          style={{ marginTop: '2em' }}
        />

        {Object.values(errors).includes(true) && (
          <Message negative>
            <Message.Header>Wrong "Frame Counter" value</Message.Header>
            <p>Please check the "Frame Counter" field</p>
          </Message>
        )}
      </Form>
    );
  }
}

MulticastGroupAddForm.propTypes = {
  UserId: PropTypes.number,
  inverted: PropTypes.bool,
  destroyMulticastGroup: PropTypes.func,
  postMulticastGroup: PropTypes.func,
  parentHandleSidebar: PropTypes.func,
  multicastGroup: PropTypes.object,
};

const MulticastGroupCreate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MulticastGroupAddForm);

export { MulticastGroupCreate };
