/** @format */
import React from 'react';
import PropTypes from 'prop-types';
import { Label, Popup, Progress } from 'semantic-ui-react';

const ProgressPercent = ({ status, total, success, error }) => (
  <Progress
    value={success + error}
    total={total}
    progress="percent"
    warning={status === 'pending'}
    success={status === 'completed'}
    error={status === 'error'}
    precision={0}
  />
);

ProgressPercent.propTypes = {
  status: PropTypes.string,
  total: PropTypes.number,
  success: PropTypes.number,
  error: PropTypes.number,
};

const RenderStatus = ({ status, message }) => {
  let color;

  if (status === 'pending') color = 'yellow';
  else if (status === 'completed') color = 'green';
  else if (status === 'error') color = 'red';
  else color = 'grey';

  return (
    <>
      {status && (
        <>
          {status === 'error' && message ? (
            <Popup
              content={message}
              trigger={<Label color={color}>{status}</Label>}
            />
          ) : (
            <Label color={color}>{status}</Label>
          )}
        </>
      )}
    </>
  );
};

RenderStatus.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
};

const MessageList = ({ message }) => {
  return (
    <>
      {message.split(';').map(m => {
        return (
          <Label color={'red'} key={m}>
            {m}
          </Label>
        );
      }) || '-'}
    </>
  );
};

MessageList.propTypes = {
  message: PropTypes.string,
};

export { MessageList, RenderStatus, ProgressPercent };
