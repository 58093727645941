/** @format */

import * as actions from '../actions/basicstationConfigurations';

const defaultState = {
  isLoading: false,
  error: false,
};

export const basicstationConfigurations = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_BASIC_STATION_CONFIGURATION_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_BASIC_STATION_CONFIGURATION_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_BASIC_STATION_CONFIGURATION_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    default:
      return state;
  }
};