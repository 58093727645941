/** @format */

import * as actions from '../actions/gatewayShare';

const defaultState = {
  isLoading: false,
  error: false,
};

export const gatewayShare = (state = defaultState, action) => {
  switch (action.type) {
    case actions.POST_GATEWAY_SHARE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.POST_GATEWAY_SHARE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.POST_GATEWAY_SHARE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DELETE_GATEWAY_SHARE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.DELETE_GATEWAY_SHARE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.DELETE_GATEWAY_SHARE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_GATEWAY_SHARE_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
