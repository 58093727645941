/** @format */

import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchUplinkDevEui,
  setUplinksAppIdFilter,
  setUplinksDevEuiFilter,
  setUplinksOwnerFilter,
  setUplinksPayloadFilter,
  setUplinksPortFilter,
  setUplinksTypeFilter,
  setUplinksSFFilter,
} from '../../../../actions/uplinks';
import {
  consoleTableOptionsSF,
  consoleTableOptionsType,
} from '../../../../utils/utilityConstants';
import { FilterSelect } from './FilterSelect';
import { DateTimeRange } from './DateTimeRange';

const initState = {
  filterOwner: '',
  filterPort: '',
  filterPayload: '',
};

export const Inputs = () => {
  const dispatch = useDispatch();
  const { filters, messages } = useSelector(store => store.uplinks);
  const [state, setState] = useState(initState);

  const handleOnSearchOwner = event =>
    setState({ ...state, filterOwner: event.target.value });

  const handleOnChangeOwner = newOptions =>
    dispatch(setUplinksOwnerFilter(newOptions));

  const tempOwners = messages
    .filter(message => message.user)
    .map(message => {
      return {
        key: message.id,
        text: message.user,
        value: message.user,
      };
    });

  const handleOnChangeSF = newOptions =>
    dispatch(setUplinksSFFilter(newOptions));

  const handleOnChangeType = newOptions =>
    dispatch(setUplinksTypeFilter(newOptions));

  const handleOnSearchPort = event =>
    setState({ ...state, filterPort: event.target.value });

  const handleOnChangePort = newOptions =>
    dispatch(setUplinksPortFilter(newOptions));

  const tempPort = messages
    .filter(message => message.port)
    .map(message => {
      return {
        key: message.id,
        text: message.port,
        value: message.port,
      };
    });

  const handleOnChangeAppId = newOptions =>
    dispatch(setUplinksAppIdFilter(newOptions));

  const tempAppId = messages
    .filter(message => message.appid)
    .map(device => ({
      key: device.id,
      text: device.appid,
      value: device.appid,
    }));

  let timer;
  const handleOnSearchDevEui = event => {
    const text = event.target.value;
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (text && text.length > 2) dispatch(fetchUplinkDevEui(text));
    }, 1500);
  };

  const handleOnChangeDevEui = newOptions =>
    dispatch(setUplinksDevEuiFilter(newOptions));

  const tempDevEui = filters.devEuis.records.map(device => ({
    key: device.id,
    text: device.deveui,
    value: device.deveui,
  }));

  const handleOnSearchPayload = event =>
    setState({ ...state, filterOwner: event.target.value });

  const handleOnChangePayload = newOptions =>
    dispatch(setUplinksPayloadFilter(newOptions));

  const tempPayload = messages
    .filter(item => item.message.payload)
    .map(message => {
      return {
        key: message.id,
        text: message.message.payload,
        value: message.message.payload,
      };
    });

  return (
    <Table.Row>
      <Table.HeaderCell>
        <DateTimeRange />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <FilterSelect
          placeHolder="Owner"
          values={filters.owners.map(owner => owner.text)}
          options={filterOptions(tempOwners, filters.owners, state.filterOwner)}
          onSearch={handleOnSearchOwner}
          onChange={handleOnChangeOwner}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>{''}</Table.HeaderCell>
      <Table.HeaderCell>
        <FilterSelect
          placeHolder="SF"
          values={filters.SFs.map(t => t.value)}
          options={consoleTableOptionsSF}
          onChange={handleOnChangeSF}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <FilterSelect
          placeHolder="type"
          values={filters.types.map(t => t.text)}
          options={consoleTableOptionsType}
          onChange={handleOnChangeType}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <FilterSelect
          placeHolder="Port"
          values={filters.ports.map(p => p.text)}
          options={filterOptions(tempPort, filters.ports, state.filterPort)}
          onSearch={handleOnSearchPort}
          onChange={handleOnChangePort}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <FilterSelect
          placeHolder="appid"
          values={filters.appIds.map(a => a.text)}
          options={filterOptions(tempAppId, filters.appIds)}
          onChange={handleOnChangeAppId}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <FilterSelect
          placeHolder="DevEui"
          values={filters.devEuis.selectedFilters.map(d => d.text)}
          options={filterOptions(tempDevEui, filters.devEuis.selectedFilters)}
          onSearch={handleOnSearchDevEui}
          onChange={handleOnChangeDevEui}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <FilterSelect
          placeHolder="Payload"
          values={filters.payload.map(p => p.text)}
          options={filterOptions(
            tempPayload,
            filters.payload,
            state.filterPayload,
          )}
          onSearch={handleOnSearchPayload}
          onChange={handleOnChangePayload}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>{''}</Table.HeaderCell>
    </Table.Row>
  );
};

const filterOptions = (valuesOpt, filtersOpt, stateOpt) => {
  const options = stateOpt
    ? [
        ...valuesOpt,
        ...filtersOpt,
        {
          key: stateOpt,
          text: stateOpt,
          value: stateOpt,
        },
      ]
    : [...valuesOpt, ...filtersOpt];

  const distinctOptions = options.reduce((acc, curr) => {
    if (acc.findIndex(a => a.value === curr.value) < 0) acc.push(curr);

    return acc;
  }, []);

  return distinctOptions;
};
