/** @format */

import React from 'react';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button } from 'semantic-ui-react';
import { setUplinksOrderByFilter } from '../../../../actions/uplinks';
import { useDispatch, useSelector } from 'react-redux';

export const Header = () => {
  const dispatch = useDispatch();
  const isDescending = useSelector(
    store => store.uplinks.filters.orderBy === 'desc',
  );

  const listButtonIcon = isDescending ? faArrowDown : faArrowUp;

  const handleSortedList = () =>
    dispatch(setUplinksOrderByFilter(isDescending ? 'asc' : 'desc'));

  return (
    <Table.Row>
      <Table.HeaderCell>
        Time
        <Button
          icon
          size="mini"
          onClick={handleSortedList}
          style={{ backgroundColor: 'transparent' }}
        >
          <FontAwesomeIcon icon={listButtonIcon} />
        </Button>
      </Table.HeaderCell>
      <Table.HeaderCell>Owner</Table.HeaderCell>
      <Table.HeaderCell>SQNO</Table.HeaderCell>
      <Table.HeaderCell>SF</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Port</Table.HeaderCell>
      <Table.HeaderCell>AppId</Table.HeaderCell>
      <Table.HeaderCell>DevEui</Table.HeaderCell>
      <Table.HeaderCell>Payload</Table.HeaderCell>
      <Table.HeaderCell>{''}</Table.HeaderCell>
    </Table.Row>
  );
};
