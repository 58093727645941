/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_USER_EMAIL_REQUEST = 'userEmail/FETCH_REQUEST';
export const FETCH_USER_EMAIL_SUCCESS = 'userEmail/FETCH_SUCCESS';
export const FETCH_USER_EMAIL_FAILURE = 'userEmail/FETCH_FAILURE';

export const FETCH_USER_REQUEST = 'user/FETCH_REQUEST';
export const FETCH_USER_SUCCESS = 'user/FETCH_SUCCESS';
export const FETCH_USER_FAILURE = 'user/FETCH_FAILURE';

export const FETCH_USER_BY_NAME_OR_EMAIL_REQUEST = 'user/FETCH_REQUEST';
export const FETCH_USER_BY_NAME_OR_EMAIL_SUCCESS = 'user/FETCH_SUCCESS';
export const FETCH_USER_BY_NAME_OR_EMAIL_FAILURE = 'user/FETCH_FAILURE';

export const FETCH_USER_BY_TOKEN_REQUEST = 'user/FETCH_REQUEST';
export const FETCH_USER_BY_TOKEN_SUCCESS = 'user/FETCH_SUCCESS';
export const FETCH_USER_BY_TOKEN_FAILURE = 'user/FETCH_FAILURE';

export const FETCH_USER_STATS_REQUEST = 'userStats/FETCH_REQUEST';
export const FETCH_USER_STATS_SUCCESS = 'userStats/FETCH_SUCCESS';
export const FETCH_USER_STATS_FAILURE = 'userStats/FETCH_FAILURE';

export const POST_USER_REQUEST = 'user/POST_REQUEST';
export const POST_USER_SUCCESS = 'user/POST_SUCCESS';
export const POST_USER_FAILURE = 'user/POST_FAILURE';

export const PUT_USER_REQUEST = 'user/PUT_REQUEST';
export const PUT_USER_SUCCESS = 'user/PUT_SUCCESS';
export const PUT_USER_FAILURE = 'user/PUT_FAILURE';

export const DELETE_USER_REQUEST = 'user/DELETE_REQUEST';
export const DELETE_USER_SUCCESS = 'user/DELETE_SUCCESS';
export const DELETE_USER_FAILURE = 'user/DELETE_FAILURE';

export const DESTROY_USER_SUCCESS = 'user/DESTROY_SUCCESS';

export const DESTROY_USER_STATS_SUCCESS = 'userStats/DESTROY_SUCCESS';

export const USER_CHANGE_FORGET_PASSWORD_REQUEST =
  'password/CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_FORGET_PASSWORD_SUCCESS =
  'password/CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_FORGET_PASSWORD_FAILURE =
  'password/CHANGE_PASSWORD_FAILURE';

// ACTION CREATORS
export const fetchUserEmail = mail => ({
  [CALL_API]: {
    endpoint: url.UserEmail(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify({ mail }),
    types: [
      FETCH_USER_EMAIL_REQUEST,
      {
        type: FETCH_USER_EMAIL_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_USER_EMAIL_FAILURE,
        payload: (action, state, res) => {
          history.push('/');
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchUser = user => ({
  [CALL_API]: {
    endpoint: url.User(user || ''),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_USER_REQUEST,
      {
        type: FETCH_USER_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_USER_FAILURE,
        payload: (action, state, res) => {
          history.push('/');
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const postUserForgetPassword = user => ({
  [CALL_API]: {
    endpoint: url.UserForgetPassword(),
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    body: JSON.stringify(user),
    types: [
      FETCH_USER_BY_NAME_OR_EMAIL_REQUEST,
      {
        type: FETCH_USER_BY_NAME_OR_EMAIL_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_USER_BY_NAME_OR_EMAIL_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const fetchUserByToken = token => ({
  [CALL_API]: {
    endpoint: url.UserByToken(),
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    body: JSON.stringify({ token }),
    types: [
      FETCH_USER_BY_TOKEN_REQUEST,
      {
        type: FETCH_USER_BY_TOKEN_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_USER_BY_TOKEN_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const fetchUserStats = user => ({
  [CALL_API]: {
    endpoint: url.UserStats(user),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_USER_STATS_REQUEST,
      {
        type: FETCH_USER_STATS_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_USER_STATS_FAILURE,
        payload: (action, state, res) => {
          history.push('/');
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const postUser = user => ({
  [CALL_API]: {
    endpoint: url.UserCreate(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(user),
    types: [
      POST_USER_REQUEST,
      {
        type: POST_USER_SUCCESS,
        payload: (action, state, res) => {
          const result = res.json().then(userres => {
            history.push({ pathname: `${PAGES_PATH}/user/${userres.id}` });
            return userres;
          });
          return result;
        },
      },
      {
        type: POST_USER_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/users`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const putUser = (user, id) => ({
  [CALL_API]: {
    endpoint: url.User(id),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(user),
    types: [
      PUT_USER_REQUEST,
      {
        type: PUT_USER_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/user/${id}` });
          return res.json().then(json => json);
        },
      },
      {
        type: PUT_USER_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const deleteUser = user => ({
  [CALL_API]: {
    endpoint: url.User(user),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_USER_REQUEST,
      {
        type: DELETE_USER_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/users` });
          return res.json().then(json => json);
        },
      },
      {
        type: DELETE_USER_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/users`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyUser = () => ({
  type: DESTROY_USER_SUCCESS,
});

export const destroyUserStats = () => ({
  type: DESTROY_USER_STATS_SUCCESS,
});

export const userChangePassword = (token, uid, password) => ({
  [CALL_API]: {
    endpoint: url.UserUpdatePassword(uid),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      password,
    }),
    types: [
      USER_CHANGE_FORGET_PASSWORD_REQUEST,
      {
        type: USER_CHANGE_FORGET_PASSWORD_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: USER_CHANGE_FORGET_PASSWORD_FAILURE,
        payload: (action, state, res) => res.json().then(err => err),
      },
    ],
  },
});
