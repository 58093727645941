/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import idx from 'idx';

import {
  destroyGatewayProfile,
  postGatewayProfile,
} from '../../actions/gatewayProfile';
import { fetchUsersOptions } from '../../actions/users';
import { fetchGatewayProfilesOptions } from '../../actions/gatewayProfiles';
import { getCurrentUserId, userHasRole } from '../../lib/auth';
import { formatOptions } from '../../lib/utilities';

/* eslint-disable */
const mapStateToProps = state => {
  return {
    gatewayProfile: state.gatewayProfile,
    users: state.users,
    usersOptions: state.usersOptions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postGatewayProfile: gatewayProfile => {
      dispatch(postGatewayProfile(gatewayProfile));
    },
    destroyGatewayProfile: () => {
      dispatch(destroyGatewayProfile());
    },
    fetchUsersOptions: () => {
      dispatch(fetchUsersOptions());
    },
    fetchGatewayProfilesOptions: (user, params = {}) => {
      dispatch(fetchGatewayProfilesOptions(user, params));
    },
  };
};

class GatewayProfileAddForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gatewayProfile: {
        UserId: this.props.UserId,
        supportsLbt: false,
        supports32bitFCnt: false,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchUserId = this.handleSearchUserId.bind(this);
    this.handleChangeUserId = this.handleChangeUserId.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { fetchUsersOptions, fetchGatewayProfilesOptions } = this.props;

    if (userHasRole('admin')) {
      fetchUsersOptions();
    } else {
      fetchGatewayProfilesOptions(getCurrentUserId());
    }
  }

  componentWillUnmount() {
    const { destroyGatewayProfile } = this.props;

    destroyGatewayProfile();
  }

  handleChange(e, { name, value }) {
    this.setState({
      gatewayProfile: { ...this.state.gatewayProfile, [name]: value },
    });
  }

  handleCheck(e, { name, checked }) {
    this.setState({
      gatewayProfile: { ...this.state.gatewayProfile, [name]: checked },
    });
  }

  handleSubmit(e) {
    const { gatewayProfile } = this.state;
    const {
      postGatewayProfile,
      destroyGatewayProfile,
      handleGatewayProfileSidebar,
      UserId,
    } = this.props;

    postGatewayProfile(gatewayProfile);
    destroyGatewayProfile();
    this.setState({
      gatewayProfile: {
        UserId: UserId,
      },
    });
    handleGatewayProfileSidebar(e);
  }

  handleSearchUserId(e, { searchQuery }) {
    const { fetchUsersOptions } = this.props;

    const params = { filter: { username: searchQuery } };
    fetchUsersOptions(params);
  }

  handleChangeUserId(e, { value }) {
    e.persist();

    this.setState({
      gatewayProfile: {
        ...this.state.gateway,
        UserId: value,
        username: e.target.textContent,
        GatewayProfileId: null,
      },
    });
  }

  render() {
    const { gatewayProfile } = this.state;
    const { users, usersOptions } = this.props;

    return (
      <Form
        onSubmit={this.handleSubmit}
        inverted={this.props.inverted || false}
      >
        <Form.Input
          label="Label"
          name="label"
          value={gatewayProfile.label || ''}
          onChange={this.handleChange}
        />

        <Form.Dropdown
          label="Band"
          name="band"
          value={gatewayProfile.band || ''}
          selection
          options={[
            { key: 'EU863-870', value: 'EU863-870', text: 'EU863-870' },
            { key: 'US902-928', value: 'US902-928', text: 'US902-928' },
            { key: 'AS920-925', value: 'AS920-925', text: 'AS920-925' },
          ]}
          onChange={this.handleChange}
        />

        <Form.Dropdown
          name="maxTxPower"
          label="Max Transmission Power"
          placeholder="choose one..."
          selection
          required
          options={[
            { key: 14, text: 14, value: 14 },
            { key: 27, text: 27, value: 27 },
          ]}
          value={gatewayProfile.maxTxPower}
          onChange={this.handleChange}
        />

        <Form.Checkbox
          toggle
          label="Supports LBT (Listen Before Talk)"
          name="supportsLbt"
          checked={gatewayProfile.supportsLbt}
          onChange={this.handleCheck}
        />

        <Form.Checkbox
          toggle
          label="Supports Class B [not available]"
          name="supportsClassB"
          checked={gatewayProfile.supportsClassB || false}
          onChange={this.handleCheck}
          disabled
        />

        {userHasRole('admin') && (
          <Form.Dropdown
            name="stdUserGateway"
            label="Standard User"
            placeholder="choose one..."
            selection
            required
            search
            options={
              idx(usersOptions, _ => formatOptions(_.options, 'Owner')) || []
            }
            value={idx(gatewayProfile, _ => _.UserId)}
            onChange={this.handleChangeUserId}
            onSearchChange={this.handleSearchUserId}
            disabled={idx(usersOptions, _ => _.isFetching)}
            loading={idx(usersOptions, _ => _.isFetching)}
          />
        )}

        <Form.Button
          content="Save"
          type="submit"
          style={{ marginTop: '2em' }}
        />
      </Form>
    );
  }
}

GatewayProfileAddForm.propTypes = {
  UserId: PropTypes.number,
  usersOptions: PropTypes.object,
  inverted: PropTypes.bool,
  destroyGatewayProfile: PropTypes.func,
  postGatewayProfile: PropTypes.func,
  handleGatewayProfileSidebar: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  fetchGatewayProfilesOptions: PropTypes.func,
};

const GatewayProfileCreate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GatewayProfileAddForm);

export { GatewayProfileCreate };
