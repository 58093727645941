/** @format */

import * as actions from '../actions/applicationShare';

const defaultState = {
  isLoading: false,
  error: false,
};

export const applicationShare = (state = defaultState, action) => {
  switch (action.type) {
    case actions.POST_APPLICATION_SHARE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.POST_APPLICATION_SHARE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.POST_APPLICATION_SHARE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DELETE_APPLICATION_SHARE_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.DELETE_APPLICATION_SHARE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.DELETE_APPLICATION_SHARE_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_APPLICATION_SHARE_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };
    default:
      return state;
  }
};
