/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Button, Card, Divider, Grid, Header, List, Label } from 'semantic-ui-react';
import { ApplicationDevicesMap, ApplicationDevicesTable, UtilityTopic } from '..';
import {
  destroyApplication,
  fetchApplication,
} from '../../actions/application';
import { fetchUser, destroyUser } from '../../actions/user'
import { userHasRole } from '../../lib/auth';
import { ListItem } from '../../components';
import { PAGES_PATH } from '../../lib/variables';
import _ from 'lodash';

/* eslint-disable */
const mapStateToProps = state => {
  return {
    user: state.user,
    application: state.application.application,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchApplication: application => {
      dispatch(fetchApplication(application));
    },
    destroyApplication: () => {
      dispatch(destroyApplication());
    },
    fetchUser: userId => {
      dispatch(fetchUser(userId))
    },
    destroyUser: () => {
      dispatch(destroyUser());
    }
  };
};

class Application extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.notify = this.notify.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { fetchApplication } = this.props;

    fetchApplication(this.props.match.params.aid);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchUser } = this.props;
    const { application, user } = this.props;
    const { application: applicationPrev, user: userPrev } = prevProps

    if (!_.isEqual(application, applicationPrev)) {
      const { UserId } = application;

      if (userHasRole('admin') && (!_.isEqual(user, userPrev) || !('id' in user))) fetchUser(UserId)
    }
  }

  componentWillUnmount() {
    const { destroyApplication, destroyUser } = this.props;

    destroyApplication();
    destroyUser();
  }

  notify() {
    toast({
      title: 'COPIED TO CLIPBOARD',
      color: 'teal',
      type: 'success',
      icon: 'copy',
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { application } = this.props;
    const { user } = this.props;
    return (
      <Fragment>
        {/* <SemanticToastContainer position="top-right" /> */}
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Application Info
        </Header>
        <Button
          as={Link}
          key="edit"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/application/${application.id}/edit`}
        >
          <FontAwesomeIcon
            icon="edit"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Edit
        </Button>

        <Button
          as={Link}
          key="addShare"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={{
            pathname: `${PAGES_PATH}/application/share`,
            state: {
              applications: [application.id],
              UserId: application.UserId,
            },
          }}
        >
          <FontAwesomeIcon
            icon="share-alt"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Share
        </Button>

        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/applications`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>

        <Divider clearing />
        <Grid columns={2} doubling style={{ marginTop: '5em' }}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Details</Card.Header>
                <Divider />
                <Card.Description>
                  <List verticalAlign="middle" divided selection>
                    {userHasRole('admin') && <ListItem
                      label="ApplicationId"
                      content={idx(application, _ => _.id) || 'no name'}
                      copy={true}
                    />}
                    <ListItem
                      label="appid"
                      content={idx(application, _ => _.appid) || 'no name'}
                      copy={true}
                    />  
                    <ListItem
                      label="label"
                      content={idx(application, _ => _.label) || 'no name'}
                      copy={true}
                    />
                    {idx(application, _ => _.apiVersion) && <ListItem
                      label="api version"
                      content={<Label size='mini'>v{idx(application, _ => _.apiVersion)}</Label>}
                      copy={false}
                    />}
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Map</Card.Header>
                <Divider />
                <Card.Description>
                  <ApplicationDevicesMap application={application} />
                </Card.Description>
              </Card.Content>
            </Card>
            {application && application.appid && <Card fluid>
              <Card.Content>
                <Card.Header>MQTT</Card.Header>
                <Divider />
                <Card.Description>
                  <UtilityTopic appid={application.appid} user={user.username} />
                </Card.Description>
              </Card.Content>
            </Card>}
          </Grid.Column>
        </Grid>
        <ApplicationDevicesTable />
      </Fragment>
    );
  }
}

Application.propTypes = {
  application: PropTypes.object,
  match: PropTypes.object,
  destroyApplication: PropTypes.func,
  fetchApplication: PropTypes.func,
};

const ApplicationRead = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Application),
);

export { ApplicationRead };
