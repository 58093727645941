/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { useThisQueryString } from '../lib/urls';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_GATEWAY_PROFILES_REQUEST = 'gatewayProfiles/FETCH_REQUEST';
export const FETCH_GATEWAY_PROFILES_SUCCESS = 'gatewayProfiles/FETCH_SUCCESS';
export const FETCH_GATEWAY_PROFILES_FAILURE = 'gatewayProfiles/FETCH_FAILURE';

export const FETCH_GATEWAY_PROFILES_OPTIONS_REQUEST =
  'gatewayProfilesOptions/FETCH_REQUEST';
export const FETCH_GATEWAY_PROFILES_OPTIONS_SUCCESS =
  'gatewayProfilesOptions/FETCH_SUCCESS';
export const FETCH_GATEWAY_PROFILES_OPTIONS_FAILURE =
  'gatewayProfilesOptions/FETCH_FAILURE';

export const FETCH_GATEWAY_PROFILE_PROPERTIES_REQUEST =
  'gatewayProfileProperties/FETCH_REQUEST';
export const FETCH_GATEWAY_PROFILE_PROPERTIES_SUCCESS =
  'gatewayProfileProperties/FETCH_SUCCESS';
export const FETCH_GATEWAY_PROFILE_PROPERTIES_FAILURE =
  'gatewayProfileProperties/FETCH_FAILURE';

export const DESTROY_GATEWAY_PROFILES_SUCCESS =
  'gatewayProfiles/DESTROY_SUCCESS';

export const DESTROY_GATEWAY_PROFILES_OPTIONS_SUCCESS =
  'gatewayProfilesOptions/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchGatewayProfiles = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.GatewayProfiles(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_GATEWAY_PROFILES_REQUEST,
      {
        type: FETCH_GATEWAY_PROFILES_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_GATEWAY_PROFILES_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/gateways`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchGatewayProfilesOptions = user => ({
  [CALL_API]: {
    endpoint: url.GatewayProfilesOptions(user),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_GATEWAY_PROFILES_OPTIONS_REQUEST,
      {
        type: FETCH_GATEWAY_PROFILES_OPTIONS_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({ options: json })),
      },
      {
        type: FETCH_GATEWAY_PROFILES_OPTIONS_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/gateways`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchGatewayProfileProperties = () => ({
  [CALL_API]: {
    endpoint: url.GatewayProfileProperties(),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_GATEWAY_PROFILE_PROPERTIES_REQUEST,
      {
        type: FETCH_GATEWAY_PROFILE_PROPERTIES_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_GATEWAY_PROFILE_PROPERTIES_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/gateways`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyGatewayProfiles = () => ({
  type: DESTROY_GATEWAY_PROFILES_SUCCESS,
});
export const destroyGatewayProfilesOptions = () => ({
  type: DESTROY_GATEWAY_PROFILES_OPTIONS_SUCCESS,
});
