/** @format */

import { Placeholder } from 'semantic-ui-react';
import React from 'react';

const StatsPlaceholder = () => (
  <Placeholder fluid>
    <p style={{ textAlign: 'center', color: '#666666' }}> No data available </p>
    <Placeholder.Paragraph>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Paragraph>
  </Placeholder>
);

export default StatsPlaceholder;
