/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Icon, Input, Message } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';

import { destroyAccessToken, postAccessToken } from '../../actions/accesstoken';

// characters for generateString
const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const notify = description => {
  toast({
    title: 'COPIED TO CLIPBOARD',
    description,
    color: 'teal',
    type: 'success',
    icon: 'copy',
  });
};

/**
 * generateString
 * @param {*} length - length of the string
 * @returns random string
 * @description Generate random string
 */
const generateString = length => {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const AccessTokenCreate = ({ onCancel = () => {} }) => {
  // username from me reducer
  const { username } = useSelector(state => state.me);
  const accessTokenResponse = useSelector(state => state.accesstoken);

  // accessToken state
  const [accessToken, setAccessToken] = useState({
    label: '',
    expiresAt: moment().add(1, 'years'),
  });
  const [success, setSuccess] = useState(false);

  // dispatch
  const dispatch = useDispatch();

  // handle submit
  const handleSubmit = e => {
    const data = {
      label: accessToken.label,
      expiresAt: accessToken.expiresAt.format('YYYY-MM-DD').toString(),
    };

    dispatch(postAccessToken(data));
  };

  // handle change
  const handleChange = e => {
    setAccessToken({ ...accessToken, [e.target.name]: e.target.value });
  };

  const onCancelHandler = () => {
    onCancel();
  };

  // handle change expiresAt
  const handleChangeExpiresAt = (e, { name, value }) => {
    setAccessToken({
      ...accessToken,
      [name]: moment(value, 'DD-MM-YYYY'),
    });
  };

  // useEffect for set accessToken label
  useEffect(() => {
    // if username and accessToken label is empty
    if (username && accessToken.label === '') {
      setAccessToken({
        ...accessToken,
        label: `${username}_${generateString(20)}`, // generate random string with length 20 and add username
      });
    }
  }, [username]);

  useEffect(() => {
    if (accessTokenResponse && 'token' in accessTokenResponse) {
      setSuccess(true);
    }
  }, [accessTokenResponse]);

  useEffect(() => {
    return () => {
      dispatch(destroyAccessToken());
    };
  }, []);

  return (
    <>
      {accessTokenResponse && 'token' in accessTokenResponse && (
        <Message icon color="yellow">
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header>New access token created!</Message.Header>
            Your new access token is:{' '}
            <span
              className="list-item-id"
              onClick={() => notify(accessTokenResponse.token)}
            >
              <CopyToClipboard text={accessTokenResponse.token}>
                <span>{accessTokenResponse.token}</span>
              </CopyToClipboard>
            </span>
            <br />
            Remember to copy the new access token, it will no longer be possible
            recover its value.
          </Message.Content>
        </Message>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group unstackable widths={2}>
          <Form.Field
            label="Label"
            required
            control={Input}
            type="text"
            name="label"
            value={accessToken.label}
            onChange={handleChange}
            width={3}
            disabled={success}
          />
          <Form.Field
            label="Expires At"
            control={DateInput}
            name="expiresAt"
            minDate={moment()}
            value={accessToken.expiresAt.toString()}
            iconPosition="left"
            onChange={handleChangeExpiresAt}
            onClear={() =>
              setAccessToken({
                ...accessToken,
                expiresAt: moment().add(1, 'days'),
              })
            }
            clearable
            width={3}
            required
            disabled={success}
          />
        </Form.Group>
        <Form.Group>
          <Form.Button
            type="submit"
            disabled={
              !(accessToken.label !== '' && accessToken.expiresAt) || success
            }
          >
            Save
          </Form.Button>
          <Form.Button type="button" color="red" onClick={onCancelHandler}>
            {success ? 'Close' : 'Cancel'}
          </Form.Button>
        </Form.Group>
      </Form>
    </>
  );
};

AccessTokenCreate.propTypes = {
  onCancel: PropTypes.func,
};

export { AccessTokenCreate };
