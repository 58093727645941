/** @format */

import * as actions from '../actions/users';

const defaultState = {
  filter: {},
  sort: {},
  isFetching: false,
  error: false,
};

export const users = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_USERS_REQUEST:
      return {
        isFetching: true,
        error: false,
      };
    case actions.FETCH_USERS_SUCCESS:
      return {
        ...action.payload,
        isFetching: false,
        error: false,
      };
    case actions.FETCH_USERS_FAILURE:
      return {
        isFetching: false,
        error: true,
      };

    case actions.DESTROY_USERS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

const defaultOptions = {
  filter: {},
  sort: {},
  options: [],
  isFetching: false,
  error: false,
};

export const usersOptions = (state = defaultOptions, action) => {
  switch (action.type) {
    case actions.FETCH_USERS_OPTIONS_REQUEST:
      return {
        isFetching: true,
        error: false,
        options: [],
      };
    case actions.FETCH_USERS_OPTIONS_SUCCESS:
      return {
        ...action.payload,
        isFetching: false,
        error: false,
      };
    case actions.FETCH_USERS_OPTIONS_FAILURE:
      return {
        isFetching: false,
        error: true,
        options: [],
      };

    case actions.DESTROY_USERS_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        error: false,
        options: [],
      };

    default:
      return state;
  }
};
