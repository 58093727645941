/** @format */

import Axios from 'axios';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Card, Divider, List } from 'semantic-ui-react';
import { ListItem } from '../../components';
import { getCurrentUserToken } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  // gateway: state.gateway.data || {}
});

const mapDispatchToProps = dispatch => ({});

class _GatewayPackets extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    // The component custom status
    this.state = {
      pkt: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Update component status getting info from API REST
   */
  componentDidMount() {
    Axios.get(url.StatsPackets(this.props.match.params.gid), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res =>
        this.setState({
          ...this.state,
          pkt: {
            ...res.data,
          },
        }),
      )
      .catch(error => {
        devLog(error);
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.stat !== nextProps.stat) {
      return true;
    }

    return false;
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  /**
   * @param {string} code the packets description code
   * @returns {string} the related human-readable description
   */
  format = code => {
    if (code === 'rxpk') {
      return 'received packet';
    }
    if (code === 'txpk') {
      return 'transmitted packet';
    }

    let descrCode = parseInt(code, 16);
    let descr = descrCode.toString(16);
    if (descr.length === 1) {
      descr = `0${descr}`;
    }

    if (descr === code) {
      descrCode = Math.floor(parseInt(code, 16) / 32);
      switch (descrCode) {
        case 0:
          return 'join request';
        case 1:
          return 'join accept';
        case 2:
          return 'unconfirmed uplink';
        case 3:
          return 'unconfirmed downlink';
        case 4:
          return 'confirmed uplink';
        case 5:
          return 'confirmed downlink';
        case 6:
          return 'RFU';
        case 7:
          return 'proprietary';
        default:
      }
    } // no default needed if '---' is returned

    return '---';
  };

  /**
   * Provide JSX given the Packets information
   * @param {object} Packets the Packets object, e.g. {rows: Array(1), size: 1, offset: 0, limits: -1}
   * @returns {object} the related JSX
   */
  alertsList(pkt) {
    if (idx(pkt, _ => _.size) > 0) {
      const list = idx(pkt, _ => _.rows);
      devLog(JSON.stringify(list));
      return list.map(d => (
        <ListItem
          key={d.key}
          label={this.format(d.key)}
          content={d.doc_count}
          copy
        />
      ));
    }
    return <ListItem label="Packets" content="none" copy />;
  }

  render() {
    const { pkt } = this.state;

    return (
      <Card.Content>
        <Card.Header>Packets [last 6h]</Card.Header>
        <Divider />
        <Card.Description>
          <List verticalAlign="middle" divided selection>
            {this.alertsList(pkt)}
          </List>
        </Card.Description>
      </Card.Content>
    );
  }
}

_GatewayPackets.propTypes = {
  gateway: PropTypes.object,
  match: PropTypes.object,
  stat: PropTypes.object,
};

const GatewayPackets = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(_GatewayPackets),
);

export { GatewayPackets };
