/** @format */

import React, { useContext } from 'react';
import {
  Button,
  Card,
  Header,
  Popup,
  Container,
  List,
} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSave,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import './MacCard.css';
import { MacCommandCtx } from '../MacCommand';
import MacCardListItem from './MacCardListItem';
import MacCommandStatusIcon from '../MacCommandStatusIcon';
import PropTypes from 'prop-types';

const MacCard = props => {
  const { deviceNeverSend } = useContext(MacCommandCtx);
  const {
    title = 'Title',
    pendingMode = false,
    editMode = false,
    visible = true,
    state,
    startEdit,
    endEdit,
    togglePending,
  } = props;

  const displayStateLabel = () => (
    <>
      Status
      {state === 'pending' && !editMode && (
        <Popup
          content={`${pendingMode ? 'Current' : 'Pending'} Values`}
          trigger={
            <Button
              size={'mini'}
              icon
              disabled={!visible || deviceNeverSend}
              style={{ marginLeft: '0.4em' }}
              color={pendingMode ? 'grey' : null}
              onClick={() => togglePending()}
            >
              <FontAwesomeIcon
                icon={pendingMode ? faEyeSlash : faEye}
                className="icon"
              />
            </Button>
          }
        />
      )}
    </>
  );

  return (
    <Card
      fluid
      className={[
        'mac-card',
        visible ? '' : 'not-visible',
        pendingMode ? 'pending' : '',
      ].join(' ')}
    >
      <Card.Content className={'mac-card__header'}>
        <Card.Header content={title} />
      </Card.Content>
      <Card.Content>
        <Container textAlign="center" className={'mac-card__actions-container'}>
          {editMode ? (
            <>
              <Button
                compact
                icon
                color="red"
                labelPosition="left"
                onClick={e => endEdit()}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="icon"
                  style={{ padding: '0.4em' }}
                />
                Cancel
              </Button>

              <Button
                key="save"
                compact
                icon
                color="green"
                labelPosition="left"
                onClick={() => endEdit(true)}
              >
                <FontAwesomeIcon
                  icon={faSave}
                  className="icon"
                  style={{ padding: '0.4em' }}
                />
                Save
              </Button>
            </>
          ) : (
            <>
              {pendingMode ? (
                <Header.Subheader content={'Pending Values...'} />
              ) : (
                <Button
                  compact
                  icon
                  disabled={!visible || deviceNeverSend}
                  color="linkedin"
                  labelPosition="left"
                  onClick={() => startEdit()}
                >
                  <FontAwesomeIcon
                    icon={'edit'}
                    className="icon"
                    style={{ padding: '0.4em' }}
                  />
                  Edit
                </Button>
              )}
            </>
          )}
        </Container>

        <List divided verticalAlign="middle">
          <MacCardListItem
            label={displayStateLabel()}
            value={<MacCommandStatusIcon state={state} />}
            editable={false}
          />
          {props.children}
        </List>
      </Card.Content>
    </Card>
  );
};

MacCard.propTypes = {
  title: PropTypes.string,
  pendingMode: PropTypes.bool,
  editMode: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  visible: PropTypes.bool,
  startEdit: PropTypes.func,
  state: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endEdit: PropTypes.func,
  children: PropTypes.node,
  togglePending: PropTypes.func,
};

export default MacCard;
