/**
 * /* eslint-disable no-mixed-operators
 *
 * @format
 */

/* eslint-disable no-console */
import axios from 'axios/index';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Header, Input, Label, Modal } from 'semantic-ui-react';
import {
  destroyDevicesSharedWith,
  fetchDevicesSharedWith,
} from '../../actions/devices';
import { deleteDeviceShare } from '../../actions/deviceShare';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import DefaultTable from '../../components/Utils/DefaultTable';
import { getCurrentUserToken, userHasRole } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  devicesSharedWith: state.devicesSharedWith,
  applications: state.applications,
  column: state.devices.column,
  direction: state.devices.direction,
  users: state.users,
  usersOptions: state.usersOptions.options || [],
});

const mapDispatchToProps = dispatch => ({
  fetchDevicesSharedWith: (params = { qs: '' }) => {
    dispatch(fetchDevicesSharedWith(params));
  },
  deleteDeviceShare: (device, guest) => {
    dispatch(deleteDeviceShare(device, guest));
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
  fetchUsers: (username, params = { qs: '' }) => {
    dispatch(fetchUsers(username, params));
  },
  destroyDevicesSharedWith: () => {
    dispatch(destroyDevicesSharedWith());
  },
});

class DvTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [],
      share: null,
      fetched: false,
      devicesShared: null,
      applications: null,
      // sort: false
      offset: 0,
      limit: 12,
      modalOpen: false,
      modalType: null,
      device: null,
      deveui: null,
      check: null,
      checkOwner: false,
      selected: [],
      owners: [],
      owner: null,
      devices: [],
      column: null,
      direction: null,
      search: '',
      sortModel: {
        label: '',
        direction: null,
        /* asc || desc || null */
      },
      users: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.applicationSharedWith();
    const { limit } = this.state;

    this.props.fetchDevicesSharedWith({
      qs: limit > 0 ? `limit=${limit}` : '',
    });
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
      this.props.fetchUsers({ qs: 'limit=10000' });
    }
    this.manageVisibleFilters();
  }

  // componentWillUnmount() {
  //   // this.props.destroyDevicesSharedWith();
  // }

  manageVisibleFilters() {
    const nextFormGroup = [
      {
        width: 3,
        label: 'Label',
        name: 'label',
        type: 'text',
      },
    ];
    if (userHasRole('admin')) {
      nextFormGroup.push({
        width: 4,
        label: 'Shared With',
        name: 'guestid',
        type: 'select',
      });
    }
    this.setState({
      ...this.state,
      formGroup: nextFormGroup,
    });
  }

  fetch(qs) {
    this.props.fetchDevicesSharedWith(qs);
  }

  applicationSharedWith() {
    axios
      .get(url.Applications(), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCurrentUserToken(),
        },
      })
      .then(res => {
        this.setState({ applications: res.data });
      })
      .catch(err => devLog(err));
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      share: null,
      deveui: null,
      check: null,
    });
  }

  handleDeleteModal(share) {
    this.setState({
      modalOpen: true,
      share,
      deveui: share.device.deveui,
    });
  }

  handleDelete() {
    const { share, limit } = this.state;
    this.props.deleteDeviceShare(share.id, share.GuestId);
    this.setState({
      modalOpen: false,
      share: null,
      deveui: null,
      check: null,
    });
    setTimeout(() => {
      this.props.fetchDevicesSharedWith();
      this.props.fetchDevicesSharedWith({
        qs: limit > 0 ? `limit=${limit}` : '',
      });
    }, 300);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  findTheLabel(appid, userID) {
    return this.state.applications.rows.filter(
      element => element.appid === appid && element.UserId === userID,
    );
  }

  render() {
    const { deveui, check, formGroup, share } = this.state;
    const { applications, users, usersOptions, devicesSharedWith } = this.props;
    return (
      <Fragment>
        <DefaultTable
          headers={[
            { sortable: true, name: 'Label', sortBy: 'label' },
            { name: 'Sharing' },
            { name: 'Application' },
            { name: 'DevEui' },
            { name: 'Type' },
            { name: 'Action' },
          ]}
          path={'device'}
          users={users}
          typeOfSharing={'With'}
          tableType={'sharing'}
          applications={applications}
          handleCheck={this.handleCheck}
          handleDownlinkModal={this.handleDownlinkModal}
          formGroup={formGroup}
          usersOptions={usersOptions}
          handleDeleteModal={this.handleDeleteModal}
          fetch={this.fetch}
          items={devicesSharedWith}
        />

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will remove a share for{' '}
              {idx(share, _ => _.guest.username)}
            </h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the application label</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {deveui}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={deveui !== check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

DvTable.propTypes = {
  devicesSharedWith: PropTypes.object,
  fetchDevicesSharedWith: PropTypes.func,
  destroyDevicesSharedWith: PropTypes.func,
  destroyDeviceShare: PropTypes.func,
  deleteDeviceShare: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  fetchUsers: PropTypes.func,
  applications: PropTypes.object,
  users: PropTypes.object,
  usersOptions: PropTypes.array,
};

const DevicesTableSharedWith = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvTable),
);

export { DevicesTableSharedWith };
