/** @format */

import * as actions from '../actions/gatewayProfiles';

const defaultState = {
  data: {},
  isLoading: false,
  error: false,
};

export const gatewayProfiles = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_GATEWAY_PROFILES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.FETCH_GATEWAY_PROFILES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_GATEWAY_PROFILES_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      };
    case actions.FETCH_GATEWAY_PROFILE_PROPERTIES_REQUEST:
      return {
        ...state,
        data: {},
        isLoading: true,
        error: false,
      };
    case actions.FETCH_GATEWAY_PROFILE_PROPERTIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_GATEWAY_PROFILE_PROPERTIES_FAILURE:
      return {
        ...state,
        data: {},
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_GATEWAY_PROFILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};

const defaultOptions = {
  filter: {},
  sort: {},
  options: [],
  isFetching: false,
  error: false,
};

export const gatewayProfilesOptions = (state = defaultOptions, action) => {
  switch (action.type) {
    case actions.FETCH_GATEWAY_PROFILES_OPTIONS_REQUEST:
      return {
        isLoading: true,
        error: false,
        options: [],
      };
    case actions.FETCH_GATEWAY_PROFILES_OPTIONS_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_GATEWAY_PROFILES_OPTIONS_FAILURE:
      return {
        ...action.payload,
        options: [],
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_GATEWAY_PROFILES_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
