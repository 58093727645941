/** @format */

import React from 'react';
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  List,
} from 'semantic-ui-react';
import { Informations } from '..';

import './Footer.css';

const Footer = () => (
  <div className="footer" style={{ background: '#ffffff' }}>
    <Container>
      <Grid stackable columns={4}>
        <Grid.Row>
          <Grid.Column floated="left" textAlign="left">
            <Image.Group size="small">
              <Image src={'/assets/images/logoColor.png'} />
            </Image.Group>
          </Grid.Column>
          <Grid.Column floated="left">
            <List>
              <List.Item>
                <List.Header>BERGAMO</List.Header>
                Via Suardi, 26
              </List.Item>
              <List.Item>
                <List.Header>BRESCIA</List.Header>
                Via A. Lamarmora, 230
              </List.Item>
              <List.Item>
                <List.Header>CREMONA</List.Header>
                Via dell’Innovazione Digitale, 3
              </List.Item>
              <List.Item>
                <List.Header>MILANO</List.Header>
                Piazza Trento, 13
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column floated="left">
            <Header color="red" textAlign="left">
              CONTATTI
            </Header>
            <Divider hidden />
            <List>
              <List.Item
                icon="phone"
                content={<a href="tel:+390303554929">030 3554929</a>}
              />
              <List.Item
                icon="fax"
                content={<a href="tel:+390303554522">030 3554522</a>}
              />
              <List.Item
                icon="mail"
                content={
                  <a href="mailto:info@a2asmartcity.it">info@a2asmartcity.it</a>
                }
              />
              <List.Item
                icon="linkify"
                content={
                  <a href="http://www.a2asmartcity.it">
                    http://www.a2asmartcity.it
                  </a>
                }
              />
            </List>
            {/* <div className="phone">tel. <a href="tel:+390303554929">030 3554929</a></div>
              <div className="fax">fax. <a href="tel:+390303554522">030 3554522</a></div>
              <div className="mail"><a href="mailto:info@a2asmartcity.it">info@a2asmartcity.it</a></div> */}
          </Grid.Column>
          <Grid.Column floated="right">
            <Header color="red" textAlign="left">
              DOCUMENTI & PRIVACY
            </Header>
            <List>
              <List.Item
                content={
                  <a href="http://www.a2asmartcity.io/wp-content/uploads/2018/05/condizioni-generali-contratto-rev-05_2018-def.pdf">
                    Informativa sul trattamento dei dati personali
                  </a>
                }
              />
              <List.Item
                content={
                  <a href="http://www.a2asmartcity.io/informativa-privacy/">
                    Informativa privacy per il cliente finale
                  </a>
                }
              />
              <List.Item
                content={
                  <a href="http://www.a2asmartcity.io/wp-content/uploads/2016/10/carta_servizi.pdf">
                    Carta dei servizi
                  </a>
                }
              />
              <List.Item
                content={
                  <a href="http://www.a2asmartcity.io/wp-content/uploads/2016/09/Codice_etico.pdf">
                    Codice etico Gruppo A2A
                  </a>
                }
              />
              <List.Item
                content={
                  <a href="http://www.a2asmartcity.io/wp-content/uploads/2018/05/http://www.a2asmartcity.io/wp-content/uploads/2018/05/http://www.a2asmartcity.io/wp-content/uploads/2018/05/MOG-A2A-Smart-City-14-06-2017.pdf">
                    Abstract Modello 231/01
                  </a>
                }
              />
            </List>
            <div className="informations">
              <Informations />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </div>
);

export { Footer };
