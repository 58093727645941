/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Button, Card, Divider, Grid, Header, List } from 'semantic-ui-react';
import { MulticastGroupDevicesTable } from '..';
import {
  destroyMulticastGroup,
  fetchMulticastGroup,
} from '../../actions/multicastGroup';
import { ListItem } from '../../components';
import { formatKeys, checkPadding } from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';

const mapStateToProps = state => ({
  multicastGroup: state.multicastGroup,
});

const mapDispatchToProps = dispatch => ({
  fetchMulticastGroup: multicastGroup => {
    dispatch(fetchMulticastGroup(multicastGroup));
  },
  destroyMulticastGroup: () => {
    dispatch(destroyMulticastGroup());
  },
});

class MulticastGroup extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      multicastGroup: null,
      modalOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchMulticastGroup(this.props.match.params.mgid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.multicastGroup !== nextProps.multicastGroup) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.props.destroyMulticastGroup();
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { multicastGroup } = this.props;
    const mgid = `?mgid=${multicastGroup.id}`;
    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Multicast Group Info
        </Header>
        <Button
          as={Link}
          key="edit"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/multicast/${multicastGroup.id}/edit`}
        >
          <FontAwesomeIcon
            icon="edit"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Edit
        </Button>

        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={{ pathname: `${PAGES_PATH}/devices`, state: { activeIndex: 3 } }}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>

        <Divider clearing />
        <Grid columns={2} stackable style={{ marginTop: '5em' }}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Multicast Group Info</Card.Header>
                <Divider />
                <Card.Description>
                  <List verticalAlign="middle" divided selection>
                    <ListItem
                      label="label"
                      content={idx(multicastGroup, _ => _.label) || 'no name'}
                      copy={true}
                    />
                    <ListItem
                      shouldBeFormatted
                      label="device ADDRESS"
                      content={idx(multicastGroup, _ => formatKeys(checkPadding(_.devaddr, 8)))}
                      copy={true}
                    />
                    <ListItem
                      shouldBeFormatted
                      label="application session key"
                      content={idx(multicastGroup, _ => formatKeys(_.appSKey))}
                      copy={true}
                    />
                    <ListItem
                      shouldBeFormatted
                      label="network session key"
                      content={idx(multicastGroup, _ => formatKeys(_.nwkSKey))}
                      copy={true}
                    />
                    <ListItem
                      label="supports 32bit counter"
                      content={
                        idx(multicastGroup, _ => _.supports32bitFCnt)
                          ? 'yes'
                          : 'no'
                      }
                      copy={false}
                    />
                    <ListItem
                      label="frame count"
                      content={idx(multicastGroup, _ => _.fCnt)}
                      copy={true}
                    />
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Devices</Card.Header>
                <Divider />
                <MulticastGroupDevicesTable
                  multicastGroup={mgid}
                  isLimited
                  canUserDelete={false}
                />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

MulticastGroup.propTypes = {
  match: PropTypes.object,
  multicastGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fetchMulticastGroup: PropTypes.func,
  destroyMulticastGroup: PropTypes.func,
  deleteMulticastGroup: PropTypes.func,
};

const MulticastGroupRead = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MulticastGroup),
);

export { MulticastGroupRead };
