/** @format */

import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { MacCommandCtx } from '../MacCommand';
import MacCard from './MacCard';
import MacCardListItem from './MacCardListItem';
import { useMacCard } from './useMacCard';

/**
 * L’invio del comando si realizza tramite la chiamata PUT /api/v2/device/{{did}}/params/duty-cycle
 * HTTP/1.1 – { "maxDCycle": {{ maxDCycle }} }
 * La risposta sarà HTTP/1.1 200 OK – { "maxDCycle": {{ maxDCycle }} }
 *
 * HTTP/1.1 200 OK: i campi presenti potranno essere:
 * state: (sempre presente) che potrà prendere i valori “pending” (se in attesa di una risposta) o “sync”.
 * maxDCycle: (sempre presente) Max Duty Cycle
 * pendingValues: oggetto contenente il valore maxDCycle inviato al dispositivo.
 *
 * HTTP/1.1 403 Forbidden - { "status": 403, "message": "Information not available" };
 * quando il device non ha mai parlato. In questo caso la tabella avrà il campo maxDCycle valorizzato al 100%.
 * @returns {*}
 * @constructor
 */
const MacCardDutyCycle = () => {
  const { deviceID, deviceNeverSend } = useContext(MacCommandCtx);
  const title = 'Duty Cycle';
  const { param1 } = { param1: 'maxDCycle' };
  const cycleValues = [
    '100',
    '50',
    '25',
    '12,5',
    '6,25',
    '3,125',
    '1,562',
    '0,781',
    '0,390',
    '0,195',
    '0,097',
    '0,048',
    '0,024',
    '0,012',
    '0,006',
    '0,003',
  ];
  const options = cycleValues.map((x, i) => ({
    key: i + x,
    text: x,
    value: i,
  }));
  const neverSendData = {
    [param1]: 0,
  };
  const {
    editMode,
    pendingMode,
    togglePending,
    displayDataListParam,
    startEdit,
    changeEdit,
    endEdit,
    tempData,
    data,
  } = useMacCard(deviceID, 'dutyCycle', deviceNeverSend, neverSendData);

  return (
    <MacCard
      title={title}
      startEdit={() => startEdit()}
      editMode={editMode}
      endEdit={save => endEdit(save)}
      pendingMode={pendingMode}
      togglePending={() => togglePending()}
      state={data && data.state}
    >
      <MacCardListItem
        label={'Max Duty Cycle'}
        editMode={editMode}
        value={displayDataListParam(param1, cycleValues)}
      >
        {editMode && (
          <Dropdown
            fluid
            search
            selection
            name={param1}
            value={tempData[param1]}
            options={options}
            onChange={(e, selection) => changeEdit(selection)}
          />
        )}
      </MacCardListItem>
    </MacCard>
  );
};

export default MacCardDutyCycle;
