/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Container,
  Header,
  Input,
  Label,
  Loader,
  Modal,
  Pagination,
  Table,
  Grid,
} from 'semantic-ui-react';
import { deleteGateway, destroyGateway } from '../../actions/gateway';
import { destroyGateways, fetchGateways } from '../../actions/gateways';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import { getCurrentUserToken, userHasRole } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import {
  EMPTY_STRING_VALUE,
  EMPTY_TABLE_MESSAGE,
  formatKeys,
  formatNormalString,
  getProfileLabel,
  searchMethod,
} from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';
import SearchBox from '../../ui/SearchBox';
import devLog from '../../lib/devLog';
import './GatewaysTable.css';

const mapStateToProps = state => ({
  gateways: state.gateways,
  users: state.users.rows,
  usersOptions: state.usersOptions.options || [],
});

const mapDispatchToProps = dispatch => ({
  fetchGateways: (params = { qs: '' }) => {
    dispatch(fetchGateways(params));
  },
  fetchUsers: (params = { qs: '' }) => {
    dispatch(fetchUsers(params));
  },
  deleteGateway: (gateway, id) => {
    dispatch(deleteGateway(gateway, id));
  },
  destroyGateways: () => {
    dispatch(destroyGateways());
  },
  destroyGateway: () => {
    dispatch(destroyGateway());
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
});

class GwTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [
        {
          width: 3,
          label: 'Label',
          name: 'label',
          type: 'text',
        },
        {
          width: 3,
          label: 'Gateway ID',
          name: 'id',
          type: 'text',
        },
        {
          width: 2,
          label: 'Status',
          name: 'reachable',
          type: 'select',
        },
        {
          width: 2,
          label: 'Visibility',
          name: 'public',
          type: 'select',
        },
        {
          width: 3,
          label: 'IP',
          name: 'ip',
          type: 'text',
        },
      ],
      offset: 0,
      limit: 12,
      searchModel: {},
      sortModel: {
        label: '',
        direction: null /* asc || desc || null */,
      },
      modalOpen: false,
      gateway: null,
      gatewayId: null,
      check: null,
      selected: [],
      gateways: {},
      direction: null,
      column: null,
    };

    if (userHasRole('admin')) {
      this.state.formGroup.splice(1, 0, {
        width: 3,
        label: 'Owner',
        name: 'uid',
        type: 'select',
      });
    }

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.getUsersUsed = this.getUsersUsed.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams ? queryParams.join('&') : '';
        this.props.fetchGateways({ qs });
      },

      handlePageChange: (e, { activePage }) => {
        const { gateways, offset } = this.props;
        const { searchModel, sortModel, limit } = this.state;

        const newOffset = (activePage - 1) * gateways.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );

        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        let currSortModel = null;
        if (newSortModel) currSortModel = { ...newSortModel };
        else if (sortModel.label) currSortModel = { ...sortModel };

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        // debugger
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);
      },

      handleSearchChange: (e, { name, value }) => {
        const { searchModel } = this.state;
        const newFormGroup = [...this.state.formGroup];
        const newSearchModel = { ...searchModel };

        const formIdx = newFormGroup.map(x => x.name).indexOf(name);
        switch (value) {
          case '':
            delete newSearchModel[name];
            break;

          default:
            switch (name) {
              case 'id':
                newSearchModel[name] = encodeURIComponent(
                  formatNormalString(value, ':'),
                );
                break;
              default:
                newSearchModel[name] = encodeURIComponent(value);
            }
        }
        if (formIdx >= 0) newFormGroup[formIdx].selected = value;
        this.setState({
          searchModel: newSearchModel,
          formGroup: newFormGroup,
        });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;

            case 'desc':
              direction = null;
              break;

            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    const { limit } = this.state;

    Axios.get(url.GatewayProfilesParams('?limit=1000'), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(response => this.setState({ profiles: response.data.rows }))
      .catch(error => devLog(error));

    this.props.fetchGateways({ qs: limit > 0 ? `limit=${limit}` : '' });
    userHasRole('admin') &&
      this.props.fetchUsers({ qs: 'sort=username:asc&limit=10000' });
  }

  componentWillUnmount() {
    // this.props.destroyGateways();
    this.props.destroyGateway();
  }

  componentWillReceiveProps(nextProps) {
    nextProps.gateways && this.setState({ gateways: nextProps.gateways });
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      gateway: null,
      gatewayId: null,
      check: null,
    });
  }

  handleDeleteModal(gateway) {
    this.setState({ modalOpen: true, gateway, gatewayId: gateway.id });
  }

  handleDelete() {
    const { gateway } = this.state;
    this.props.deleteGateway(gateway, gateway.id);
    setTimeout(() => {
      this.props.fetchGateways({ qs: 'limit=12' });
    }, 1500);
    this.setState({
      modalOpen: false,
      gateway: null,
      gatewayId: null,
      check: null,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleCheck(value) {
    const { selected } = this.state;
    const i = selected.indexOf(value);

    if (i === -1) {
      selected.push(value);
    } else {
      selected.splice(i, 1);
    }

    this.setState({ selected });
  }

  getUsersUsed = (id, users) =>
    users.map(user => user.id === id && user.username);

  render() {
    const { selected, profiles, formGroup, gateways } = this.state;
    const { users, usersOptions } = this.props;

    return (
      <Fragment>
        <Grid.Row style={{ marginBottom: '2em' }}>
          <Grid.Column>
            <Button
              as={Link}
              key="addNew"
              compact
              icon
              color="red"
              labelPosition="left"
              to={`${PAGES_PATH}/gateway/add`}
            >
              <FontAwesomeIcon
                icon="plus-circle"
                className="icon"
                style={{ padding: '0.4em' }}
              />
              Add new Gateway
            </Button>

            <Button
              as={Link}
              key="addShare"
              disabled={selected.length === 0}
              compact
              icon
              color="red"
              labelPosition="left"
              to={{
                pathname: `${PAGES_PATH}/gateway/share`,
                state: { gateways: selected },
              }}
            >
              <FontAwesomeIcon
                icon="share-alt"
                className="icon"
                style={{ padding: '0.4em' }}
              />
              Share Gateway(s)
            </Button>
          </Grid.Column>
        </Grid.Row>
        {formGroup.length && (
          <SearchBox
            visibilityProps={['public', 'private']}
            formGroup={formGroup}
            usersOptions={usersOptions}
            startSearch={this.searchBox.handleSearch}
            updateSearch={this.searchBox.handleSearchChange}
          />
        )}

        <div className='div_full_with'>
          {idx(gateways.rows, _ => _.length) > 0 ? (
            <Table sortable celled={false}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="unsortable" />
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('label')}
                    onClick={this.searchBox.handleSort('label')}
                  >
                    Label
                  </Table.HeaderCell>
                  {userHasRole('admin') && (
                    <Table.HeaderCell
                      sorted={this.searchBox.handleSorted('UserId')}
                      onClick={this.searchBox.handleSort('UserId')}
                    >
                      Owner
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('GatewayProfileId')}
                    onClick={this.searchBox.handleSort('GatewayProfileId')}
                  >
                    Profile
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('id')}
                    onClick={this.searchBox.handleSort('id')}
                  >
                    Gateway ID
                  </Table.HeaderCell>
                  <Table.HeaderCell key="headerIP" className="unsortable">
                    IP
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted(
                      'supportsRemoteControl',
                    )}
                    onClick={this.searchBox.handleSort('supportsRemoteControl')}
                  >
                    RC
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('public')}
                    onClick={this.searchBox.handleSort('public')}
                  >
                    Visibility
                  </Table.HeaderCell>
                  <Table.HeaderCell key="headerStatus" className="unsortable">
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell className="unsortable">
                    Shared With
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    key="headerActions"
                    className="unsortable"
                    textAlign="center"
                  >
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {idx(gateways.rows, accessory =>
                  accessory.map(row => (
                    <Table.Row key={`row-${row.id}`}>
                      <Table.Cell key={`row-${row.id}-select`}>
                        <Checkbox
                          checked={selected.indexOf(row.id) !== -1}
                          onChange={() => this.handleCheck(row.id)}
                        />
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-label`}>
                        <Link
                          to={`${PAGES_PATH}/gateway/${idx(row, _ => _.id)}`}
                        >
                          <strong>{idx(row, _ => _.label) || 'no name'}</strong>
                        </Link>
                      </Table.Cell>
                      {userHasRole('admin') && (
                        <Table.Cell key={`row-${row.id}-owner`}>
                          <Link
                            to={`${PAGES_PATH}/user/${idx(row, _ => _.UserId)}`}
                          >
                            {idx(
                              row,
                              _ =>
                                users.map(u =>
                                  u.id === _.UserId ? u.username : null,
                                ) || EMPTY_STRING_VALUE,
                            )}
                          </Link>
                        </Table.Cell>
                      )}

                      <Table.Cell key={`row-${row.id}-profile`}>
                        {idx(row, _ =>
                          getProfileLabel(_.GatewayProfileId, profiles),
                        ) || EMPTY_STRING_VALUE}
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-gatewayId`}>
                        {idx(row, _ => formatKeys(_.id)) || EMPTY_STRING_VALUE}
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-ip`}>
                        {idx(row, _ => _.status.ip) || '---'}
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-rc`}>
                        {idx(row, _ => _.supportsRemoteControl) ? 'yes' : 'no'}
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-public`}>
                        {idx(row, _ => _.public) ? (
                          <Label size="mini" color="green">
                            Public
                          </Label>
                        ) : (
                          <Label size="mini" color="blue">
                            Private
                          </Label>
                        )}
                      </Table.Cell>

                      <Table.Cell key={`row-${row.id}-status`}>
                        {idx(row, _ => _.status.reachable) ? (
                          <Label size="mini" color="green">
                            UP
                          </Label>
                        ) : (
                          <Label size="mini" color="red">
                            DOWN
                          </Label>
                        )}
                      </Table.Cell>
                      <Table.Cell
                        key={`row-${row.id}-shares`}
                        style={{
                          textAlign: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        {row.shares.length > 0 ? (
                          <>
                            <FontAwesomeIcon
                              icon="share-alt"
                              style={{ marginRight: '2em' }}
                            />
                            {row.shares.length}
                          </>
                        ) : (
                          EMPTY_STRING_VALUE
                        )}
                      </Table.Cell>
                      <Table.Cell
                        key={`row-${row.id}-tools`}
                        textAlign="center"
                      >
                        <Link to={`${PAGES_PATH}/gateway/${row.id}/edit`}>
                          <FontAwesomeIcon icon="edit" />
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                        <Link
                          to="#"
                          onClick={() => this.handleDeleteModal(row)}
                        >
                          <FontAwesomeIcon icon="trash-alt" />
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  )),
                )}
              </Table.Body>
            </Table>
          ) : (
              (
                !idx(gateways, _ => _.isLoading) && EMPTY_TABLE_MESSAGE
              ) || 
              (
                idx(gateways, _ => _.isLoading) && 
                  <div className='div_full_with text_center'>
                    <Loader active inline content="Loading" />
                  </div>
              )
            )
          }
        </div>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will delete this gateway and all related shares.
            </h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the Gateway ID</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {this.state.gatewayId}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={this.state.gatewayId !== this.state.check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>

        <Container textAlign="center">
          {/*
              Pagination:
              SemanticUI  => https://react.semantic-ui.com/addons/pagination/
              Web Service => https://documenter.getpostman.com/view/7664580/SW11XeM5?version=8ed314ff-c1e3-48cf-b62e-0ba509032cb9#common
              */}
          {gateways && gateways.size > gateways.limit && (
            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              activePage={parseInt(gateways.offset / gateways.limit, 10) + 1}
              totalPages={Math.ceil(gateways.size / gateways.limit)}
              onPageChange={this.searchBox.handlePageChange}
            />
          )}
        </Container>
      </Fragment>
    );
  }
}

GwTable.propTypes = {
  gateways: PropTypes.object,
  fetchGateways: PropTypes.func,
  destroyGateways: PropTypes.func,
  destroyGateway: PropTypes.func,
  deleteGateway: PropTypes.func,
  offset: PropTypes.object,
  fetchUsers: PropTypes.func,
  users: PropTypes.array,
  usersOptions: PropTypes.array,
};

const GatewaysTable = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GwTable),
);

export { GatewaysTable };
