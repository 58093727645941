/** @format */

import 'react-semantic-toasts/styles/react-semantic-alert.css';

import {
  Button,
  Card,
  Divider,
  Grid,
  Header,
  Label,
  List,
} from 'semantic-ui-react';
import React, { Component, Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faTable } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import idx from 'idx';
import moment from 'moment';
import { withRouter } from 'react-router';

import Axios from 'axios';
import {
  getCurrentUserId,
  userHasRole,
  getCurrentUserToken,
} from '../../lib/auth';
import { destroyGateway, fetchGateway } from '../../actions/gateway';
import { fetchUser, destroyUser } from '../../actions/user'
import { ListItem, MapContainer } from '../../components';
import { ENABLE_RNC, ENABLE_POWEROFFHISTORY, GOOGLE_MAPS_API_KEY, PAGES_PATH, ENABLE_GATEWAY_STATS_INFO } from '../../lib/variables';
import { Endpoints as url } from '../../lib/endpoints';
import { EMPTY_STRING_VALUE, formatKeys } from '../../lib/utilities';

import { GatewayUptime } from './GatewayUptime';
import { GatewayAlerts } from './GatewayAlerts';
import { GatewayPackets } from './GatewayPackets';
import devLog from '../../lib/devLog';
import { BasicstationConfiguration, UtilityTopic } from '..';
import { isEqual } from 'lodash';

const mapStateToProps = state => ({
  user: state.user
  // gateway: state.gateway.data || {}
});

const mapDispatchToProps = dispatch => ({
  fetchGateway: (owner, gateway) => {
    dispatch(fetchGateway(owner, gateway));
  },
  destroyGateway: () => {
    dispatch(destroyGateway());
  },
  fetchUser: userId => {
    dispatch(fetchUser(userId))
  },
  destroyUser: () => {
    dispatch(destroyUser());
  }
});

class GatewayShow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    const { groups } = window.location.pathname.match(/\/dashboard\/gateway\/(?<gatewayId>[A-Fa-f0-9]{16})/i)

    this.state = {
      gatewayId: groups && groups.gatewayId ? groups.gatewayId : null,
      gateway: {},
      modalOpen: false,
      check: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    Axios.get(url.Gateway(this.props.match.params.gid), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(responseFromGatewayFetch =>
        Axios.get(
          url.GatewayProfile(responseFromGatewayFetch.data.GatewayProfileId),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getCurrentUserToken(),
            },
          },
        )
          .then(responseFromGatewayProfileFetch =>
            this.setState({
              ...this.state,
              gateway: {
                ...this.state.gateway,
                profile: { ...responseFromGatewayProfileFetch.data },
                ...responseFromGatewayFetch.data,
              },
            }),
          )
          .catch(error => devLog(error)),
      )
      .catch(error => devLog(error));
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-next-line no-shadow
    const { fetchUser } = this.props;
    const { user } = this.props;
    const { gateway = {} } = this.state

    const { gateway: gatewayPrev } = prevState
    const { user: userPrev } = prevProps

    if (!isEqual(gateway, gatewayPrev)) {
      const { UserId = null } = gateway;

      if (userHasRole('admin') && (!isEqual(user, userPrev) || !('id' in user))) fetchUser(UserId)
    }
  }

  componentWillUnmount() {
    this.props.destroyGateway();
    this.props.destroyUser();
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { gatewayId, gateway } = this.state;
    const { user } = this.props

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          {gateway.label || 'Gateway Details'} {gateway.public && '(public)'}
        </Header>

        {(userHasRole('admin') || gateway.UserId === getCurrentUserId()) && (
          <Button
            as={Link}
            key="edit"
            compact
            icon
            color="red"
            labelPosition="left"
            floated="right"
            style={{ marginTop: '2.4em' }}
            to={`${PAGES_PATH}/gateway/${gateway.id}/edit`}
          >
            <FontAwesomeIcon
              icon="edit"
              className="icon"
              style={{ padding: '0.4em' }}
            />
            Edit
          </Button>
        )}

        {idx(gateway, _ => _.id) && <Button
          as={Link}
          key="addShare"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={{
            pathname: `${PAGES_PATH}/gateway/share`,
            state: { gateways: [gateway.id], UserId: gateway.UserId },
          }}
        >
          <FontAwesomeIcon
            icon="share-alt"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Share
        </Button>}

        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/gateways`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>

        {ENABLE_RNC ?
          <Button
            as={Link}
            key="spectral-scann"
            compact
            icon
            color="red"
            labelPosition="left"
            floated="right"
            style={{ marginTop: '2.4em' }}
            to={`${PAGES_PATH}/gateway/${gatewayId}/spectral-scann`}
          >
            <FontAwesomeIcon
              icon={faChartBar} className="icon"
              style={{ padding: '0.4em' }}
            />
            Spectral scann
          </Button> : <span />
        }

        {ENABLE_POWEROFFHISTORY ?
          <Button
            as={Link}
            key="poweroff-history"
            compact
            icon
            color="red"
            labelPosition="left"
            floated="right"
            style={{ marginTop: '2.4em' }}
            to={`${PAGES_PATH}/gateway/${gatewayId}/poweroff-history`}
          >
            <FontAwesomeIcon
              icon={faTable} className="icon"
              style={{ padding: '0.4em' }}
            />
            Power failure
          </Button> : <span />
        }

        <Divider clearing />
        {gateway && gateway.id && <Fragment>
          {gateway.public ? (
            <Label color="green">Public Gateway</Label>
          ) : (
            <Label color="blue">Private Gateway</Label>
          )}
        </Fragment>}

        <Grid columns={2} doubling style={{ marginTop: '5em' }}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Network Info</Card.Header>
                <Divider />
                <Card.Description>
                  <List verticalAlign="middle" divided selection>
                    {idx(gateway, _ => _.id) && <ListItem
                      label="label"
                      content={idx(gateway, _ => _.label)}
                      copy
                    />}
                    <ListItem
                      label="gateway ID"
                      content={idx(gateway, _ => formatKeys(_.id || gatewayId, null))}
                      copy
                      shouldBeFormatted
                    />
                    {idx(gateway, _ => _.id) && <ListItem
                      label="IP address"
                      content={
                        idx(gateway, _ => _.status.ip) || EMPTY_STRING_VALUE
                      }
                      copy
                    />}
                    {idx(gateway, _ => _.id) && <ListItem
                      label="model"
                      content={idx(gateway, _ => _.model) || EMPTY_STRING_VALUE}
                      copy={false}
                    />}
                    {idx(gateway, _ => _.id) && <ListItem
                      label="serial"
                      content={
                        idx(gateway, _ => _.serial) || EMPTY_STRING_VALUE
                      }
                      copy={false}
                    />}
                    {idx(gateway, _ => _.id) && <ListItem
                      label="firmware"
                      content={
                        idx(gateway, _ => _.firmware) || EMPTY_STRING_VALUE
                      }
                      copy={false}
                    />}
                    {idx(gateway, _ => _.id) && <ListItem
                      label="PktFwd"
                      content={
                        idx(gateway, _ => _.status.pkfw) || EMPTY_STRING_VALUE
                      }
                      copy={false}
                    />}
                    {idx(gateway, _ => _.id) && <ListItem
                      label="HAL"
                      content={
                        idx(gateway, _ => _.status.hal) || EMPTY_STRING_VALUE
                      }
                      copy={false}
                    />}
                    {idx(gateway, _ => _.id) && <ListItem
                      label="configuraton"
                      content={
                        idx(gateway, _ => _.configuration.id) || EMPTY_STRING_VALUE
                      }
                      copy={!!gateway.configuration}
                    />}
                    {idx(gateway, _ => _.status) && <ListItem
                      label="last update"
                      content={
                        idx(gateway, _ => _.status.lastUpdateTime && `${moment(_.status.lastUpdateTime).utc().format('DD-MM-YYYY HH:mm:ss')} UTC`) || EMPTY_STRING_VALUE
                      }
                      text={idx(gateway, _ => _.status.lastUpdateTime)}
                      copy={!!gateway.status.lastUpdateTime}
                    />}
                  </List>
                </Card.Description>
              </Card.Content>
              {idx(gateway, _ => _.profile) && <Card.Content>
                <Card.Header>Profile</Card.Header>
                <Divider />
                <Card.Description>
                  <List verticalAlign="middle" divided selection>
                    <ListItem
                      label="label"
                      content={idx(gateway, _ => _.profile.label)}
                      copy
                    />
                    <ListItem
                      label="max transmission power"
                      content={idx(gateway, _ => _.profile.maxTxPower)}
                      copy
                    />
                    <ListItem
                      label="band"
                      content={idx(gateway, _ => _.profile.band)}
                      copy
                    />
                    <ListItem
                      label="supports Class B"
                      content={
                        idx(gateway, _ => _.profile.supportsClassB)
                          ? 'yes'
                          : 'no'
                      }
                      copy={false}
                    />
                    <ListItem
                      label="supports LBT"
                      content={
                        idx(gateway, _ => _.profile.supportsLbt) ? 'yes' : 'no'
                      }
                      copy={false}
                    />
                    <ListItem
                      label="supports remote control"
                      content={
                        idx(gateway, _ => _.supportsRemoteControl)
                          ? 'yes'
                          : 'no'
                      }
                      copy={false}
                    />
                  </List>
                </Card.Description>
              </Card.Content>}
              {idx(gateway, _ => _.supportsRemoteControl) && (
                <Card.Content>
                  <Card.Header>Remote control</Card.Header>
                  <Divider />
                  <Card.Description>
                    <List verticalAlign="middle" divided selection>
                      {idx(gateway, _ => _.supportsRemoteControl) && (
                        <ListItem
                          label="username"
                          content={idx(gateway, _ => _.rctrl.username)}
                          copy
                        />
                      )}
                      {idx(gateway, _ => _.supportsRemoteControl) && (
                        <ListItem
                          label="password"
                          content={idx(gateway, _ => _.rctrl.password)}
                          copy
                        />
                      )}
                    </List>
                  </Card.Description>
                </Card.Content>
              )}

              {ENABLE_GATEWAY_STATS_INFO && idx(gateway, _ => _.id) && <GatewayUptime />}
              {ENABLE_GATEWAY_STATS_INFO && idx(gateway, _ => _.id) && <GatewayAlerts />}
              {ENABLE_GATEWAY_STATS_INFO && idx(gateway, _ => _.id) && <GatewayPackets />}
            </Card>
          </Grid.Column>
          <Grid.Column>
            {idx(gateway, _ => _.position.lat) &&
              idx(gateway, _ => _.position.lng) ? (
              <Card fluid>
                <Card.Content>
                  <Card.Header>Map</Card.Header>
                  <Divider />
                  {GOOGLE_MAPS_API_KEY && <MapContainer
                    markers={[
                      { lat: gateway.position.lat, lng: gateway.position.lng },
                    ]}
                    isMarkerShown
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={<div style={{ height: '400px' }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                  />}
                  {!GOOGLE_MAPS_API_KEY && <iframe title='DevicePosition' src={`https://maps.google.com/maps?q=${gateway.position.lat},${gateway.position.lng}&z=16&output=embed`} height="450" width="100%" style={{ border: 0 }} />}

                </Card.Content>
              </Card>
            ) : (
              ''
            )}
            {gateway && gateway.id && <Card fluid>
              <Card.Content>
                <Card.Header>MQTT</Card.Header>
                <Divider />
                <Card.Description>
                  <UtilityTopic gatewayId={gateway.id} user={user.username} />
                </Card.Description>
              </Card.Content>
            </Card>}

            {gateway.configuration && <BasicstationConfiguration configuration={gateway.configuration} />}
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

GatewayShow.propTypes = {
  user: PropTypes.object,
  gateway: PropTypes.object,
  match: PropTypes.object,
  destroyGateway: PropTypes.func,
  fetchGateway: PropTypes.func,
  deleteGateway: PropTypes.func,
  fetchGateways: PropTypes.func,
  fetchUser: PropTypes.func,
  destroyUser: PropTypes.func,
};

const GatewayRead = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GatewayShow),
);

export { GatewayRead };
