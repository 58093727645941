/** @format */

import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import ReactGA from 'react-ga4';
import { PAGES_PATH, ID_MEASUREMENT } from '../lib/variables';
import {
  Authentication,
  Dashboard,
  Home,
  Maintenance,
  NotFound,
} from '../pages';
import { PrivateRoute } from '../components';
import history from '../history';
import './App.css';

import {
  faArrowRight,
  faArrowLeft,
  faCopy,
  faInfoCircle,
  faEdit,
  faEye,
  faEllipsisV,
  faGlasses,
  faList,
  faMapMarkerAlt,
  faPaperPlane,
  faPlusCircle,
  faSearch,
  faSignOutAlt,
  faUser,
  faShareAlt,
  faTimes,
  faTrashAlt,
  faSatelliteDish,
  faRocket,
  faTerminal,
  faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import { configureStore } from '../configureStore';

if (ID_MEASUREMENT) ReactGA.initialize(ID_MEASUREMENT);

library.add(
  faArrowRight,
  faArrowLeft,
  faCopy,
  faInfoCircle,
  faEdit,
  faEllipsisV,
  faEye,
  faGlasses,
  faList,
  faMapMarkerAlt,
  faPaperPlane,
  faPlusCircle,
  faSearch,
  faSignOutAlt,
  faUser,
  faShareAlt,
  faTimes,
  faTrashAlt,
  faSatelliteDish,
  faRocket,
  faTerminal,
  faFileCsv,
);

const store = configureStore();
const { REACT_APP_NODE_ENV } = process.env;

const App = () => (
  <Fragment>
    <div className={`${REACT_APP_NODE_ENV} strip-top`} />
    <div className={`main-content-top-${REACT_APP_NODE_ENV}`}>
      {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
        <Maintenance />
      ) : (
        <Provider store={store} data-test="component-app">
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/user" component={Authentication} />
              <Route
                exact
                path="/logout"
                render={() => <Redirect to={`/`} />}
              />
              <PrivateRoute path={`${PAGES_PATH}`} component={Dashboard} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </Provider>
      )}
    </div>
    <div className={`${REACT_APP_NODE_ENV} strip-bottom`} />
  </Fragment>
);

export default App;
