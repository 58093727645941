/** @format */

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Button, Card, Divider, Header, List } from 'semantic-ui-react';
import { destroyUserProfile } from '../../actions/userProfile';
import { fetchUserProfileSingle } from '../../actions/userProfileSingle';
import { ListItem } from '../../components';
import { getCurrentUserId, userHasRole } from '../../lib/auth';
import { PAGES_PATH } from '../../lib/variables';

const mapStateToProps = state => ({
  userProfileSingle: state.userProfileSingle,
});

const mapDispatchToProps = dispatch => ({
  fetchUserProfileSingle: userProfileSingle => {
    dispatch(fetchUserProfileSingle(userProfileSingle));
  },
  destroyUserProfile: () => {
    dispatch(destroyUserProfile());
  },
});

class UserProfileShow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.goBack = this.goBack.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let uid = null;

    if (this.props.match.params.uid) {
      // eslint-disable-next-line prefer-destructuring
      uid = this.props.match.params.uid;
    } else {
      uid = getCurrentUserId();
    }

    this.props.fetchUserProfileSingle(uid);
  }

  componentWillUnmount() {
    this.props.destroyUserProfile();
  }

  handleClose() {
    this.setState({ modalOpen: false, username: null, check: null });
  }

  handleDeleteModal() {
    this.setState({ modalOpen: true });
  }

  handleDelete = () => {
    // const { userProfileSingle, deleteUser } = this.props
    //
    // deleteUser(userProfileSingle.id)
    // this.setState({ modalOpen: false, check: null })
  };

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { userProfileSingle } = this.props;

    return (
      <Fragment>
        {/* <SemanticToastContainer position="top-right" /> */}
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          {idx(userProfileSingle, _ => _.label)}
        </Header>
        <Button
          as={Link}
          key="edit"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/user-profile/${userProfileSingle.id}/edit`}
        >
          <FontAwesomeIcon
            icon="edit"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Edit
        </Button>
        {userHasRole('admin') && (
          <Button
            as={Link}
            key="list"
            compact
            icon
            color="red"
            labelPosition="left"
            floated="right"
            style={{ marginTop: '2.4em' }}
            to={{ pathname: `${PAGES_PATH}/users`, state: 1 }}
          >
            <FontAwesomeIcon
              icon="list"
              className="icon"
              style={{ padding: '0.4em' }}
            />
            List
          </Button>
        )}
        <Button
          as={Link}
          key="back"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          onClick={this.goBack}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Back
        </Button>

        <Divider clearing />

        <Card fluid style={{ marginTop: '5em' }}>
          <Card.Content>
            <Card.Header>Profile</Card.Header>
            <Divider />
            <Card.Description>
              <List verticalAlign="middle" divided selection>
                <ListItem
                  label="ID"
                  content={`${idx(userProfileSingle, _ => _.id)}`}
                  copy={true}
                />
                <ListItem
                  label="profile"
                  content={idx(userProfileSingle, _ => _.label)}
                  copy={true}
                />
                <ListItem
                  label="role"
                  content={idx(userProfileSingle, _ => _.role)}
                  copy={true}
                />
                {!userHasRole('admin') && (
                  <Fragment>
                    <ListItem
                      label="max devices"
                      content={`${idx(userProfileSingle, _ => _.maxdevices)}`}
                      copy={true}
                    />
                    <ListItem
                      label="max gateways"
                      content={`${idx(userProfileSingle, _ => _.maxgateways)}`}
                      copy={true}
                    />
                    <ListItem
                      label="max multicast groups"
                      content={`${idx(userProfileSingle, _ => _.maxgroups)}`}
                      copy={true}
                    />
                    <ListItem
                      label="max uplink"
                      content={idx(userProfileSingle, _ => _.uplink)}
                      copy={true}
                    />
                    <ListItem
                      label="max downlink"
                      content={idx(userProfileSingle, _ => _.downlink)}
                      copy={true}
                    />
                    <ListItem
                      label="cache life"
                      content={idx(userProfileSingle, _ => _.cacheperiod)}
                      copy={true}
                    />
                    <ListItem
                      label="profile life"
                      content={idx(userProfileSingle, _ => _.life)}
                      copy={true}
                    />
                  </Fragment>
                )}
              </List>
            </Card.Description>
          </Card.Content>
        </Card>
      </Fragment>
    );
  }
}

UserProfileShow.propTypes = {
  userProfileSingle: PropTypes.object,
  match: PropTypes.object,
  fetchUserProfileSingle: PropTypes.func,
  history: PropTypes.object,
  // fetchUser: PropTypes.func,
  // deleteUser: PropTypes.func,
  destroyUserProfile: PropTypes.func,
};

const UserProfileRead = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfileShow),
);

export { UserProfileRead };
