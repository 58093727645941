/** @format */

import React, { useState, useEffect } from 'react';
import { ImportDevices, /* ImportDevicesTemplate, */ ImportDevicesTemplateDownload } from '../';
import { Container, Divider, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { userHasRole } from '../../lib/auth';
import { toastSuccess, toastError } from '../../lib/utilities';

const ImportDevicesTab = ({
  userId = null,
  users = {},
  applications = {},
  onSeachApplications,
  onSeachUsers,
  onReload,
}) => {
  const [application, setApplication] = useState(null);
  const [applicationVersion, setApplicationVersion] = useState(null);
  const [user, setUser] = useState(userId);
  const [hasUserId, setHasUserId] = useState(!userHasRole('admin') && userId);
  const isFetching = false;

  const generateOptions = (element, mapping = {}) => {
    return element.map(e => {
      const { key = 'id', text = 'label', value = 'id' } = mapping;
      return {
        key: e[key],
        text: `${e[text]} ${'apiVersion' in e && e.apiVersion != '2' ? `[v${e.apiVersion}]` : ''}`,
        value: e[value],
      };
    });
  };

  const handleChangeUser = (e, { value }) => {
    setHasUserId(true);
    setUser(value);
    setApplication(null);
    onSeachApplications(null, value);
  };

  const handleSearchChangeUsers = (e, { searchQuery }) => {
    onSeachUsers(searchQuery);
  };

  const handleChangeApplication = (e, { value }) => {
    setApplication(value);

    const { rows: apps = [] } = applications
    const { apiVersion = null } = apps.find(a => a.id === value);

    setApplicationVersion(apiVersion);
  };

  const handleSearchChangeApplications = (e, { searchQuery }) => {
    onSeachApplications(searchQuery, user);
  };

  useEffect(() => {
    return () => {
      setUser(null);
      setHasUserId(false);
      setApplication(null);
      onReload();
    };
  }, []);

  return (
    <>
      <Container>
        <Form>
          <Form.Group widths="equal">
            {userHasRole('admin') && (
              <Form.Dropdown
                fluid
                selection
                multiple={false}
                search={true}
                options={generateOptions(
                  'rows' in users && users.rows ? users.rows : [],
                  { key: 'id', text: 'username', value: 'id' },
                )}
                value={user}
                placeholder="Select user"
                onChange={handleChangeUser}
                onSearchChange={handleSearchChangeUsers}
                disabled={isFetching}
                loading={isFetching}
              />
            )}

            <Form.Dropdown
              fluid
              selection
              multiple={false}
              search={true}
              options={generateOptions(
                'rows' in applications && applications.rows
                  ? applications.rows
                  : [],
              )}
              value={application}
              placeholder="Select application"
              onChange={handleChangeApplication}
              onSearchChange={handleSearchChangeApplications}
              disabled={(isFetching || !hasUserId) && userHasRole('admin')}
              loading={isFetching}
            />
          </Form.Group>
        </Form>

        <Divider hidden />

        <ImportDevices
          ApplicationId={application}
          disabled={!application}
          onUploadFinished={response => {
            setUser(null);
            setHasUserId(false);
            setApplication(null);
            onSeachUsers();
            if (response instanceof Error) {
              const error = response;
              if (error.response) {
                toastError(error.response.data.message);
              }
            } else {
              toastSuccess(`${response.data.count} device have been imported`);
            }
          }}
        />
        <ImportDevicesTemplateDownload applicationVersion={applicationVersion} />
        {/* <ImportDevicesTemplate /> */}
      </Container>
    </>
  );
};

ImportDevicesTab.propTypes = {
  userId: PropTypes.number,
  users: PropTypes.object,
  applications: PropTypes.object,
  onSeachApplications: PropTypes.func,
  onSeachUsers: PropTypes.func,
  onReload: PropTypes.func,
};

export { ImportDevicesTab };
