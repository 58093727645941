/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import PropTypes from 'prop-types';
import mqttClient from 'mqtt';
import { updateUplinks } from '../../actions/uplinks';
import {
  getCurrentUserName,
  getCurrentUserMqttToken,
  userHasRole,
} from '../../lib/auth';
import {
  MQTT_CLIENT1_HOSTNAME,
  MQTT_CLIENT1_PORT,
  MQTT_CLIENT1_PROTOCOL,
  MQTT_TOPIC_PREFIX,
} from '../../lib/variables';

import {
  REGEX_ALERT,
  REGEX_EVENT,
  REGEX_UPLINK,
  REGEX_EVENT_GW,
  REGEX_ALERT_GW,
  REGEX_ALERT_USER,
} from '../../lib/regex';

import 'react-semantic-toasts/styles/react-semantic-alert.css';

const mapStateToProps = state => ({
  uplinks: state.uplinks,
});

const mapDispatchToProps = dispatch => ({
  updateUplinks: (message = {}) => {
    dispatch(updateUplinks(message));
  },
});

const showToast = message => {
  toast({ ...message });
};

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const extractJSON = message => {
  if (IsJsonString(message)) {
    return JSON.parse(message);
  } else {
    return { payload: message };
  }
};

class MqttClientWrapper extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.options = {
      port: parseInt(MQTT_CLIENT1_PORT, 10),
      hostname: MQTT_CLIENT1_HOSTNAME,
      username: getCurrentUserName()
        ? getCurrentUserName()
        : process.env.REACT_APP_MQTT_CLIENT1_DEFAULT_USERNAME,
      password: getCurrentUserMqttToken(),
      protocol: MQTT_CLIENT1_PROTOCOL,
      rejectUnauthorized: JSON.parse(
        process.env.REACT_APP_MQTT_CLIENT1_REJECTUNAUTHORIZED,
      ),
      secureProtocol: process.env.REACT_APP_MQTT_CLIENT1_SECUREPROTOCOL,
      protocolId: process.env.REACT_APP_MQTT_CLIENT1_PROTOCOLID,
      protocolVersion: parseInt(
        process.env.REACT_APP_MQTT_CLIENT1_PROTOCOLVERSION,
        10,
      ),
      keepalive: parseInt(process.env.REACT_APP_MQTT_CLIENT1_KEEPALIVE, 10),
      clean: JSON.parse(process.env.REACT_APP_MQTT_CLIENT1_CLEAN),
      clientId: `${
        getCurrentUserName()
          ? getCurrentUserName()
          : process.env.REACT_APP_MQTT_CLIENT1_DEFAULT_USERNAME
      }::${Math.random().toString(36).substr(2, 10)}`,
      path: process.env.REACT_APP_MQTT_CLIENT1_PATH,
    };

    this.id = 0;
  }

  render() {
    return (
      <Fragment>
        <div id="toast">
          <SemanticToastContainer />
        </div>
        {this.props.children}
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.uplinks.isSocketConnectionOpen !==
      prevProps.uplinks.isSocketConnectionOpen
    ) {
      if (this.props.uplinks.isSocketConnectionOpen) {
        this.client = mqttClient.connect(this.options);

        this.client.on('connect', () => {
          showToast({
            title: 'Connected',
            type: 'success',
            color: 'teal',
          });

          if (userHasRole('admin')) {
            this.client.subscribe(`${MQTT_TOPIC_PREFIX}/users/+/apps/+/devices/+/#`);
            this.client.subscribe(
              `${MQTT_TOPIC_PREFIX}/users/+/shared/+/apps/+/devices/+/#`,
            );
            this.client.subscribe(`${MQTT_TOPIC_PREFIX}/users/+/gateways/+/events/#`);
          } else {
            this.client.subscribe(
              `${MQTT_TOPIC_PREFIX}/users/${this.options.username}/apps/+/devices/+/#`,
            );
            this.client.subscribe(
              `${MQTT_TOPIC_PREFIX}/users/${this.options.username}/shared/+/apps/+/devices/+/#`,
            );
            this.client.subscribe(
              `${MQTT_TOPIC_PREFIX}/users/${this.options.username}/gateways/+/events/#`,
            );
          }
        });

        this.client.on('message', (topic, message) => {
          this.id += 1;
          let uplink = {
            id: this.id,
            topic,
            message: extractJSON(message.toString()),
            timestamp: new Date().getTime(),
          };

          if (REGEX_UPLINK.test(topic)) {
            const topicData = topic.match(REGEX_UPLINK);
            if ('groups' in topicData) {
              uplink = {
                ...uplink,
                ...topicData.groups,
              };
            }
          } else if (REGEX_EVENT.test(topic)) {
            const topicData = topic.match(REGEX_EVENT);
            if ('groups' in topicData) {
              uplink = {
                ...uplink,
                ...topicData.groups,
              };
            }
          } else if (REGEX_ALERT.test(topic)) {
            const topicData = topic.match(REGEX_ALERT);
            if ('groups' in topicData) {
              uplink = {
                ...uplink,
                ...topicData.groups,
              };
            }
          } else if (REGEX_EVENT_GW.test(topic)) {
            const topicData = topic.match(REGEX_EVENT_GW);
            if ('groups' in topicData) {
              uplink = {
                ...uplink,
                ...topicData.groups,
              };
            }
          } else if (REGEX_ALERT_GW.test(topic)) {
            const topicData = topic.match(REGEX_ALERT_GW);
            if ('groups' in topicData) {
              uplink = {
                ...uplink,
                ...topicData.groups,
              };
            }
          } else if (REGEX_ALERT_USER.test(topic)) {
            const topicData = topic.match(REGEX_ALERT_USER);
            if ('groups' in topicData) {
              uplink = {
                ...uplink,
                ...topicData.groups,
              };
            }
          }

          if (
            uplink &&
            'deveui' in uplink &&
            'type' in uplink &&
            uplink.type === 'uplink'
          ) {
            // showToast({
            //   title: [
            //     uplink && 'type' in uplink ? uplink.type.toString() : null,
            //     uplink && 'deveui' in uplink ? uplink.deveui.toString() : null,
            //   ]
            //     .filter(e => {
            //       if (e !== null) {
            //         return true;
            //       }
            //       return false;
            //     })
            //     .join(' - '),
            //   description:
            //     uplink && 'message' in uplink && 'payload' in uplink.message
            //       ? uplink.message.payload.toString()
            //       : '-',
            //   type: 'info',
            //   time: 3000,
            // });
          }

          this.props.updateUplinks(uplink);
        });

        this.client.on('packetreceive', packet => {});
      } else {
        if (this.client) {
          this.client.end();
          showToast({
            title: 'Disconnected',
            description: `You close the socket connection`,
            type: 'info',
            color: 'yellow',
          });
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.client) {
      this.client.end();
    }
  }
}

MqttClientWrapper.propTypes = {
  children: PropTypes.node,
  updateUplinks: PropTypes.func,
  uplinks: PropTypes.object,
  fetchUplinkDevEui: PropTypes.func,
};

const MqttClient = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MqttClientWrapper),
);

export { MqttClient };
