/** @format */

import {
  Button,
  Divider,
  Form,
  Header,
  Sidebar,
  Grid,
  Message,
} from 'semantic-ui-react';
import React, { Component, Fragment } from 'react';
import Cleave from 'cleave.js/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import idx from 'idx';
import { withRouter } from 'react-router';
import Axios from 'axios';
import { toast } from 'react-semantic-toasts';
import { Endpoints as url } from '../../lib/endpoints';
import {
  generateSimpleQueryV2,
  generateUrl,
  usersOptionsFilter,
} from '../../lib/urls';
import history from '../../history';
import {
  formatBytes,
  formatNormalString,
  isCoordinate,
  coupleOfBytesLengths,
  generatePlaceholder,
  formatOptions,
  clean,
  cleaveOptions,
} from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';
import {
  getCurrentUserId,
  userHasRole,
  getCurrentUserToken,
} from '../../lib/auth';
import {
  destroyGatewayProfilesOptions,
  fetchGatewayProfilesOptions,
  fetchGatewayProfileProperties,
} from '../../actions/gatewayProfiles';
import { destroyGateway, postGateway } from '../../actions/gateway';
import { fetchBasicstationConfigurations } from '../../actions/basicstationConfigurations'; 
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  gatewayProfilesOptions: state.gatewayProfilesOptions,
  gateway: state.gateway.data || {},
  gatewayProfile: state.gatewayProfile,
  gatewayProfileProperties: state.gatewayProfiles.data,
  basicstationConfigurations: state.basicstationConfigurations
});

const mapDispatchToProps = dispatch => ({
  fetchGatewayProfilesOptions: (user, params = {}) => {
    dispatch(fetchGatewayProfilesOptions(user, params));
  },
  fetchGatewayProfileProperties: () => {
    dispatch(fetchGatewayProfileProperties());
  },
  postGateway: gateway => {
    dispatch(postGateway(gateway));
  },
  destroyGatewayProfilesOptions: () => {
    dispatch(destroyGatewayProfilesOptions());
  },
  destroyGateway: () => {
    dispatch(destroyGateway());
  },
  fetchBasicstationConfigurations: () => {
    dispatch(fetchBasicstationConfigurations())
  }
});

class GatewayAddForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      gateway: {
        id: '',
        band: '',
        maxTxPower: '',
        supportsClassB: false,
        supportsLbt: false,
        GatewayProfileId: null,
        UserId: null,
        users: null,
        public: true,
        lat: '',
        alt: '',
        lng: '',
      },
      defaultFormWidth: 8,
      gatewayProfileSidebar: false,
      errors: {
        lat: false,
        lng: false,
        alt: false,
      },
    };

    this.handleGatewayProfileSidebar = this.handleGatewayProfileSidebar.bind(
      this,
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeUserId = this.handleChangeUserId.bind(this);
    this.handleSearchProfile = this.handleSearchProfile.bind(this);
    this.handleSearchUserId = this.handleSearchUserId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onDeviceBlur = this.onDeviceBlur.bind(this);
    this.handleChangeCleave = this.handleChangeCleave.bind(this);
  }

  componentDidMount() {
    const usersOptionFilterEndpoint = generateUrl(
      url.Users(),
      generateSimpleQueryV2(usersOptionsFilter()),
    );

    if (userHasRole('admin')) {
      Axios.get(usersOptionFilterEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCurrentUserToken(),
        },
      })
        .then(res => {
          const users = [];
          res.data.rows.map(d =>
            users.push({ key: d.id, text: d.username, value: d.id }),
          );
          this.setState({ users });
        })
        .catch(err => devLog(err));
    }

    Axios.get(url.GatewayProfile(1), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(responseFromGatewayProfileFetch =>
        this.setState({
          ...this.state,
          gateway: {
            ...responseFromGatewayProfileFetch.data,
            ...this.state.gateway,
            GatewayProfileId: responseFromGatewayProfileFetch.data.id,
          },
        }),
      )
      .catch(error => {
        toast({
          title: 'error',
          description: error.response.data.message || 'generic error',
          color: 'red',
          type: 'error',
          time: 4000,
        });
      });

    this.props.fetchGatewayProfileProperties();
    this.props.fetchBasicstationConfigurations();

    if (!userHasRole('admin')) {
      this.setState({
        ...this.state,
        gateway: {
          ...this.state.gateway,
          UserId: getCurrentUserId(),
        },
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }
    if (
      nextProps.gatewayProfilesOptions.options !==
      this.props.gatewayProfilesOptions.options
    ) {
      return true;
    }

    if (nextProps.gatewayProfile !== this.props.gatewayProfile) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { gateway } = this.state;

    if (this.state.gateway.UserId !== prevState.gateway.UserId) {
      this.props.fetchGatewayProfilesOptions(gateway.UserId);
    }
  }

  componentWillUnmount() {
    this.props.destroyGatewayProfilesOptions();
    this.props.destroyGateway();
  }

  // HANDLER SECTION
  handleGatewayProfileSidebar(e) {
    e.preventDefault();
    this.setState({ gatewayProfileSidebar: !this.state.gatewayProfileSidebar });
  }

  handleChange(e, { name, value }) {
    if (name === 'configuration') {
      this.setState({ gateway: { ...this.state.gateway, configuration:  value !== '' ? { id: value, data: {} } : null } });
    } else this.setState({ gateway: { ...this.state.gateway, [name]: value } });
  }

  handleChangeCleave(e) {
    this.setState({
      gateway: { ...this.state.gateway, [e.target.name]: e.target.value },
    });
  }

  handleChangeNumber(e, { name, value }) {
    this.setState({
      gateway: { ...this.state.gateway, [name]: Number(value) },
    });
  }

  handleCheck(e, { name, checked }) {
    this.setState({ gateway: { ...this.state.gateway, [name]: checked } });
  }

  handleChangeUserId(e, { value }) {
    e.persist();

    this.setState({
      gateway: {
        ...this.state.gateway,
        UserId: value,
        // GatewayProfileId: null
      },
    });
  }

  handleSearchUserId(e, { searchQuery }) {
    const { fetchUsersOptions } = this.props;

    const params = { filter: { username: searchQuery } };
    fetchUsersOptions(params);
  }

  handleSearchProfile(e, { searchQuery }) {
    const params = { filter: { label: searchQuery } };
    this.props.fetchGatewayProfilesOptions(this.state.gateway.UserId, params);
  }

  handleNewGatewayProfile(name, value) {
    this.props.fetchGatewayProfilesOptions(this.state.gateway.UserId);
    this.setState({ gateway: { ...this.state.gateway, [name]: value } });
  }

  handleSubmit(e) {
    const { gateway } = this.state;

    e.preventDefault();

    Axios.get(
      generateUrl(url.GatewayProfilesOptions(), {
        supportsClassB: gateway.supportsClassB,
        supportsLbt: gateway.supportsLbt,
        band: gateway.band,
        maxTxPower: gateway.maxTxPower,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCurrentUserToken(),
        },
      },
    )
      .then(
        response =>
          response.status === 200 &&
          this.setState(
            {
              ...this.state,
              gateway: {
                ...this.state.gateway,
                GatewayProfileId: response.data.rows[0].id,
              },
            },
            () => {
              let formattedGateway = {
                ...gateway,
                GatewayProfileId: response.data.rows[0].id,
              };

              delete formattedGateway.maxTxPower;
              delete formattedGateway.band;
              delete formattedGateway.supportsClassB;
              delete formattedGateway.supportsLbt;
              delete formattedGateway.createdAt;
              delete formattedGateway.updatedAt;
              !userHasRole('admin') && delete formattedGateway.UserId;

              formattedGateway = {
                ...formattedGateway,
                lat: Number(formattedGateway.lat),
                lng: Number(formattedGateway.lng),
                alt: Number(formattedGateway.alt),
              };

              Number.isNaN(formattedGateway.lat) && delete formattedGateway.lat;
              Number.isNaN(formattedGateway.lng) && delete formattedGateway.lng;
              Number.isNaN(formattedGateway.alt) && delete formattedGateway.alt;

              if (!formattedGateway.model) delete formattedGateway.model;
              if (!formattedGateway.serial) delete formattedGateway.serial;
              if (!formattedGateway.firmware) delete formattedGateway.firmware;

              // set configurationId
              if (formattedGateway.configuration) formattedGateway.configurationId = formattedGateway.configuration.id;
              else formattedGateway.configurationId = null;

              delete formattedGateway.configuration;

              Axios.post(
                url.GatewayCreate(),
                JSON.stringify(clean(formattedGateway)),
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: getCurrentUserToken(),
                  },
                },
              )
                .then(res => {
                  history.push({
                    pathname: `${PAGES_PATH}/gateway/${res.data.id}`,
                  });
                })
                .catch(error => {
                  toast({
                    title: 'error',
                    description: error.response.data.message || 'generic error',
                    color: 'red',
                    type: 'error',
                    time: 4000,
                  });
                });
            },
          ),
      )
      .catch(error => {
        toast({
          title: 'error',
          description: error.response.data.message || 'generic error',
          color: 'red',
          type: 'error',
          time: 4000,
        });
      });
  }

  validateField(ev) {
    if (ev.target.value === '') {
      return this.setState({
        errors: { ...this.state.errors, [ev.target.name]: false },
      });
    }
    return isCoordinate(ev.target.value, ev.target.name)
      ? this.setState({
          errors: { ...this.state.errors, [ev.target.name]: false },
        })
      : this.setState({
          errors: { ...this.state.errors, [ev.target.name]: true },
        });
  }

  onDeviceBlur(event, bytesEnum) {
    const reducer = formatNormalString(
      event.target.value.replace(/^0+/g, ''),
      ':',
    );

    this.setState({
      gateway: {
        ...this.state.gateway,
        [event.target.name]: reducer.padStart(bytesEnum * 2, '0'),
      },
    });
  }

  render() {
    const { gateway, errors, users, defaultFormWidth } = this.state;
    const { gatewayProfileProperties, basicstationConfigurations } = this.props;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Add Gateway
        </Header>
        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/gateways`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>
        <Divider clearing />
        <Sidebar.Pushable>
          <Sidebar.Pusher>
            <Form onSubmit={this.handleSubmit}>
              {userHasRole('admin') && (
                <Form.Dropdown
                  name="UserId"
                  label="Owner"
                  placeholder="choose one..."
                  width={defaultFormWidth}
                  search
                  selection
                  required
                  options={users !== undefined || users !== null ? users : []}
                  value={idx(gateway, _ => _.UserId)}
                  onChange={this.handleChangeUserId}
                  disabled={users === null}
                  loading={users === null}
                />
              )}

              <Form.Dropdown
                name="band"
                label="Band"
                placeholder="choose one..."
                width={defaultFormWidth}
                search
                selection
                required
                options={
                  idx(gatewayProfileProperties, _ =>
                    formatOptions(_.band, 'Band'),
                  ) || []
                }
                value={idx(gateway, _ => _.band)}
                onChange={this.handleChange}
                disabled={userHasRole('admin') && !idx(gateway, _ => _.UserId)}
              />

              <Form.Dropdown
                name="maxTxPower"
                label="Max Tx Power"
                placeholder="choose one..."
                width={defaultFormWidth}
                search
                selection
                required
                options={
                  idx(gatewayProfileProperties, _ =>
                    formatOptions(_.maxTxPower, 'Max Tx Power'),
                  ) || []
                }
                value={idx(gateway, _ => _.maxTxPower)}
                onChange={this.handleChange}
                disabled={userHasRole('admin') && !idx(gateway, _ => _.UserId)}
              />

              <Form.Checkbox
                toggle
                label="Supports Class B"
                name="supportsClassB"
                checked={gateway.supportsClassB}
                onChange={this.handleCheck}
                disabled={userHasRole('admin') && !idx(gateway, _ => _.UserId)}
              />

              <Form.Checkbox
                toggle
                label="Supports Lbt"
                name="supportsLbt"
                checked={gateway.supportsLbt}
                onChange={this.handleCheck}
                disabled={userHasRole('admin') && !idx(gateway, _ => _.UserId)}
              />

              <Form.Checkbox
                toggle
                label="Make this Gateway public"
                name="public"
                checked={idx(gateway, _ => _.public)}
                onChange={this.handleCheck}
                disabled={userHasRole('admin') && !gateway.UserId}
              />

              <Form.Dropdown
                name="configuration"
                label="Basic Station Configuration"
                placeholder="choose one..."
                width={defaultFormWidth}
                search
                selection
                clearable
                options={
                  idx(basicstationConfigurations.rows, _ => formatOptions(_, 'BasicstationConfigurations'),
                  ) || []
                }
                value={idx(gateway.configuration, _ => _.id)}
                onChange={this.handleChange}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Input
                width={defaultFormWidth}
                label="Label"
                name="label"
                required
                value={idx(gateway, _ => _.gateway.label)}
                onChange={this.handleChange}
                disabled={userHasRole('admin') && !gateway.UserId}
              />

              <Form.Input
                width={9}
                label="Gateway ID"
                required
                disabled={userHasRole('admin') && !idx(gateway, _ => _.UserId)}
              >
                <Cleave
                  placeholder={generatePlaceholder(
                    coupleOfBytesLengths.GATEWAYID,
                  )}
                  name="id"
                  value={formatBytes(gateway.id, null)}
                  options={{
                    ...cleaveOptions,
                    blocks: new Array(coupleOfBytesLengths.GATEWAYID).fill(2),
                  }}
                  onBlur={ev =>
                    this.onDeviceBlur(ev, coupleOfBytesLengths.GATEWAYID)
                  }
                  onChange={this.handleChangeCleave}
                />
              </Form.Input>

              <Header as="h4">Remote Control</Header>
              <Divider clearing />

              <Form.Checkbox
                toggle
                label="This gateway supports Remote Control?"
                name="supportsRemoteControl"
                checked={idx(gateway, _ => _.gateway.supportsRemoteControl)}
                onChange={this.handleCheck}
                disabled={userHasRole('admin') && !gateway.UserId}
              />

              {gateway && gateway.supportsRemoteControl === true && (
                <Fragment>
                  <Form.Input
                    width={defaultFormWidth}
                    label="Username"
                    name="rctrlUsername"
                    required
                    value={idx(gateway, _ => _.rctrlUsername)}
                    onChange={this.handleChange}
                    disabled={userHasRole('admin') && !gateway.UserId}
                  />
                  <Form.Input
                    width={defaultFormWidth}
                    label="Password"
                    name="rctrlPassword"
                    required
                    value={idx(gateway, _ => _.rctrlPassword)}
                    onChange={this.handleChange}
                    disabled={!gateway.UserId}
                  />
                </Fragment>
              )}

              <Header as="h4">Position</Header>
              <Divider clearing />

              <Grid padded columns={2}>
                <Grid.Column mobile={16} tablet={7} computer={7}>
                  <Form.Input
                    // width={ defaultFormWidth }
                    label="Latitude"
                    name="lat"
                    value={idx(gateway, _ => _.lat)}
                    onChange={this.handleChange}
                    onBlur={this.validateField}
                    disabled={userHasRole('admin') && !gateway.UserId}
                    error={errors.lat}
                  />

                  <Form.Input
                    // width={ defaultFormWidth }
                    label="Longitude"
                    name="lng"
                    value={idx(gateway, _ => _.lng)}
                    onBlur={this.validateField}
                    onChange={this.handleChange}
                    disabled={userHasRole('admin') && !gateway.UserId}
                    error={errors.lng}
                  />

                  <Form.Input
                    // width={ defaultFormWidth }
                    label="Altitude"
                    name="alt"
                    value={idx(gateway, _ => _.alt)}
                    onChange={this.handleChange}
                    disabled={userHasRole('admin') && !gateway.UserId}
                    error={errors.alt}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={7} computer={7}>
                  {Object.values(errors).includes(true) && (
                    <Message negative>
                      <Message.Header>Set all coordinates</Message.Header>
                      <p>Please check your coordinates</p>
                    </Message>
                  )}
                </Grid.Column>
              </Grid>

              <Header as="h4">Gateway Info</Header>
              <Divider clearing />

              <Form.Input
                width={defaultFormWidth}
                label="Model"
                name="model"
                value={idx(gateway, _ => _.model)}
                onChange={this.handleChange}
                disabled={userHasRole('admin') && !gateway.UserId}
              />

              <Form.Input
                width={defaultFormWidth}
                label="Serial"
                name="serial"
                value={idx(gateway, _ => _.serial)}
                onChange={this.handleChange}
                disabled={userHasRole('admin') && !gateway.UserId}
              />

              <Form.Input
                width={defaultFormWidth}
                label="Firmware"
                name="firmware"
                value={idx(gateway, _ => _.firmware)}
                onChange={this.handleChange}
                disabled={userHasRole('admin') && !gateway.UserId}
              />

              <Form.Button
                content="Save"
                type="submit"
                disabled={userHasRole('admin') && !gateway.UserId}
              />
            </Form>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    );
  }
}

GatewayAddForm.propTypes = {
  gatewayProfilesOptions: PropTypes.object,
  gatewayProfile: PropTypes.object,
  fetchGatewayProfilesOptions: PropTypes.func,
  destroyGatewayProfilesOptions: PropTypes.func,
  destroyGateway: PropTypes.func,
  postGateway: PropTypes.func,
  fetchGatewayProfileProperties: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  gatewayProfileProperties: PropTypes.object,
  basicstationConfigurations: PropTypes.object,
  fetchBasicstationConfigurations: PropTypes.func
};

const GatewayCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GatewayAddForm),
);

export { GatewayCreate };
