/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import idx from 'idx';
import PropTypes from 'prop-types';

import { MapContainer } from '../../components';
import {
  destroyMulticastGroupDevicesMap,
  fetchMulticastGroupDevicesMap,
} from '../../actions/multicastGroupDevices';
import { GOOGLE_MAPS_API_KEY } from '../../lib/variables';

const mapStateToProps = state => ({
  multicastGroupDevicesMap: state.multicastGroupDevicesMap,
});

const mapDispatchToProps = dispatch => ({
  fetchMulticastGroupDevicesMap: multicastGroup => {
    dispatch(fetchMulticastGroupDevicesMap(multicastGroup));
  },
  destroyMulticastGroupDevicesMap: () => {
    dispatch(destroyMulticastGroupDevicesMap());
  },
});

class DvMap extends Component {
  componentDidMount() {
    this.props.fetchMulticastGroupDevicesMap(this.props.match.params.mgid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (
      this.props.multicastGroupDevicesMap !== nextProps.multicastGroupDevicesMap
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.props.destroyMulticastGroupDevicesMap();
  }

  render() {
    const { multicastGroupDevicesMap } = this.props;

    return (
      <Fragment>
        {idx(multicastGroupDevicesMap, _ => _.coord) && (
          <MapContainer
            bounds={idx(multicastGroupDevicesMap, _ => _.boundingBox)}
            markers={idx(multicastGroupDevicesMap, _ => _.coord)}
            isMarkerShown
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        )}
      </Fragment>
    );
  }
}

DvMap.propTypes = {
  match: PropTypes.object,
  multicastGroupDevicesMap: PropTypes.object,
  fetchMulticastGroupDevicesMap: PropTypes.func,
  destroyMulticastGroupDevicesMap: PropTypes.func,
};

const MulticastGroupDevicesMap = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvMap),
);

export { MulticastGroupDevicesMap };
