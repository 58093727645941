/** @format */

import React, { Component } from 'react';
import { Card, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { userHasRole } from '../../lib/auth';
import { axiosCall } from './axiosService';
import StatsPlaceholder from './StatPLaceholder';

class Top extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      rows: [],
      error: false,
    };
  }

  componentDidMount() {
    axiosCall(this.props.url, 'get')
      .then(res => {
        const { rows } = res.data;
        this.setState({ rows });
      })
      .catch(() => {
        this.setState({ error: true });
        this.props.errorhandler();
      });
  }

  getHeaders = title => {
    if (title === 'Top 10 of Users by Upstream Messages [last 24h]') {
      return (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      );
    }
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Device EUI</Table.HeaderCell>
          <Table.HeaderCell>Application ID</Table.HeaderCell>
          {userHasRole('admin') ? (
            <Table.HeaderCell>User</Table.HeaderCell>
          ) : (
            ''
          )}
          <Table.HeaderCell>Count</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  };

  getRows = (title, rows) => {
    if (title === 'Top 10 of Users by Upstream Messages [last 24h]') {
      return rows.map(d => (
        <Table.Row key={d.key}>
          <Table.Cell>{d.key}</Table.Cell>
          <Table.Cell>{d.doc_count}</Table.Cell>
        </Table.Row>
      ));
    }
    return rows.map(d => (
      <Table.Row key={d.device}>
        <Table.Cell>{d.device !== undefined ? d.device : ''}</Table.Cell>
        <Table.Cell>{d.appid !== undefined ? d.appid : ''}</Table.Cell>
        {userHasRole('admin') ? (
          <Table.Cell>{d.user !== undefined ? d.user : ''}</Table.Cell>
        ) : (
          ''
        )}
        <Table.Cell>{d.count !== undefined ? d.count : ''}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    const { title } = this.props;
    const { error, rows } = this.state;
    return (
      <Card fluid>
        {error !== true ? (
          <Card.Content>
            <Card.Header>{title}</Card.Header>
            <Table unstackable basic="very">
              {this.getHeaders(title)}
              {rows.length > 0 ? (
                <Table.Body>{this.getRows(title, rows)}</Table.Body>
              ) : (
                <Table.Body />
              )}
            </Table>
            {rows.length > 0 ? (
              ''
            ) : (
              <div
                style={{
                  padding: '2rem 0rem',
                  textAlign: 'center',
                  fontSize: '1.2em',
                }}
              >
                Nothing To show
              </div>
            )}
          </Card.Content>
        ) : (
          <Card.Content>
            <Card.Header
              style={{ textAlign: 'center', color: '#666666' }}
              content={title}
            />
            <Card.Description>
              <StatsPlaceholder />
            </Card.Description>
          </Card.Content>
        )}
      </Card>
    );
  }
}

Top.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  errorhandler: PropTypes.func.isRequired,
};

export { Top };
