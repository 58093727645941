/** @format */

import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { MacCommandCtx } from '../MacCommand';
import MacCard from './MacCard';
import MacCardListItem from './MacCardListItem';
import { useMacCard } from './useMacCard';

/**
 * [REQ 2.12-004] RX Params Setup (rx-timing):
 * deve essere realizzata una sezione dove sia possibile inviare il comando (con il semplice click su un bottone)
 * e attendere la risposta di tale comando. Il comando dovrà avere i seguenti parametri:
 * rx1Delay: [Delay] prende i valori interi da 1 a 15, all’utente devono essere restituite le righe corrispondenti ai seguenti valori:
 * 1 -> Default (1s)
 * 2 -> 1s
 * 3 -> 2s
 * 4 -> 3s
 * 5 -> 4s
 * 6 -> 5s
 * 7 -> 6s
 * 8 -> 7s
 * 9 -> 8s
 * 10 -> 9s
 * 11 -> 10s
 * 12 -> 11s
 * 13 -> 12s
 * 14 -> 13s
 * 15 -> 14s
 *
 * L’invio del comando si realizza tramite la chiamata PUT /api/v2/device/{{did}}/params/rx-timing
 * HTTP/1.1 – { "rx1Delay": {{ rx1Delay }} }
 *
 * Con l’endpoint GET /api/v2/device/{{did}}/params/rx-timing HTTP/1.1 si andrà a prelevare lo stato del dispositivo che potrà essere:
 * HTTP/1.1 200 OK: i campi presenti potranno essere:
 * state: (sempre presente) che potrà prendere i valori “pending” (se in attesa di una risposta) o “sync”.
 * rx1Delay: (sempre presente) Delay
 * pendingValues: oggetto contenente il valore rx1Delay inviato al dispositivo.
 *
 * HTTP/1.1 403 Forbidden - { "status": 403, "message": "Information not available" };
 * quando il device non ha mai parlato. In questo caso la tabella avrà il campo rx1Delay valorizzato a ”Default (1s)”.
 *
 * Una volta inviato il comando (PUT) il componente dovrà periodicamente richiedere lo stato (GET) per visualizzare gli aggiornamenti.
 *
 * @returns {*}
 * @constructor
 */
const MacCardDelay = () => {
  const { deviceID, deviceNeverSend } = useContext(MacCommandCtx);
  const title = 'Delay between TX and RX';
  const delayValues = [
    'Default (1s)',
    '1s',
    '2s',
    '3s',
    '4s',
    '5s',
    '6s',
    '7s',
    '8s',
    '9s',
    '10s',
    '11s',
    '12s',
    '13s',
    '14s',
  ];
  const options = delayValues.map((x, i) => ({
    key: i + x,
    text: x,
    value: i,
  }));
  const { param1 } = { param1: 'rx1Delay' };
  const neverSendData = {
    [param1]: 0,
  };
  const {
    editMode,
    pendingMode,
    togglePending,
    displayDataListParam,
    startEdit,
    changeEdit,
    endEdit,
    tempData,
    data,
  } = useMacCard(deviceID, 'rxDelay', deviceNeverSend, neverSendData);

  return (
    <MacCard
      title={title}
      startEdit={() => startEdit()}
      editMode={editMode}
      endEdit={save => endEdit(save)}
      pendingMode={pendingMode}
      togglePending={() => togglePending()}
      state={data && data.state}
    >
      <MacCardListItem
        label={'Delay'}
        editMode={editMode}
        value={displayDataListParam(param1, delayValues)}
      >
        {editMode && (
          <Dropdown
            fluid
            search
            selection
            name={param1}
            value={tempData[param1]}
            options={options}
            onChange={(e, selection) => changeEdit(selection)}
          />
        )}
      </MacCardListItem>
    </MacCard>
  );
};

export default MacCardDelay;
