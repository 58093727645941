/** @format */

import { CALL_API } from 'redux-api-middleware';
import { getCurrentUserToken } from '../lib/auth';
import { Endpoints as url } from '../lib/endpoints';
import { useThisQueryString } from '../lib/urls';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_USER_PROFILES_LIST_REQUEST =
  'userProfilesList/FETCH_REQUEST';
export const FETCH_USER_PROFILES_LIST_SUCCESS =
  'userProfilesList/FETCH_SUCCESS';
export const FETCH_USER_PROFILES_LIST_FAILURE =
  'userProfilesList/FETCH_FAILURE';

export const FETCH_USER_PROFILES_OPTIONS_REQUEST =
  'userProfilesOptions/FETCH_REQUEST';
export const FETCH_USER_PROFILES_OPTIONS_SUCCESS =
  'userProfilesOptions/FETCH_SUCCESS';
export const FETCH_USER_PROFILES_OPTIONS_FAILURE =
  'userProfilesOptions/FETCH_FAILURE';

export const DESTROY_USER_PROFILES_LIST_SUCCESS =
  'userProfilesList/DESTROY_SUCCESS';

export const DESTROY_USER_PROFILES_OPTIONS_SUCCESS =
  'userProfilesOptions/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchUserProfilesList = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.UserProfilesList(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_USER_PROFILES_LIST_REQUEST,
      {
        type: FETCH_USER_PROFILES_LIST_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            ...json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_USER_PROFILES_LIST_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const fetchUserProfilesOptions = () => ({
  [CALL_API]: {
    endpoint: url.UserProfilesOptions(),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_USER_PROFILES_OPTIONS_REQUEST,
      {
        type: FETCH_USER_PROFILES_OPTIONS_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({ options: json })),
        // TODO: debug this call 401
      },
      {
        type: FETCH_USER_PROFILES_OPTIONS_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const destroyUserProfilesList = () => ({
  type: DESTROY_USER_PROFILES_LIST_SUCCESS,
});

export const destroyUserProfilesOptions = () => ({
  type: DESTROY_USER_PROFILES_OPTIONS_SUCCESS,
});
