/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Pagination, Popup, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { getAccessTokens } from '../../actions/accesstoken';
import { AccessTokenModalDelete } from './AccessTokenModelDelete';
import { userHasRole } from '../../lib/auth';
import { PAGES_PATH } from '../../lib/variables';

const AccessTokensTable = () => {
  const accessTokens = useSelector(state => state.accesstokens);

  const [selected, setSelected] = useState({});

  const dispatch = useDispatch();

  const handleClose = () => {
    setSelected({});
  };

  const handleDelete = () => {
    dispatch(
      getAccessTokens({
        offset: accessTokens.offset,
        limit: accessTokens.limit,
      }),
    );
  };

  const onPageChange = (e, { activePage }) => {
    if (accessTokens)
      dispatch(
        getAccessTokens({
          offset: (activePage - 1) * accessTokens.limit,
          limit: accessTokens.limit,
        }),
      );
  };

  useEffect(() => {
    dispatch(
      getAccessTokens({
        offset: accessTokens.offset,
        limit: accessTokens.limit,
      }),
    );
  }, []);

  return (
    <>
      <Table sortable celled={false}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={13}>Label</Table.HeaderCell>
            {userHasRole('admin') && (
              <Table.HeaderCell width={5}>User</Table.HeaderCell>
            )}
            <Table.HeaderCell>Expires At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {accessTokens.rows &&
            accessTokens.rows.map(accessToken => {
              return (
                <Table.Row key={accessToken.id}>
                  <Table.Cell>{accessToken.label}</Table.Cell>
                  {userHasRole('admin') && (
                    <Table.Cell>
                      <Link to={`${PAGES_PATH}/user/${accessToken.user.id}`}>
                        {accessToken.user.username}
                      </Link>
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    <Popup
                      content={moment(accessToken.expiresAt).toString()}
                      trigger={
                        <div>
                          {moment
                            .duration(
                              moment(accessToken.expiresAt).diff(moment()),
                            )
                            .humanize(true)}
                        </div>
                      }
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Link
                      to="#"
                      onClick={() => {
                        setSelected(accessToken);
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      {accessTokens &&
      'rows' in accessTokens &&
      accessTokens.rows.length > 0 ? (
        ''
      ) : (
        <div
          style={{
            padding: '2rem 0rem',
            textAlign: 'center',
            fontSize: '1.2em',
          }}
        >
          Nothing To show
        </div>
      )}

      {accessTokens && 'rows' in accessTokens && accessTokens.rows.length > 0 && (
        <Container textAlign="center">
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={
              parseInt(accessTokens.offset / accessTokens.limit, 10) + 1
            }
            totalPages={Math.ceil(
              (accessTokens.count || 0) / accessTokens.limit,
            )}
            onPageChange={onPageChange}
          />
        </Container>
      )}

      <AccessTokenModalDelete
        element={selected}
        onClose={handleClose}
        onSuccess={handleDelete}
      />
    </>
  );
};

export { AccessTokensTable };
