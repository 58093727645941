/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import idx from 'idx';
import PropTypes from 'prop-types';

import { MapContainer } from '../../components';
import {
  destroyApplicationDevicesMap,
  fetchApplicationDevicesMap,
} from '../../actions/applicationDevices';
import { GOOGLE_MAPS_API_KEY } from '../../lib/variables';

const mapStateToProps = state => ({
  application: state.application,
  applicationDevicesMap: state.applicationDevicesMap,
});

const mapDispatchToProps = dispatch => ({
  fetchApplicationDevicesMap: application => {
    dispatch(fetchApplicationDevicesMap(application));
  },
  destroyApplicationDevicesMap: () => {
    dispatch(destroyApplicationDevicesMap());
  },
});

class DvMap extends Component {
  componentDidMount() {
    this.props.fetchApplicationDevicesMap(this.props.match.params.aid);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.applicationDevicesMap !== nextProps.applicationDevicesMap) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.props.destroyApplicationDevicesMap();
  }

  render() {
    const { applicationDevicesMap } = this.props;

    return (
      <Fragment>
        {idx(applicationDevicesMap, _ => _.coord.length) > 0 && GOOGLE_MAPS_API_KEY && (
          <MapContainer
            bounds={idx(applicationDevicesMap, _ => _.boundingBox)}
            markers={idx(applicationDevicesMap, _ => _.coord)}
            isMarkerShown
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        )}
      </Fragment>
    );
  }
}

DvMap.propTypes = {
  match: PropTypes.object,
  applicationDevicesMap: PropTypes.object,
  fetchApplicationDevicesMap: PropTypes.func,
  destroyApplicationDevicesMap: PropTypes.func,
};

const ApplicationDevicesMap = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvMap),
);

export { ApplicationDevicesMap };
