/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const POST_APPLICATION_SHARE_REQUEST = 'applicationShare/POST_REQUEST';
export const POST_APPLICATION_SHARE_SUCCESS = 'applicationShare/POST_SUCCESS';
export const POST_APPLICATION_SHARE_FAILURE = 'applicationShare/POST_FAILURE';

export const DELETE_APPLICATION_SHARE_REQUEST =
  'applicationShare/DELETE_REQUEST';
export const DELETE_APPLICATION_SHARE_SUCCESS =
  'applicationShare/DELETE_SUCCESS';
export const DELETE_APPLICATION_SHARE_FAILURE =
  'applicationShare/DELETE_FAILURE';

export const DESTROY_APPLICATION_SHARE_SUCCESS =
  'applicationShare/DESTROY_SUCCESS';

// ACTION CREATORS
export const postApplicationShare = applications => ({
  [CALL_API]: {
    endpoint: url.ApplicationShareCreate(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(applications),
    types: [
      POST_APPLICATION_SHARE_REQUEST,
      {
        type: POST_APPLICATION_SHARE_SUCCESS,
        payload: (action, state, res) => {
          history.push({
            pathname: `${PAGES_PATH}/applications`,
            state: { activeIndex: 1 },
          });
          return res.json().then(json => json);
        },
      },
      {
        type: POST_APPLICATION_SHARE_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const deleteApplicationShare = applicationShareId => ({
  [CALL_API]: {
    endpoint: url.ApplicationShareDelete(applicationShareId),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_APPLICATION_SHARE_REQUEST,
      {
        type: DELETE_APPLICATION_SHARE_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/applications` });
          return res.json().then(json => json);
        },
      },
      {
        type: DELETE_APPLICATION_SHARE_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/applications`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyApplicationShare = () => ({
  type: DESTROY_APPLICATION_SHARE_SUCCESS,
});
