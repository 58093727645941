/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';

export const GET_DOWNLINKS_REQUEST = 'downlinks/GET_REQUEST';
export const GET_DOWNLINKS_SUCCESS = 'downlinks/GET_SUCCESS';
export const GET_DOWNLINKS_FAILURE = 'downlinks/GET_FAILURE';
export const DESTROY_DOWNLINKS_SUCCESS = 'downlinks/DESTROY_SUCCESS';

export const getDownlinks = (application, device) => ({
  [CALL_API]: {
    endpoint: url.Downlinks(application, device),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      GET_DOWNLINKS_REQUEST,
      {
        type: GET_DOWNLINKS_SUCCESS,
        payload: (action, state, res) => res.json().then(app => app),
      },
      {
        type: GET_DOWNLINKS_FAILURE,
        payload: (action, state, res) => res.json().then(err => err),
      },
    ],
  },
});

export const destroyDownlinks = () => ({
  type: DESTROY_DOWNLINKS_SUCCESS,
});
