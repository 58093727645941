/** @format */

import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
} from '../actions/updatePassword';

const initialState = {
  data: {},
  isLoading: false,
  error: false,
};

export const updatePassword = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        data: { ...action.payload },
        isLoading: false,
        error: false,
      };

    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        data: {},
        isLoading: false,
        error: true,
      };

    default:
      return state;
  }
};
