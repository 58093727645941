/** @format */

import React from 'react';
import { Grid } from 'semantic-ui-react';
import MacCardDutyCycle from '../MacCard/MacCardDutyCycle';
import MacCardRxParams from '../MacCard/MacCardRxParams';
import MacCardPingSlot from '../MacCard/MacCardPingSlot';
import MacCardDelay from '../MacCard/MacCardDelay';

const MacCards = () => {
  const macCards = [
    // eslint-disable-next-line react/display-name
    { id: 'MacCardDutyCycle', component: () => <MacCardDutyCycle /> },
    // eslint-disable-next-line react/display-name
    { id: 'MacCardRxParams', component: () => <MacCardRxParams /> },
    // eslint-disable-next-line react/display-name
    { id: 'MacCardPingSlot', component: () => <MacCardPingSlot /> },
    // eslint-disable-next-line react/display-name
    { id: 'MacCardDelay', component: () => <MacCardDelay /> },
  ];

  return (
    <Grid>
      <Grid.Row>
        {macCards.map(x => (
          <Grid.Column
            key={x.id}
            computer={4}
            tablet={8}
            mobile={16}
            style={{ margin: '1rem auto' }}
          >
            {x.component()}
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default MacCards;
