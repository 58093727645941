/** @format */

import * as actions from '../actions/application';

const defaultState = {
  application: {},
  isLoading: false,
  error: false,
};

export const application = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_APPLICATION_REQUEST:
      return {
        application: {},
        isLoading: true,
        error: false,
      };
    case actions.FETCH_APPLICATION_SUCCESS:
      return {
        application: { ...action.payload },
        isLoading: false,
        error: false,
      };
    case actions.FETCH_APPLICATION_FAILURE:
      return {
        ...action.payload,
        application: {},
        isLoading: false,
        error: true,
      };

    case actions.POST_APPLICATION_REQUEST:
      return {
        application: {},
        isLoading: true,
        error: false,
      };
    case actions.POST_APPLICATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.POST_APPLICATION_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DELETE_APPLICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.DELETE_APPLICATION_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
