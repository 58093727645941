/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Divider, Form, Header, Message } from 'semantic-ui-react';
import idx from 'idx';
import PropTypes from 'prop-types';

import { userHasRole } from '../../lib/auth';
import { destroyUsersOptions, fetchUsersOptions } from '../../actions/users';
import { destroyDeviceShare, postDeviceShare } from '../../actions/deviceShare';
import { formatOptions } from '../../lib/utilities';

const mapStateToProps = state => ({
  deviceShare: state.deviceShare,
  usersOptions: state.usersOptions,
});

const mapDispatchToProps = dispatch => ({
  fetchUsersOptions: (params = {}) => {
    dispatch(fetchUsersOptions(params));
  },
  postDeviceShare: devices => {
    dispatch(postDeviceShare(devices));
  },
  destroyUsersOptions: () => {
    dispatch(destroyUsersOptions());
  },
  destroyDeviceShare: () => {
    dispatch(destroyDeviceShare());
  },
});

class DeviceShareAddForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      share: {
        ids: this.props.location.state.devices,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeUserId = this.handleChangeUserId.bind(this);
    this.handleSearchUserIdChange = this.handleSearchUserIdChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
    }
  }

  componentWillUnmount() {
    this.props.destroyUsersOptions();
    this.props.destroyDeviceShare();
  }

  // HANDLER SECTION
  handleChange(e, { name, value }) {
    this.setState({ share: { ...this.state.share, [name]: value } });
  }

  handleCheck(e, { name, checked }) {
    this.setState({ share: { ...this.state.share, [name]: checked } });
  }

  handleChangeUserId(e, { value }) {
    this.setState({
      share: {
        ...this.state.share,
        GuestId: value,
      },
    });
  }

  handleSearchUserIdChange(e, { searchQuery }) {
    const params = { filter: { username: searchQuery } };
    this.props.fetchUsersOptions(params);
  }

  handleSubmit(e) {
    const { share } = this.state;

    e.preventDefault();
    this.props.postDeviceShare(share);
  }

  render() {
    const { share } = this.state;
    const { usersOptions, deviceShare } = this.props;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Share Device{share.ids.length > 1 ? 's' : ''}
        </Header>
        <Divider clearing />
        {this.props.deviceShare.error === true && (
          <Message negative>
            <Message.Header>OPS... Guest not found!</Message.Header>
            <p>{this.props.deviceShare.message}</p>
            <p>Please check your information or contact-us.</p>
          </Message>
        )}

        <Form onSubmit={this.handleSubmit}>
          {userHasRole('admin') && (
            <Form.Dropdown
              name="GuestId"
              label="Share to"
              placeholder="choose one..."
              width={8}
              selection
              required
              search
              options={
                idx(usersOptions, _ => formatOptions(_.options, 'Owner')) || []
              }
              value={idx(share, _ => _.GuestId)}
              onChange={this.handleChangeUserId}
              onSearchChange={this.handleSearchUserIdChange}
              disabled={idx(usersOptions, _ => _.isFetching)}
              loading={idx(usersOptions, _ => _.isFetching)}
            />
          )}

          {!userHasRole('admin') && (
            <Fragment>
              <Form.Input
                width={8}
                label="Guest email"
                name="GuestEmail"
                required
                onChange={this.handleChange}
                error={deviceShare.error}
              />
            </Fragment>
          )}

          <Form.Input
            width={8}
            label="Label"
            name="label"
            required
            onChange={this.handleChange}
          />

          <Form.Button content="Save" type="submit" />
        </Form>
      </Fragment>
    );
  }
}

DeviceShareAddForm.propTypes = {
  location: PropTypes.object,
  deviceShare: PropTypes.object,
  usersOptions: PropTypes.object,
  fetchUsersOptions: PropTypes.func,
  destroyUsersOptions: PropTypes.func,
  destroyDeviceShare: PropTypes.func,
  postDeviceShare: PropTypes.func,
};

const DeviceShareCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceShareAddForm),
);

export { DeviceShareCreate };
