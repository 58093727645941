/** @format */

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class ShareButton extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { path } = this.props;
    return (
      <Button
        as={Link}
        key="share"
        compact
        icon
        color="red"
        labelPosition="left"
        floated="right"
        style={{ marginTop: '2.4em' }}
        to={path}
      >
        <FontAwesomeIcon
          icon="share-alt"
          className="icon"
          style={{ padding: '0.4em' }}
        />
        Share
      </Button>
    );
  }
}

ShareButton.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
