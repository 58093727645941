/** @format */

import Axios from 'axios';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Card, Divider, List } from 'semantic-ui-react';
import { ListItem } from '../../components';
import { getCurrentUserToken } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  // gateway: state.gateway.data || {}
});

const mapDispatchToProps = dispatch => ({});

class _GatewayUptime extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    // The component custom status
    this.state = {
      stat: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Update component status getting info from API REST
   */
  componentDidMount() {
    Axios.get(url.StatsUptime(this.props.match.params.gid), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res =>
        this.setState({
          ...this.state,
          stat: {
            ...this.state.stat,
            uptime: res.data.uptime,
          },
        }),
      )
      .catch(error => {
        devLog(error);
        /**
         * To perform further check use error.response.data.message, e.g.
         *    error.response.data.message === 'Uptime not found'
         *    error.response.data.status === 404
         */
        this.setState({
          ...this.state,
          stat: {
            ...this.state.stat,
            uptime: 'none',
          },
        });
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.stat !== nextProps.stat) {
      return true;
    }

    return false;
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { stat } = this.state;

    return (
      <Card.Content>
        <Card.Header>Uptime [last 6h]</Card.Header>
        <Divider />
        <Card.Description>
          <List verticalAlign="middle" divided selection>
            <ListItem label="Uptime" content={idx(stat, _ => _.uptime)} copy />
          </List>
        </Card.Description>
      </Card.Content>
    );
  }
}

_GatewayUptime.propTypes = {
  gateway: PropTypes.object,
  match: PropTypes.object,
  stat: PropTypes.object,
};

const GatewayUptime = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(_GatewayUptime),
);

export { GatewayUptime };
