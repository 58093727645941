import React, { Fragment, useEffect, useState } from 'react';
import { Button, Divider, Form, Header, Label } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { PAGES_PATH } from '../../lib/variables';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { GatewayIdFormatter } from './SpectralScan';
import PropTypes from 'prop-types';
import { rncGatewayConfig, rncGatewayGetConfig } from './service';

import { toast } from 'react-semantic-toasts';

export const RncConfiguration = (props) => {

  const [pollingInterval, setPollingInterval] = useState('');

  const { gid } = props.match.params

  useEffect(() => {
    if(gid) {
      rncGatewayGetConfig(gid).then(response => {
        setPollingInterval(response.data.configuration.polling_interval)
      })
    }
  },[gid]);

  const handleSubmit = () => {
    rncGatewayConfig(gid, pollingInterval).then(response => {
      toast({
        type: 'success',
        title: 'Success',
        description: 'Configurazione salvata',
      });
    }).catch(error => {
      toast({
        type: 'error',
        title: 'Error',
        description: 'Errore nel salvataggio della configurazione',
      });
    })
  }

  return <Fragment>
    <Header as='h1' style={{ arginTop: '1em' }} floated='left'>
      RNC Configuration
    </Header>
    <Button
      as={Link}
      key='list'
      compact
      icon
      color='red'
      labelPosition='left'
      floated='right'
      style={{ marginTop: '2.4em' }}
      to={`${PAGES_PATH}/gateways`}
    >
      <FontAwesomeIcon
        icon='list'
        className='icon'
        style={{ padding: '0.4em' }}
      />
      List
    </Button>
    <Button
      as={Link}
      key='rnc'
      compact
      icon
      color='red'
      labelPosition='left'
      floated='right'
      style={{ marginTop: '2.4em' }}
      to={`${PAGES_PATH}/gateway/${gid}/rnc`}
    >
      <FontAwesomeIcon
        icon={faCogs}
        className='icon'
        style={{ padding: '0.4em' }}
      />
      RNC
    </Button>

    <Divider clearing />
    <div className='ui four column grid' style={{ 'marginRight': '10px' }}>

      <div className='row'>
        <div className='sixteen wide column'>
          <Label>
            Gateway
            <Label.Detail>
              <GatewayIdFormatter id={gid} />
            </Label.Detail>
          </Label>
        </div>
      </div>

      <div className='row'>
        <div className='sixteen wide column'>
          <Form onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>Polling interval (seconds)</label>
                <input
                  placeholder='Polling interval'
                  name='polling_interval'
                  value={pollingInterval}
                  onChange={ (event) => setPollingInterval(event.target.value) }
                />
              </Form.Field>
            </Form.Group>
            <Button type='submit'>SAVE</Button>
          </Form>
        </div>
      </div>
    </div>
  </Fragment>;
};

RncConfiguration.propTypes = {
  match: PropTypes.object,
};
