/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Header,
  Input,
  Label,
  Modal,
} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SVG from 'react-inlinesvg';
import idx from 'idx';
import PropTypes from 'prop-types';

import {
  deleteApplication,
  fetchApplication,
  putApplication,
} from '../../actions/application';
import { PAGES_PATH } from '../../lib/variables';
import { DevicesList } from '../DevicesList';

const mapStateToProps = state => ({
  application: state.application.application,
});

const mapDispatchToProps = dispatch => ({
  fetchApplication: application => {
    dispatch(fetchApplication(application));
  },
  putApplication: application => {
    dispatch(putApplication(application));
  },
  deleteApplication: application => {
    dispatch(deleteApplication(application));
  },
});

class ApplicationEditForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      application: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchApplication(this.props.match.params.aid);
  }

  // eslint-disable-next-line react/no-deprecated
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (idx(nextProps, _ => _.application)) {
      this.setState({
        application: nextProps.application,
      });
    }
  }

  handleChange(e, { name, value }) {
    this.setState({
      application: { ...this.state.application, [name]: value },
    });
  }

  handleChangeUserId(e, { value }) {
    e.persist();
    this.setState({
      device: {
        ...this.state.device,
        UserId: value,
        username: e.target.textContent,
        ApplicationId: null,
      },
    });
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  handleDeleteModal() {
    this.setState({ modalOpen: true });
  }

  handleDelete() {
    const { application } = this.props;

    this.props.deleteApplication(application);
    this.setState({ modalOpen: false, check: null });
  }

  handleSubmit(e) {
    const { application } = this.state;
    e.preventDefault();
    this.props.putApplication(application);
  }

  render() {
    const { application } = this.state;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Edit Application
        </Header>
        <Button
          key="delete"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          onClick={() => this.handleDeleteModal()}
        >
          <FontAwesomeIcon
            icon="trash-alt"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Delete
        </Button>

        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/applications`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>

        <Divider clearing />
        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            width={8}
            label="Label"
            name="label"
            value={idx(application, _ => _.label) || ''}
            onChange={this.handleChange}
          />

          <Form.Button content="Save" type="submit" />
        </Form>

        {idx(application, _ => _.label) && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            basic
            size="small"
          >
            <div style={{ width: '40%' }}>
              <SVG src="/assets/images/robotRYS.svg" />
            </div>
            <Header as="h1" content="Are you sure??" />
            <Modal.Content>
              <h3>
                This action will delete this Application and all related
                devices:
              </h3>
              <DevicesList application={application} />
              <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
              <p>
                To proceed, please fill the field with the application label
              </p>
              <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
                {idx(application, _ => _.label)}
              </Label>
              <div>
                <Input
                  name="check"
                  placeholder="label..."
                  width={8}
                  onChange={this.handleChange}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={this.handleDelete}
                color="red"
                disabled={application.label !== application.check}
              >
                Proceed
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}
      </Fragment>
    );
  }
}

ApplicationEditForm.propTypes = {
  application: PropTypes.object,
  match: PropTypes.object,
  destroyApplication: PropTypes.func,
  fetchApplication: PropTypes.func,
  putApplication: PropTypes.func,
  deleteApplication: PropTypes.func,
};

const ApplicationUpdate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationEditForm),
);

export { ApplicationUpdate };
