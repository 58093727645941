/** @format */

import { CALL_API } from 'redux-api-middleware';

import { objectToQueryString } from '../lib/urls';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';

// ACTION TYPES
export const FETCH_DEVICE_MANAGER_IMPORT_LIST_REQUEST =
  'device-manager-import-list/FETCH_REQUEST';
export const FETCH_DEVICE_MANAGER_IMPORT_LIST_DEVICE_SUCCESS =
  'device-manager-import-list/FETCH_SUCCESS';
export const FETCH_DEVICE_MANAGER_IMPORT_LIST_DEVICE_FAILURE =
  'device-manager-import-list/FETCH_FAILURE';
export const FETCH_DEVICE_MANAGER_IMPORT_DETAIL_REQUEST =
  'device-manager-import-detail/FETCH_REQUEST';
export const FETCH_DEVICE_MANAGER_IMPORT_DETAIL_DEVICE_SUCCESS =
  'device-manager-import-detail/FETCH_SUCCESS';
export const FETCH_DEVICE_MANAGER_IMPORT_DETAIL_DEVICE_FAILURE =
  'device-manager-import-detail/FETCH_FAILURE';

// ACTION CREATORS
export const fetchDeviceManagerImportList = (query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.DeviceManagerImportList()}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_MANAGER_IMPORT_LIST_REQUEST,
      {
        type: FETCH_DEVICE_MANAGER_IMPORT_LIST_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_MANAGER_IMPORT_LIST_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchDeviceManagerImportDetail = (id, query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.DeviceManagerImportDetail(id)}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_MANAGER_IMPORT_DETAIL_REQUEST,
      {
        type: FETCH_DEVICE_MANAGER_IMPORT_DETAIL_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_MANAGER_IMPORT_DETAIL_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});
