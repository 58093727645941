/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Divider, Grid, Header, Sidebar } from 'semantic-ui-react';
import { UserProfileCreate } from '..';
import history from '../../history';
import { PAGES_PATH } from '../../lib/variables';

const userProfileAddForm = props => {
  // HANDLER SECTION
  const preventDefault = e => {
    e.preventDefault();
  };
  const goToUsersList = () => {
    history.push({ pathname: `${PAGES_PATH}/users`, state: 1 });
  };

  return (
    <Fragment>
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        Create User Profile
      </Header>
      <Button
        as={Link}
        key="list"
        compact
        icon
        color="red"
        labelPosition="left"
        floated="right"
        style={{ marginTop: '2.4em' }}
        to={{ pathname: `${PAGES_PATH}/users`, state: 1 }} // #1 to open directly the tab of profiles
      >
        <FontAwesomeIcon
          icon="list"
          className="icon"
          style={{ padding: '0.4em' }}
        />
        List
      </Button>
      <Divider clearing />
      <Sidebar.Pusher>
        <Grid>
          <Grid.Column mobile={16} tablet={6} computer={6}>
            <UserProfileCreate
              parentHandleNewUserProfile={goToUsersList}
              handleUserProfileSidebar={preventDefault}
            />
          </Grid.Column>
        </Grid>
      </Sidebar.Pusher>
    </Fragment>
  );
};

const UserProfileCreatePage = withRouter(userProfileAddForm);

export { UserProfileCreatePage };
