/**
 * /* eslint-disable no-unused-expressions
 *
 * @format
 */

/* eslint-disable no-nested-ternary */
/** @format */

import React, { useEffect, useState } from 'react';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  pollingStates,
  useMacCommandPolling,
  useMacCommandService,
} from '../useMacCommandService';
import { toastError } from '../../../lib/utilities';
import devLog from '../../../lib/devLog';

export const useMacCard = (
  deviceID,
  cardName,
  deviceNeverSend,
  neverSendData,
  options,
) => {
  const visible = options && options.visible ? options.visible : true;
  const frequencyParam = (options && options.frequencyParam) || null;

  const { macCard } = useMacCommandService(deviceID);

  const [editMode, setEditMode] = useState(false);
  const [pendingMode, setPendingMode] = useState(false);
  const [state, setState] = useState('---');
  const [tempData, setTempData] = useState(null);
  const [data, setData] = useState(null);

  const getValues = () => {
    macCard[cardName]
      .get()
      .then(res => {
        setState(res.data.state);
        setData(res.data);
      })
      .catch(err => {
        const message =
          (err &&
            err.response &&
            err.response.data &&
            err.response.data.message) ||
          'Error';
        toastError({ message });
      });
  };

  useEffect(() => {
    if (visible) {
      if (deviceNeverSend) setData({ state: '---', ...neverSendData });
      else getValues();
    }
  }, [visible, deviceNeverSend]);

  const { setPolling } = useMacCommandPolling(deviceID, getValues);

  useEffect(() => {
    if (state === 'pending') {
      setPolling(pollingStates[0]);
    } else {
      setPolling(pollingStates[1]);
    }
  }, [state]);

  const sendValues = newParams => {
    macCard[cardName]
      .put(newParams)
      .then(res => {
        devLog(res);
        getValues();
      })
      .catch(err => {
        const message =
          (err &&
            err.response &&
            err.response.data &&
            err.response.data.message) ||
          'Error';
        toastError({ message });
      });
  };

  const formatFrequency = fr => (editMode ? fr : +(fr / 10000).toFixed(3));

  const startEdit = () => {
    const nextTempData = { ...data };
    // frequency param:
    // GET arriva in formato intero 1000000
    // PUT accetta solo in formato digit 100.000
    if (
      frequencyParam !== null &&
      ['rxParams', 'pingSlotChannel'].includes(cardName)
    ) {
      nextTempData[frequencyParam] = formatFrequency(
        nextTempData[frequencyParam],
      );
    }
    setTempData(nextTempData);
    setEditMode(true);
  };

  const changeEdit = ({ name, value }) => {
    const nextTemp = { ...tempData };
    nextTemp[name] = Number.isNaN(value) ? value : +value;
    setTempData(nextTemp);
  };

  const endEdit = (save = false) => {
    if (save) {
      const sendTemp = { ...tempData };
      if (sendTemp.state) delete sendTemp.state;
      if (sendTemp.pendingValues) delete sendTemp.pendingValues;
      sendValues(sendTemp);
    }
    setTempData(null);
    setEditMode(false);
  };

  const togglePending = () => setPendingMode(!pendingMode);

  const setData2 = () => {
    if (editMode) return tempData;
    else if (
      data &&
      data.state === 'pending' &&
      data.pendingValues &&
      pendingMode
    )
      return data.pendingValues;
    else return data;
  };

  const questionMark2 = () => (
    <FontAwesomeIcon
      icon={faQuestion}
      className="icon"
      style={{ color: '#8e8e8e' }}
    />
  );

  const displayDataParam = param => {
    const data2 = setData2();
    return data2 && param in data2 ? data2[param] : questionMark2();
  };

  const displayDataListParam = (param, list) => {
    const data2 = setData2();    
    return data2 && param in data2 ? list[data2[param]] : questionMark2();
  };

  const displayDataFrequency = param => {
    const data2 = setData2();
    return data2 && param in data2
      ? Number.isNaN(data2[param])
        ? questionMark2()
        : formatFrequency(data2[param])
      : questionMark2();
  };

  return {
    state,
    editMode,
    pendingMode,
    togglePending,
    displayDataParam,
    displayDataListParam,
    displayDataFrequency,
    startEdit,
    changeEdit,
    endEdit,
    tempData,
    data,
  };
};
