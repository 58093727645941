/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import idx from 'idx';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Form,
  Header,
  Segment,
  Sidebar,
  Message,
  Grid,
} from 'semantic-ui-react';

import Cleave from 'cleave.js/react';

import Axios from 'axios';
import { ApplicationCreate } from '..';
import {
  getCurrentUserId,
  userHasRole,
  getCurrentUserName,
  getCurrentUserToken,
} from '../../lib/auth';
import { destroyUsersOptions, fetchUsersOptions } from '../../actions/users';
import {
  destroyDeviceProfilesOptions,
  fetchDeviceProfilesOptions,
  fetchDeviceProfileId,
} from '../../actions/deviceProfiles';
import {
  destroyApplicationsOptions,
  fetchApplicationsOptions,
} from '../../actions/applications';
import { destroyDevice, postDevice } from '../../actions/device';
import { PAGES_PATH } from '../../lib/variables';
import {
  formatBytes,
  formatNormalString,
  isCoordinate,
  coupleOfBytesLengths,
  generatePlaceholder,
  formatOptions,
  cleaveOptions,
  clean,
  toastError,
} from '../../lib/utilities';
import { Endpoints as url } from '../../lib/endpoints';
import ListButton from '../../components/Utils/ListButton';
import {
  generateSimpleQueryV2,
  generateUrl,
  usersOptionsFilter,
} from '../../lib/urls';
import devLog from '../../lib/devLog';

/* eslint-disable */
const mapStateToProps = state => {
  return {
    usersOptions: state.usersOptions,
    applicationsOptions: state.applicationsOptions,
    options: state.deviceProfilesOptions.options,
    deviceProfile: state.deviceProfilesOptions.deviceProfile,
    deviceFromProps: state.device,
    application: state.application,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUsersOptions: (params = {}) => {
      dispatch(fetchUsersOptions(params));
    },
    fetchDeviceProfilesOptions: () => {
      dispatch(fetchDeviceProfilesOptions());
    },
    fetchApplicationsOptions: (user, params = {}) => {
      dispatch(fetchApplicationsOptions(user, params));
    },
    postDevice: device => {
      dispatch(postDevice(device));
    },
    fetchDeviceProfileId: (
      macVersion,
      supports32bitFCnt,
      supportsClassB,
      supportsClassC,
    ) => {
      dispatch(
        fetchDeviceProfileId(
          macVersion,
          supports32bitFCnt,
          supportsClassB,
          supportsClassC,
        ),
      );
    },
    destroyUsersOptions: () => {
      dispatch(destroyUsersOptions());
    },
    destroyDeviceProfilesOptions: () => {
      dispatch(destroyDeviceProfilesOptions());
    },
    destroyApplicationsOptions: () => {
      dispatch(destroyApplicationsOptions());
    },
    destroyDevice: () => {
      dispatch(destroyDevice());
    },
  };
};

class DeviceAddForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      defaultFormWidth: 8,
      device: {
        UserId: getCurrentUserId(),
        username: getCurrentUserName(),
        supports32bitFCnt: true,
        supportsClassB: false,
        supportsClassC: false,
        macVersion: '1.0.2',
        deveui: '',
        devaddr: '',
        nwkSKey: '',
        appSKey: '',
        appeui: '',
        appKey: '',
        lat: '',
        lng: '',
        alt: '',
        DeviceProfileId: null,
      },
      deviceApplicationSidebar: false,
      errors: {
        lat: false,
        lng: false,
        alt: false,
      },
    };

    this.handleDeviceApplicationSidebar = this.handleDeviceApplicationSidebar.bind(
      this,
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCleave = this.handleChangeCleave.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeUserId = this.handleChangeUserId.bind(this);
    this.handleSearchApplication = this.handleSearchApplication.bind(this);
    this.handleSearchUserId = this.handleSearchUserId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onDeviceBlur = this.onDeviceBlur.bind(this);
  }

  componentDidMount() {
    const {
      fetchUsersOptions,
      fetchApplicationsOptions,
      fetchDeviceProfilesOptions,
    } = this.props;
    const usersOptionFilterEndpoint = generateUrl(
      url.Users(),
      generateSimpleQueryV2(usersOptionsFilter()),
    );

    if (userHasRole('admin')) {
      Axios.get(usersOptionFilterEndpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCurrentUserToken(),
        },
      })
        .then(res => {
          this.setState({ users: formatOptions(res.data.rows, 'Owner') });
        })
        .catch(err => devLog(err));
    } else {
      fetchApplicationsOptions(getCurrentUserId());
    }

    fetchDeviceProfilesOptions();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }

    if (nextProps.usersOptions.options !== this.props.usersOptions.options) {
      return true;
    }

    if (
      nextProps.applicationsOptions.options !==
      this.props.applicationsOptions.options
    ) {
      return true;
    }

    if (nextProps.application !== this.props.application) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchApplicationsOptions, application } = this.props;

    if (this.state.device.UserId !== prevState.device.UserId) {
      const params = { filter: { uid: this.state.device.UserId } };
      fetchApplicationsOptions(this.state.device.UserId, params);
    }

    if (prevProps.application.id !== this.props.application.id) {
      const params = { filter: { label: application.label } };
      fetchApplicationsOptions(this.state.device.UserId, params);
      this.setState({
        device: {
          ...this.state.device,
          appid: application.id,
        },
      });
    }
  }

  componentWillUnmount() {
    const {
      destroyUsersOptions,
      destroyDeviceProfilesOptions,
      destroyApplicationsOptions,
      destroyDevice,
    } = this.props;

    destroyUsersOptions();
    destroyDeviceProfilesOptions();
    destroyApplicationsOptions();
    destroyDevice();
  }

  // HANDLER SECTION
  handleDeviceApplicationSidebar(e) {
    e.preventDefault();
    this.setState({
      deviceApplicationSidebar: !this.state.deviceApplicationSidebar,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({
      device: {
        ...this.state.device,
        [name]: value,
        ...(name === 'macVersion' && (value === '1.0.2' || value === '1.0.4')
          ? {
              supports32bitFCnt: true,
              ...(name === 'macVersion' &&
              ((process.env.REACT_APP_LORA_104_CLASS_B_ALLOWED != 'true' &&
                value === '1.0.4') ||
                value === '1.0.2')
                ? { ['supportsClassB']: false }
                : {}),
              ...(process.env.REACT_APP_LORA_104_CLASS_C_ALLOWED != 'true'
                ? { ['supportsClassC']: false }
                : {}),
            }
          : {}),
      },
    });
  }

  handleChangeCleave(e) {
    this.setState({
      device: { ...this.state.device, [e.target.name]: e.target.value },
    });
  }

  handleCheck(e, { name, checked }) {
    this.setState({ device: { ...this.state.device, [name]: checked } });
    setTimeout(() => {
      if (
        this.state.device.macVersion === '1.0.3' &&
        name === 'supportsClassB'
      ) {
        this.setState({
          ...this.state,
          device: { ...this.state.device, ['supportsClassC']: false },
        });
      }
      if (
        this.state.device.macVersion === '1.0.3' &&
        name === 'supportsClassC'
      ) {
        this.setState({
          ...this.state,
          device: { ...this.state.device, ['supportsClassB']: false },
        });
      }
      if (
        this.state.device.macVersion === '1.0.4' &&
        name === 'supportsClassB'
      ) {
        this.setState({
          ...this.state,
          device: {
            ...this.state.device,
            ['supportsClassC']: false,
            ['supports32bitFCnt']: true,
          },
        });
      }
      if (
        this.state.device.macVersion === '1.0.4' &&
        name === 'supportsClassC'
      ) {
        this.setState({
          ...this.state,
          device: {
            ...this.state.device,
            ['supportsClassB']: false,
            ['supports32bitFCnt']: true,
          },
        });
      }
    }, 200);
  }

  handleChangeUserId(e, { value }) {
    e.persist();

    this.setState({
      device: {
        ...this.state.device,
        UserId: value,
        username: e.target.textContent,
        appid: null,
      },
    });
  }

  handleSearchUserId(e, { searchQuery }) {
    const { fetchUsersOptions } = this.props;

    const params = { filter: { username: searchQuery } };
    fetchUsersOptions(params);
  }

  handleSearchApplication(e, { searchQuery }) {
    const { fetchApplicationsOptions } = this.props;
    let params = {};
    if (userHasRole('admin')) {
      params = {
        filter: { label: searchQuery, uid: this.state.device.UserId },
      };
    } else {
      params = { filter: { label: searchQuery } };
    }
    fetchApplicationsOptions(this.state.device.UserId, params);
  }

  handleSubmit(e) {
    const { device } = this.state;
    const { postDevice, deviceProfile } = this.props;
    e.preventDefault();

    Axios.get(
      url.DeviceProfileID(
        device.macVersion,
        device.supports32bitFCnt,
        device.supportsClassB,
        device.supportsClassC,
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCurrentUserToken(),
        },
      },
    )
      .then(response => {
        return response.status === 200 &&
          0 in response.data.rows &&
          response.data.rows[0]
          ? this.setState(
              {
                ...this.state,
                device: {
                  ...this.state.device,
                  DeviceProfileId: response.data.rows[0].id,
                },
              },
              () => {
                let formattedDevice = {
                  ...device,
                  deveui: formatNormalString(device.deveui, ' '),
                  DeviceProfileId: response.data.rows[0].id,
                };

                if (formattedDevice.type === 'ABP') {
                  formattedDevice = {
                    ...formattedDevice,
                    devaddr: formatNormalString(formattedDevice.devaddr, ' '),
                    nwkSKey: formatNormalString(formattedDevice.nwkSKey, ' '),
                    appSKey: formatNormalString(formattedDevice.appSKey, ' '),
                  };

                  delete formattedDevice.appKey;
                  delete formattedDevice.appeui;
                  delete formattedDevice.username;
                } else {
                  formattedDevice = {
                    ...formattedDevice,
                    appeui: formatNormalString(formattedDevice.appeui, ' '),
                    appKey: formatNormalString(formattedDevice.appKey, ' '),
                  };

                  delete formattedDevice.devaddr;
                  delete formattedDevice.nwkSKey;
                  delete formattedDevice.appSKey;
                  delete formattedDevice.username;
                }

                formattedDevice = {
                  ...formattedDevice,
                  lat:
                    isCoordinate(formattedDevice.lat, 'lat') &&
                    parseFloat(formattedDevice.lat),
                  lng:
                    isCoordinate(formattedDevice.lng, 'lng') &&
                    parseFloat(formattedDevice.lng),
                  alt:
                    isCoordinate(formattedDevice.alt, 'alt') &&
                    parseFloat(formattedDevice.alt),
                };

                isNaN(formattedDevice.lat) && delete formattedDevice.lat;
                isNaN(formattedDevice.lng) && delete formattedDevice.lng;
                isNaN(formattedDevice.alt) && delete formattedDevice.alt;

                !userHasRole('admin') && delete formattedDevice.UserId;

                delete formattedDevice.macVersion;
                delete formattedDevice.supports32bitFCnt;
                delete formattedDevice.supportsClassB;
                delete formattedDevice.supportsClassC;

                formattedDevice.model ? null : delete formattedDevice.model;
                formattedDevice.serial ? null : delete formattedDevice.serial;
                formattedDevice.firmware
                  ? null
                  : delete formattedDevice.firmware;

                postDevice(clean(formattedDevice));
              },
            )
          : toastError(
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : 'generic error',
            );
      })
      .catch(error =>
        toastError(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.message
            ? error.response.data.message
            : 'generic error',
        ),
      );
  }

  validateField(ev) {
    if (ev.target.value === '') {
      return this.setState({
        errors: { ...this.state.errors, [ev.target.name]: false },
      });
    } else {
      return isCoordinate(ev.target.value, ev.target.name)
        ? this.setState({
            errors: { ...this.state.errors, [ev.target.name]: false },
          })
        : this.setState({
            errors: { ...this.state.errors, [ev.target.name]: true },
          });
    }
  }

  onDeviceBlur(event, bytesEnum) {
    const reducer = formatNormalString(
      event.target.value.replace(/^0+/g, ''),
      ':',
    );

    this.setState({
      device: {
        ...this.state.device,
        [event.target.name]: reducer.padStart(bytesEnum * 2, '0'),
      },
    });
  }

  render() {
    const {
      device,
      deviceApplicationSidebar,
      errors,
      users,
      defaultFormWidth,
    } = this.state;
    const { applicationsOptions, options } = this.props;
    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Add Device
        </Header>
        <ListButton path={`${PAGES_PATH}/devices`} />
        <Divider clearing />
        <Sidebar.Pushable>
          <Sidebar
            as={Segment}
            inverted
            color="red"
            direction="right"
            animation="overlay"
            visible={deviceApplicationSidebar}
            width="very wide"
          >
            <Button
              floated="right"
              onClick={this.handleDeviceApplicationSidebar}
              size="mini"
            >
              close
            </Button>
            {device.UserId && (
              <ApplicationCreate
                inverted
                UserId={device.UserId}
                handleDeviceApplicationSidebar={
                  this.handleDeviceApplicationSidebar
                }
              />
            )}
          </Sidebar>

          <Sidebar.Pusher>
            <Form onSubmit={this.handleSubmit}>
              {userHasRole('admin') ? (
                <Form.Dropdown
                  name="UserId"
                  label="Owner"
                  placeholder="choose one..."
                  width={defaultFormWidth}
                  selection
                  required
                  search
                  options={users !== undefined || users !== null ? users : []}
                  value={idx(device, _ => _.UserId)}
                  onChange={this.handleChangeUserId}
                  onSearchChange={this.handleSearchUserId}
                  disabled={users === null ? true : false}
                  loading={users === null ? true : false}
                />
              ) : (
                ''
              )}
              <Form.Group widths="equal">
                <Form.Dropdown
                  name="appid"
                  label="Application"
                  placeholder="choose one..."
                  width={defaultFormWidth}
                  search
                  selection
                  required
                  options={
                    idx(applicationsOptions, _ =>
                      formatOptions(_.options.rows, 'Application'),
                    ) || []
                  }
                  value={idx(device, _ => _.appid)}
                  onChange={this.handleChange}
                  onSearchChange={this.handleSearchApplication}
                  loading={idx(applicationsOptions, _ => _.isFetching)}
                  disabled={!device.UserId}
                />
                <Form.Button
                  width={defaultFormWidth}
                  content="Create Application"
                  label="&nbsp;"
                  onClick={this.handleDeviceApplicationSidebar}
                  disabled={!device.UserId}
                />
              </Form.Group>
              <Form.Dropdown
                name="macVersion"
                label="Protocol"
                placeholder="choose one..."
                width={defaultFormWidth}
                selection
                required
                options={
                  idx(options, _ => formatOptions(_.macVersion, 'Protocol')) ||
                  []
                }
                value={device.macVersion}
                onChange={this.handleChange}
                disabled={!(device.UserId && device.appid)}
              />
              <Form.Checkbox
                toggle
                label="Supports 32 bit Frame Counter"
                name="supports32bitFCnt"
                checked={idx(device, _ => _.supports32bitFCnt)}
                onChange={this.handleCheck}
                disabled={
                  !(
                    device.UserId &&
                    device.appid &&
                    device.macVersion &&
                    device.macVersion != '1.0.4'
                  )
                }
              />
              <Form.Checkbox
                toggle
                label="Supports class B"
                name="supportsClassB"
                checked={idx(device, _ => _.supportsClassB)}
                onChange={this.handleCheck}
                disabled={
                  !(
                    device.UserId &&
                    device.appid &&
                    (device.macVersion === '1.0.3' ||
                      (device.macVersion === '1.0.4' &&
                        process.env.REACT_APP_LORA_104_CLASS_B_ALLOWED ===
                          'true'))
                  )
                }
              />
              <Form.Checkbox
                toggle
                label="Supports class C"
                name="supportsClassC"
                checked={idx(device, _ => _.supportsClassC)}
                onChange={this.handleCheck}
                disabled={
                  !(
                    device.UserId &&
                    device.appid &&
                    (device.macVersion != '1.0.4' ||
                      (device.macVersion === '1.0.4' &&
                        process.env.REACT_APP_LORA_104_CLASS_C_ALLOWED ===
                          'true'))
                  )
                }
              />
              <Form.Dropdown
                width={defaultFormWidth}
                label="Type"
                name="type"
                placeholder="choose one..."
                required
                selection
                options={[
                  { key: 0, value: 'ABP', text: 'ABP' },
                  { key: 128, value: 'OTAA', text: 'OTAA' },
                ]}
                value={device.type}
                onChange={this.handleChange}
                disabled={!(device.UserId && device.appid && device.macVersion)}
              />
              <Form.Input
                width={defaultFormWidth}
                label="Label"
                name="label"
                required
                value={idx(device, _ => _.device.label)}
                onChange={this.handleChange}
                disabled={
                  !(device.UserId && device.macVersion && device.type !== '')
                }
              />
              <Form.Input
                label="Dev Eui"
                required
                width={defaultFormWidth}
                disabled={
                  !(device.UserId && device.macVersion && device.type !== '')
                }
              >
                <Cleave
                  placeholder={generatePlaceholder(coupleOfBytesLengths.DEVEUI)}
                  name="deveui"
                  value={formatBytes(device.deveui, null)}
                  options={{
                    ...cleaveOptions,
                    blocks: new Array(coupleOfBytesLengths.DEVEUI).fill(2),
                  }}
                  onBlur={ev =>
                    this.onDeviceBlur(ev, coupleOfBytesLengths.DEVEUI)
                  }
                  onChange={this.handleChangeCleave}
                />
              </Form.Input>
              {device.type === 'OTAA' && (
                <Form.Input
                  width={9}
                  label={device.macVersion === '1.1' ? 'Join Eui' : 'App Eui'}
                  required
                  disabled={
                    !(device.UserId && device.macVersion && device.type !== '')
                  }
                >
                  <Cleave
                    placeholder={generatePlaceholder(
                      coupleOfBytesLengths.JOINEUI,
                    )}
                    name="appeui"
                    value={formatBytes(device.appeui, null)}
                    options={{
                      ...cleaveOptions,
                      blocks: new Array(coupleOfBytesLengths.JOINEUI).fill(2),
                    }}
                    onBlur={ev =>
                      this.onDeviceBlur(ev, coupleOfBytesLengths.JOINEUI)
                    }
                    onChange={this.handleChangeCleave}
                  />
                </Form.Input>
              )}
              {device.type === 'OTAA' && (
                <Fragment>
                  <Header as="h4">Authentication Keys</Header>
                  <Divider clearing />
                  <Form.Input
                    width={9}
                    label="Application Key"
                    required
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                  >
                    <Cleave
                      placeholder={generatePlaceholder(
                        coupleOfBytesLengths.APPKEY,
                      )}
                      name="appKey"
                      value={formatBytes(device.appKey, null)}
                      options={{
                        ...cleaveOptions,
                        blocks: new Array(coupleOfBytesLengths.APPKEY).fill(2),
                      }}
                      onBlur={ev =>
                        this.onDeviceBlur(ev, coupleOfBytesLengths.APPKEY)
                      }
                      onChange={this.handleChangeCleave}
                    />
                  </Form.Input>
                </Fragment>
              )}
              {device.macVersion !== '1.1' && device.type === 'ABP' && (
                <Fragment>
                  <Form.Input
                    width={9}
                    label="Device Address"
                    required
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                  >
                    <Cleave
                      placeholder={generatePlaceholder(
                        coupleOfBytesLengths.DEVADDR,
                      )}
                      name="devaddr"
                      value={formatBytes(device.devaddr, null)}
                      options={{
                        ...cleaveOptions,
                        blocks: new Array(coupleOfBytesLengths.DEVADDR).fill(2),
                      }}
                      onBlur={ev =>
                        this.onDeviceBlur(ev, coupleOfBytesLengths.DEVADDR)
                      }
                      onChange={this.handleChangeCleave}
                    />
                  </Form.Input>

                  <Fragment>
                    <Header as="h4">Authentication Keys</Header>
                    <Divider clearing />
                    <Form.Input
                      width={9}
                      label="Application Session Key"
                      required
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                    >
                      <Cleave
                        placeholder={generatePlaceholder(
                          coupleOfBytesLengths.APPSKEY,
                        )}
                        name="appSKey"
                        value={formatBytes(device.appSKey, null)}
                        options={{
                          ...cleaveOptions,
                          blocks: new Array(coupleOfBytesLengths.APPSKEY).fill(
                            2,
                          ),
                        }}
                        onBlur={ev =>
                          this.onDeviceBlur(ev, coupleOfBytesLengths.APPSKEY)
                        }
                        onChange={this.handleChangeCleave}
                      />
                    </Form.Input>

                    <Form.Input
                      width={9}
                      label="Network Session Key"
                      required
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                    >
                      <Cleave
                        placeholder={generatePlaceholder(
                          coupleOfBytesLengths.NWKSKEY,
                        )}
                        name="nwkSKey"
                        value={formatBytes(device.nwkSKey, null)}
                        options={{
                          ...cleaveOptions,
                          blocks: new Array(coupleOfBytesLengths.NWKSKEY).fill(
                            2,
                          ),
                        }}
                        onBlur={ev =>
                          this.onDeviceBlur(ev, coupleOfBytesLengths.NWKSKEY)
                        }
                        onChange={this.handleChangeCleave}
                      />
                    </Form.Input>
                  </Fragment>
                </Fragment>
              )}
              {device.macVersion === '1.1' && device.type === 'OTAA' && (
                <Fragment>
                  <Header as="h4">Authentication Keys</Header>
                  <Divider clearing />
                  <Form.Input
                    width={9}
                    label="Application Key"
                    required
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                  >
                    <Cleave
                      placeholder={generatePlaceholder(
                        coupleOfBytesLengths.APPKEY,
                      )}
                      name="appKey"
                      value={formatBytes(device.appKey, null)}
                      options={{
                        ...cleaveOptions,
                        blocks: new Array(coupleOfBytesLengths.APPKEY).fill(2),
                      }}
                      onBlur={ev =>
                        this.onDeviceBlur(ev, coupleOfBytesLengths.APPKEY)
                      }
                      onChange={this.handleChangeCleave}
                    />
                  </Form.Input>

                  <Form.Input
                    width={9}
                    label="Network Session Key"
                    required
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                  >
                    <Cleave
                      placeholder={generatePlaceholder(
                        coupleOfBytesLengths.NWKSKEY,
                      )}
                      name="nwkSKey"
                      value={formatBytes(device.nwkSKey, null)}
                      options={{
                        ...cleaveOptions,
                        blocks: new Array(coupleOfBytesLengths.NWKSKEY).fill(2),
                      }}
                      onBlur={ev =>
                        this.onDeviceBlur(ev, coupleOfBytesLengths.NWKSKEY)
                      }
                      onChange={this.handleChangeCleave}
                    />
                  </Form.Input>
                </Fragment>
              )}
              {device.macVersion === '1.1' && device.type === 'ABP' && (
                <Fragment>
                  <Form.Input
                    width={9}
                    label="Device Address"
                    required
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                  >
                    <Cleave
                      placeholder={generatePlaceholder(
                        coupleOfBytesLengths.DEVADDR,
                      )}
                      name="devaddr"
                      value={formatBytes(device.devaddr, null)}
                      options={{
                        ...cleaveOptions,
                        blocks: new Array(coupleOfBytesLengths.DEVADDR).fill(2),
                      }}
                      onBlur={ev =>
                        this.onDeviceBlur(ev, coupleOfBytesLengths.DEVADDR)
                      }
                      onChange={this.handleChangeCleave}
                    />
                  </Form.Input>

                  <Fragment>
                    <Header as="h4">Authentication Keys</Header>
                    <Divider clearing />
                    <Form.Input
                      width={9}
                      label="Application Session Key"
                      required
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                    >
                      <Cleave
                        placeholder={generatePlaceholder(
                          coupleOfBytesLengths.APPSKEY,
                        )}
                        name="appSKey"
                        value={formatBytes(device.appSKey, null)}
                        options={{
                          ...cleaveOptions,
                          blocks: new Array(coupleOfBytesLengths.APPSKEY).fill(
                            2,
                          ),
                        }}
                        onBlur={ev =>
                          this.onDeviceBlur(ev, coupleOfBytesLengths.APPSKEY)
                        }
                        onChange={this.handleChangeCleave}
                      />
                    </Form.Input>

                    <Form.Input
                      width={defaultFormWidth}
                      label="Forwarding Network Session Integrity Key"
                      name="fNwkSIntKey"
                      value={idx(device, _ => _.device.fNwkSIntKey)}
                      onChange={this.handleChange}
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                    />

                    <Form.Input
                      width={defaultFormWidth}
                      label="Serving Network Session Integrity Key"
                      name="sNwkSIntKey"
                      value={idx(device, _ => _.device.sNwkSIntKey)}
                      onChange={this.handleChange}
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                    />

                    <Form.Input
                      width={defaultFormWidth}
                      label="Network Session Encryption Key"
                      name="nwkSEncKey"
                      value={idx(device, _ => _.device.nwkSEncKey)}
                      onChange={this.handleChange}
                      disabled={
                        !(
                          device.UserId &&
                          device.macVersion &&
                          device.type !== ''
                        )
                      }
                    />
                  </Fragment>
                </Fragment>
              )}
              <Header as="h4">Position</Header>
              <Divider clearing />
              <Grid padded columns={2}>
                <Grid.Column mobile={16} tablet={7} computer={7}>
                  <Form.Input
                    // width={defaultFormWidth}
                    label="Latitude"
                    name="lat"
                    onBlur={this.validateField}
                    value={device.lat}
                    onChange={this.handleChange}
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                    error={errors.lat}
                  />

                  <Form.Input
                    // width={defaultFormWidth}
                    label="Longitude"
                    name="lng"
                    value={device.lng}
                    onChange={this.handleChange}
                    onBlur={this.validateField}
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                    error={errors.lng}
                  />

                  <Form.Input
                    // width={defaultFormWidth}
                    label="Altitude"
                    name="alt"
                    value={device.alt}
                    onChange={this.handleChange}
                    onBlur={this.validateField}
                    disabled={
                      !(
                        device.UserId &&
                        device.macVersion &&
                        device.type !== ''
                      )
                    }
                    error={errors.alt}
                  />
                </Grid.Column>
                <Grid.Column>
                  {Object.values(errors).includes(true) && (
                    <Message negative>
                      <Message.Header>Set all coordinates</Message.Header>
                      <p>Please check your coordinates</p>
                    </Message>
                  )}
                </Grid.Column>
              </Grid>
              <Header as="h4">Device Details</Header>
              <Divider clearing />
              <Form.Input
                width={defaultFormWidth}
                label="Model"
                name="model"
                value={idx(device, _ => _.device.model)}
                onChange={this.handleChange}
                disabled={
                  !(device.UserId && device.macVersion && device.type !== '')
                }
              />
              <Form.Input
                width={defaultFormWidth}
                label="Serial"
                name="serial"
                value={idx(device, _ => _.device.serial)}
                onChange={this.handleChange}
                disabled={
                  !(device.UserId && device.macVersion && device.type !== '')
                }
              />
              <Form.Input
                width={defaultFormWidth}
                label="Firmware"
                name="firmware"
                value={idx(device, _ => _.device.firmware)}
                onChange={this.handleChange}
                disabled={
                  !(device.UserId && device.macVersion && device.type !== '')
                }
              />
              <Form.Button
                content="Save"
                type="submit"
                disabled={
                  !(device.UserId && device.macVersion && device.type !== '') &&
                  !Object.values(errors).includes(true)
                }
              />
            </Form>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    );
  }
}

DeviceAddForm.propTypes = {
  application: PropTypes.object,
  usersOptions: PropTypes.object,
  applicationsOptions: PropTypes.object,
  fetchUsersOptions: PropTypes.func,
  deviceProfilesOptions: PropTypes.object,
  fetchApplicationsOptions: PropTypes.func,
  fetchDeviceProfilesOptions: PropTypes.func,
  destroyUsersOptions: PropTypes.func,
  destroyDeviceProfilesOptions: PropTypes.func,
  destroyApplicationsOptions: PropTypes.func,
  destroyDevice: PropTypes.func,
  postDevice: PropTypes.func,
};

const DeviceCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceAddForm),
);

export { DeviceCreate };
