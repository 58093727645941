/** @format */

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Dimmer,
  Divider,
  Header,
  Loader,
  Segment,
  Table,
} from 'semantic-ui-react';
import devLog from '../../../lib/devLog';
import FrequencyChannel from '../FrequencyChannel/FrequencyChannel';
import { MacCommandCtx } from '../MacCommand';
import {
  pollingStates,
  useMacCommandPolling,
  useMacCommandService,
} from '../useMacCommandService';

const FrequencyChannels = props => {
  const { deviceID, deviceNeverSend, handleDeviceError } = useContext(
    MacCommandCtx,
  );

  const [rows, setRows] = useState([]);
  const [editRows, setEditRows] = useState([]);
  const [loader, setLoader] = useState(true);

  const { deviceFrequencyCh } = useMacCommandService(deviceID);
  const getFrCh = () => {
    deviceFrequencyCh
      .get()
      .then(res => {
        if (res.data && res.data.rows) {
          setRows(res.data.rows);
          setLoader(false);
        }
      })
      .catch(err => {
        handleDeviceError(err);
        setLoader(false);
      });
  };
  const { setPolling } = useMacCommandPolling(deviceID, getFrCh);

  const putFrCh = (newRow, isFromInsert) => {
    deviceFrequencyCh
      .put(newRow)
      .then(res => {})
      .catch(err => {
        handleDeviceError(err);
      })
      .finally(() => {
        getFrCh();
        if (isFromInsert) setEditRows([]);
      });
  };
  const delFrCh = row => {
    deviceFrequencyCh
      .del(row.index)
      .then(res => {
        getFrCh();
      })
      .catch(err => {
        handleDeviceError(err);
        getFrCh();
      });
  };

  useEffect(() => {
    if (deviceID !== null && !deviceNeverSend) getFrCh();
    if (deviceNeverSend) setLoader(false);
  }, [deviceID, deviceNeverSend]);

  useEffect(() => {
    if (
      rows &&
      rows.length > 0 &&
      rows.map(({ state }) => state).includes('pending')
    ) {
      setPolling(pollingStates[0]);
    } else {
      setPolling(pollingStates[1]);
    }
  }, [rows]);

  const setAvailableIndex = () => {
    const unAvailableIndex = rows.map(x => x.index);
    for (let i = 0; i <= 15; i += 1) {
      if (!unAvailableIndex.includes(i)) {
        return i;
      }
    }
    return null;
  };

  const addRow = () => {
    const chIndex = setAvailableIndex();
    const lastRow = [...rows].pop();
    const newRow = { ...lastRow, index: chIndex, edit: true };
    delete newRow.state;
    if (chIndex !== null && chIndex <= 15) setEditRows([...editRows, newRow]);
    else devLog('impossible to update');
  };

  return (
    <Card fluid>
      <Card.Content>
        <Header as="h3" floated="left" content={'Frequency Channels'} />
        <Button
          key="insert"
          icon
          disabled={deviceNeverSend || editRows.length > 0}
          color="linkedin"
          labelPosition="left"
          floated="right"
          onClick={() => addRow()}
        >
          <FontAwesomeIcon
            icon={faPlus}
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Insert
        </Button>

        <Divider clearing className="secondary-color" />
        {loader === false ? (
          <>
            <Table
              className="unstackable"
              basic="very"
              style={{ textAlign: 'center' }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Index</Table.HeaderCell>
                  <Table.HeaderCell>Frequency [Mhz]</Table.HeaderCell>
                  <Table.HeaderCell>MinDR</Table.HeaderCell>
                  <Table.HeaderCell>MaxDR</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              {rows.length > 0 && (
                <Table.Body>
                  {rows.map((row, idx) => (
                    <FrequencyChannel
                      key={row.index}
                      deleteRow={() => delFrCh(row)}
                      editRow={editedRow => putFrCh(editedRow, false)}
                      stopPolling={() => setPolling(pollingStates[1])}
                      row={row}
                      idx={idx}
                    />
                  ))}
                  {editRows &&
                    editRows.length > 0 &&
                    editRows.map((eRow, idx) => (
                      <FrequencyChannel
                        key={eRow.index}
                        deleteRow={() => setEditRows([])}
                        editRow={editedRow => putFrCh(editedRow, true)}
                        editOn={true}
                        stopPolling={() => setPolling(pollingStates[1])}
                        row={eRow}
                        idx={eRow.index}
                      />
                    ))}
                </Table.Body>
              )}
            </Table>
            {deviceNeverSend && (
              <Segment style={{ textAlign: 'center' }}>
                Nothing to show!
              </Segment>
            )}
          </>
        ) : (
          <div>
            <Segment style={{ height: '100px' }}>
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
            </Segment>
          </div>
        )}
      </Card.Content>
    </Card>
  );
};

export default FrequencyChannels;
