/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { toastError } from '../lib/utilities';

export const POST_DOWNLINK_REQUEST = 'downlink/POST_REQUEST';
export const POST_DOWNLINK_SUCCESS = 'downlink/POST_SUCCESS';
export const POST_DOWNLINK_FAILURE = 'downlink/POST_FAILURE';
export const DELETE_DOWNLINK_REQUEST = 'downlink/DELETE_REQUEST';
export const DELETE_DOWNLINK_SUCCESS = 'downlink/DELETE_SUCCESS';
export const DELETE_DOWNLINK_FAILURE = 'downlink/DELETE_FAILURE';

export const postDownlink = (application, device, downlink) => ({
  [CALL_API]: {
    endpoint: url.DownlinkCreate(application, device),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(downlink),
    types: [
      POST_DOWNLINK_REQUEST,
      {
        type: POST_DOWNLINK_SUCCESS,
        payload: (action, state, res) => res.json().then(app => app),
      },
      {
        type: POST_DOWNLINK_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const deleteDownlink = (application, device, downlinkId) => ({
  [CALL_API]: {
    endpoint: url.DownlinkDelete(application, device, downlinkId),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_DOWNLINK_REQUEST,
      {
        type: DELETE_DOWNLINK_SUCCESS,
        payload: (action, state, res) => res.json().then(app => app),
      },
      {
        type: DELETE_DOWNLINK_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});
