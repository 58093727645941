/** @format */

import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Header } from 'semantic-ui-react';
import { destroyApplication, postApplication } from '../../actions/application';
import {
  destroyApplications,
  fetchApplications,
} from '../../actions/applications';
import { fetchGatewayProfilesOptions } from '../../actions/gatewayProfiles';
import { fetchUsersOptions } from '../../actions/users';
import { getCurrentUserId, userHasRole } from '../../lib/auth';
import { formatOptions, toastError } from '../../lib/utilities';

const mapStateToProps = state => ({
  application: state.application,
  usersOptions:
    state.usersOptions.options.length > 0
      ? [...formatOptions(state.usersOptions.options, 'Standard User')]
      : state.usersOptions.options,
});

const mapDispatchToProps = dispatch => ({
  postApplication: application => {
    dispatch(postApplication(application));
  },
  destroyApplication: () => {
    dispatch(destroyApplication());
  },
  fetchApplications: () => {
    dispatch(fetchApplications());
  },
  destroyApplications: () => {
    dispatch(destroyApplications());
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
  fetchGatewayProfilesOptions: (user, params = {}) => {
    dispatch(fetchGatewayProfilesOptions(user, params));
  },
});

class DeviceApplicationAddForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      disableDropdown: false,
      application: {
        UserId: null,
        label: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeUserId = this.handleChangeUserId.bind(this);
  }

  componentDidMount() {
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
    } else {
      this.props.fetchGatewayProfilesOptions(getCurrentUserId());
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { UserId, usersOptions } = this.props;
    if (
      userHasRole('admin') &&
      usersOptions.length > 0 &&
      prevProps.UserId !== UserId
    ) {
      const disableDropdown =
        usersOptions.map(x => x.value).includes(UserId) && UserId !== undefined;
      if (disableDropdown) this.handleChangeUserId(null, { value: UserId });
      if (this.state.disableDropdown !== disableDropdown)
        this.setState({ disableDropdown });
    }
  }

  componentWillUnmount() {
    this.props.destroyApplication();
  }

  handleChange(e, { name, value }) {
    this.setState({
      application: { ...this.state.application, [name]: value },
    });
  }

  handleSubmit(e) {
    const isSavable =
      !userHasRole('admin') ||
      (userHasRole('admin') && this.state.application.UserId !== null);

    if (isSavable) {
      const tempApp = { ...this.state.application };

      if (!userHasRole('admin')) {
        delete tempApp.UserId;
      }

      this.props.postApplication(tempApp);
      this.props.destroyApplication();

      this.setState({
        application: {
          ...this.state.application,
          label: '',
        },
      });
      this.props.handleDeviceApplicationSidebar(e);
    } else {
      toastError(
        { message: 'Select a Standard User to save application' },
        `Impossible to save ${this.state.application.label}`,
      );
    }
  }

  handleSearchUserId(e, { searchQuery }) {
    const params = { filter: { username: searchQuery } };
    this.props.fetchUsersOptions(params);
  }

  handleChangeUserId(e, { value }) {
    if (e) e.persist();

    this.setState({
      application: {
        ...this.state.application,
        UserId: value,
      },
    });
  }

  render() {
    const { application, disableDropdown } = this.state;
    const { inverted, usersOptions } = this.props;
    const uId = idx(application, _ => _.UserId);

    return (
      <Fragment>
        <Header as="h2" style={{ marginTop: '1em' }} floated="left">
          Add Application
        </Header>

        <Form
          onSubmit={this.handleSubmit}
          inverted={inverted || false}
          style={{ marginTop: '4rem' }}
        >
          <Form.Input
            label="Label"
            name="label"
            required
            value={application.label || ''}
            onChange={this.handleChange}
          />

          {userHasRole('admin') && (
            <Form.Dropdown
              name="stdUserApp"
              label="Standard User"
              placeholder="choose one..."
              selection
              required
              search
              options={usersOptions}
              value={uId}
              onChange={this.handleChangeUserId}
              disabled={usersOptions.length === 0 || disableDropdown}
              loading={usersOptions.length === 0}
            />
          )}

          <Form.Button
            content="Save"
            type="submit"
            style={{ marginTop: '2em' }}
          />
        </Form>
      </Fragment>
    );
  }
}

DeviceApplicationAddForm.propTypes = {
  UserId: PropTypes.number,
  usersOptions: PropTypes.array,
  inverted: PropTypes.bool,
  destroyApplication: PropTypes.func,
  postApplication: PropTypes.func,
  fetchApplications: PropTypes.func,
  destroyApplications: PropTypes.func,
  handleDeviceApplicationSidebar: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  fetchGatewayProfilesOptions: PropTypes.func,
};

const ApplicationCreate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceApplicationAddForm);

export { ApplicationCreate };
