/** @format */

import * as actions from '../actions/tabs';

const defaultState = {
  devices: {
    index: 0
  },
  applications: {
    index: 0
  },
  gateways: {
    index: 0
  },
  users: {
    index: 0
  }
};

export const tabs = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_TAB:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};