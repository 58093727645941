/** @format */
import React from 'react';
import PropTypes from 'prop-types';

import DefaultTable from '../../components/Utils/DefaultTable';

const DeviceProfiles = ({profiles}) => {
  return <>
    <DefaultTable
      headers={[
        {
          sortable: false,
          name: 'ID',
          key: 'id',
        },
        {
          sortable: false,
          name: 'macVersion',
          key: 'macVersion',
        },
        {
          sortable: false,
          name: 'supports32bitFCnt',
          key: 'supports32bitFCnt',
        },
        {
          sortable: false,
          name: 'supportsClassB',
          key: 'supportsClassB',
        },
        {
          sortable: false,
          name: 'supportsClassC',
          key: 'supportsClassC',
        }
      ]}
      items={profiles} 
    />
  </>
};

DeviceProfiles.propTypes = {
  profiles: PropTypes.object
};

export { DeviceProfiles };