/**
 * /* eslint-disable no-console
 *
 * @format
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
  Icon,
  Grid
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

import {
  CSV_HEADER
} from '../../lib/importGateways';

import { generateCSV } from '../../lib/csv';

const ImportGatewaysTemplateDownload = ({applicationVersion}) => {
  const { rows: users = [] } = useSelector(state => state.users);

  const csvHeader = CSV_HEADER;
  const csvData = generateCSV('gateways', csvHeader, { users: users.filter(u => !['admin', 'system'].includes(u.profile.role)).map(u => u.id).sort() });

  return (
    <>
      <Grid>
        <Grid.Row centered columns={1}>
          <Grid.Column textAlign='center'>
            <CSVLink
              data={csvData}
              headers={csvHeader}
              filename={`gateways-template.csv`}
              className={`ui button`}
            >
              <Icon name="download" /> Download template
            </CSVLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      
    </>
  )
}

ImportGatewaysTemplateDownload.propTypes = {
  applicationVersion: PropTypes.string
};

export { ImportGatewaysTemplateDownload };