/** @format */

import { CALL_API } from 'redux-api-middleware';

import { Endpoints as url } from '../lib/endpoints';
import { useThisQueryString } from '../lib/urls';
import { getCurrentUserToken, deleteCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_DEVICES_REQUEST = 'devices/FETCH_REQUEST';
export const FETCH_DEVICES_SUCCESS = 'devices/FETCH_SUCCESS';
export const FETCH_DEVICES_FAILURE = 'devices/FETCH_FAILURE';

export const FETCH_DEVICES_SHARED_BY_REQUEST = 'devicesSharedBy/FETCH_REQUEST';
export const FETCH_DEVICES_SHARED_BY_SUCCESS = 'devicesSharedBy/FETCH_SUCCESS';
export const FETCH_DEVICES_SHARED_BY_FAILURE = 'devicesSharedBy/FETCH_FAILURE';

export const FETCH_DEVICES_SHARED_WITH_REQUEST =
  'devicesSharedWith/FETCH_REQUEST';
export const FETCH_DEVICES_SHARED_WITH_SUCCESS =
  'devicesSharedWith/FETCH_SUCCESS';
export const FETCH_DEVICES_SHARED_WITH_FAILURE =
  'devicesSharedWith/FETCH_FAILURE';

export const DESTROY_DEVICES_SUCCESS = 'devices/DESTROY_SUCCESS';
export const DESTROY_DEVICES_SHARED_WITH_SUCCESS =
  'devicesSharedWith/DESTROY_SUCCESS';
export const DESTROY_DEVICES_SHARED_BY_SUCCESS =
  'devicesSharedBy/DESTROY_SUCCESS';
export const SEARCH_DEVICES = 'devices/SEARCH_DEVICES';
export const ORDER_DEVICES = 'devices/ORDER_DEVICES';

// ACTION CREATORS
export const fetchDevices = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.Devices(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICES_REQUEST,
      {
        type: FETCH_DEVICES_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            ...json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_DEVICES_FAILURE,
        payload: (action, state, res) => {
          // TODO: questo controllo dovrebbe esser fatto in OGNI chiamata e la deleteCurrentUserToken fatta solo se l'utente è veramente sloggato (401)
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          // TODO: non è detto che err abbia sempre un message ne che sia possibile trasformarlo in un JSON... la modifica andrebbe propagata e irrobustita
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchDevicesSharedBy = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.DevicesSharedBy(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICES_SHARED_BY_REQUEST,
      {
        type: FETCH_DEVICES_SHARED_BY_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            ...json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_DEVICES_SHARED_BY_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchDevicesSharedWith = (params = { qs: '' }) => ({
  [CALL_API]: {
    endpoint: useThisQueryString(url.DevicesSharedWith(), params),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICES_SHARED_WITH_REQUEST,
      {
        type: FETCH_DEVICES_SHARED_WITH_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => ({
            ...json,
            filter: 'filter' in params ? params.filter : {},
          })),
      },
      {
        type: FETCH_DEVICES_SHARED_WITH_FAILURE,
        payload: (action, state, res) => {
          if (res && res.status && res.status === 401) {
            deleteCurrentUserToken();
            history.push(`${PAGES_PATH}`);
          }
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyDevices = () => ({
  type: DESTROY_DEVICES_SUCCESS,
});

export const destroyDevicesSharedBy = () => ({
  type: DESTROY_DEVICES_SHARED_BY_SUCCESS,
});

export const destroyDevicesSharedWith = () => ({
  type: DESTROY_DEVICES_SHARED_WITH_SUCCESS,
});

export const searchDevices = text => ({ type: SEARCH_DEVICES, payload: text });

export const orderDevices = clickedColumn => ({
  type: ORDER_DEVICES,
  payload: clickedColumn,
});
