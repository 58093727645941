/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { PAGES_PATH } from '../lib/variables';
import history from '../history';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_DEVICE_REQUEST = 'device/FETCH_REQUEST';
export const FETCH_DEVICE_SUCCESS = 'device/FETCH_SUCCESS';
export const FETCH_DEVICE_FAILURE = 'device/FETCH_FAILURE';

export const POST_DEVICE_REQUEST = 'device/POST_REQUEST';
export const POST_DEVICE_SUCCESS = 'device/POST_SUCCESS';
export const POST_DEVICE_FAILURE = 'device/POST_FAILURE';

export const PUT_DEVICE_REQUEST = 'device/PUT_REQUEST';
export const PUT_DEVICE_SUCCESS = 'device/PUT_SUCCESS';
export const PUT_DEVICE_FAILURE = 'device/PUT_FAILURE';

export const DELETE_DEVICE_REQUEST = 'device/DELETE_REQUEST';
export const DELETE_DEVICE_SUCCESS = 'device/DELETE_SUCCESS';
export const DELETE_DEVICE_FAILURE = 'device/DELETE_FAILURE';

export const DESTROY_DEVICE_SUCCESS = 'device/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchDevice = device => ({
  [CALL_API]: {
    endpoint: url.Device(device),
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_REQUEST,
      {
        type: FETCH_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/devices`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const postDevice = device => ({
  [CALL_API]: {
    endpoint: url.DeviceCreate(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(device),
    types: [
      POST_DEVICE_REQUEST,
      {
        type: POST_DEVICE_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => {
            history.push({ pathname: `${PAGES_PATH}/device/${json.id}` });
            return json;
          }),
      },
      {
        type: POST_DEVICE_FAILURE,
        payload: (action, state, res, err) =>
          res.json().then(errtoast => toastError(errtoast)),
      },
    ],
  },
});

export const putDevice = device => ({
  [CALL_API]: {
    endpoint: url.Device(device.id),
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: () => {
      const newDevice = {
        ...device,
      };

      delete newDevice.id;
      return JSON.stringify(newDevice);
    },
    types: [
      PUT_DEVICE_REQUEST,
      {
        type: PUT_DEVICE_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/device/${device.id}` });
          return res.json().then(json => json);
        },
      },
      {
        type: PUT_DEVICE_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const deleteDevice = deviceId => ({
  [CALL_API]: {
    endpoint: url.Device(deviceId),
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      DELETE_DEVICE_REQUEST,
      {
        type: DELETE_DEVICE_SUCCESS,
        payload: (action, state, res) => {
          history.push({ pathname: `${PAGES_PATH}/devices` });
          return res.json().then(json => json);
        },
      },
      {
        type: DELETE_DEVICE_FAILURE,
        payload: (action, state, res) => {
          history.push(`${PAGES_PATH}/devices`);
          return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const destroyDevice = () => ({
  type: DESTROY_DEVICE_SUCCESS,
});
