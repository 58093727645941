/** @format */

import React, { useContext } from 'react';
import { Dropdown, Input, Segment } from 'semantic-ui-react';
import { MacCommandCtx } from '../MacCommand';
import MacCard from './MacCard';
import MacCardListItem from './MacCardListItem';
import { useMacCard } from './useMacCard';

/**
 * [REQ 2.12-003] RX Params Setup (rx-param): deve essere realizzata una sezione dove sia possibile inviare
 * il comando (con il semplice click su un bottone) e attendere la risposta di tale comando.
 * Il comando dovrà avere i seguenti parametri:
 * rx1DRoffset: [RX1 DROffset] prende i valori interi da 0 a 5
 * rx2DataRate: [RX2 DataRate] prende i valori interi da 0 a 6, all’utente devono essere restituite le stringhe corrispondenti ai seguenti valori:
 * 0 -> DR0 / SF12BW125
 * 1 -> DR1 / SF11BW125
 * 2 -> DR2 / SF10BW125
 * 3 -> DR3 / SF9BW125
 * 4 -> DR4 / SF8BW125
 * 5 -> DR5 / SF7BW125
 * 6 -> DR6 / SF7BW250
 * 7 -> DR7 / FSK
 * rx2Frequency: [RX2 Frequency [MHz]] prende i numeri tra 100 e 1670, consente tre cifre decimali
 ---
 * L’invio del comando si realizza tramite la chiamata PUT /api/v2/device/{{did}}/params/rx-param HTTP/1.1 –
 * { "rx1DRoffset": {{ rx1DRoffsv v et }}, "rx2DataRate": {{ rx2DataRate }}, "rx2Frequency": {{ rx2Frequency }} }
 * Con l’endpoint GET /api/v2/device/{{did}}/params/rx-param HTTP/1.1 si andrà a prelevare lo stato del dispositivo che potrà essere:
 *
 * HTTP/1.1 200 OK: i campi presenti potranno essere:
 * state: (sempre presente) che potrà prendere i valori “pending” (se in attesa di una risposta) o “sync”.
 * rx1DRoffset: (sempre presente) RX1 DROffset
 * rx2DataRate: (sempre presente) RX2 DataRate
 * rx2Frequency: (sempre presente) RX2 Frequency [MHz]
 * pendingValues: oggetto contenente i valori rx1DRoffset, rx2DataRate e rx2Frequency inviati al dispositivo.
 *
 * HTTP/1.1 403 Forbidden - { "status": 403, "message": "Information not available" }; quando il device non ha mai parlato. In questo caso la tabella avrà il campo rx1DRoffset valorizzato a 0, il campo rx2DataRate valorizzato a “DR0 / SF12BW125” (0) e il campo rx2Frequency valorizzato a 869.525.
 *
 * Una volta inviato il comando (PUT) il componente dovrà periodicamente richiedere lo stato (GET) per visualizzare gli aggiornamenti.
 * @returns {*}
 * @constructor
 */
const MacCardRxParams = () => {
  const { deviceID, deviceNeverSend } = useContext(MacCommandCtx);
  const title = 'RX Params Setup';
  const rxValues = [
    'DR0 / SF12BW125',
    'DR1 / SF11BW125',
    'DR2 / SF10BW125',
    'DR3 / SF9BW125',
    'DR4 / SF8BW125',
    'DR5 / SF7BW125',
    'DR6 / SF7BW250',
    'DR7 / FSK,', // da 0 a 6 => ma questo è 7???
  ];
  const options = rxValues.map((x, i) => ({ key: i + x, text: x, value: i }));
  const [offsetMin, offsetMax] = [0, 5];
  const [rangeMin, rangeMax] = [100, 1670];
  const { param1, param2, param3 } = {
    param1: 'rx1DRoffset',
    param2: 'rx2DataRate',
    param3: 'rx2Frequency',
  };
  const neverSendData = {
    [param1]: offsetMin,
    [param2]: 0,
    [param3]: rangeMin,
  };
  const {
    editMode,
    pendingMode,
    togglePending,
    displayDataParam,
    displayDataFrequency,
    displayDataListParam,
    startEdit,
    changeEdit,
    endEdit,
    tempData,
    data,
  } = useMacCard(deviceID, 'rxParams', deviceNeverSend, neverSendData, {
    frequencyParam: param3,
  });

  const msg = `Be careful!! The frequency should be between ${rangeMin} and  ${rangeMax}!`;
  const inputError = editMode
    ? displayDataFrequency(param3) < rangeMin ||
      displayDataFrequency(param3) > rangeMax
    : false;
  return (
    <MacCard
      title={title}
      startEdit={() => startEdit()}
      editMode={editMode}
      endEdit={save => endEdit(save)}
      pendingMode={pendingMode}
      togglePending={() => togglePending()}
      state={data && data.state}
    >
      <MacCardListItem
        label={'RX1 DRoffset'}
        editMode={editMode}
        value={displayDataParam(param1)}
      >
        {editMode && (
          <Input
            fluid
            type="range"
            min={offsetMin}
            max={offsetMax}
            name={param1}
            onChange={(e, selection) => changeEdit(selection)}
            value={tempData[param1]}
          />
        )}
      </MacCardListItem>

      <MacCardListItem
        label={'RX2 DataRate'}
        editMode={editMode}
        value={displayDataListParam(param2, rxValues)}
      >
        {editMode && (
          <Dropdown
            fluid
            search
            selection
            name={param2}
            value={tempData[param2]}
            options={options}
            onChange={(e, selection) => changeEdit(selection)}
          />
        )}
      </MacCardListItem>

      <MacCardListItem
        label={'RX Frequency [Mhz]'}
        editMode={editMode}
        value={displayDataFrequency(param3)}
      >
        {editMode && (
          <>
            <Input
              fluid
              type="range"
              min={rangeMin}
              max={rangeMax}
              step={0.001}
              name={param3}
              onChange={(e, selection) => changeEdit(selection)}
              value={tempData[param3]}
            />
            <Input
              error={inputError}
              placeholder={msg}
              type={'number'}
              step={0.001}
              fluid
              name={param3}
              value={tempData[param3]}
              onChange={(e, selection) => changeEdit(selection)}
            />

            {inputError && (
              <Segment inverted color="red" secondary content={msg} />
            )}
          </>
        )}
      </MacCardListItem>
    </MacCard>
  );
};

export default MacCardRxParams;
