/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Form, Header, Message } from 'semantic-ui-react';
import SVG from 'react-inlinesvg';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { validatePassword } from '../../lib/utilities';
import { userSignIn } from '../../actions/auth';
import {
  postUserForgetPassword,
  fetchUserByToken,
  userChangePassword,
} from '../../actions/user';
import devLog from '../../lib/devLog';

const mapStateToProps = state => ({
  user: state.user,
  password: state.password,
});

const mapDispatchToProps = dispatch => ({
  postUserForgetPassword: param => {
    dispatch(postUserForgetPassword(param));
  },
  fetchUserByToken: token => {
    dispatch(fetchUserByToken(token));
  },
  userChangePassword: (token, uid, password) => {
    dispatch(userChangePassword(token, uid, password));
  },
  userSignIn: (username, password) => {
    dispatch(userSignIn(username, password));
  },
});

class UserForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    const params = queryString.parse(this.props.location.search);

    this.state = {
      token: params.token,
      requestUserId: params.id,
      searchUser: null,
      passwordChange: null,
      passwordCheck: null,
      button: {
        disabled: false,
        content: 'ONE MORE TIME!!!',
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResend = this.handleResend.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { token } = this.state;

    if (token) {
      this.props.fetchUserByToken(token);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }

    if (nextProps.user !== this.props.user) {
      return true;
    }

    if (nextProps.password !== this.props.password) {
      return true;
    }

    return false;
  }

  handleChange(e, { name, value }) {
    this.setState({ ...this.state, [name]: value });
  }

  handleChangePassword(e) {
    const { token, passwordChange, requestUserId } = this.state;
    e.preventDefault();
    this.props.userChangePassword(token, requestUserId, passwordChange);
  }

  handleSubmit(e) {
    const { searchUser } = this.state;

    e.preventDefault();
    this.props.postUserForgetPassword({ user: searchUser });
  }

  handleResend(e) {
    const { user } = this.props;

    let c = 10;
    devLog('handleResend');
    this.props.postUserForgetPassword({ user: user.email });

    this.setState({
      ...this.state,
      button: {
        disabled: true,
        content: `Wait ${c} seconds to send another token`,
      },
    });

    const timerId = setInterval(() => {
      c -= 1;
      this.setState({
        ...this.state,
        button: {
          disabled: true,
          content: `Wait ${c} seconds to send another token`,
        },
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timerId);
      this.setState({
        ...this.state,
        button: {
          disabled: false,
          content: 'ONE MORE TIME!!!',
        },
      });
    }, 10000);
  }

  handleSignIn(e) {
    e.preventDefault();
    this.props.userSignIn(this.state.username, this.state.password);
  }

  render() {
    const { passwordChange, passwordCheck, token } = this.state;
    const { user } = this.props;

    return (
      <Fragment>
        {devLog(user)}
        {user.error === true && (
          <Message negative>
            <Message.Header>OPS! SOMETHING WENT WRONG!!!</Message.Header>
            <p>Please check your information or contact-us.</p>
          </Message>
        )}

        {!user.id && !user.passwordChanged && (
          <Fragment>
            <Container textAlign="center" style={{ marginBottom: '2em' }}>
              <SVG
                src="/assets/images/robotDOH.svg"
                className="thanks"
              />
            </Container>
            <Form onSubmit={this.handleSubmit}>
              <Header as="h1">FORGOT YOUR PASSWORD?</Header>
              <p>Please fill with your email.</p>
              <Form.Input
                name="searchUser"
                placeholder="Email"
                required
                onChange={this.handleChange}
              />
              <Form.Button content="ASK PASSWORD" type="submit" />
            </Form>
          </Fragment>
        )}

        {user.id && !token && (
          <Fragment>
            <Container textAlign="center" style={{ marginBottom: '2em' }}>
              <SVG
                src="/assets/images/robotYeah.svg"
                className="thanks"
              />
            </Container>
            <Header as="h1">Check your email account!</Header>
            <p>You will receive a reset link by email.</p>
            <p>Click-it to complete your sign up.</p>
            <h4>Enjoy!!!</h4>
            <p style={{ marginBottom: '2em' }}>
              If you have not recevied any email, request a new send
            </p>
            <Container textAlign="center">
              <Button
                color="red"
                onClick={this.handleResend}
                disabled={this.state.button.disabled}
                content={this.state.button.content}
              />
            </Container>
          </Fragment>
        )}

        {user.id && token && !user.passwordChanged && (
          <Fragment>
            <Container textAlign="center">
              <SVG
                src="/assets/images/robotLove.svg"
                className="thanks"
              />
            </Container>
            <Header as="h1">
              Hi {this.props.user.firstname}, change your password now!
            </Header>
            <p>Please fill with a new password.</p>
            <Form onSubmit={this.handleChangePassword}>
              <Form.Input
                name="passwordChange"
                type="password"
                required
                onChange={this.handleChange}
              />
              <Form.Input
                name="passwordCheck"
                type="password"
                required
                error={
                  passwordChange !== passwordCheck ||
                  !validatePassword(passwordChange)
                }
                onChange={this.handleChange}
              />
              <Form.Button
                content="Save new password"
                type="submit"
                color="red"
                disabled={
                  !(
                    passwordChange === passwordCheck &&
                    passwordChange !== null &&
                    passwordCheck !== null &&
                    validatePassword(passwordChange)
                  )
                }
              />
            </Form>
          </Fragment>
        )}

        {token && user.passwordChanged && (
          <Fragment>
            <Container textAlign="center">
              <SVG
                src="/assets/images/robotYeah.svg"
                className="thanks"
              />
            </Container>
            <Header as="h1">Your password was changed!</Header>
            <p>Please, sign-in with new credentials</p>
            <Form onSubmit={this.handleSignIn}>
              <Form.Input
                name="username"
                size="large"
                icon="user"
                label="Username"
                onChange={this.handleChange}
                required
                defaultValue={user.username}
              />
              <Form.Input
                name="password"
                type="password"
                size="large"
                icon="key"
                label="Password"
                onChange={this.handleChange}
                required
              />
              <Button color="red" type="submit" animated="vertical">
                <Button.Content visible>Sign in</Button.Content>
                <Button.Content hidden>Come on!!!</Button.Content>
              </Button>
            </Form>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

UserForgotPasswordForm.propTypes = {
  user: PropTypes.object,
  password: PropTypes.object,
  location: PropTypes.object,
  postUserForgetPassword: PropTypes.func,
  fetchUserByToken: PropTypes.func,
  userChangePassword: PropTypes.func,
  userSignIn: PropTypes.func,
};

const UserForgotPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserForgotPasswordForm);

export { UserForgotPassword };
