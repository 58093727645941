/** @format */

import * as actions from '../actions/userProfileSingle';

const defaultState = {
  options: [],
  isLoading: false,
  error: false,
};

export const userProfileSingle = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_PROFILE_SINGLE_REQUEST:
      return {
        isLoading: true,
        error: false,
        options: [],
      };
    case actions.FETCH_USER_PROFILE_SINGLE_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_USER_PROFILE_SINGLE_FAILURE:
      return {
        isLoading: false,
        error: true,
        options: [],
      };
    case actions.DESTROY_USER_PROFILE_SINGLE_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };
    default:
      return state;
  }
};
