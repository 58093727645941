/** @format */

import { CALL_API } from 'redux-api-middleware';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';
import { generateUrl, generateQueryString } from '../lib/urls';
import { toastError } from '../lib/utilities';

// ACTION TYPES
export const FETCH_GATEWAY_PROFILE_GATEWAYS_REQUEST =
  'gatewayProfileGateways/FETCH_REQUEST';
export const FETCH_GATEWAY_PROFILE_GATEWAYS_SUCCESS =
  'gatewayProfileGateways/FETCH_SUCCESS';
export const FETCH_GATEWAY_PROFILE_GATEWAYS_FAILURE =
  'gatewayProfileGateways/FETCH_FAILURE';

export const FETCH_GATEWAY_PROFILE_GATEWAYS_MAP_REQUEST =
  'gatewayProfileGatewaysMap/FETCH_REQUEST';
export const FETCH_GATEWAY_PROFILE_GATEWAYS_MAP_SUCCESS =
  'gatewayProfileGatewaysMap/FETCH_SUCCESS';
export const FETCH_GATEWAY_PROFILE_GATEWAYS_MAP_FAILURE =
  'gatewayProfileGatewaysMap/FETCH_FAILURE';

export const DESTROY_GATEWAY_PROFILE_GATEWAYS_SUCCESS =
  'gatewayProfileGateways/DESTROY_SUCCESS';

export const DESTROY_GATEWAY_PROFILE_GATEWAYS_MAP_SUCCESS =
  'gatewayProfileGatewaysMap/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchGatewayProfileGateways = (gatewayProfile, params = {}) => ({
  [CALL_API]: {
    endpoint: generateUrl(
      url.GatewayProfileGateways(gatewayProfile),
      generateQueryString(params),
    ),
    method: 'GET',
    headers: {
      'Content-Type': 'gatewayProfile/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_GATEWAY_PROFILE_GATEWAYS_REQUEST,
      {
        type: FETCH_GATEWAY_PROFILE_GATEWAYS_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_GATEWAY_PROFILE_GATEWAYS_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const fetchGatewayProfileGatewaysMap = gatewayProfile => ({
  [CALL_API]: {
    endpoint: url.GatewayProfileGatewaysMap(gatewayProfile),
    method: 'GET',
    headers: {
      'Content-Type': 'gatewayProfile/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_GATEWAY_PROFILE_GATEWAYS_MAP_REQUEST,
      {
        type: FETCH_GATEWAY_PROFILE_GATEWAYS_MAP_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(gatewayProfiles => gatewayProfiles),
      },
      {
        type: FETCH_GATEWAY_PROFILE_GATEWAYS_MAP_FAILURE,
        payload: (action, state, res) =>
          res.json().then(err => toastError(err)),
      },
    ],
  },
});

export const destroyGatewayProfileGateways = () => ({
  type: DESTROY_GATEWAY_PROFILE_GATEWAYS_SUCCESS,
});

export const destroyGatewayProfileGatewaysMap = () => ({
  type: DESTROY_GATEWAY_PROFILE_GATEWAYS_MAP_SUCCESS,
});
