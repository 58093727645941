import React, { useEffect, useState } from 'react';
import { Button, Confirm, Icon, Loader, Pagination, Select, Table } from 'semantic-ui-react';
import moment from 'moment';
import { scanHistory, deleteScanRecord } from './service';
import './ScanHistoryTable.css';
import PropTypes from 'prop-types';

export const ScanHistoryTable = ({ gid, onSelect }) => {

  const [column, setColumn] = useState('command.cid');
  const [direction, setDirection] = useState('ASC');

  const [activeCid, setActiveCid] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [list, setList] = useState([]);

  useEffect(() => {
    if(gid) {
      fetch(activePage, pageSize);
    }
  },[gid]);

  const fetch = (newActivePage, size, columnName, newDirection) => {
    setLoading(true);
    scanHistory(gid, newActivePage, size, columnName, newDirection).then(response => {
      if (response.data.content.length > 0) {
        if (onSelect && !activeCid) {
          const [first] = response.data.content;
          setActiveCid(first.command.cid);
          onSelect(first);
        }
      } else if(onSelect){
        onSelect(-1);
      }
      setLoading(false);
      if (response.status === 200) {
        setList(response.data.content);
        setTotalPage(response.data.totalPages);
        setActivePage(response.data.currentPage);
        setPageSize(response.data.pageSize);
        setColumn(response.data.sortColumn);
        setDirection(response.data.sortDirection);
      }
    }).catch(err => {
      setLoading(false);
    })
  }

  const onPaginationChange = (e, data) => {
    fetch(data.activePage-1, pageSize)
  }

  const onSortChange = (name) => {
    if(column !== name){
      fetch(activePage, pageSize, name, 'ASC')
    } else {
      fetch(activePage, pageSize, name, direction === 'ASC' ? 'DESC' : 'ASC')
    }
  }

  const getTableSortingHeader = (name) => {
    if(column === name){
      return direction === 'ASC' ? 'ascending' : 'descending';
    }
    return null
  }

  const deleteItem = (id) => {
    deleteScanRecord(id).then(() => {
      setConfirmOpen(null);
      fetch(activePage, pageSize);
    })
  }

  return <div>
    <Table celled sortable selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={getTableSortingHeader('createdAt')}
            onClick={() => onSortChange('createdAt')}
          >Date</Table.HeaderCell>
          <Table.HeaderCell>Start Freq</Table.HeaderCell>
          <Table.HeaderCell>End Freq</Table.HeaderCell>
          <Table.HeaderCell>Step</Table.HeaderCell>
          <Table.HeaderCell>Bandwidth</Table.HeaderCell>
          <Table.HeaderCell>RSSI Point</Table.HeaderCell>
          <Table.HeaderCell>RSSI Offset</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        { list.map((item, index) => (
          <Table.Row key={index} active={item.command.cid === activeCid} onClick={() => {
            if(onSelect) { onSelect(item); }
            setActiveCid(item.command.cid)
          }}>
            <Table.Cell>{moment(item.createdAt).toISOString()}</Table.Cell>
            <Table.Cell>{item.command.freq.start}</Table.Cell>
            <Table.Cell>{item.command.freq.stop}</Table.Cell>
            <Table.Cell>{item.command.freq.step}</Table.Cell>
            <Table.Cell>{item.command.bandwidth}</Table.Cell>
            <Table.Cell>{item.command.rssi_point}</Table.Cell>
            <Table.Cell>{item.command.rssi_offset}</Table.Cell>
            <Table.Cell>
              <Button animated size='mini' className="ui button radius" onClick={() => setConfirmOpen(item.id)}>
                <Button.Content hidden>Delete</Button.Content>
                <Button.Content visible>
                  <Icon name='trash alternate' />
                </Button.Content>
              </Button>
            </Table.Cell>
          </Table.Row>
        )) }
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan='8'>
            <div className="ui grid">
              <div className="four wide column">
                <Select
                  defaultValue={pageSize}
                  onChange={ (event, data) => {
                    fetch(activePage, data.value)
                  }}
                  options={[3,5,10,25,50,75,100].map(a => ({ key: a, value: a, text: a }))}
                />
              </div>
              <div className="twelve wide column right aligned content">

                { isLoading ? <span> <Loader size='mini' active inline /> Loading... </span> : null }

                <Pagination
                  size='mini'
                  activePage={ activePage + 1 }
                  onPageChange={onPaginationChange}
                  totalPages={totalPage}
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                  lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                />
              </div>

            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
    <Confirm
      open={isConfirmOpen != null}
      onCancel={ () => setConfirmOpen(null)}
      onConfirm={ () => deleteItem(isConfirmOpen) }
    />
  </div>
};

ScanHistoryTable.propTypes = {
  gid: PropTypes.string,
  onSelect: PropTypes.func,
};

export default ScanHistoryTable;

