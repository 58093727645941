/** @format */

import * as actions from '../actions/multicastGroup';

const defaultState = {
  filter: {},
  sort: {},
  isLoading: false,
  error: false,
};

export const multicastGroup = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_MULTICAST_GROUP_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.FETCH_MULTICAST_GROUP_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.FETCH_MULTICAST_GROUP_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.POST_MULTICAST_GROUP_REQUEST:
      return {
        isLoading: true,
        error: false,
      };
    case actions.POST_MULTICAST_GROUP_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.POST_MULTICAST_GROUP_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DELETE_MULTICAST_GROUP_REQUEST:
      return {
        isLoadingx: true,
        error: false,
      };
    case actions.DELETE_MULTICAST_GROUP_SUCCESS:
      return {
        ...action.payload,
        isLoading: false,
        error: false,
      };
    case actions.DELETE_MULTICAST_GROUP_FAILURE:
      return {
        ...action.payload,
        isLoading: false,
        error: true,
      };

    case actions.DESTROY_MULTICAST_GROUP_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
